import React from 'react';
export const LocationIcon2 = ({ className = '' }) => {
  return (
    <svg
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.5 2.5C6.01854 2.5 4 4.51328 4 6.98828C4 7.79453 4.38068 8.81172 5.16084 10.0984C5.8094 11.1672 6.61305 12.2289 7.32037 13.1641C7.647 13.5953 7.95483 14.0031 8.19217 14.3406C8.26266 14.4414 8.37781 14.5 8.5 14.5C8.62219 14.5 8.73734 14.4414 8.80783 14.3406C9.04517 14.0031 9.353 13.5953 9.67964 13.1641C10.3869 12.2289 11.1883 11.1672 11.8392 10.0984C12.6193 8.81172 13 7.79453 13 6.98828C13 4.51328 10.9815 2.5 8.5 2.5ZM8.5 9.0625C7.35326 9.0625 6.42037 8.13203 6.42037 6.98828C6.42037 5.84453 7.35326 4.91406 8.5 4.91406C9.64674 4.91406 10.5796 5.84453 10.5796 6.98828C10.5796 8.13203 9.64674 9.0625 8.5 9.0625Z"
        fill="#3E3E3E"
      />
    </svg>
  );
};
