import React from 'react';

export const ChevronDownIconV2 = ({ stroke = '#212121', className = '' }) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.2894 7.13159L9.49992 12.3421L14.7104 7.13159"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
