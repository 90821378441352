import React from 'react';

export const ReportFlagIconV2 = ({ color = '#212121', className = '' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.55 10.4375H12.3507C13.1016 10.4375 13.5846 9.64087 13.2374 8.97511L12.0411 6.68114C11.89 6.39139 11.89 6.04611 12.0411 5.75636L13.2374 3.46239C13.5846 2.79663 13.1016 2 12.3507 2H4C3.44771 2 3 2.44772 3 3V17"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
};
