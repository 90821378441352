import { HyphenIcon } from './HyphenIcon';
import { CrossIconV2 } from './CrossIconV2';
import { AllFiltersIconV2 } from './AllFiltersIconV2';
import { PlusIconV2 } from './PlusIconV2';
import { MinusIconV2 } from './MinusIconV2';
import { BookmarkIconOutlinedV2 } from './BookmarkIconOutlinedV2';
import { BookmarkIconFilledV2 } from './BookmarkIconFilledV2';
import { ChevronLeftIconV2 } from './ChevronLeftIconV2';
import { ChevronRightIconV2 } from './ChevronRightIconV2';
import { DoubleChevronLeftIconV2 } from './DoubleChevronLeftIconV2';
import { DoubleChevronRightIconV2 } from './DoubleChevronRightIconV2';
import { SearchIconV2 } from './SearchIconV2';
import { ArrowTopRightIconV2 } from './ArrowTopRightIconV2';
import { ArrowLeftIconV2 } from './ArrowLeftIconV2';
import { ShareIconV2 } from './ShareIconV2';
import { HeartOutlineIconV2 } from './HeartOutlineIconV2';
import { GalleryIconV2 } from './GalleryIconV2';
import { ShieldCheckIconV2 } from './ShieldCheckIconV2';
import { WhatsAppIconFilledV2 } from './WhatsAppIconFilledV2';
import { CarInGarageIconV2 } from './CarInGarageIconV2';
import { XMarkCircleIconV2 } from './XMarkCircleIconV2';
import { XMarkIconV2 } from './XMarkIconV2';
import { LensXMarkIconV2 } from './LensXMarkIconV2';
import { CheckIconV2 } from './CheckIconV2';
import { CheckBadgeIconV2 } from './CheckBadgeIconV2';
import { PhoneIconV2 } from './PhoneIconV2';
import { HandHoldingPhoneIconV2 } from './HandHoldingPhoneIconV2';
import { KeyIconV2 } from './KeyIconV2';
import { OdomotorIconV2 } from './OdomotorIconV2';
import { WarrantyStatusIconV2 } from './WarrantyStatusIconV2';
import { Vehicle360DegreeIconV2 } from './Vehicle360DegreeIconV2';
import { SoldOutIconV2 } from './SoldOutIconV2';
import { ThreeDotsIconV2 } from './ThreeDotsIconV2';
import { ReportFlagIconV2 } from './ReportFlagIconV2';
import { ChevronLeftAltIconV2 } from './ChevronLeftAltIconV2';
import { CircularArrowIconV2 } from './CircularArrowIconV2';
import { ArrowUpDownIconV2 } from './ArrowUpDownIconV2';
import { ChevronDownIconV2 } from './ChevronDownIconV2';

// Specification Icons imports
import { BootSpaceIconV2 } from './vehicleSpecificationIcons/BootSpaceIconV2';
import { CylindersIconV2 } from './vehicleSpecificationIcons/CylindersIconV2';
import { DisplacementIconV2 } from './vehicleSpecificationIcons/DisplacementIconV2';
import { FuelCapacityIconV2 } from './vehicleSpecificationIcons/FuelCapacityIconV2';
import { GroundClearanceIconV2 } from './vehicleSpecificationIcons/GroundClearanceIconV2';
import { SeatingIconV2 } from './vehicleSpecificationIcons/SeatingIconV2';
import { GenericSpecificationIconV2 } from './vehicleSpecificationIcons/GenericSpecificationIconV2';
import { CheckMarkIconAltV2 } from './CheckMarkIconAltV2';

// Nissan Tracking Icons
import CalendarIconV2 from './CalendarIconV2';
import ShippingTruckIconV2 from './ShippingTruckIconV2';
import PdiProcessIconV2 from './PdiProcessIconV2';
import ShowroomIconV2 from './ShowroomIconV2';
import RegistratioIconV2 from './RegistratioIconV2';
import DeliveredIconV2 from './DeliveredIconV2';
import { ChatIconV2 } from './ChatIconV2';

export {
  HyphenIcon,
  CrossIconV2,
  AllFiltersIconV2,
  PlusIconV2,
  MinusIconV2,
  BookmarkIconFilledV2,
  BookmarkIconOutlinedV2,
  ChevronLeftIconV2,
  ChevronRightIconV2,
  DoubleChevronLeftIconV2,
  DoubleChevronRightIconV2,
  CheckBadgeIconV2,
  PhoneIconV2,
  HandHoldingPhoneIconV2,
  SearchIconV2,
  ArrowTopRightIconV2,
  ArrowLeftIconV2,
  ShareIconV2,
  HeartOutlineIconV2,
  GalleryIconV2,
  ShieldCheckIconV2,
  WhatsAppIconFilledV2,
  CarInGarageIconV2,
  LensXMarkIconV2,
  XMarkCircleIconV2,
  XMarkIconV2,
  CheckIconV2,
  KeyIconV2,
  OdomotorIconV2,
  WarrantyStatusIconV2,
  Vehicle360DegreeIconV2,
  SoldOutIconV2,
  ReportFlagIconV2,
  ThreeDotsIconV2,
  ChevronLeftAltIconV2,
  CircularArrowIconV2,
  ArrowUpDownIconV2,
  ChevronDownIconV2,
};

// Specification icons export
export {
  BootSpaceIconV2,
  CylindersIconV2,
  DisplacementIconV2,
  FuelCapacityIconV2,
  GenericSpecificationIconV2,
  GroundClearanceIconV2,
  SeatingIconV2,
  CheckMarkIconAltV2,
};

// Nissan Tracking Icons export
export {
  CalendarIconV2,
  ShippingTruckIconV2,
  PdiProcessIconV2,
  ShowroomIconV2,
  RegistratioIconV2,
  DeliveredIconV2,
};
