export const DefaultFuelTypeIcon = ({ className = '' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 110.000000 137.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,137.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M138 1359 c-58 -22 -58 -21 -58 -662 0 -391 4 -594 11 -615 21 -61
29 -62 347 -62 l288 0 35 31 34 30 3 150 3 149 39 0 39 0 3 -159 c3 -152 4
-161 27 -185 31 -33 70 -41 115 -22 61 26 66 50 66 330 l0 245 -32 23 c-18 12
-44 33 -58 45 -14 12 -35 30 -47 41 -21 18 -23 28 -23 116 l0 97 -56 67 c-32
37 -59 69 -60 71 -2 2 -4 -26 -4 -62 0 -58 2 -67 18 -67 35 0 42 -21 42 -131
l0 -107 38 -28 c20 -16 46 -37 57 -47 11 -11 30 -26 43 -34 22 -15 22 -18 22
-240 0 -135 -4 -232 -10 -244 -12 -21 -50 -25 -68 -7 -8 8 -12 64 -12 185 l0
173 -70 0 -69 0 -3 433 -3 433 -28 29 -27 30 -288 2 c-171 1 -298 -2 -314 -8z
m562 -289 l0 -170 -260 0 -260 0 0 170 0 170 260 0 260 0 0 -170z"
        />
      </g>
    </svg>
  );
};
