export const SuppliedByIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5307 17.3577V11.218C3.58775 11.2149 3.63709 11.2108 3.68643 11.2108C4.43014 11.2108 5.17385 11.2129 5.91756 11.2088C6.13651 11.2078 6.31434 11.2796 6.46699 11.4361C6.70804 11.6839 6.96862 11.9142 7.19682 12.1733C7.3762 12.377 7.56894 12.4519 7.84391 12.4468C8.76031 12.4283 9.67723 12.4339 10.5941 12.4432C11.3214 12.4504 11.7619 13.2086 11.4 13.8228C11.2202 14.1275 10.9431 14.2819 10.5875 14.2825C9.81189 14.284 9.03631 14.2825 8.26022 14.2835C8.20266 14.2835 8.14304 14.2778 8.08753 14.2891C7.92306 14.3225 7.82541 14.421 7.82746 14.5949C7.82952 14.7683 7.92974 14.8637 8.09472 14.894C8.13841 14.9022 8.18415 14.8976 8.22887 14.8976C9.00445 14.8976 9.78002 14.8991 10.5561 14.8976C11.3363 14.8956 11.9253 14.422 12.1067 13.6647C12.1222 13.6011 12.1761 13.5273 12.2332 13.4965C13.5448 12.7869 14.856 12.0769 16.1738 11.3796C16.8589 11.0169 17.6931 11.2575 18.0903 11.9081C18.4907 12.5643 18.3088 13.4072 17.6473 13.8541C16.7031 14.4918 15.7467 15.1126 14.7968 15.7421C13.7293 16.4496 12.6618 17.1566 11.5969 17.8677C10.4991 18.6003 9.30666 18.7639 8.05104 18.413C7.00409 18.1201 5.97256 17.7727 4.93023 17.4618C4.72002 17.3992 4.49387 17.3772 4.27338 17.3618C4.03233 17.3449 3.78871 17.3582 3.53121 17.3582L3.5307 17.3577Z"
        fill="#43474D"
      />
      <path
        d="M1.07144 17.9721V10.6109C1.11769 10.6063 1.16601 10.5975 1.21432 10.5975C1.52835 10.5965 1.84239 10.597 2.15642 10.597C2.68118 10.597 2.90835 10.8212 2.90835 11.3399C2.90835 13.3049 2.90835 15.2694 2.90835 17.2343C2.90835 17.2662 2.90835 17.2985 2.90835 17.3303C2.90064 17.713 2.64931 17.968 2.26692 17.9716C1.87117 17.9757 1.47541 17.9726 1.07092 17.9726L1.07144 17.9721Z"
        fill="#43474D"
      />
      <path
        d="M3.01056 5.11149C3.48598 4.40656 3.93005 3.71907 4.41163 3.05878C4.51545 2.91666 4.75445 2.80276 4.93382 2.79815C5.98231 2.77403 7.03183 2.78635 8.09934 2.78635V5.11149H3.01056Z"
        fill="#43474D"
      />
      <path
        d="M16.723 2.87757C16.723 2.31373 16.4094 2.00128 15.8466 2.00077C13.8257 1.99974 11.8043 1.99974 9.78338 2.00077C9.20209 2.00077 8.89936 2.30706 8.89885 2.89245C8.89782 4.32437 8.89885 5.75629 8.89885 7.18821V7.45192H8.10374V5.92047H2.63822C2.63822 6.68902 2.63565 7.44423 2.63925 8.19892C2.6413 8.71095 2.96664 9.02647 3.48678 9.03109C3.72115 9.03314 3.95551 9.03109 4.18577 9.03109C4.32814 10.0218 4.94233 10.6072 5.80168 10.5923C6.62866 10.5779 7.23205 9.97766 7.34358 9.04597H12.0176C12.123 9.93765 12.6775 10.5261 13.4629 10.5877C13.8334 10.6169 14.1742 10.5246 14.48 10.3142C14.9256 10.0079 15.1271 9.56312 15.1713 9.03058C15.4118 9.03058 15.6308 9.0316 15.8502 9.03058C16.4125 9.02801 16.7224 8.7171 16.7224 8.14916C16.7235 6.39196 16.7235 4.63425 16.7224 2.87706L16.723 2.87757ZM5.75697 9.81144C5.33295 9.80579 4.98396 9.44974 4.98653 9.02596C4.9891 8.60321 5.34374 8.25074 5.76725 8.25023C6.19178 8.25023 6.54591 8.60013 6.5495 9.02339C6.5531 9.45641 6.19024 9.81708 5.75697 9.81092V9.81144ZM13.5944 9.81144C13.1607 9.81195 12.8029 9.44768 12.8117 9.01416C12.8204 8.59192 13.1807 8.2451 13.6047 8.25074C14.0287 8.25638 14.3777 8.61244 14.3752 9.03622C14.3726 9.45948 14.0185 9.81092 13.5944 9.81144Z"
        fill="#43474D"
      />
    </svg>
  );
};
