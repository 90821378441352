export const TransmissionIcon = ({ className = 'w-[0.625rem]' }) => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.578038 6.75628L5.63933 7.23652L2.59006 11.2345C2.45259 11.4266 2.46509 11.6907 2.64004 11.8588C2.8275 12.0389 3.13992 12.0509 3.33988 11.8708L9.78833 6.31206C10.2007 5.95189 9.97579 5.29156 9.41342 5.24354L4.35213 4.7633L7.4014 0.765342C7.53887 0.573247 7.52637 0.309118 7.35142 0.141035C7.25884 0.0528091 7.13439 0.00231431 7.00401 7.75936e-05C6.87363 -0.00215912 6.74738 0.0440351 6.65158 0.12903L0.203127 5.68776C-0.196777 6.04793 0.0281692 6.70826 0.578038 6.75628Z"
        fill="#929597"
      />
    </svg>
  );
};
