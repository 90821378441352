export const CartNewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M7.33366 20.1663C7.83992 20.1663 8.25033 19.7559 8.25033 19.2497C8.25033 18.7434 7.83992 18.333 7.33366 18.333C6.8274 18.333 6.41699 18.7434 6.41699 19.2497C6.41699 19.7559 6.8274 20.1663 7.33366 20.1663Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4167 20.1668C17.9229 20.1668 18.3333 19.7564 18.3333 19.2502C18.3333 18.7439 17.9229 18.3335 17.4167 18.3335C16.9104 18.3335 16.5 18.7439 16.5 19.2502C16.5 19.7564 16.9104 20.1668 17.4167 20.1668Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.87891 1.87891H3.71224L6.15057 13.2639C6.24002 13.6809 6.47202 14.0536 6.80664 14.3179C7.14126 14.5823 7.55757 14.7217 7.98391 14.7122H16.9489C17.3662 14.7116 17.7707 14.5686 18.0957 14.3069C18.4207 14.0453 18.6467 13.6806 18.7364 13.2731L20.2489 6.46224H4.69307"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
