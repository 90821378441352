import React from 'react';

const RegistratioIconV2 = ({ fill = '#212121', className = '' }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.9667 7.91968C10.1628 7.91968 9.39213 8.2388 8.82325 8.80645C8.25443 9.37469 7.93415 10.1448 7.93299 10.9487C7.93182 11.7527 8.24978 12.5239 8.81685 13.0933C9.38392 13.6633 10.1534 13.9847 10.9573 13.9871C11.7613 13.9894 12.5332 13.6732 13.1037 13.1067C13.6742 12.5408 13.9969 11.7713 14.0004 10.9674C14.0039 10.1606 13.6859 9.38517 13.1165 8.81345C12.5477 8.24114 11.7737 7.91968 10.9667 7.91968ZM12.3086 10.5333L10.9667 11.8798C10.8302 12.0163 10.6085 12.0163 10.472 11.8798L9.61558 11.0374C9.47906 10.9003 9.47964 10.678 9.61674 10.5415C9.75384 10.405 9.97611 10.4056 10.1126 10.5427L10.7077 11.1377L11.8068 10.0339C11.9439 9.89859 12.1645 9.89859 12.3016 10.0339C12.3692 10.0993 12.4072 10.1897 12.4072 10.2836C12.4072 10.3776 12.3692 10.468 12.3016 10.5333L12.3086 10.5333Z"
        fill={fill}
      />
      <path
        d="M7.45313 10.9673C7.44847 10.0968 7.76876 9.25614 8.35099 8.60855C8.93264 7.96156 9.7354 7.55434 10.6011 7.46684C10.7796 7.44875 10.9156 7.29882 10.9162 7.11913V1.51608C10.915 0.67889 10.2365 0.000462387 9.39934 -0.000732422H1.51644C0.679256 0.000434309 0.000828598 0.678935 -0.000366211 1.51608V12.4841C0.00080052 13.3213 0.679301 13.9997 1.51644 14.0009H8.19991C8.34051 14.0009 8.46769 13.9163 8.52253 13.7862C8.57679 13.6561 8.5482 13.5062 8.44961 13.4059C7.81137 12.7548 7.45313 11.879 7.45313 10.9673ZM2.53859 2.1182H8.37254C8.56565 2.1182 8.72259 2.27514 8.72259 2.46825C8.72259 2.66136 8.56565 2.8183 8.37254 2.8183H2.53859C2.34548 2.8183 2.18854 2.66136 2.18854 2.46825C2.18854 2.27514 2.34548 2.1182 2.53859 2.1182ZM2.53859 3.91504H5.80564C5.99875 3.91504 6.15569 4.07198 6.15569 4.26509C6.15569 4.4582 5.99875 4.61514 5.80564 4.61514H2.53859C2.34548 4.61514 2.18854 4.4582 2.18854 4.26509C2.18854 4.07198 2.34548 3.91504 2.53859 3.91504ZM2.53859 5.70965H8.37254C8.56565 5.70965 8.72259 5.86658 8.72259 6.0597C8.72259 6.25281 8.56565 6.40974 8.37254 6.40974H2.53859C2.34548 6.40974 2.18854 6.25281 2.18854 6.0597C2.18854 5.86658 2.34548 5.70965 2.53859 5.70965ZM5.80564 8.20426H2.53859C2.34548 8.20426 2.18854 8.04732 2.18854 7.85421C2.18854 7.6611 2.34548 7.50416 2.53859 7.50416H5.80564C5.99875 7.50416 6.15569 7.6611 6.15569 7.85421C6.15569 8.04732 5.99875 8.20426 5.80564 8.20426Z"
        fill={fill}
      />
    </svg>
  );
};

export default RegistratioIconV2;
