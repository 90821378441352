export const CrossRedIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="42" cy="42" r="40" stroke="#DE2A00" strokeWidth="4" />
      <path
        d="M27.5836 23.2072C26.3751 21.9987 24.4157 21.9987 23.2072 23.2072C21.9987 24.4157 21.9987 26.3751 23.2072 27.5836L37.6244 42.0008L23.2072 56.418C21.9987 57.6265 21.9987 59.5859 23.2072 60.7944C24.4157 62.0029 26.3751 62.0029 27.5836 60.7944L42.0008 46.3772L56.418 60.7944C57.6265 62.0029 59.5859 62.0029 60.7944 60.7944C62.0029 59.5859 62.0029 57.6265 60.7944 56.418L46.3772 42.0008L60.7944 27.5836C62.0029 26.3751 62.0029 24.4157 60.7944 23.2072C59.5859 21.9987 57.6265 21.9987 56.418 23.2072L42.0008 37.6244L27.5836 23.2072Z"
        fill="#DE2A00"
        stroke="#DE2A00"
        strokeLinecap="round"
      />
    </svg>
  );
};
