import React from 'react';

export const LensXMarkIconV2 = ({ stroke = '#B2B2B2' }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.5 32.5L30.5 61.5"
        stroke={stroke}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5 32.5L59.5 61.5"
        stroke={stroke}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.6">
        <path
          d="M45.8333 79.1667C64.2428 79.1667 79.1667 64.2428 79.1667 45.8333C79.1667 27.4238 64.2428 12.5 45.8333 12.5C27.4238 12.5 12.5 27.4238 12.5 45.8333C12.5 64.2428 27.4238 79.1667 45.8333 79.1667Z"
          stroke={stroke}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M87.4967 87.5002L69.5801 69.5835"
          stroke={stroke}
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
