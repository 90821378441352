import React from 'react';

export const FinanceRequestIcon = ({ className = '' }) => {
  return (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M30.3207 0.5H6.21764C5.34021 0.5 4.62891 1.2113 4.62891 2.08873V15.7064C4.62891 16.5839 5.34021 17.2952 6.21764 17.2952H30.3207C31.1982 17.2952 31.9095 16.5839 31.9095 15.7064V2.08873C31.9095 1.2113 31.1982 0.5 30.3207 0.5Z"
        fill="#4C0055"
      />
      <path d="M30.72 1.88281H5.82031V15.9298H30.72V1.88281Z" fill="white" />
      <mask
        id="mask0_7095_108598"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="28"
        height="18"
      >
        <path
          d="M30.3168 0.492188H6.21373C5.3363 0.492188 4.625 1.20349 4.625 2.08092V15.6986C4.625 16.5761 5.3363 17.2874 6.21373 17.2874H30.3168C31.1942 17.2874 31.9055 16.5761 31.9055 15.6986V2.08092C31.9055 1.20349 31.1942 0.492188 30.3168 0.492188Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_7095_108598)">
        <path
          d="M30.1893 18.9099C31.9507 18.9099 33.3786 17.482 33.3786 15.7206C33.3786 13.9592 31.9507 12.5312 30.1893 12.5312C28.4279 12.5312 27 13.9592 27 15.7206C27 17.482 28.4279 18.9099 30.1893 18.9099Z"
          fill="#4C0055"
        />
        <path
          d="M5.869 18.9099C7.63041 18.9099 9.05832 17.482 9.05832 15.7206C9.05832 13.9592 7.63041 12.5312 5.869 12.5312C4.10759 12.5312 2.67969 13.9592 2.67969 15.7206C2.67969 17.482 4.10759 18.9099 5.869 18.9099Z"
          fill="#4C0055"
        />
        <path
          d="M30.1893 5.08957C31.9507 5.08957 33.3786 3.66166 33.3786 1.90025C33.3786 0.138843 31.9507 -1.28906 30.1893 -1.28906C28.4279 -1.28906 27 0.138843 27 1.90025C27 3.66166 28.4279 5.08957 30.1893 5.08957Z"
          fill="#4C0055"
        />
        <path
          d="M5.869 5.08957C7.63041 5.08957 9.05832 3.66166 9.05832 1.90025C9.05832 0.138843 7.63041 -1.28906 5.869 -1.28906C4.10759 -1.28906 2.67969 0.138843 2.67969 1.90025C2.67969 3.66166 4.10759 5.08957 5.869 5.08957Z"
          fill="#4C0055"
        />
      </g>
      <path
        d="M26.266 4.32031H2.16295C1.28552 4.32031 0.574219 5.03161 0.574219 5.90905V19.5268C0.574219 20.4042 1.28552 21.1155 2.16295 21.1155H26.266C27.1435 21.1155 27.8548 20.4042 27.8548 19.5268V5.90905C27.8548 5.03161 27.1435 4.32031 26.266 4.32031Z"
        fill="#4C0055"
      />
      <path
        d="M26.6693 5.69531H1.76953V19.7423H26.6693V5.69531Z"
        fill="white"
      />
      <path
        d="M14.2197 18.3534C17.329 18.3534 19.8495 15.8329 19.8495 12.7236C19.8495 9.61432 17.329 7.09375 14.2197 7.09375C11.1104 7.09375 8.58984 9.61432 8.58984 12.7236C8.58984 15.8329 11.1104 18.3534 14.2197 18.3534Z"
        fill="#4C0055"
      />
      <path
        d="M13.1706 11.16C13.1706 11.7894 13.1747 12.4188 13.1697 13.0482C13.1624 13.967 12.5531 14.6775 11.6493 14.8311C10.6193 15.0061 9.59618 14.131 9.6135 13.0901C9.6176 12.839 9.80172 12.6453 10.0392 12.6421C10.2748 12.6385 10.4639 12.8126 10.4712 13.0641C10.4917 13.7751 11.2009 14.1853 11.7892 13.8968C12.1379 13.7259 12.3088 13.4333 12.3092 13.0441C12.3097 12.3764 12.3092 11.7092 12.3092 11.0415C12.3092 10.4076 12.3083 9.7732 12.3097 9.13925C12.3102 8.88495 12.4455 8.70447 12.6579 8.66436C12.93 8.61332 13.1642 8.80838 13.1688 9.10097C13.1742 9.48243 13.1706 9.86389 13.1706 10.2453C13.1706 10.5507 13.1706 10.8556 13.1706 11.1609V11.16Z"
        fill="white"
      />
      <path
        d="M13.861 11.4039C13.861 11.0224 13.8564 10.6409 13.8628 10.2595C13.8669 10.0312 14.0227 9.85934 14.2297 9.832C14.4315 9.80511 14.6307 9.92041 14.6918 10.1123C14.7159 10.1875 14.7205 10.2718 14.7209 10.3515C14.7232 11.0333 14.7196 11.7151 14.7232 12.3974C14.7255 12.7839 15.055 13.0213 15.4027 12.8928C15.5714 12.8304 15.6776 12.7087 15.7127 12.53C15.7209 12.4881 15.7245 12.4452 15.7295 12.4024C15.7591 12.1572 15.9528 11.9904 16.1875 12.0077C16.414 12.0246 16.5868 12.2174 16.5863 12.4521C16.584 13.1503 16.0025 13.7546 15.307 13.781C14.55 13.8098 13.9184 13.2834 13.8742 12.5619C13.8505 12.1773 13.8701 11.7899 13.8701 11.4039C13.8673 11.4039 13.8641 11.4039 13.8614 11.4039H13.861Z"
        fill="white"
      />
      <path
        d="M16.1329 16.8064C16.003 16.7941 15.8699 16.7859 15.7378 16.7686C15.4784 16.7353 15.3057 16.522 15.3403 16.285C15.3768 16.0371 15.5983 15.8831 15.8649 15.9204C16.7463 16.0448 17.5885 15.5248 17.8675 14.6799C18.016 14.231 17.9837 13.7907 17.8041 13.3541C17.7626 13.2534 17.7417 13.1313 17.7554 13.0246C17.7777 12.8482 17.9381 12.7179 18.1081 12.6988C18.3127 12.676 18.48 12.7667 18.5675 12.964C18.9312 13.7821 18.9253 14.5983 18.4923 15.3836C17.9855 16.3019 17.1811 16.7576 16.1329 16.8055V16.8064Z"
        fill="white"
      />
      <path
        d="M16.0027 15.2796C15.7657 15.2773 15.5811 15.0891 15.582 14.8498C15.5829 14.6133 15.7716 14.4228 16.0068 14.4219C16.2433 14.4205 16.4443 14.6251 16.4384 14.8617C16.4325 15.0955 16.2383 15.2823 16.0027 15.2801V15.2796Z"
        fill="white"
      />
      <path
        d="M14.2803 15.2796C14.0452 15.2755 13.8597 15.0827 13.8633 14.8457C13.867 14.6106 14.0598 14.4205 14.2936 14.4219C14.5324 14.4232 14.7252 14.6251 14.7179 14.8658C14.7106 15.1028 14.5196 15.2837 14.2803 15.2796Z"
        fill="white"
      />
      <mask
        id="mask1_7095_108598"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="4"
        width="28"
        height="18"
      >
        <path
          d="M26.2621 4.32812H2.15905C1.28161 4.32812 0.570312 5.03943 0.570312 5.91686V19.5346C0.570312 20.412 1.28161 21.1233 2.15905 21.1233H26.2621C27.1396 21.1233 27.8509 20.412 27.8509 19.5346V5.91686C27.8509 5.03943 27.1396 4.32812 26.2621 4.32812Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_7095_108598)">
        <path
          d="M26.1463 22.738C27.9078 22.738 29.3357 21.3101 29.3357 19.5487C29.3357 17.7873 27.9078 16.3594 26.1463 16.3594C24.3849 16.3594 22.957 17.7873 22.957 19.5487C22.957 21.3101 24.3849 22.738 26.1463 22.738Z"
          fill="#4C0055"
        />
        <path
          d="M1.82604 22.738C3.58745 22.738 5.01535 21.3101 5.01535 19.5487C5.01535 17.7873 3.58745 16.3594 1.82604 16.3594C0.0646243 16.3594 -1.36328 17.7873 -1.36328 19.5487C-1.36328 21.3101 0.0646243 22.738 1.82604 22.738Z"
          fill="#4C0055"
        />
        <path
          d="M26.1463 8.91769C27.9078 8.91769 29.3357 7.48979 29.3357 5.72838C29.3357 3.96697 27.9078 2.53906 26.1463 2.53906C24.3849 2.53906 22.957 3.96697 22.957 5.72838C22.957 7.48979 24.3849 8.91769 26.1463 8.91769Z"
          fill="#4C0055"
        />
        <path
          d="M1.82604 8.91769C3.58745 8.91769 5.01535 7.48979 5.01535 5.72838C5.01535 3.96697 3.58745 2.53906 1.82604 2.53906C0.0646243 2.53906 -1.36328 3.96697 -1.36328 5.72838C-1.36328 7.48979 0.0646243 8.91769 1.82604 8.91769Z"
          fill="#4C0055"
        />
      </g>
    </svg>
  );
};
