import React from 'react';

export const ChatBotIcon = ({ className = '' }) => {
  return (
    <svg
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="48" cy="48" r="48" fill="url(#paint0_linear_2326_468)" />
      <mask id="path-2-inside-1_2326_468" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.5125 60.4661C70.7254 56.8328 72 52.5652 72 48C72 34.7452 61.2548 24 48 24C34.7452 24 24 34.7452 24 48C24 61.2548 34.7452 72 48 72C52.6258 72 56.946 70.6913 60.6104 68.4239L71.288 71.449L68.5125 60.4661Z"
        />
      </mask>
      <path
        d="M68.5125 60.4661L66.8044 59.4258L66.3679 60.1425L66.5735 60.9561L68.5125 60.4661ZM60.6104 68.4239L61.1556 66.4996L60.3076 66.2594L59.5581 66.7232L60.6104 68.4239ZM71.288 71.449L70.7428 73.3732L74.0757 74.3175L73.227 70.959L71.288 71.449ZM70 48C70 52.188 68.8317 56.097 66.8044 59.4258L70.2207 61.5064C72.619 57.5685 74 52.9425 74 48H70ZM48 26C60.1503 26 70 35.8497 70 48H74C74 33.6406 62.3594 22 48 22V26ZM26 48C26 35.8497 35.8497 26 48 26V22C33.6406 22 22 33.6406 22 48H26ZM48 70C35.8497 70 26 60.1503 26 48H22C22 62.3594 33.6406 74 48 74V70ZM59.5581 66.7232C56.2007 68.8006 52.2435 70 48 70V74C53.0082 74 57.6913 72.582 61.6628 70.1247L59.5581 66.7232ZM71.8331 69.5247L61.1556 66.4996L60.0653 70.3482L70.7428 73.3732L71.8331 69.5247ZM66.5735 60.9561L69.3489 71.939L73.227 70.959L70.4516 59.9761L66.5735 60.9561Z"
        fill="white"
        mask="url(#path-2-inside-1_2326_468)"
      />
      <line
        x1="36.9995"
        y1="45"
        x2="55.9995"
        y2="45"
        stroke="white"
        strokeWidth="2"
      />
      <line
        x1="36.9995"
        y1="53"
        x2="49.9995"
        y2="53"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2326_468"
          x1="48"
          y1="0"
          x2="48"
          y2="96"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
