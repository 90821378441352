export const VerifiedIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <path
        fill="#4c0055"
        d="M39.6,0c.3,.2,.7,.2,1,.3,2.8,.9,4.6,3.3,4.7,6.3,0,5.3,0,10.7,0,16,0,.9-.5,1.5-1.3,1.5-.8,0-1.4-.6-1.4-1.6,0-5.2,0-10.4,0-15.7,0-2-1.1-3.5-2.9-4-.5-.1-.9-.2-1.4-.2-10.5,0-20.9,0-31.4,0-2.6,0-4.3,1.7-4.3,4.3,0,14,0,28,0,42,0,2.7,1.7,4.3,4.4,4.3,6,0,12,0,17.9,0,.5,0,.9,0,1.3,.4,.4,.4,.5,.9,.3,1.4-.2,.5-.5,.8-1,.9-.1,0-.3,0-.4,0-6.1,0-12.3,0-18.4,0-3.2,0-5.8-2.1-6.5-5.2,0-.1,0-.3,0-.4-.1-.2,0-.4-.1-.6V6.3c.1-.2,0-.4,0-.6,0-1.9,1.1-3.3,2.6-4.4C3.5,.5,4.6,0,5.8,0H39.6Z"
      />
      <path fill="#62206a" d="M64,46.4c-.2-.2-.2-.5,0-.7v.7Z" />
      <path fill="#5e1966" d="M45.7,64c.2-.2,.5-.2,.7,0h-.7Z" />
      <path fill="#86548c" d="M0,5.6s0,0,.1,0c0,.2,0,.5-.1,.6v-.6Z" />
      <path fill="#86548c" d="M0,49.7c.2,.2,.1,.4,.1,.6,0,0,0,0-.1,0v-.6Z" />
      <path fill="#5f1b67" d="M46.9,64c.2-.2,.4-.2,.6,0h-.6Z" />
      <path fill="#906296" d="M64,48c-.2,0-.1-.2,0-.2v.2Z" />
      <path
        fill="#4c0055"
        d="M22.7,21.3c1.3,0,2.6,0,3.9,0,3.9,0,6.7,2.8,6.8,6.7,0,.8,0,1.7,0,2.5,0,.9-.6,1.5-1.3,1.5-.8,0-1.3-.6-1.3-1.5,0-.9,0-1.8,0-2.7,0-2.1-1.8-3.8-3.9-3.8-2.7,0-5.5,0-8.2,0-2.2,0-3.9,1.8-3.9,4,0,.8,0,1.7,0,2.5,0,.9-.5,1.4-1.3,1.5-.8,0-1.4-.6-1.3-1.5,0-1.4-.1-2.9,.2-4.3,.7-2.7,3.3-4.8,6.1-4.9,1.4,0,2.9,0,4.3,0,0,0,0,0,0,0Z"
      />
      <path
        fill="#4c0055"
        d="M17.4,37.3c2.6,0,5.1,0,7.7,0,.4,0,.9,0,1.2,.4,.4,.4,.5,.8,.3,1.3-.1,.5-.5,.8-1.1,.9-.2,0-.3,0-.5,0-5.1,0-10.2,0-15.4,0-.1,0-.3,0-.4,0-.7,0-1.3-.6-1.3-1.3,0-.7,.6-1.3,1.3-1.3,1.9,0,3.7,0,5.6,0,.8,0,1.6,0,2.4,0Z"
      />
      <path
        fill="#4c0055"
        d="M16,45.3c2.2,0,4.4,0,6.6,0,.9,0,1.4,.5,1.4,1.3,0,.8-.6,1.3-1.5,1.3-1.9,0-3.7,0-5.6,0-2.5,0-4.9,0-7.4,0-.6,0-1.1-.2-1.4-.8-.2-.5-.1-1,.2-1.4,.3-.4,.8-.5,1.2-.5,2.1,0,4.3,0,6.4,0Z"
      />
      <path
        fill="#4c0055"
        d="M64,44.9c-.2-.3-.2-.7-.2-1.1-1.5-9.5-11.1-16.2-20.5-14.1-9,1.9-14.8,10.1-13.8,19.2,.8,7.1,6.2,13.1,13.2,14.7,.7,.2,1.4,.2,2.1,.5h1c.2-.1,.5-.1,.7,0h.4c.2-.1,.4-.1,.6,0h.9c.7-.1,1.4,0,2.1-.3,5.3-1.3,9.2-4.3,11.8-9.1,1-1.9,1.6-3.9,1.8-6v-.6c0,0,0-.2,0-.2v-1.4c-.1-.2-.1-.5,0-.7v-.7Zm-17.7,16.4c-8.3-.3-14.6-7.1-14.3-15.4,.3-8,7.2-14.2,15.4-13.9,7.8,.3,14.3,7.2,13.9,15-.3,8.2-7.1,14.6-15,14.3Z"
      />
      <path
        fill="#4c0055"
        d="M22.7,8c-2.9,0-5.3,2.3-5.4,5.3,0,3,2.3,5.3,5.2,5.4,2.9,0,5.4-2.3,5.4-5.2,0-3-2.3-5.4-5.3-5.4Zm0,8c-1.5,0-2.6-1.2-2.6-2.7,0-1.5,1.2-2.7,2.6-2.7,1.5,0,2.7,1.2,2.7,2.7,0,1.5-1.2,2.6-2.7,2.6Z"
      />
      <path
        fill="#4c0055"
        d="M37.3,46.8c0-.7,.2-1.1,.7-1.3,.5-.3,1.1-.2,1.5,.2,.7,.7,1.5,1.5,2.2,2.2,.6,.6,1.2,1.1,1.7,1.7,.3,.3,.5,.4,.8,0,2.4-2.8,4.9-5.6,7.4-8.5,.2-.2,.4-.4,.5-.6,.6-.7,1.4-.7,2-.2,.6,.5,.6,1.3,0,2-.8,.9-1.5,1.8-2.3,2.6-2.2,2.6-4.5,5.1-6.7,7.7-.8,.9-1.5,.9-2.4,0-1.6-1.6-3.3-3.3-4.9-4.9-.3-.3-.6-.6-.6-1Z"
      />
    </svg>
  );
};
