export const PrevIconWithBorder = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="white" stroke="#272828" />
      <path
        d="M22.8372 15.1641H11.5641L14.9571 11.6355C15.0183 11.574 15.0671 11.5004 15.1007 11.4191C15.1343 11.3377 15.152 11.2502 15.1527 11.1617C15.1535 11.0732 15.1373 10.9854 15.105 10.9035C15.0728 10.8215 15.0252 10.7471 14.965 10.6845C14.9048 10.6219 14.8333 10.5724 14.7545 10.5389C14.6757 10.5054 14.5913 10.4885 14.5062 10.4893C14.4211 10.4901 14.3369 10.5084 14.2587 10.5434C14.1805 10.5783 14.1098 10.6291 14.0507 10.6928L9.56348 15.3595C9.4433 15.4845 9.37579 15.654 9.37579 15.8308C9.37579 16.0076 9.4433 16.1771 9.56348 16.3021L14.0507 20.9688C14.1716 21.0902 14.3335 21.1574 14.5016 21.1559C14.6696 21.1544 14.8304 21.0843 14.9493 20.9607C15.0681 20.8371 15.1355 20.6699 15.137 20.4951C15.1384 20.3203 15.0738 20.1519 14.9571 20.0261L11.5641 16.4975H22.8372C23.0072 16.4975 23.1703 16.4272 23.2905 16.3022C23.4107 16.1772 23.4782 16.0076 23.4782 15.8308C23.4782 15.654 23.4107 15.4844 23.2905 15.3594C23.1703 15.2344 23.0072 15.1641 22.8372 15.1641Z"
        fill="#272828"
        stroke="#272828"
        strokeWidth="0.5"
      />
    </svg>
  );
};
