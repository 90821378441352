export const BestSellerIcon = ({ className = '' }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <path
        fill="#4c0055"
        d="M40.8,53.7c1.1,1.8,2.1,3.6,3.2,5.4,1-1.5,2-2.9,2.9-4.4,.4-.6,.8-.8,1.5-.7,1.5,.2,3,.4,4.6,.7-.5-.8-.9-1.5-1.3-2.2-.7-1.2-1.4-2.4-2.2-3.6-.2-.3-.2-.4,.2-.6,1.3-.8,1.3-.8,2.1,.5,1.3,2.1,2.5,4.3,3.8,6.4,.3,.4,.4,.9,.1,1.3-.3,.5-.8,.5-1.3,.4-1.9-.3-3.7-.5-5.6-.8-.4,0-.6,0-.8,.4-1,1.6-2.1,3.1-3.1,4.7-.7,1.1-1.5,1.1-2.2,0-1.4-2.3-2.7-4.6-4.1-6.9-.2-.4-.4-.5-.8-.3-.6,.2-1.2,.5-1.8,.9-2.8,1.7-5.7,1.7-8.5,0-.6-.4-1.2-.6-1.8-.8-.4-.1-.5,0-.7,.3-1.4,2.3-2.7,4.7-4.1,7-.7,1.1-1.4,1.2-2.2,0-1-1.6-2.1-3.1-3.1-4.7-.2-.3-.4-.4-.8-.4-1.9,.3-3.7,.5-5.6,.8-.5,0-.9,0-1.2-.4-.3-.4-.2-.8,0-1.2,1.6-2.8,3.3-5.6,4.9-8.3,.2-.4,.2-.5-.2-.8-2.5-1.6-3.8-4-3.6-7,0-1.4-.2-2.7-1-3.9-1.8-2.9-1.8-5.8,0-8.7,.7-1.2,1.1-2.5,1-3.9-.1-3.3,1.3-5.7,4.2-7.3,1.3-.7,2.3-1.7,3-3,1.6-2.9,4-4.3,7.3-4.2,1.4,0,2.7-.3,3.9-1,2.9-1.8,5.8-1.8,8.7,0,1.2,.8,2.5,1.1,3.9,1,3.2-.1,5.7,1.3,7.2,4.1,.7,1.4,1.7,2.4,3.1,3.1,2.8,1.5,4.2,4,4.1,7.2,0,1.5,.3,2.8,1.1,4.1,1.7,2.8,1.7,5.6,0,8.4-.8,1.3-1.1,2.6-1.1,4.1,.1,3.2-1.3,5.6-4,7.1-1.4,.8-2.4,1.8-3.2,3.2-1.4,2.6-3.6,3.9-6.6,4.1Z"
      />
      <path
        fill="#4c0055"
        d="M12.3,13.7c-.1-.1-.2-.2-.3-.3-2.3-2.3-4.6-4.6-6.9-6.9-.2-.2-.3-.3,0-.6,1.1-1.2,1.1-1.2,2.2,0,2,2,4,4,6,6,.4,.4,.4,.6,0,1-.3,.2-.5,.5-.7,.7,0,0-.2,.1-.2,.2Z"
      />
      <path
        fill="#4c0055"
        d="M51.6,13.7c-.2-.2-.4-.4-.6-.7-.7-.7-.7-.7,0-1.4,2.1-2.1,4.3-4.3,6.4-6.4,.3-.3,.5-.3,.8,0,1,1.1,1,1,0,2.1-2,2-4,4-6.1,6-.1,.1-.2,.3-.5,.4Z"
      />
      <path
        fill="#4c0055"
        d="M2.7,62.7c-.2-.1-.3-.3-.4-.4q-1-1,0-2c1.2-1.2,2.4-2.4,3.7-3.7,.3-.3,.4-.3,.7,0,.3,.4,.9,.7,1,1.1,0,.4-.6,.7-.9,1.1-1.2,1.2-2.3,2.4-3.5,3.5-.1,.1-.2,.2-.4,.4Z"
      />
      <path
        fill="#4c0055"
        d="M57.7,56.3c.1,0,.2,.2,.3,.2,1.5,1.5,2.9,2.9,4.4,4.4,.2,.2,.3,.3,0,.6-1.1,1.2-1.1,1.2-2.2,0-1.1-1.1-2.3-2.3-3.5-3.4-.4-.4-.4-.7,0-1,.2-.2,.4-.4,.7-.7,0,0,.2-.1,.2-.2Z"
      />
      <path
        fill="#4c0055"
        d="M8.4,43c0,.2-.1,.2-.3,.3-1.8,.8-3.6,1.7-5.4,2.5-.2,.1-.3,0-.4-.1q-.9-1.5,.7-2.2c1.4-.6,2.8-1.3,4.2-1.9,.3-.2,.5-.2,.6,.2,.2,.4,.4,.9,.6,1.3Z"
      />
      <path
        fill="#4c0055"
        d="M62.4,44.2c-.3,.5-.5,1.1-.8,1.6,0,.1-.2,0-.3,0-1.8-.8-3.6-1.7-5.3-2.5-.3-.1-.3-.2-.2-.5,.6-1.5,.6-1.5,2.1-.8,1.4,.7,2.8,1.3,4.2,2,.1,0,.3,0,.4,.3Z"
      />
      <path
        fill="#4c0055"
        d="M62.3,19.8c0,.2-.1,.2-.2,.2-1.8,.8-3.6,1.6-5.4,2.4-.2,.1-.3,0-.4-.2,0,0,0,0,0,0q-.7-1.5,.8-2.1c1.4-.6,2.7-1.2,4.1-1.8,.4-.2,.5,0,.7,.3,.2,.4,.4,.8,.5,1.3Z"
      />
      <path
        fill="#4c0055"
        d="M2.5,18.1c.1,0,.2,0,.3,.1,1.7,.7,3.4,1.5,5.1,2.2,.4,.2,.4,.3,.2,.6-.2,.4-.3,1.1-.7,1.3-.3,.1-.9-.3-1.3-.5-1.4-.6-2.8-1.2-4.2-1.8-.3-.1-.4-.3-.3-.6,.2-.3,.3-.7,.5-1.1,0-.1,0-.3,.3-.3Z"
      />
      <path
        fill="#4c0055"
        d="M19.8,1.7c.2,0,.2,.1,.3,.3,.6,1.3,1.1,2.7,1.7,4,.1,.3,0,.3-.2,.5q-1.5,.8-2.1-.7c-.4-.9-.8-1.9-1.2-2.8-.2-.3-.1-.5,.3-.6,.4-.1,.8-.4,1.3-.5Z"
      />
      <path
        fill="#4c0055"
        d="M45.9,2.5c0,.1,0,.2-.1,.3-.5,1.3-1.1,2.5-1.6,3.8-.1,.3-.3,.4-.6,.3-1.4-.6-1.4-.6-.8-2,.4-.9,.8-1.9,1.2-2.9,.1-.3,.2-.4,.5-.2,.4,.2,.8,.3,1.1,.5,.1,0,.3,0,.3,.2Z"
      />
      <path
        fill="#4c0055"
        d="M33,60c0,.5,0,1,0,1.6,0,.3-.1,.4-.4,.4-1.6,0-1.6,0-1.6-1.5,0-.7,0-1.4,0-2.1,0-.3,.1-.4,.4-.4,1.6,0,1.6,0,1.6,1.5s0,.3,0,.5Z"
      />
      <path
        fill="#4c0055"
        d="M3.5,31c1.5,0,1.5,0,1.5,1.5,0,.4-.1,.6-.5,.5-.7,0-1.4,0-2.1,0-.3,0-.3,0-.4-.4,0-1.7-.1-1.7,1.5-1.7Z"
      />
      <path
        fill="#4c0055"
        d="M60.4,33c-1.5,0-1.5,0-1.5-1.5,0-.4,0-.6,.5-.5,.7,0,1.4,0,2.1,0,.3,0,.5,0,.5,.4,0,1.6,0,1.6-1.6,1.6Z"
      />
      <path
        fill="#4c0155"
        d="M2.6,1.3c.2,.1,.3,.2,.4,.3,.5,.5,1,1,1.5,1.4,.2,.2,.2,.3,0,.5-1.1,1.2-1.1,1.2-2.3,0q-.9-.9,0-1.8c.2-.2,.3-.3,.4-.5Z"
      />
      <path
        fill="#4c0155"
        d="M60.6,4.7c-.2-.2-.4-.5-.6-.7-.7-.7-.7-.7,0-1.4,.2-.2,.4-.4,.6-.6q.7-.7,1.5,0c.2,.2,.3,.3,.5,.5,.1,.1,.1,.2,0,.4-.5,.5-1.1,1.1-1.6,1.6,0,0-.1,0-.3,.2Z"
      />
      <path fill="#4c0055" d="M33,3q0,1-1,1t-1-1,1-1,1,1Z" />
      <path
        fill="#fefefe"
        d="M23.8,10.3c1.8,0,3.4-.3,4.9-1.3,2.2-1.4,4.4-1.4,6.6,0,1.5,1,3.2,1.4,5,1.3,2.4-.1,4.3,.9,5.4,3.1,.9,1.7,2.2,3,3.9,3.9,2.1,1.1,3.2,3,3.1,5.4,0,1.8,.3,3.5,1.3,5.1,1.4,2.1,1.4,4.3,0,6.4-1,1.6-1.4,3.2-1.3,5.1,.1,2.4-1,4.3-3.1,5.4-1.7,.9-2.9,2.1-3.8,3.8-1.1,2.2-3,3.2-5.5,3.1-1.8,0-3.5,.3-5,1.3-2.2,1.4-4.3,1.4-6.5,0-1.5-1-3.2-1.4-5-1.3-2.5,.1-4.4-.9-5.5-3.1-.9-1.6-2.1-2.9-3.7-3.7-2.3-1.2-3.3-3.1-3.2-5.6,0-1.7-.3-3.3-1.3-4.8-1.5-2.2-1.5-4.5,0-6.7,.9-1.5,1.3-3.1,1.3-4.8-.1-2.6,1-4.5,3.2-5.6,1.6-.8,2.8-2,3.6-3.6,1.2-2.3,3.1-3.3,5.6-3.2Z"
      />
      <path
        fill="#fefefe"
        d="M23.2,53.7c-1.1,1.8-2.1,3.6-3.2,5.4-1-1.4-1.9-2.8-2.8-4.2-.4-.7-.9-1-1.8-.8-1.4,.3-2.9,.4-4.4,.6,.8-1.3,1.5-2.6,2.3-3.8,.6-1,1.2-1.9,1.7-2.9,.2-.3,.3-.3,.6,0,.3,.4,.6,.8,.9,1.3,1.4,2.8,3.6,4.2,6.8,4.5Z"
      />
      <path
        fill="#4c0055"
        d="M31.1,12c10.4,0,18.5,7.1,19.8,16.6,1.3,10.1-5.9,19.7-16,21.2-10,1.4-19.3-4.9-21.4-14.8-1.2-5.5,0-10.5,3.2-15.1,.3-.4,.5-.4,.8-.1,.3,.3,.9,.5,1,.9,0,.3-.4,.7-.7,1.1-6.7,10.2-.5,24.1,11.5,26,9.5,1.5,18.2-5,19.6-14.4,1.3-9.4-5.6-18.3-15.1-19.2-5.3-.5-9.8,1.1-13.7,4.8-.3,.3-.5,.4-.7,0,0-.1-.2-.2-.4-.4-.7-.7-.7-.7,0-1.4,3.7-3.4,8-5.1,12.1-5.1Z"
      />
      <path
        fill="#4c0055"
        d="M27.3,40c-2.4,0-4.7,0-7.1,0-.9,0-1.3-.3-1.3-1.3,0-4.5,0-9,0-13.5,0-.9,.3-1.2,1.2-1.3,2.6,0,5.2,0,7.9,0,.4,0,.6-.1,.9-.4,1.4-1.3,2.5-2.6,3.3-4.4,.5-1.2,1.5-1.8,2.8-1.8,1.2,0,2.2,.5,2.9,1.6,.5,.8,.6,1.7,.3,2.6-.2,.7-.4,1.3-.6,2-.1,.4,0,.5,.3,.5,1.2,0,2.3,0,3.5,0,2.8,0,4.4,2.6,3.2,5-.4,.9-.6,1.7-.6,2.6,0,1.4,0,2.8,0,4.2,0,2.3-1.7,4.1-4.1,4.1-.8,0-1.6,0-2.4,0-.3,0-.5,0-.5-.5,0-1.5,0-1.5,1.5-1.5,.5,0,.9,0,1.4,0,1.3,0,2.1-.8,2.1-2.1,0-1.5,0-3.1,0-4.6,0-1,.1-2,.7-2.8,.9-1.2,0-2.6-1.5-2.5-1.6,0-3.2,0-4.8,0-1.1,0-1.5-.5-1.1-1.6,.3-1.1,.7-2.2,1.1-3.3,.2-.8,0-1.4-.6-1.7-.6-.3-1.3,0-1.6,.7-.8,2-2.1,3.5-3.7,4.9-.3,.2-.4,.5-.4,.9,0,3.8,0,7.7,0,11.5,0,.5,0,.6,.6,.6,1.3,0,2.6,0,3.9,0,.4,0,.5,0,.5,.5,0,1.5,0,1.5-1.5,1.5h-6.2Z"
      />
      <path
        fill="#fefefe"
        d="M28,32c0,1.8,0,3.7,0,5.5,0,.4,0,.5-.5,.5-2,0-4,0-6.1,0-.4,0-.5,0-.5-.5,0-3.7,0-7.4,0-11.1,0-.3,0-.5,.4-.5,2,0,4.1,0,6.1,0,.4,0,.5,.2,.5,.5,0,1.9,0,3.7,0,5.6Z"
      />
      <path
        fill="#4c0155"
        d="M26,34.5c0,.8-.7,1.5-1.5,1.5-.8,0-1.5-.7-1.5-1.5,0-.8,.7-1.4,1.5-1.4,.8,0,1.5,.7,1.5,1.5Z"
      />
    </svg>
  );
};
