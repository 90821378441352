import React from 'react';

export const DisplacementIconV2 = ({ color = '#212121', className = '' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.4859 7.92005H18.9431C18.517 7.92005 18.1716 8.26923 18.1716 8.70005V11.04H16.6288V9.48005C16.6288 8.61971 15.9368 7.92005 15.0859 7.92005H11.2288V6.36005H14.3145C14.7406 6.36005 15.0859 6.01087 15.0859 5.58005C15.0859 5.14923 14.7406 4.80005 14.3145 4.80005H6.60019C6.17411 4.80005 5.82877 5.14923 5.82877 5.58005C5.82877 6.01087 6.17411 6.36005 6.60019 6.36005H9.68591V7.92005H5.82877C4.97788 7.92005 4.28591 8.61971 4.28591 9.48005V12.6H2.74305V9.48005C2.74305 9.04923 2.39771 8.70005 1.97162 8.70005C1.54554 8.70005 1.2002 9.04923 1.2002 9.48005V17.28C1.2002 17.7109 1.54554 18.06 1.97162 18.06C2.39771 18.06 2.74305 17.7109 2.74305 17.28V14.16H4.28591V16.89C4.28591 17.3786 4.51682 17.8453 4.9028 18.1378L7.47397 20.0875C7.73934 20.2893 8.06797 20.4 8.4002 20.4H15.0859C15.9368 20.4 16.6288 19.7004 16.6288 18.84V16.5H18.1716V18.84C18.1716 19.2709 18.517 19.62 18.9431 19.62H20.4859C21.7621 19.62 22.8002 18.5704 22.8002 17.28V10.26C22.8002 8.96967 21.7621 7.92005 20.4859 7.92005ZM21.2573 17.28C21.2573 17.7101 20.9112 18.06 20.4859 18.06H19.7145V15.72C19.7145 15.2892 19.3691 14.94 18.9431 14.94H15.8573C15.4313 14.94 15.0859 15.2892 15.0859 15.72V18.84H8.4002L5.82877 16.89V9.48005H15.0859V11.82C15.0859 12.2509 15.4313 12.6 15.8573 12.6H18.9431C19.3691 12.6 19.7145 12.2509 19.7145 11.82V9.48005H20.4859C20.9112 9.48005 21.2573 9.83001 21.2573 10.26V17.28Z"
        fill={color}
      />
    </svg>
  );
};
