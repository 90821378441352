export const CallIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99954 17.9906C8.16025 19.1513 9.38545 20.1992 10.6751 21.1343C11.9648 22.0693 13.2545 22.8108 14.5442 23.3589C15.8339 23.907 17.055 24.1811 18.2077 24.1811C18.9895 24.1811 19.719 24.04 20.3961 23.7579C21.0732 23.4758 21.6858 23.0325 22.2339 22.428C22.5563 22.0733 22.8102 21.6924 22.9957 21.2853C23.1811 20.8783 23.2738 20.4692 23.2738 20.0581C23.2738 19.7518 23.2133 19.4596 23.0924 19.1815C22.9715 18.9035 22.7659 18.6637 22.4757 18.4622L18.7639 15.8263C18.4818 15.6329 18.2198 15.4879 17.9779 15.3911C17.7362 15.2944 17.5065 15.246 17.2888 15.246C17.0148 15.246 16.7488 15.3206 16.4909 15.4697C16.2329 15.6188 15.9669 15.8304 15.6928 16.1045L14.8344 16.9507C14.7135 17.0798 14.5603 17.1443 14.3749 17.1443C14.2782 17.1443 14.1875 17.1281 14.1028 17.0958C14.0182 17.0637 13.9477 17.0355 13.8912 17.0113C13.5124 16.8097 13.0288 16.4611 12.4404 15.9653C11.852 15.4697 11.2595 14.9236 10.663 14.3271C10.0746 13.7306 9.53054 13.1381 9.03078 12.5498C8.53103 11.9613 8.18443 11.4817 7.99098 11.111C7.95874 11.0465 7.92851 10.9719 7.9003 10.8873C7.87209 10.8026 7.85798 10.716 7.85798 10.6273C7.85798 10.45 7.91844 10.3009 8.03934 10.18L8.89779 9.29735C9.16379 9.02329 9.37336 8.75729 9.5265 8.49936C9.67966 8.24142 9.75623 7.97542 9.75623 7.70135C9.75623 7.48372 9.70585 7.254 9.6051 7.01218C9.50434 6.77036 9.35321 6.5084 9.1517 6.22629L6.55218 2.56278C6.3426 2.2726 6.09272 2.06101 5.80255 1.92801C5.51237 1.79501 5.20607 1.72852 4.88365 1.72852C4.05341 1.72852 3.27557 2.07915 2.55013 2.78041C1.96171 3.34465 1.53249 3.96732 1.26246 4.64843C0.992435 5.32955 0.857422 6.05701 0.857422 6.83082C0.857422 7.99154 1.12745 9.21069 1.6675 10.4883C2.20756 11.7659 2.94106 13.0455 3.86802 14.3271C4.79499 15.6087 5.83882 16.8299 6.99954 17.9906Z"
        fill="#4C0055"
      />
    </svg>
  );
};
