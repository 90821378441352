import moment from 'moment';
import 'moment/locale/ar';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, ReactElement, useEffect, useState } from 'react';
import {
  AuthService,
  NotificationService,
  PackageSubscription,
} from '../../../helpers/services';
import { CommonUtils } from '../../../helpers/utilities';
import { SessionUtils } from '../../../helpers/utilities/session.utils';
import { useAppContext } from '../../../provider/AppProvider';
import { useCityContext } from '../../../provider/CityProvider';
import { useGlobalDataContext } from '../../../provider/GlobalDataProvider';
import { useTranslationContext } from '../../../provider/TranslationProvider';
import { RedirectURL } from '../../../types/constants';
import { Locales, SocialMedia } from '../../../types/enums';
import {
  ConfigurationResponse,
  NewHeaderMenu,
  NotificationData,
  SocialMediaResponse,
  UserNotificationResponse,
} from '../../../types/models';
import SelectCity from '../../home/SelectCity';
import { ArrowDownIcon, BookmarkIconNew, LocationIcon2 } from '../../icons';
import { UserIcon } from '../../icons/UserIcon';
import LocaleSwitcher from '../LanguageSwitcher';
import HeaderDropdownMenu from './HeaderDropdownMenu';
import Menu from './Menu';
import MobileHeader from './MobileHeader';
import StickyHeader from './StickyHeader';

type HeaderProps = {
  careerEmail: ConfigurationResponse;
  socialMedia: SocialMediaResponse;
  phoneNumber: string;
  headerMenu: NewHeaderMenu[];
};

const Header: FC<HeaderProps> = ({
  careerEmail,
  socialMedia,
  headerMenu,
}): ReactElement => {
  const { LabelConstants } = useTranslationContext();
  const { generalData } = useGlobalDataContext();
  const router = useRouter();
  const { pathname, query, asPath, locale: activeLocale } = router;
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    showSignUpButton: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [notificationData, setNotificationData] =
    useState<Array<NotificationData>>();
  const [notificationUnreadCount, setNotificationUnreadCount] =
    useState<number>();
  const [pathName, setPathName] = useState<string>('');
  const { setShoppingCartCount, shoppingCartCount, isLoggedIn, setIsLoggedIn } =
    useAppContext();

  const { cities, selectCity, setSelectCity } = useCityContext();

  useEffect(() => {
    document.addEventListener('scroll', function () {
      const headerTop = document.querySelector('.header') as HTMLInputElement;
      const headerSticky = document.querySelector(
        '.header-sticky'
      ) as HTMLInputElement;

      if (headerTop) {
        const scrollThreshold = headerTop.offsetHeight;

        if (window.scrollY > scrollThreshold) {
          headerTop.classList.add('header-hide');
          headerSticky.classList.remove('header-hide');
        } else {
          headerTop.classList.remove('header-hide');
          headerSticky.classList.add('header-hide');
        }
      }
    });
  }, []);

  useEffect(() => {
    const initialLoad = async () => {
      /* Check User Session */
      const isAuthenticated = SessionUtils.isValidSession();
      await setAuth({
        isAuthenticated: isAuthenticated,
        showSignUpButton: true,
      });
      if (isAuthenticated) {
        const unreadCount: number =
          await NotificationService.fetchNotificationsUnreadCount();
        if (
          notificationUnreadCount !== unreadCount ||
          notificationData === undefined
        ) {
          setNotificationUnreadCount(unreadCount && unreadCount);

          /* Fetch user notifications data*/
          const response: UserNotificationResponse =
            await NotificationService.fetchNotificationsList(
              1,
              5,
              0,
              CommonUtils.getLanguageId(router.locale!)
            );
          if (response) {
            setNotificationData(
              response.DataList && response.DataList.slice(0, 5)
            );
          }
        }
        const shoppingCount = await PackageSubscription.getShoppingCartCount();
        setShoppingCartCount(shoppingCount);
        localStorage.removeItem(RedirectURL);
      }
    };
    if (router.isReady || isLoggedIn) initialLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, isLoggedIn]);

  useEffect(() => {
    moment.locale(router.locale);
  }, [router.locale]);

  useEffect(() => {
    setPathName(router.asPath);
  }, [pathname]);

  useEffect(() => {
    /* Fetch user notifications data*/
    const fetchNotificationData = async () => {
      if (auth.isAuthenticated) {
        const unreadCount: number =
          await NotificationService.fetchNotificationsUnreadCount();

        setNotificationUnreadCount(unreadCount && unreadCount);

        /* Fetch user notifications data*/
        const response: UserNotificationResponse =
          await NotificationService.fetchNotificationsList(
            1,
            5,
            0,
            CommonUtils.getLanguageId(router.locale!)
          );
        if (response) {
          setNotificationData(
            response.DataList && response.DataList.slice(0, 5)
          );
        }
      }
      // const headerData = await GlobalService.getNewHeaderMenu(router.locale);
      // setHeaderMenu(headerData);
    };
    fetchNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);

  useEffect(() => {
    let dir = router.locale == 'ar' ? 'rtl' : 'ltr';
    let lang = router.locale == 'ar' ? 'ar' : 'en';
    (document.querySelector('html') as HTMLElement).setAttribute('dir', dir);
    (document.querySelector('html') as HTMLElement).setAttribute('lang', lang);
  }, [router.locale]);

  useEffect(() => {
    const handleAuth = () => {
      const isPageActive = !document.hidden;
      if (isPageActive) {
        const isAuthenticated = SessionUtils.isValidSession();
        if (!isAuthenticated) {
          setAuth({
            isAuthenticated: false,
            showSignUpButton: true,
          });
        } else {
          setAuth({
            isAuthenticated: true,
            showSignUpButton: false,
          });
        }
      }
    };
    document.addEventListener('visibilitychange', handleAuth);
    return () => {
      document.removeEventListener('visibilitychange', handleAuth);
    };
  }, []);

  const onSignInClickHandler = async () => {
    localStorage.setItem(RedirectURL, router.asPath);
    // const response = await AuthService.fetchSignInUrl(
    //   CommonUtils.getLanguageId(router.locale!)
    // );
    // if (response) {
    //   window.location.href = response;
    // }
    router.push('/sign-in');
  };

  const contactNumber =
    socialMedia && socialMedia.SocialMedia.length > 0
      ? socialMedia.SocialMedia.filter(
          (itm) => itm.SocialMediaType.trim() === SocialMedia.WhatsApp
        ).map(function (data) {
          return data.ContactNumber;
        })
      : null;

  const mediaEmail =
    socialMedia && socialMedia.SocialMedia?.length > 0
      ? socialMedia.SocialMedia.filter(
          (itm) => itm.SocialMediaType === SocialMedia.Email
        ).map(function (data) {
          return data.MediaEmail;
        })
      : null;

  const openMenuBar = (isOpen: boolean) => {
    setOpenMenu(isOpen);
    if (isOpen) {
      (document.querySelector('body') as HTMLElement).classList.add(
        'body-scroll'
      );
    } else {
      (document.querySelector('body') as HTMLElement).classList.remove(
        'body-scroll'
      );
    }
  };

  return (
    <>
      <header className="header-section header bg-white lg:block hidden">
        <div className="header-top">
          <div className="mx-auto gogo-container px-4">
            <div className="relative flex py-3 justify-between">
              <div className="relative flex items-center">
                <div className="flex flex-shrink-0 items-center">
                  {generalData?.Logo?.data?.attributes?.url && (
                    <Link href="/">
                      <a
                        className="flex items-center"
                        tabIndex={1}
                        onClick={() => openMenu && openMenuBar(false)}
                      >
                        <picture className="cursor-pointer">
                          <source
                            srcSet={`${process.env.NEXT_PUBLIC_Image_CDN_URL}/CMS/Common/gogo-motors.svg`}
                            type="image/svg"
                          />
                          <img
                            src={`${process.env.NEXT_PUBLIC_CMS_API_URL}${generalData?.Logo?.data?.attributes?.url}`}
                            className="!h-[34px] w-[100px]"
                            alt={LabelConstants?.GOGO_MOTOR_LOGO}
                          />
                        </picture>
                      </a>
                    </Link>
                  )}
                </div>

                <div className="relative mx-4 lg:block hidden">
                  {selectCity?.City && (
                    <button
                      className="inline-flex items-center rounded-md bg-white-100 py-2 px-4 text-gray"
                      onClick={() => setOpenModal(true)}
                      tabIndex={2}
                    >
                      <LocationIcon2 className="w-[17px] h-[17px]" />
                      <span className="mx-2 text-15">{selectCity?.City}</span>
                      <ArrowDownIcon
                        className="h-2.5 w-2.5 text-gray"
                        fill="#3A3A3C"
                      />
                    </button>
                  )}
                </div>
              </div>

              {/* <!-- SEARCH BOX --> */}
              <div
                id="searchbox"
                className="relative lg:flex hidden flex-1 items-center justify-end px-4 ms-auto"
              >
                {/* <div className="">
                  <div className="relative">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-6">
                      <SearchIcon fill={'#6B7588'} />
                    </div>
                    <input
                      id="search"
                      name="search"
                      tabIndex={3}
                      // value={search}
                      className="!w-[235px] !h-[38px] block rounded-full border-0 bg-white-100 py-[9px] pl-14 pr-3 text-gray-100 text-sm focus:outline-none placeholder-gray-500"
                      placeholder={t(LabelConstants.SEARCH_YOUR_FAVOURITE_CAR)}
                      type="search"
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                          router.push(
                            `/all-listings?searchKey=${e.target.value}`
                          );
                        }
                      }}
                    />
                  </div>
                </div>*/}
              </div>

              {/* <!-- RIGHT SIDE ICONS --> */}
              <div className="lg:relative flex items-center">
                <button
                  type="button"
                  id="wishlistid"
                  aria-label="wishlist"
                  className="relative lg:block hidden flex-shrink-0 bg-white text-gray"
                  title={LabelConstants?.MY_CONCIERGE}
                  onClick={() => {
                    router.push('/wishlist');
                  }}
                  tabIndex={4}
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Save</span>
                  <BookmarkIconNew className="w-7 h-7" stroke="#E9E9E9" />
                </button>
                <div className="lg:flex hidden items-center">
                  <div className="border-l border-stone h-12 mx-5"></div>
                </div>

                {!auth.isAuthenticated && auth.showSignUpButton && (
                  <button
                    type="button"
                    id="loginid"
                    aria-label="login"
                    className="relative flex gap-2 items-center flex-shrink-0 text-15 font-medium bg-white text-gray"
                    onClick={onSignInClickHandler}
                    tabIndex={5}
                  >
                    <UserIcon />
                    <span className="lg:block hidden">
                      {LabelConstants?.LOGIN}
                    </span>
                  </button>
                )}

                {/* {auth?.isAuthenticated && (
                  <button
                    type="button"
                    className="relative flex gap-2 items-center flex-shrink-0 text-15 font-medium bg-white text-gray"
                    onClick={() => {
                      router.push('/profile');
                    }}
                  >
                    <UserIcon />
                    <span className="lg:block hidden">
                      {t(LabelConstants.ACCOUNT)}
                    </span>
                  </button>
                )} */}

                {/* Profile Menu */}
                {auth?.isAuthenticated && (
                  <div className="flex gap-2">
                    {SessionUtils.getUserDetails()?.FirstName ? (
                      <div className="whitespace-nowrap text-[#3A3A3C] text-[15px] leading-[18.9px] font-medium self-center hidden md:flex items-center gap-1">
                        <div> {LabelConstants?.HELLO},</div>
                        <div className="text-ellipsis max-w-20 overflow-hidden">
                          {SessionUtils.getUserDetails()?.FirstName}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <HeaderDropdownMenu
                      ariaLabel="open profile menu"
                      tabIndex={6}
                      setLabelButton={() => (
                        <>
                          <UserIcon />
                          {/* <span className="lg:block hidden">
                            {t(LabelConstants.ACCOUNT)}
                          </span> */}
                        </>
                      )}
                    >
                      {({ close }) => (
                        <ul className="flex flex-col w-52 divide-y">
                          <Link href="/profile">
                            <li
                              className="header-dropdown-item"
                              tabIndex={7}
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <ProfileIcon className="h-6 w-6" /> */}
                                <span>{LabelConstants?.PROFILE}</span>
                              </a>
                            </li>
                          </Link>
                          <Link href="/my-bookmarks">
                            <li
                              className="header-dropdown-item"
                              tabIndex={8}
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <MyBookmarkIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_BOOKMARKS}</span>
                              </a>
                            </li>
                          </Link>
                          {/* Task 12338: Hide My Quotation link from Profile Menu */}
                          {/* <Link href="/quotations">
                            <li
                              className="header-dropdown-item"
                              onClick={() => close()}
                            >
                              <a className="w-full flex gap-3 items-center">
                                <QuotationIcon className="h-5 w-5" />
                                <span>{t(LabelConstants.MY_QUOTATIONS)}</span>
                              </a>
                            </li>
                          </Link> */}
                          <Link href="/dashboard">
                            <li
                              className="header-dropdown-item"
                              tabIndex={9}
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <VehicleIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_VEHICLES}</span>
                              </a>
                            </li>
                          </Link>
                          <Link href="/my-wishlist">
                            <li
                              className="header-dropdown-item"
                              tabIndex={10}
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-3 items-center">
                                {/* <ConciergeIcon className="h-5 w-5" /> */}
                                <span>{LabelConstants?.MY_CONCIERGE}</span>
                              </a>
                            </li>
                          </Link>
                          {/* <Link href="/my-orders">
                            <li
                              className="header-dropdown-item"
                              onClick={() => {
                                close();
                                openMenu && openMenuBar(false);
                              }}
                            >
                              <a className="w-full flex gap-2 items-center">
                                <span>{t(LabelConstants.MY_ORDERS)}</span>
                              </a>
                            </li>
                          </Link> */}
                          <div
                            className="header-dropdown-item"
                            tabIndex={11}
                            onClick={async () => {
                              const logoutUrl = SessionUtils.getLogoutURL();
                              await AuthService.signOut(logoutUrl);
                              await AuthService.logout();
                              setAuth({
                                isAuthenticated: false,
                                showSignUpButton: true,
                              });
                              setIsLoggedIn(false);
                              router.push(
                                router.locale === Locales.AR ? '/' : '/en'
                              );
                              close();
                              openMenu && openMenuBar(false);
                              if (logoutUrl) {
                                window.location.href =
                                  router.locale === Locales.AR ? '/' : '/en';
                              }
                            }}
                          >
                            <li className="w-full flex gap-3 items-center">
                              {/* <LogoutIcon className="h-5 w-5" /> */}
                              <span>{LabelConstants?.LOGOUT}</span>
                            </li>
                          </div>
                        </ul>
                      )}
                    </HeaderDropdownMenu>
                  </div>
                )}

                {/* <!-- Language dropdown --> */}
                <div className="relative mx-8 flex-shrink-0 lg:block hidden">
                  <div>
                    <LocaleSwitcher />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-menu border-t border-stone">
          <div className="mx-auto gogo-container px-4">
            <nav
              className="lg:flex hidden lg:gap-x-10 gap-x-6 py-2 lg:h-12"
              aria-label="Global"
            >
              {headerMenu.map((item, index) => (
                <Menu
                  {...item.attributes}
                  key={index}
                  tabIndex={14 + (index + 1)}
                />
              ))}
            </nav>
          </div>
        </div>
      </header>
      <StickyHeader
        menuItem={headerMenu}
        auth={auth}
        setAuth={() =>
          setAuth({
            isAuthenticated: false,
            showSignUpButton: true,
          })
        }
        onSignInClickHandler={onSignInClickHandler}
        setIsLoggedIn={() => setIsLoggedIn(false)}
        setOpenModal={() => setOpenModal(true)}
      />
      <MobileHeader
        menuItem={headerMenu}
        auth={auth}
        onSignInClickHandler={onSignInClickHandler}
        configurationEmail={careerEmail?.ConfigurationValue}
        setAuth={() =>
          setAuth({
            isAuthenticated: false,
            showSignUpButton: true,
          })
        }
        setIsLoggedIn={() => setIsLoggedIn(false)}
        setOpenModal={() => setOpenModal(true)}
      />
      {openModal && (
        <SelectCity
          show={openModal}
          onClose={(val: any) => {
            if (val) {
              setSelectCity(val);
              localStorage.setItem('city', JSON.stringify(val));
            }
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default Header;
