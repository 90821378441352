export const MojazFlipReportIcon = ({ className = '' }) => {
  return (
    <svg
      width="91"
      height="90"
      className={className}
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_11116_223099)">
        <path
          d="M0.261719 0H84.2617C87.5754 0 90.2617 2.68629 90.2617 6V84C90.2617 87.3137 87.5754 90 84.2617 90H0.261719V0Z"
          fill="#FE5000"
        />
        <path
          d="M48.0117 22.6234H57.6367L48.0117 12.7234V22.6234ZM35.7617 10.0234H49.7617L60.2617 20.8234V42.4234C60.2617 43.3782 59.893 44.2939 59.2366 44.969C58.5802 45.6442 57.69 46.0234 56.7617 46.0234H35.7617C34.8335 46.0234 33.9432 45.6442 33.2868 44.969C32.6305 44.2939 32.2617 43.3782 32.2617 42.4234V13.6234C32.2617 11.6254 33.8192 10.0234 35.7617 10.0234ZM37.5117 42.4234H41.0117V31.6234H37.5117V42.4234ZM44.5117 42.4234H48.0117V28.0234H44.5117V42.4234ZM51.5117 42.4234H55.0117V35.2234H51.5117V42.4234Z"
          fill="white"
        />
        <path
          d="M58.7046 70.8807L58.9412 67.5677C59.3041 67.5677 59.6117 67.481 59.8641 67.3074C60.1323 67.1181 60.4084 66.8105 60.6923 66.3845C60.9763 65.9428 61.3076 65.3512 61.6862 64.6097C62.1595 63.6632 62.6486 62.9217 63.1534 62.3854C63.674 61.849 64.2025 61.4704 64.7389 61.2495C65.291 61.0129 65.8432 60.8945 66.3953 60.8945C67.0895 60.8945 67.7363 61.0444 68.3358 61.3441C68.9352 61.6439 69.4558 62.0541 69.8976 62.5747C70.3393 63.0795 70.6785 63.6632 70.9151 64.3258C71.1675 64.9726 71.2937 65.6431 71.2937 66.3372C71.2937 67.1733 71.1123 67.9306 70.7495 68.6089C70.4024 69.2715 69.9133 69.8 69.2823 70.1944C68.6671 70.573 67.9571 70.7623 67.1526 70.7623C66.4742 70.7623 65.6854 70.644 64.7862 70.4074C63.887 70.1707 62.9404 69.6738 61.9465 68.9166L63.1534 66.0532C63.8633 66.5423 64.5259 66.8972 65.1411 67.1181C65.7722 67.339 66.2928 67.4494 66.703 67.4494C67.0185 67.4494 67.2788 67.4179 67.4839 67.3547C67.6889 67.2916 67.8388 67.1891 67.9335 67.0471C68.0281 66.9051 68.0755 66.7158 68.0755 66.4792C68.0755 66.1479 67.9966 65.8087 67.8388 65.4616C67.6811 65.1146 67.4681 64.8227 67.1999 64.5861C66.9475 64.3337 66.6477 64.2075 66.3007 64.2075C65.9694 64.2075 65.6854 64.3337 65.4488 64.5861C65.2279 64.8227 65.007 65.1698 64.7862 65.6273C64.5811 66.069 64.3287 66.5896 64.0289 67.1891C63.6661 67.9148 63.3111 68.5222 62.9641 69.0112C62.617 69.4845 62.2463 69.8631 61.8519 70.1471C61.4733 70.4153 61.0236 70.6046 60.503 70.715C59.9982 70.8254 59.3987 70.8807 58.7046 70.8807Z"
          fill="white"
        />
        <path
          d="M47.6439 76.2053L46.9576 72.8923C48.0777 72.7503 49.0558 72.5768 49.892 72.3717C50.7281 72.1824 51.4301 71.9142 51.998 71.5672C52.566 71.2201 52.9919 70.7468 53.2759 70.1473C53.5598 69.5321 53.7018 68.7433 53.7018 67.7809C53.7018 66.8502 53.5598 66.1087 53.2759 65.5565C52.9919 65.0044 52.6133 64.7283 52.14 64.7283C51.8087 64.7283 51.5326 64.8466 51.3118 65.0833C51.0909 65.3041 50.9253 65.5723 50.8148 65.8878C50.7202 66.1876 50.6729 66.4558 50.6729 66.6924C50.6729 66.8344 50.7123 66.9843 50.7912 67.142C50.8701 67.2998 51.0436 67.4339 51.3118 67.5443C51.58 67.6547 51.998 67.7178 52.566 67.7336C53.1497 67.7494 53.9385 67.6942 54.9323 67.568H58.9079C59.5073 67.568 59.9333 67.7257 60.1857 68.0412C60.4539 68.341 60.588 68.7196 60.588 69.1771C60.588 69.6662 60.4223 70.0763 60.091 70.4076C59.7598 70.7231 59.2865 70.8809 58.6712 70.8809H52.1637C51.1698 70.8809 50.3179 70.7626 49.608 70.5259C48.8981 70.2893 48.3538 69.9107 47.9752 69.3901C47.5966 68.8537 47.4073 68.1438 47.4073 67.2603C47.4073 66.5504 47.5256 65.8405 47.7622 65.1306C48.0146 64.4207 48.3617 63.7739 48.8034 63.1902C49.2451 62.5907 49.7579 62.1174 50.3416 61.7703C50.941 61.4075 51.5879 61.2261 52.282 61.2261C53.2286 61.2261 54.0568 61.5179 54.7667 62.1016C55.4766 62.6853 56.0288 63.4584 56.4232 64.4207C56.8333 65.383 57.0384 66.4321 57.0384 67.568C57.0384 69.3506 56.6598 70.8651 55.9026 72.1114C55.1453 73.3577 54.0647 74.32 52.6606 74.9984C51.2566 75.6925 49.5843 76.0948 47.6439 76.2053Z"
          fill="white"
        />
        <path
          d="M31.4127 70.8806L31.6494 67.5677C32.5013 67.5677 33.3453 67.5203 34.1814 67.4257C35.0175 67.331 35.83 67.189 36.6188 66.9997C37.4075 66.8104 38.1569 66.5659 38.8668 66.2662C39.5925 65.9664 40.2709 65.6036 40.9019 65.1776C40.4286 64.8937 39.9159 64.6491 39.3638 64.444C38.8116 64.239 38.22 64.0891 37.589 63.9944C36.9737 63.884 36.319 63.8288 35.6249 63.8288C35.3725 63.8288 35.1043 63.8367 34.8203 63.8524C34.5521 63.8682 34.276 63.8998 33.9921 63.9471C33.7081 63.9787 33.4241 64.026 33.1402 64.0891L32.6669 60.8471C33.1244 60.7209 33.6056 60.6342 34.1104 60.5868C34.631 60.5237 35.0885 60.4922 35.4829 60.4922C36.3348 60.4922 37.1157 60.5868 37.8256 60.7762C38.5513 60.9655 39.2139 61.2021 39.8134 61.4861C40.4286 61.77 40.9966 62.054 41.5172 62.338C42.0535 62.6219 42.5584 62.8586 43.0316 63.0479C43.5207 63.2372 43.994 63.3318 44.4515 63.3318H45.327L45.7057 66.4791C45.0273 66.5107 44.3884 66.6448 43.7889 66.8814C43.2052 67.1023 42.6215 67.3862 42.0378 67.7333C41.4698 68.0646 40.8546 68.4117 40.192 68.7745C39.5452 69.1374 38.8116 69.4765 37.9913 69.7921C37.1709 70.1076 36.2244 70.3679 35.1516 70.573C34.0788 70.7781 32.8325 70.8806 31.4127 70.8806ZM38.5119 76.3233C38.0544 76.3233 37.66 76.1655 37.3287 75.85C37.0132 75.5345 36.8554 75.148 36.8554 74.6905C36.8554 74.233 37.0132 73.8307 37.3287 73.4836C37.66 73.1523 38.0544 72.9867 38.5119 72.9867C38.9694 72.9867 39.3559 73.1523 39.6714 73.4836C40.0027 73.8307 40.1683 74.233 40.1683 74.6905C40.1683 75.148 40.0027 75.5345 39.6714 75.85C39.3559 76.1655 38.9694 76.3233 38.5119 76.3233Z"
          fill="white"
        />
        <path
          d="M21.138 76.2287L19.9312 73.2944C20.7673 73.0104 21.5324 72.6792 22.2265 72.3005C22.9365 71.9219 23.5517 71.5039 24.0723 71.0464C24.5929 70.5889 24.9952 70.0919 25.2792 69.5555C25.5631 69.0034 25.7051 68.4118 25.7051 67.7807C25.7051 67.1182 25.5552 66.3925 25.2555 65.6037C24.9558 64.8149 24.5929 64.0103 24.167 63.19L27.2669 61.6992C27.5509 62.1882 27.7954 62.7167 28.0005 63.2846C28.2214 63.8368 28.4028 64.3337 28.5448 64.7754C28.6868 65.2172 28.7814 65.5169 28.8287 65.6747C29.0496 66.4319 29.3493 66.9367 29.728 67.1892C30.1224 67.4416 30.7534 67.5678 31.6211 67.5678C32.2206 67.5678 32.6465 67.7255 32.8989 68.041C33.1671 68.3408 33.3012 68.7194 33.3012 69.1769C33.3012 69.666 33.1356 70.0761 32.8043 70.4074C32.473 70.7229 31.9997 70.8807 31.3844 70.8807C30.7376 70.8807 30.1697 70.786 29.6806 70.5967C29.1916 70.4074 28.7972 70.1314 28.4974 69.7685C28.2135 69.4057 28.0321 68.9797 27.9532 68.4907L28.8287 68.9876C28.7341 69.8237 28.458 70.6125 28.0005 71.354C27.5588 72.1112 26.983 72.8054 26.2731 73.4364C25.5631 74.0674 24.7665 74.6196 23.883 75.0929C22.9996 75.5661 22.0846 75.9448 21.138 76.2287ZM25.9654 59.2854C25.4764 59.2854 25.0583 59.1198 24.7112 58.7885C24.3799 58.4572 24.2143 58.047 24.2143 57.558C24.2143 57.0689 24.3799 56.6509 24.7112 56.3038C25.0583 55.9567 25.4764 55.7832 25.9654 55.7832C26.4387 55.7832 26.841 55.9567 27.1723 56.3038C27.5193 56.6509 27.6929 57.0689 27.6929 57.558C27.6929 58.047 27.5193 58.4572 27.1723 58.7885C26.841 59.1198 26.4387 59.2854 25.9654 59.2854Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11116_223099">
          <rect width="91" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
