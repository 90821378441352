import { FlutterChannelMessage } from '../../types/enums';

export class FlutterUtils {
  static sendMessage(key: FlutterChannelMessage, data: any) {
    if (window && (window as any).AppChannel) {
      const message = JSON.stringify({ key, data });
      (window as any).AppChannel.postMessage(message);
    }
  }
}
