export const LastIcon = ({ className = '' }) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.17527 17.4247C5.95656 17.2059 5.83369 16.9092 5.83369 16.5998C5.83369 16.2905 5.95656 15.9938 6.17527 15.775L11.9503 9.99998L6.17527 4.22498C5.96275 4.00495 5.84516 3.71025 5.84782 3.40435C5.85048 3.09845 5.97317 2.80584 6.18948 2.58953C6.40579 2.37322 6.69841 2.25052 7.0043 2.24787C7.3102 2.24521 7.6049 2.3628 7.82494 2.57532L14.4248 9.17515C14.6435 9.39393 14.7664 9.69063 14.7664 9.99998C14.7664 10.3093 14.6435 10.606 14.4248 10.8248L7.82494 17.4247C7.60616 17.6434 7.30946 17.7662 7.00011 17.7662C6.69075 17.7662 6.39406 17.6434 6.17527 17.4247Z"
        fill="currentColor"
      />
      <path
        d="M15.5085 17.4247C15.2898 17.2059 15.1669 16.9092 15.1669 16.5998C15.1669 16.2905 15.2898 15.9938 15.5085 15.775L21.2835 9.99998L15.5085 4.22498C15.296 4.00495 15.1784 3.71025 15.1811 3.40435C15.1837 3.09845 15.3064 2.80584 15.5227 2.58953C15.739 2.37322 16.0317 2.25052 16.3376 2.24787C16.6435 2.24521 16.9382 2.3628 17.1582 2.57532L23.758 9.17515C23.9767 9.39393 24.0996 9.69063 24.0996 9.99998C24.0996 10.3093 23.9767 10.606 23.758 10.8248L17.1582 17.4247C16.9394 17.6434 16.6427 17.7662 16.3334 17.7662C16.024 17.7662 15.7273 17.6434 15.5085 17.4247Z"
        fill="currentColor"
      />
    </svg>
  );
};
