import React from 'react';
export const FilledLocationIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25233 15.9138C10.1406 14.1588 13.5 10.5732 13.5 7.28277C13.5 5.46218 12.8152 3.71615 11.5962 2.4288C10.3772 1.14144 8.72391 0.418213 7 0.418213C5.27609 0.418213 3.62279 1.14144 2.40381 2.4288C1.18482 3.71615 0.5 5.46218 0.5 7.28277C0.5 10.5732 3.85833 14.1588 5.74767 15.9138C6.09229 16.2387 6.53807 16.4182 7 16.4182C7.46193 16.4182 7.90771 16.2387 8.25233 15.9138ZM4.83333 7.28277C4.83333 6.67591 5.06161 6.0939 5.46794 5.66478C5.87426 5.23566 6.42536 4.99459 7 4.99459C7.57464 4.99459 8.12574 5.23566 8.53206 5.66478C8.93839 6.0939 9.16667 6.67591 9.16667 7.28277C9.16667 7.88964 8.93839 8.47165 8.53206 8.90077C8.12574 9.32988 7.57464 9.57096 7 9.57096C6.42536 9.57096 5.87426 9.32988 5.46794 8.90077C5.06161 8.47165 4.83333 7.88964 4.83333 7.28277Z"
        fill="currentColor"
      />
    </svg>
  );
};
