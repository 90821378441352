export const BackArrowIcon = ({ className = '', fill = 'white' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.82622 21.646L20.1514 11.3208L20.1514 17.5361C20.1495 17.6464 20.1696 17.7559 20.2104 17.8583C20.2513 17.9607 20.3121 18.0539 20.3894 18.1326C20.4667 18.2112 20.5589 18.2737 20.6605 18.3163C20.7622 18.3589 20.8714 18.3809 20.9816 18.3809C21.0919 18.3809 21.201 18.3589 21.3027 18.3163C21.4044 18.2737 21.4965 18.2112 21.5738 18.1326C21.6511 18.0539 21.7119 17.9607 21.7528 17.8583C21.7937 17.7559 21.8137 17.6464 21.8118 17.5361L21.8118 9.31638C21.8118 9.0962 21.7243 8.88506 21.5686 8.72938C21.4129 8.57369 21.2018 8.48621 20.9816 8.48618L12.7618 8.48618C12.5442 8.48996 12.3367 8.57909 12.1841 8.73437C12.0315 8.88965 11.946 9.09866 11.946 9.31638C11.946 9.53409 12.0315 9.7431 12.1841 9.89838C12.3367 10.0537 12.5442 10.1428 12.7619 10.1466H18.9772L8.65197 20.4718C8.49626 20.6275 8.40878 20.8387 8.40878 21.0589C8.40878 21.2791 8.49626 21.4903 8.65197 21.646C8.80769 21.8017 9.01888 21.8892 9.2391 21.8892C9.45931 21.8892 9.67051 21.8017 9.82622 21.646Z"
        fill={fill}
        stroke={fill}
      />
    </svg>
  );
};
