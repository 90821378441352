export const EmailIcon = ({ className = '', primaryColor = '' }) => {
  return (
    <svg
      width="52"
      height="53"
      viewBox="0 0 52 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="26" cy="26.8076" r="26" fill="white" />
      <path
        d="M25.7533 27.5021C26.2369 27.5021 26.7326 27.2482 27.2405 26.7404L37.7593 16.3303C37.308 15.9031 36.4818 15.6895 35.2808 15.6895H15.8146C14.791 15.6895 14.0816 15.8869 13.6867 16.2819L24.2661 26.7404C24.7659 27.2482 25.2616 27.5021 25.7533 27.5021ZM12.6831 34.5752L21.2313 26.1238L12.6589 17.6723C12.4736 18.0189 12.3809 18.6033 12.3809 19.4255V32.7738C12.3809 33.6281 12.4816 34.2286 12.6831 34.5752ZM16.1774 36.5219H35.6436C36.6511 36.5219 37.3564 36.3284 37.7593 35.9416L29.0419 27.3087L28.244 28.1188C27.4299 28.8926 26.5996 29.2795 25.7533 29.2795C24.8988 29.2795 24.0686 28.8926 23.2626 28.1188L22.4525 27.3087L13.7592 35.9294C14.2187 36.3244 15.0247 36.5219 16.1774 36.5219ZM38.7871 34.5632C38.9806 34.2085 39.0773 33.612 39.0773 32.7738V19.4255C39.0773 18.6114 38.9886 18.0391 38.8113 17.7086L30.2632 26.1238L38.7871 34.5632Z"
        fill="#4C0055"
      />
    </svg>
  );
};
