export class CookieUtils {
  /**
   * This method is used to set a public cookie for infinite amout of time with the given key and value for all domains.
   * @param key The key of the cookie.
   */
  public static setPublicCookie(key: string, value: string): void {
    if (typeof window !== 'undefined') {
      document.cookie = `${key}=${value}`;
    }
  }

  /**
   * This method is used to get the value of a public cookie with the given key.
   * @param key The key of the cookie.
   */
  public static getPublicCookie(cookieKey: string): string | null | undefined {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      return cookies[cookieKey];
    }
  }

  /**
   * This method is used to delete a public cookie with the given key.
   * @param key The key of the cookie.
   */
  public static deletePublicCookie(key: string): void {
    if (typeof window !== 'undefined') {
      document.cookie = `${key}=`;
    }
  }
}
