export const FulfilledByIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.83292 2.8894C1.13171 2.36633 1.43096 1.84325 1.72882 1.31926C1.86228 1.08427 1.98836 0.844663 2.12874 0.613829C2.16015 0.562583 2.23865 0.509491 2.29546 0.50903C4.38651 0.503951 6.4771 0.504413 8.56815 0.504875C8.58986 0.504875 8.61202 0.509491 8.65451 0.514108C8.62495 0.573663 8.60233 0.623985 8.57554 0.671537C8.18255 1.3742 7.78678 2.07594 7.39702 2.78044C7.34021 2.8834 7.27648 2.92218 7.15641 2.92218C5.106 2.91802 3.05513 2.91941 1.00471 2.91941C0.947448 2.91941 0.889722 2.92218 0.832458 2.92356C0.832458 2.91202 0.832458 2.90048 0.832458 2.88894L0.83292 2.8894Z"
        fill="#43474D"
      />
      <path
        d="M11.0951 11.858H9.48895V1.39377C9.52544 1.45148 9.55591 1.49487 9.58178 1.54058C9.9055 2.1172 10.2325 2.69198 10.5497 3.27276C10.6435 3.4445 10.7654 3.51652 10.963 3.51606C12.662 3.51098 14.3605 3.51283 16.0595 3.51283C16.1279 3.51283 16.1957 3.51283 16.2752 3.51283V5.7944C14.4667 5.28703 12.8703 5.63605 11.6373 7.07646C10.4047 8.5164 10.3151 10.1479 11.0951 11.858Z"
        fill="#43474D"
      />
      <path
        d="M9.70093 0.503232C9.77759 0.503232 9.82931 0.503232 9.88057 0.503232C11.9185 0.503232 13.9565 0.504617 15.9944 0.5C16.1265 0.5 16.1962 0.537395 16.2609 0.655121C16.6423 1.35086 17.0339 2.04059 17.4218 2.73263C17.4496 2.78203 17.4745 2.83328 17.5114 2.90437C17.4496 2.90945 17.4024 2.9173 17.3553 2.9173C15.3003 2.91776 13.2448 2.9173 11.1898 2.91869C11.0993 2.91869 11.0332 2.91084 10.9806 2.81527C10.5696 2.0706 10.1507 1.32962 9.7351 0.587256C9.72448 0.568327 9.71894 0.546629 9.70139 0.503232H9.70093Z"
        fill="#43474D"
      />
      <path
        d="M8.76094 1.56461C8.44553 2.12646 8.1255 2.68508 7.81978 3.25201C7.7168 3.44314 7.58565 3.51886 7.36675 3.51793C5.67978 3.51055 3.99234 3.51378 2.30491 3.51378H2.08093V11.8552H8.87639V1.37994C8.82421 1.46304 8.79004 1.51244 8.76094 1.56461ZM5.9975 10.8622C5.95224 10.8677 5.9056 10.8654 5.85942 10.8654C5.41655 10.8654 4.97368 10.8654 4.53035 10.8654C4.30591 10.8654 4.08147 10.8654 3.85703 10.8654C3.6326 10.8654 3.40816 10.8654 3.18372 10.864C3.11584 10.864 3.04426 10.8599 2.98053 10.8395C2.84291 10.7952 2.77733 10.6867 2.78519 10.5482C2.79304 10.4097 2.8697 10.31 3.01193 10.2805C3.07889 10.2666 3.14909 10.2657 3.2179 10.2657C4.09256 10.2648 4.96675 10.2652 5.84141 10.2657C5.89313 10.2657 5.94532 10.2648 5.99658 10.2708C6.16791 10.2915 6.27597 10.407 6.27551 10.5653C6.27551 10.7232 6.16606 10.8419 5.9975 10.8626V10.8622Z"
        fill="#43474D"
      />
      <path
        d="M14.8709 6.17165C12.8727 6.16565 11.2106 7.8055 11.2028 9.79068C11.1945 11.846 12.8182 13.4859 14.8704 13.496C16.8678 13.5057 18.5261 11.8543 18.5298 9.85115C18.5339 7.82212 16.8982 6.17719 14.8704 6.17119L14.8709 6.17165ZM16.9707 8.85303C16.9375 8.91166 16.8876 8.96244 16.8391 9.01092C16.152 9.69973 15.4634 10.3872 14.7753 11.075C14.5403 11.3096 14.4151 11.3096 14.1824 11.0773C13.7344 10.6295 13.2865 10.1817 12.8385 9.73435C12.724 9.61986 12.6847 9.49059 12.7725 9.34517C12.8468 9.22144 12.9669 9.17758 13.0999 9.21774C13.1719 9.23944 13.2389 9.29669 13.2948 9.35117C13.6476 9.69834 13.9962 10.0497 14.3468 10.3996C14.3869 10.4393 14.4285 10.4776 14.4858 10.533C14.809 10.2053 15.1267 9.8807 15.4468 9.55846C15.7714 9.23159 16.0975 8.90658 16.424 8.58156C16.4688 8.53724 16.5145 8.49062 16.568 8.45968C16.6927 8.38812 16.8151 8.40798 16.9162 8.50677C17.0169 8.60557 17.0419 8.72745 16.9707 8.85349V8.85303Z"
        fill="#43474D"
      />
    </svg>
  );
};
