export const RightTickIcon = ({ className = '' }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="path-1-inside-1_811_65" fill="white">
        <path d="M6.11118 13.7249L0.263716 7.62376C0.18011 7.5366 0.113788 7.4331 0.068539 7.31919C0.0232898 7.20528 0 7.08319 0 6.95989C0 6.83659 0.0232898 6.7145 0.068539 6.60059C0.113788 6.48668 0.18011 6.38319 0.263716 6.29602L1.53663 4.96828C1.6202 4.88107 1.71942 4.81189 1.82863 4.76469C1.93783 4.7175 2.05488 4.6932 2.17309 4.6932C2.2913 4.6932 2.40835 4.7175 2.51756 4.76469C2.62676 4.81189 2.72598 4.88107 2.80955 4.96828L6.75029 9.07875L15.1904 0.275074C15.274 0.187868 15.3732 0.118689 15.4824 0.0714912C15.5916 0.0242932 15.7087 0 15.8269 0C15.9451 0 16.0622 0.0242932 16.1714 0.0714912C16.2806 0.118689 16.3798 0.187868 16.4634 0.275074L17.7363 1.60282C17.8199 1.68998 17.8862 1.79348 17.9315 1.90739C17.9767 2.0213 18 2.14339 18 2.26669C18 2.38999 17.9767 2.51208 17.9315 2.62599C17.8862 2.7399 17.8199 2.84339 17.7363 2.93056L7.3841 13.7249C7.30053 13.8121 7.20131 13.8813 7.09211 13.9285C6.9829 13.9757 6.86585 14 6.74764 14C6.62943 14 6.51238 13.9757 6.40318 13.9285C6.29397 13.8813 6.19475 13.8121 6.11118 13.7249V13.7249Z" />
      </mask>
      <path
        d="M6.11118 13.7249L0.263716 7.62376C0.18011 7.5366 0.113788 7.4331 0.068539 7.31919C0.0232898 7.20528 0 7.08319 0 6.95989C0 6.83659 0.0232898 6.7145 0.068539 6.60059C0.113788 6.48668 0.18011 6.38319 0.263716 6.29602L1.53663 4.96828C1.6202 4.88107 1.71942 4.81189 1.82863 4.76469C1.93783 4.7175 2.05488 4.6932 2.17309 4.6932C2.2913 4.6932 2.40835 4.7175 2.51756 4.76469C2.62676 4.81189 2.72598 4.88107 2.80955 4.96828L6.75029 9.07875L15.1904 0.275074C15.274 0.187868 15.3732 0.118689 15.4824 0.0714912C15.5916 0.0242932 15.7087 0 15.8269 0C15.9451 0 16.0622 0.0242932 16.1714 0.0714912C16.2806 0.118689 16.3798 0.187868 16.4634 0.275074L17.7363 1.60282C17.8199 1.68998 17.8862 1.79348 17.9315 1.90739C17.9767 2.0213 18 2.14339 18 2.26669C18 2.38999 17.9767 2.51208 17.9315 2.62599C17.8862 2.7399 17.8199 2.84339 17.7363 2.93056L7.3841 13.7249C7.30053 13.8121 7.20131 13.8813 7.09211 13.9285C6.9829 13.9757 6.86585 14 6.74764 14C6.62943 14 6.51238 13.9757 6.40318 13.9285C6.29397 13.8813 6.19475 13.8121 6.11118 13.7249V13.7249Z"
        fill="white"
        stroke="#929597"
        mask="url(#path-1-inside-1_811_65)"
      />
    </svg>
  );
};
