import React from 'react';

export const TwoNumberAr = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 76 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.9365 109.395L14.3885 110.033C14.3885 107.16 14.2288 103.436 13.9096 98.8599C13.6968 94.1779 13.2712 88.9106 12.6327 83.058C12.1006 77.2054 11.409 71.0336 10.5577 64.5426C9.81282 57.9452 8.90833 51.2413 7.84423 44.4311C6.78013 37.6208 5.60962 30.9702 4.33269 24.4791C3.05577 17.9881 1.67244 11.9227 0.182693 6.28299L22.05 0.217604C24.1782 7.34709 25.9872 15.0086 27.4769 23.2022C29.0731 31.3958 30.4032 39.6958 31.4673 48.1022C32.5314 56.5086 33.3827 64.5958 34.0212 72.3638C34.766 80.1317 35.2449 87.208 35.4577 93.5926C35.7769 99.9772 35.9365 105.245 35.9365 109.395ZM41.2038 42.1964C36.9474 42.1964 32.8506 41.824 28.9135 41.0791C25.0827 40.3343 21.9436 39.483 19.4962 38.5253V16.8176C22.5821 17.8817 26.0936 18.733 30.0308 19.3715C33.968 19.9035 37.2667 20.1695 39.9269 20.1695C43.0128 20.1695 45.5135 19.9035 47.4289 19.3715C49.3442 18.733 50.8872 17.7221 52.0577 16.3388C53.3346 14.9554 54.3455 13.0932 55.0904 10.7522C55.8353 8.30478 56.5269 5.27209 57.1654 1.65414L75.8404 5.16567C75.3083 12.4016 73.925 18.8394 71.6904 24.4791C69.4558 30.0125 65.891 34.3753 60.9962 37.5676C56.2077 40.6535 49.6103 42.1964 41.2038 42.1964Z"
        fill="url(#paint0_linear_15731_215961)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15731_215961"
          x1="-7.00023"
          y1="53.1067"
          x2="79.9997"
          y2="53.1067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F47F20" />
          <stop offset="1" stopColor="#EC2024" />
        </linearGradient>
      </defs>
    </svg>
  );
};
