import React from 'react';

export const HeartOutlineIconV2 = ({ stroke = '#212121', className = '' }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.75 10.1667C14.8675 8.95 16 7.49167 16 5.58333C16 4.36776 15.5654 3.20197 14.7918 2.34243C14.0182 1.48289 12.969 1 11.875 1C10.555 1 9.625 1.41667 8.5 2.66667C7.375 1.41667 6.445 1 5.125 1C4.03098 1 2.98177 1.48289 2.20818 2.34243C1.4346 3.20197 1 4.36776 1 5.58333C1 7.5 2.125 8.95833 3.25 10.1667L8.5 16L13.75 10.1667Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
