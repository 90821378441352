import { useRouter } from 'next/router';
import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

const Context = createContext<{
  LabelConstants: any;
  setLabelConstants: (value: SetStateAction<any>) => void;
}>({
  LabelConstants: {},
  setLabelConstants(value) {},
});

export function TranslationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const [LabelConstants, setLabelConstants] = useState<any>({});

  useEffect(() => {
    const worker = new Worker('/worker.js');

    // Send data to the worker
    worker.postMessage({
      type: 'getTranslations',
      args: {
        baseUrl: process.env.NEXT_PUBLIC_NODE_BACKEND_API_URL,
        currentLocale: router.locale,
      },
    });

    // Listen for messages from the worker
    worker.addEventListener('message', (event) => {
      setLabelConstants(event.data);
    });

    return () => {
      worker.terminate();
    };
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        LabelConstants,
        setLabelConstants,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useTranslationContext() {
  return useContext(Context);
}
