export const InboxIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39 72.6818C57.602 72.6818 72.6818 57.602 72.6818 39C72.6818 20.398 57.602 5.31818 39 5.31818C20.398 5.31818 5.31818 20.398 5.31818 39C5.31818 57.602 20.398 72.6818 39 72.6818ZM39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z"
        fill="#4C0055"
      />
      <path
        d="M21.6785 58.4889C20.4875 58.4889 19.4676 58.0645 18.6187 57.2156C17.7699 56.3668 17.3462 55.3476 17.3477 54.1581V28.1733C17.3477 26.9823 17.7721 25.9624 18.6209 25.1136C19.4698 24.2648 20.4889 23.8411 21.6785 23.8425H43.549C43.4046 24.5643 43.3324 25.2861 43.3324 26.0079C43.3324 26.7297 43.4046 27.4515 43.549 28.1733H21.6785V54.1581H56.3248V36.6184C57.1549 36.4379 57.9308 36.1853 58.6526 35.8605C59.3744 35.5357 60.0421 35.1387 60.6556 34.6695V54.1581C60.6556 55.3491 60.2312 56.369 59.3824 57.2178C58.5335 58.0666 57.5144 58.4903 56.3248 58.4889H21.6785ZM54.1594 32.5041C52.3549 32.5041 50.8211 31.8725 49.558 30.6094C48.2948 29.3462 47.6632 27.8124 47.6632 26.0079C47.6632 24.2034 48.2948 22.6696 49.558 21.4064C50.8211 20.1433 52.3549 19.5117 54.1594 19.5117C55.9639 19.5117 57.4978 20.1433 58.7609 21.4064C60.0241 22.6696 60.6556 24.2034 60.6556 26.0079C60.6556 27.8124 60.0241 29.3462 58.7609 30.6094C57.4978 31.8725 55.9639 32.5041 54.1594 32.5041ZM39.0016 39.0003L46.9053 34.074C47.4106 34.5432 47.9613 34.9496 48.5576 35.2931C49.1538 35.6367 49.7759 35.9341 50.4241 36.1853L40.1385 42.6273C39.7776 42.8439 39.3986 42.9522 39.0016 42.9522C38.6047 42.9522 38.2257 42.8439 37.8648 42.6273L21.6785 32.5041V28.1733L39.0016 39.0003Z"
        fill="#4C0055"
      />
    </svg>
  );
};
