export const ExpandArrowIcon = ({ className = '' }) => {
  return (
    <svg
      width="36"
      height="19"
      viewBox="0 0 36 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.510201 0.491027C1.12863 -0.104157 2.09636 -0.158265 2.7783 0.328704L2.97367 0.491027L18 14.9519L33.0263 0.491027C33.6448 -0.104157 34.6125 -0.158265 35.2944 0.328704L35.4898 0.491027C36.1082 1.08621 36.1644 2.01758 35.6585 2.67388L35.4898 2.86191L19.2317 18.509C18.6133 19.1042 17.6456 19.1583 16.9636 18.6713L16.7683 18.509L0.510201 2.86191C-0.170067 2.20721 -0.170067 1.14573 0.510201 0.491027Z"
        fill="currentColor"
      />
    </svg>
  );
};
