import React from "react";

export const CartPackageDeleteIcon = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 27"
      className={className}
    >
      <path
        id="Icon_material-delete"
        d="M9,28.5a3.009,3.009,0,0,0,3,3H24a3.009,3.009,0,0,0,3-3v-18H9ZM28.5,6H23.25l-1.5-1.5h-7.5L12.75,6H7.5V9h21Z"
        transform="translate(-7.5 -4.5)"
        fill="#dc323a"
      ></path>
    </svg>
  );
};
