export const CollapseArrowIcon = ({ className = '' }) => {
  return (
    <svg
      width="36"
      height="19"
      viewBox="0 0 36 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.510201 18.509C1.12863 19.1042 2.09636 19.1583 2.7783 18.6713L2.97367 18.509L18 4.04812L33.0263 18.509C33.6448 19.1042 34.6125 19.1583 35.2944 18.6713L35.4898 18.509C36.1082 17.9138 36.1644 16.9824 35.6585 16.3261L35.4898 16.1381L19.2317 0.491028C18.6133 -0.104156 17.6456 -0.158264 16.9636 0.328705L16.7683 0.491028L0.510201 16.1381C-0.170067 16.7928 -0.170067 17.8543 0.510201 18.509Z"
        fill="currentColor"
      />
    </svg>
  );
};
