export const DefaultTransmissionIcon = ({ className = '' }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 167.000000 131.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,131.000000) scale(0.100000,-0.100000)"
        fill="currentColor"
        stroke="none"
      >
        <path
          d="M597 1303 c-27 -4 -28 -6 -25 -56 l3 -52 103 -3 102 -3 0 -60 0 -60
73 3 72 3 3 57 3 57 102 3 102 3 3 51 c3 50 2 51 -29 58 -34 6 -466 6 -512 -1z"
        />
        <path
          d="M525 1003 c-16 -14 -57 -51 -91 -83 l-61 -58 -73 -4 c-94 -5 -100
-13 -100 -130 l0 -88 -40 0 -40 0 0 89 c0 49 -3 96 -6 105 -9 23 -99 23 -108
0 -9 -24 -7 -507 3 -522 5 -9 25 -12 57 -10 l49 3 9 103 8 102 34 0 34 0 0
-82 c0 -46 3 -93 6 -104 8 -29 45 -44 110 -44 30 0 61 -6 69 -12 8 -7 41 -37
72 -66 96 -89 64 -82 416 -85 l315 -2 39 41 c48 52 85 93 148 169 46 55 50 64
53 122 l4 63 33 0 32 0 9 -102 9 -103 49 -3 c32 -2 52 1 57 10 11 17 11 509 0
526 -5 9 -25 12 -57 10 l-49 -3 -9 -102 -8 -103 -33 0 -34 0 -3 169 c-3 163
-4 170 -27 195 l-24 26 -411 0 -411 -1 -30 -26z m555 -143 c0 -26 25 -36 45
-20 13 10 19 9 35 -5 14 -13 17 -22 10 -29 -17 -17 -11 -43 10 -49 28 -7 28
-57 1 -57 -23 0 -32 -18 -20 -41 13 -25 -13 -54 -37 -42 -25 14 -44 6 -44 -18
0 -30 -44 -25 -58 6 -12 26 -26 32 -37 15 -9 -14 -21 -12 -39 6 -12 12 -13 20
-6 35 13 24 5 39 -21 39 -30 0 -25 44 6 58 22 10 24 15 14 33 -9 18 -7 25 11
43 16 15 24 17 27 9 8 -21 33 -14 45 12 14 32 58 35 58 5z m-295 -180 c7 -30
36 -42 50 -20 9 15 36 12 56 -6 17 -15 17 -18 2 -40 -20 -31 -10 -54 22 -54
24 0 26 -3 23 -37 -2 -31 -7 -39 -26 -41 -31 -5 -38 -27 -19 -56 15 -22 14
-25 -9 -45 -23 -20 -26 -20 -48 -6 -22 14 -25 14 -40 0 -9 -9 -16 -23 -16 -31
0 -9 -11 -14 -34 -14 -27 0 -36 5 -42 23 -10 32 -25 38 -51 21 -20 -13 -25
-12 -43 3 -22 20 -26 43 -8 61 14 14 -3 38 -33 47 -14 5 -19 16 -19 40 0 29 4
34 28 37 23 2 26 7 23 28 -7 52 -6 63 11 74 14 8 23 8 38 -2 31 -19 50 -15 50
11 0 31 7 37 46 37 28 0 33 -4 39 -30z"
        />
      </g>
    </svg>
  );
};
