export const ContactUsIcon = ({ className = '' }) => {
  return (
    <svg
      width="154"
      height="125"
      viewBox="0 0 154 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="154" height="124.852" fill="url(#pattern0_3270_8063)" />
      <defs>
        <pattern
          id="pattern0_3270_8063"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_3270_8063"
            transform="matrix(0.00222222 0 0 0.00274103 0 -0.116732)"
          />
        </pattern>
        <image id="image0_3270_8063" width="450" height="450" />
      </defs>
    </svg>
  );
};
