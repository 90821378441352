import React from 'react';

export const OdomotorIconV2 = ({ color = '#212121', className = '' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.9497 9.11037L14.9498 9.11026C15.0897 8.72806 14.8941 8.30452 14.5118 8.16396L14.5117 8.16395C13.7083 7.86876 12.863 7.71883 12 7.71883C7.98509 7.71883 4.71882 10.9851 4.71882 15C4.71882 15.4074 5.04916 15.7375 5.45613 15.7375C5.86357 15.7375 6.19367 15.4074 6.19367 15C6.19367 11.7988 8.79857 9.19369 12 9.19369C12.6893 9.19369 13.3633 9.31313 14.0029 9.54808C14.3851 9.68861 14.8092 9.49268 14.9497 9.11037ZM20.3845 17.031L20.3845 17.031C20.3069 17.3526 20.0343 17.567 19.7037 17.567H4.29676C3.96617 17.567 3.69352 17.3526 3.61596 17.031L3.61594 17.031C3.45607 16.37 3.37507 15.6866 3.37507 15C3.37507 10.2446 7.24441 6.37485 12 6.37485C16.7558 6.37485 20.6251 10.2446 20.6251 15C20.6251 15.6867 20.5444 16.37 20.3845 17.031ZM21.8179 17.3776L21.7207 17.3542L21.8179 17.3776C22.0051 16.6025 22.1 15.8028 22.1 15C22.1 12.3025 21.0493 9.76604 19.142 7.85851C17.2344 5.95074 14.6977 4.9 12 4.9C9.30251 4.9 6.76602 5.95074 4.85849 7.85851C2.95097 9.76604 1.9 12.3025 1.9 15C1.9 15.8032 1.99488 16.603 2.18211 17.3776C2.41881 18.3572 3.28861 19.0419 4.29676 19.0419H19.7037C20.7114 19.0419 21.5812 18.3572 21.8179 17.3776ZM17.1487 10.8943C17.4369 10.6066 17.4368 10.1397 17.1488 9.85166C16.8608 9.5636 16.3939 9.5636 16.1058 9.85166L12.715 13.2425C12.4942 13.1523 12.2529 13.1019 12 13.1019C10.9536 13.1019 10.1022 13.9537 10.1022 15C10.1022 16.0471 10.9536 16.8981 12 16.8981C13.0468 16.8981 13.8981 16.0471 13.8981 15C13.8981 14.7477 13.8482 14.5061 13.7578 14.2854L17.1487 10.8943Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  );
};
