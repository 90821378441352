import React from 'react';

export const ShieldCheckIconV2 = ({
  shieldColor = 'white',
  checkColor = '#1C4BB1',
  className = '',
}) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86225 12.3416C8.18161 13.6258 6.07618 13.9928 6.07618 13.9928C6.02539 14.0024 5.97461 14.0024 5.92382 13.9928C5.92382 13.9928 3.82301 13.6258 2.13775 12.3416C0.964987 11.4484 0 10.1207 0 8.20398V3.36632C0 2.58902 0.475567 1.89861 1.18199 1.65238C2.73798 1.11165 5.85456 0.025347 5.85456 0.025347C5.9469 -0.008449 6.04848 -0.008449 6.14544 0.025347C6.14544 0.025347 9.25741 1.10682 10.818 1.65238C11.5244 1.89861 12 2.58902 12 3.36632V8.20398C12 10.1207 11.035 11.4484 9.86225 12.3416Z"
        fill={shieldColor}
      />
      <path
        d="M3 7.5L5 9.5L9.5 5"
        stroke={checkColor}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
