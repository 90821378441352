export const UploadFileIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8232 0.161713C15.6949 0.0264894 15.5188 -0.0546875 15.3364 -0.0546875H4.41361C2.39807 -0.0546875 0.734375 1.60239 0.734375 3.61771V24.2728C0.734375 26.2883 2.39807 27.9454 4.41361 27.9454H19.2387C21.2542 27.9454 22.9179 26.2883 22.9179 24.2728V7.87181C22.9179 7.696 22.8367 7.52702 22.7218 7.39842L15.8232 0.161713ZM16.0193 2.33277L20.6387 7.18202H17.6358C16.7431 7.18202 16.0193 6.4651 16.0193 5.57237V2.33277ZM19.2387 26.5927H4.41361C3.14896 26.5927 2.08704 25.5445 2.08704 24.2728V3.61771C2.08704 2.35306 3.14212 1.29798 4.41361 1.29798H14.6667V5.57237C14.6667 7.21577 15.9924 8.53468 17.6358 8.53468H21.5652V24.2728C21.5652 25.5445 20.5101 26.5927 19.2387 26.5927Z"
        fill="#929597"
      />
      <path
        d="M17.3927 21.9258H6.26032C5.8884 21.9258 5.58398 22.23 5.58398 22.6021C5.58398 22.974 5.8884 23.2784 6.26032 23.2784H17.3995C17.7714 23.2784 18.0758 22.974 18.0758 22.6021C18.0758 22.23 17.7714 21.9258 17.3927 21.9258Z"
        fill="#929597"
      />
      <path
        d="M8.3639 14.6482L11.1504 11.6519V19.0375C11.1504 19.4094 11.4548 19.7138 11.8267 19.7138C12.1989 19.7138 12.5031 19.4094 12.5031 19.0375V11.6519L15.2896 14.6482C15.4248 14.79 15.6008 14.8646 15.7833 14.8646C15.9456 14.8646 16.1148 14.8037 16.2432 14.6819C16.5138 14.4249 16.5341 13.9988 16.2772 13.7283L12.3138 9.47421C12.1852 9.33899 12.0094 9.25781 11.8201 9.25781C11.6306 9.25781 11.4548 9.33899 11.3264 9.47421L7.36307 13.7283C7.10608 13.9988 7.12638 14.4316 7.39682 14.6819C7.68094 14.9389 8.10691 14.9186 8.3639 14.6482Z"
        fill="#929597"
      />
    </svg>
  );
};
