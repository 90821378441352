import { configureStore } from '@reduxjs/toolkit';
import selfListingReducer from '../lib/self-listing/selfListing.slice';
import warrantReducer from '../lib/vas/warranty-flow/warranty.slice';
import homeReducer from './home/home.slice';
import offersDealsReducer from './deals-offers/deals-offers.slice';
import reportListingReducer from './report-listing/reportListing.slice';
import usedCarsFilterSlice from './used-cars/usedCarsFilter.slice';
import globalReducer from '../lib/global.slice';

export const makeStore = () => {
  return configureStore({
    reducer: {
      warranty: warrantReducer,
      selfListing: selfListingReducer,
      usedCarsFilter: usedCarsFilterSlice,
      reportThisListing: reportListingReducer,
      home: homeReducer,
      offersdeals: offersDealsReducer,
      global: globalReducer,
    },
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
