import React from 'react';

const CalendarIconV2 = ({ fill = '#212121', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.52539 7.67334V16.2158C2.52539 16.6831 2.90568 17.0634 3.373 17.0634H16.6046C17.0719 17.0634 17.4522 16.6831 17.4522 16.2158V7.67334H2.52539ZM8.92539 14.7972L6.82432 12.6962L7.51427 12.0062L8.92539 13.4173L12.4633 9.87939L13.1533 10.5693L8.92539 14.7972Z"
        fill={fill}
      />
      <path
        d="M17.4522 4.81826V6.69768H2.52539V4.81826C2.52539 4.35094 2.90568 3.97065 3.373 3.97065H5.44461V2.91699H6.42022V3.97065H13.5617V2.91699H14.5373V3.97065H16.6046C17.0719 3.97065 17.4522 4.35094 17.4522 4.81826Z"
        fill={fill}
      />
    </svg>
  );
};

export default CalendarIconV2;
