export const UploadIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30.8 22.765"
      className={className}
      fill="currentColor"
    >
      <path
        id="Path_779"
        data-name="Path 779"
        d="M19.4,968.362a9.282,9.282,0,0,0-9.165,7.543A7.68,7.68,0,0,0,11.7,991.127H28.1a6.677,6.677,0,0,0,.607-13.328,9.272,9.272,0,0,0-9.311-9.437Zm0,2.009a7.293,7.293,0,0,1,5.2,2.145,7.37,7.37,0,0,1,2.113,6.1,1,1,0,0,0,.994,1.13h.4a4.687,4.687,0,0,1,0,9.374H11.7a5.693,5.693,0,0,1-.523-11.362,1,1,0,0,0,.9-.879,7.352,7.352,0,0,1,7.323-6.507Zm0,6.026a.9.9,0,0,0-.68.262l-3.683,3.348a1.014,1.014,0,0,0-.063,1.423,1.051,1.051,0,0,0,1.423.063l2-1.82v6.434a1,1,0,0,0,2.009,0v-6.434l2,1.82a1.073,1.073,0,0,0,1.423-.063,1.011,1.011,0,0,0-.063-1.423l-3.683-3.348A.847.847,0,0,0,19.4,976.4Z"
        transform="translate(-4 -968.362)"
        fill="currentColor"
      />
    </svg>
  );
};
