export const ValidatedIcon = ({ className = '', fill = '#824C88' }) => {
  return (
    <svg
      width="20"
      height="20"
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={fill} />
      <path
        d="M8.29989 11.8883L5.80936 9.44438C5.72008 9.35682 5.61408 9.28737 5.49743 9.23998C5.38077 9.1926 5.25574 9.16821 5.12948 9.16821C5.00321 9.16821 4.87818 9.1926 4.76152 9.23998C4.64487 9.28737 4.53887 9.35682 4.44959 9.44438C4.26927 9.6212 4.16797 9.86103 4.16797 10.1111C4.16797 10.3612 4.26927 10.601 4.44959 10.7778L7.6208 13.8877C7.70983 13.9757 7.81575 14.0455 7.93244 14.0932C8.04913 14.1408 8.17428 14.1654 8.30069 14.1654C8.42709 14.1654 8.55225 14.1408 8.66894 14.0932C8.78563 14.0455 8.89154 13.9757 8.98058 13.8877L15.5472 7.44654C15.6377 7.35935 15.7097 7.25545 15.759 7.14084C15.8083 7.02624 15.834 6.90318 15.8346 6.77879C15.8352 6.65439 15.8107 6.53111 15.7624 6.41606C15.7142 6.301 15.6432 6.19645 15.5536 6.10844C15.4639 6.02042 15.3574 5.95069 15.2401 5.90325C15.1229 5.85581 14.9972 5.83161 14.8703 5.83204C14.7435 5.83247 14.618 5.85753 14.501 5.90576C14.3841 5.954 14.2781 6.02446 14.1891 6.11308L8.29989 11.8883Z"
        fill="white"
      />
    </svg>
  );
};
