export const SortLetterAtoZIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 34 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.42948 13.5347C1.56968 13.5847 1.71836 13.6066 1.86703 13.5992C2.0157 13.5917 2.16145 13.5551 2.29595 13.4913C2.43045 13.4275 2.55107 13.3379 2.65092 13.2275C2.75077 13.1171 2.82789 12.9881 2.87788 12.8479L3.41848 11.3337H7.00208L7.54268 12.8479C7.59269 12.9881 7.66982 13.1171 7.76967 13.2274C7.86952 13.3378 7.99014 13.4275 8.12464 13.4913C8.25913 13.555 8.40488 13.5917 8.55354 13.5991C8.7022 13.6066 8.85088 13.5847 8.99108 13.5347C9.13128 13.4847 9.26026 13.4075 9.37065 13.3077C9.48104 13.2078 9.57068 13.0872 9.63445 12.9527C9.69823 12.8182 9.73489 12.6725 9.74234 12.5238C9.74979 12.3752 9.72789 12.2265 9.67788 12.0863L7.03495 4.68561C6.90033 4.30948 6.65277 3.98414 6.32614 3.75412C5.99951 3.52409 5.60978 3.40063 5.21028 3.40063C4.81078 3.40063 4.42105 3.52409 4.09442 3.75412C3.76779 3.98414 3.52023 4.30948 3.38561 4.68561L0.74268 12.0863C0.692652 12.2265 0.670733 12.3752 0.678175 12.5238C0.685617 12.6725 0.722273 12.8182 0.786051 12.9527C0.849828 13.0872 0.939478 13.2079 1.04988 13.3077C1.16028 13.4076 1.28927 13.4847 1.42948 13.5347ZM5.21028 6.31648L6.19288 9.06708H4.22768L5.21028 6.31648ZM1.81028 14.7337C1.5097 14.7337 1.22143 14.8532 1.00889 15.0657C0.796351 15.2782 0.676946 15.5665 0.676946 15.8671C0.676946 16.1677 0.796351 16.4559 1.00889 16.6685C1.22143 16.881 1.5097 17.0004 1.81028 17.0004H5.87441L1.20281 21.672C0.41628 22.4574 0.971613 23.8004 2.08228 23.8004H8.61028C8.91086 23.8004 9.19913 23.681 9.41167 23.4685C9.62421 23.2559 9.74361 22.9677 9.74361 22.6671C9.74361 22.3665 9.62421 22.0782 9.41167 21.8657C9.19913 21.6532 8.91086 21.5337 8.61028 21.5337H4.54615L9.21775 16.8621C10.0031 16.0767 9.44781 14.7337 8.33715 14.7337H1.81028Z"
        fill="url(#paint0_linear_13099_206419)"
      />
      <path
        d="M17.5 3C17.1884 3 16.8895 3.1301 16.6691 3.36168L13.3444 6.85514C13.2352 6.96991 13.1486 7.10617 13.0895 7.25612C13.0304 7.40608 13 7.56681 13 7.72912C13 7.89143 13.0304 8.05216 13.0895 8.20212C13.1486 8.35207 13.2352 8.48833 13.3444 8.6031C13.4536 8.71787 13.5832 8.80892 13.7259 8.87103C13.8686 8.93315 14.0215 8.96512 14.1759 8.96512C14.3303 8.96512 14.4832 8.93315 14.6259 8.87103C14.7685 8.80892 14.8982 8.71787 15.0073 8.6031L16.3248 7.21708L16.3248 22.7647C16.3248 23.0923 16.4486 23.4065 16.669 23.6382C16.8894 23.8699 17.1883 24 17.5 24C17.8117 24 18.1106 23.8699 18.331 23.6382C18.5514 23.4065 18.6752 23.0923 18.6752 22.7647L18.6752 7.21708L19.9927 8.6031C20.2132 8.83489 20.5123 8.96511 20.8241 8.96511C21.136 8.96511 21.4351 8.83489 21.6556 8.6031C21.8761 8.3713 22 8.05692 22 7.72912C22 7.40131 21.8761 7.08693 21.6556 6.85514L18.3309 3.36168C18.1105 3.1301 17.8116 3 17.5 3Z"
        fill="url(#paint1_linear_13099_206419)"
      />
      <path
        d="M26.5 24C26.8116 24 27.1105 23.8699 27.3309 23.6383L30.6556 20.1449C30.7648 20.0301 30.8514 19.8938 30.9105 19.7439C30.9696 19.5939 31 19.4332 31 19.2709C31 19.1086 30.9696 18.9478 30.9105 18.7979C30.8514 18.6479 30.7648 18.5117 30.6556 18.3969C30.5464 18.2821 30.4168 18.1911 30.2741 18.129C30.1314 18.0669 29.9785 18.0349 29.8241 18.0349C29.6697 18.0349 29.5168 18.0669 29.3741 18.129C29.2315 18.1911 29.1018 18.2821 28.9927 18.3969L27.6752 19.7829L27.6752 4.23531C27.6752 3.90769 27.5514 3.59348 27.331 3.36181C27.1106 3.13015 26.8117 3 26.5 3C26.1883 3 25.8894 3.13015 25.669 3.36181C25.4486 3.59348 25.3248 3.90768 25.3248 4.23531L25.3248 19.7829L24.0073 18.3969C23.7868 18.1651 23.4877 18.0349 23.1759 18.0349C22.864 18.0349 22.5649 18.1651 22.3444 18.3969C22.1239 18.6287 22 18.9431 22 19.2709C22 19.5987 22.1239 19.9131 22.3444 20.1449L25.6691 23.6383C25.8895 23.8699 26.1884 24 26.5 24Z"
        fill="url(#paint2_linear_13099_206419)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13099_206419"
          x1="0.676758"
          y1="14.3357"
          x2="9.74391"
          y2="14.3538"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_13099_206419"
          x1="13"
          y1="14.2568"
          x2="22.0002"
          y2="14.2741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_13099_206419"
          x1="31"
          y1="12.7432"
          x2="21.9998"
          y2="12.7259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
