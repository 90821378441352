export const CircleSuccessIcon = ({ className = '' }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.1675 10.8325C39.747 6.41198 33.7515 3.92857 27.5 3.92857C21.2485 3.92857 15.253 6.41198 10.8325 10.8325C6.41198 15.253 3.92857 21.2485 3.92857 27.5C3.92857 33.7515 6.41198 39.747 10.8325 44.1675C15.253 48.588 21.2485 51.0714 27.5 51.0714C33.7515 51.0714 39.747 48.588 44.1675 44.1675C48.588 39.747 51.0714 33.7515 51.0714 27.5C51.0714 21.2485 48.588 15.253 44.1675 10.8325ZM46.9454 8.05456C41.7882 2.89731 34.7935 0 27.5 0C20.2065 0 13.2118 2.89731 8.05456 8.05456C2.89731 13.2118 0 20.2065 0 27.5C0 34.7935 2.89731 41.7882 8.05456 46.9454C13.2118 52.1027 20.2065 55 27.5 55C34.7935 55 41.7882 52.1027 46.9454 46.9454C52.1027 41.7882 55 34.7935 55 27.5C55 20.2065 52.1027 13.2118 46.9454 8.05456Z"
        fill="#824C88"
      />
      <path
        d="M23.3283 31.9873L17.223 26.1806C17.0041 25.9726 16.7443 25.8075 16.4583 25.6949C16.1723 25.5824 15.8658 25.5244 15.5563 25.5244C15.2468 25.5244 14.9402 25.5824 14.6543 25.6949C14.3683 25.8075 14.1085 25.9726 13.8896 26.1806C13.4476 26.6007 13.1992 27.1706 13.1992 27.7647C13.1992 28.3589 13.4476 28.9287 13.8896 29.3489L21.6636 36.7378C21.8818 36.9469 22.1415 37.1129 22.4275 37.2261C22.7136 37.3394 23.0204 37.3977 23.3303 37.3977C23.6402 37.3977 23.947 37.3394 24.233 37.2261C24.5191 37.1129 24.7787 36.9469 24.997 36.7378L41.0947 21.4337C41.3165 21.2266 41.4929 20.9797 41.6138 20.7074C41.7347 20.4351 41.7977 20.1427 41.7992 19.8471C41.8006 19.5516 41.7405 19.2587 41.6222 18.9853C41.504 18.7119 41.33 18.4635 41.1102 18.2544C40.8905 18.0453 40.6293 17.8796 40.3418 17.7669C40.0544 17.6541 39.7463 17.5966 39.4353 17.5977C39.1243 17.5987 38.8166 17.6582 38.53 17.7728C38.2433 17.8875 37.9834 18.0549 37.7652 18.2654L23.3283 31.9873Z"
        fill="#824C88"
      />
    </svg>
  );
};
