import React from 'react';

const SearchResultsNotFound = () => {
  return (
    <div>
      <svg
        width="118"
        height="80"
        viewBox="0 0 118 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M68.6938 57.2854C72.9177 45.778 73.6273 35.4531 70.2788 34.224C66.9303 32.9949 60.7916 41.3271 56.5678 52.8345C52.3439 64.3418 51.6343 74.6668 54.9828 75.8959C58.3313 77.125 64.47 68.7928 68.6938 57.2854Z"
          fill="#D1D1D6"
        />
        <path
          d="M59.4067 69.8844C58.9121 69.793 58.5447 69.357 58.5516 68.8465C58.5884 58.6855 61.8847 49.7063 68.4346 41.9208C68.7554 41.5405 69.3175 41.4461 69.7535 41.6964L77.6303 46.2312L84.9966 50.472L99.1313 58.614L106.485 62.8508L117.209 69.0332L113.279 79.7396L101.031 77.5049L92.6837 75.9785L76.5398 73.0228L68.1784 71.4913L59.4067 69.8844Z"
          fill="#E0E0E0"
        />
        <path
          d="M116.004 74.6487C117.089 71.693 117.629 69.1725 117.211 69.0188C116.792 68.8651 115.573 71.1366 114.488 74.0922C113.404 77.0478 112.863 79.5684 113.282 79.7221C113.701 79.8757 114.92 77.6043 116.004 74.6487Z"
          fill="#D1D1D6"
        />
        <path
          d="M92.6829 75.9787C93.2142 73.175 94.0018 70.3733 95.0357 67.5577C96.1232 64.5951 97.4838 61.6275 99.1315 58.6152L106.485 62.8521C103.774 67.8556 101.963 72.7082 101.03 77.5052L92.6829 75.9787Z"
          fill="#F4F7F6"
        />
        <path
          d="M68.1775 71.4911C69.0674 67.5065 70.2433 63.522 71.7112 59.5235C73.325 55.1267 75.2935 50.7091 77.6304 46.231L84.9967 50.4718C81.029 58.1191 78.2263 65.5917 76.5389 73.0216L68.1775 71.4911Z"
          fill="#F4F7F6"
        />
        <path
          d="M21.1964 60.4652C32.9028 60.4652 42.3927 57.7039 42.3927 54.2978C42.3927 50.8916 32.9028 48.1304 21.1964 48.1304C9.48994 48.1304 0 50.8916 0 54.2978C0 57.7039 9.48994 60.4652 21.1964 60.4652Z"
          fill="#D1D1D6"
        />
        <path
          d="M35.5366 52.31C35.6174 52.7826 35.3477 53.2563 34.8882 53.4179C25.7672 56.7285 16.6322 56.7285 7.49716 53.4179C7.05079 53.2553 6.78109 52.7826 6.86191 52.31L8.33539 43.7557L9.71396 35.7558L12.3621 20.4045L13.7407 12.4178L15.7545 0.769043H26.6459L28.6728 12.4846L30.0514 20.4703L32.7136 35.9156L34.0922 43.9154L35.5366 52.31Z"
          fill="#E0E0E0"
        />
        <path
          d="M21.1963 1.54303C24.2028 1.54303 26.6401 1.1976 26.6401 0.771508C26.6401 0.345414 24.2028 0 21.1963 0C18.1898 0 15.7525 0.345414 15.7525 0.771508C15.7525 1.1976 18.1898 1.54303 21.1963 1.54303Z"
          fill="#D1D1D6"
        />
        <path
          d="M30.0504 20.4733C27.3619 20.9197 24.5907 21.1358 21.7264 21.1358C18.7127 21.1358 15.605 20.8924 12.3621 20.4066L13.7406 12.4199C19.1187 13.2036 24.0644 13.2309 28.6728 12.4876L30.0504 20.4733Z"
          fill="#F4F7F6"
        />
        <path
          d="M34.0904 43.9183C30.2253 44.4314 26.2663 44.6889 22.1992 44.6889C17.7261 44.6889 13.1187 44.3778 8.33453 43.7567L9.71311 35.7568C17.8755 36.7971 25.4967 36.8516 32.7128 35.9194L34.0904 43.9183Z"
          fill="#F4F7F6"
        />
      </svg>

      <p className="text-[#757575] text-[13px] ">No results found</p>
    </div>
  );
};

export default SearchResultsNotFound;
