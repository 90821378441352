import React from 'react';

export const Vehicle360DegreeIconV2 = ({ color = 'white', className = '' }) => {
  return (
    <svg
      width="49"
      height="43"
      viewBox="0 0 49 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.39943 24.6764C5.98144 24.3047 6.1527 23.5319 5.78093 22.95C5.41111 22.368 4.63835 22.1967 4.0564 22.5685C0.704585 24.7135 0 26.9635 0 28.4702C0 33.9514 9.10379 38.2452 21.5257 38.8467L20.0211 40.3513C19.5501 40.8398 19.5559 41.6165 20.0367 42.0972C20.5175 42.578 21.2941 42.5858 21.7827 42.1147L25.3427 38.5527C25.5763 38.3192 25.7067 38.0038 25.7067 37.673C25.7067 37.3421 25.5763 37.0267 25.3427 36.7932L21.7827 33.2312C21.551 32.9918 21.2318 32.8536 20.897 32.8516C20.5623 32.8497 20.2411 32.9801 20.0055 33.2176C19.77 33.4531 19.6377 33.7743 19.6416 34.1091C19.6435 34.4419 19.7817 34.7611 20.0211 34.9927L21.3719 36.3455C9.9775 35.7421 2.49116 31.9543 2.49116 28.47C2.49116 26.8641 4.07004 25.5193 5.39943 24.6764Z"
        fill={color}
      />
      <path
        d="M44.4124 22.2522C43.8245 21.9544 43.1082 22.1646 42.7754 22.733C42.4425 23.2994 42.608 24.0274 43.153 24.3953C45.3116 25.6624 46.5087 27.1086 46.5087 28.4673C46.5087 31.64 40.3772 34.7796 31.9277 35.9416C31.2737 36.0253 30.7987 36.6034 30.8415 37.2594C30.8863 37.9173 31.4352 38.4273 32.0931 38.4233C32.1495 38.4214 32.2041 38.4175 32.2605 38.4116C42.4386 37.0199 49.0001 33.1193 49.0001 28.4712C49.0001 26.8615 48.204 24.4729 44.4124 22.2522Z"
        fill={color}
      />
      <path
        d="M8.96017 14.396V18.4816C8.96406 21.2046 11.1713 23.4138 13.8963 23.4177H13.908V24.607C13.908 25.2941 14.4667 25.8527 15.1538 25.8527C15.8428 25.8527 16.3995 25.2941 16.3995 24.607V23.4177H32.6V24.607H32.6019C32.6019 25.2941 33.1586 25.8527 33.8476 25.8527C34.5347 25.8527 35.0934 25.2941 35.0934 24.607V23.4177H35.105C37.8301 23.4138 40.0373 21.2046 40.0412 18.4816V14.396C40.0373 12.6052 39.1711 10.9235 37.7152 9.88018L39.2101 8.61303C39.7239 8.16534 39.7842 7.38678 39.3424 6.86706C38.9025 6.34734 38.124 6.27923 37.5984 6.71329L36.6796 7.48993L34.6534 3.21553C34.412 2.5109 34.015 1.87054 33.4894 1.34305C32.5999 0.478815 31.4087 -0.00193747 30.1688 5.86874e-06H18.8227C17.5808 0.00389853 16.3896 0.490514 15.502 1.35865C15.0095 1.84721 14.6319 2.43895 14.4003 3.09101L12.3234 7.49192L11.4008 6.7114L11.4027 6.71335C11.1497 6.49924 10.8227 6.39413 10.4938 6.42333C10.1648 6.45058 9.85924 6.60824 9.64512 6.85933C9.20133 7.38488 9.26751 8.17123 9.79306 8.61696L11.2879 9.88411C9.83197 10.9254 8.966 12.6052 8.96017 14.396ZM37.5497 18.4796V18.4816C37.5477 19.8304 36.4538 20.9243 35.1049 20.9263H13.8966C12.5478 20.9243 11.4539 19.8304 11.4519 18.4816V14.396C11.4539 12.6889 12.8378 11.305 14.5448 11.3031H34.4571C36.1641 11.305 37.548 12.6889 37.55 14.396L37.5497 18.4796ZM16.6937 4.03512C16.8066 3.70033 16.9934 3.39277 17.2367 3.13779C17.6591 2.72125 18.2294 2.48962 18.8211 2.49156H30.1672C30.7512 2.48961 31.3156 2.7154 31.736 3.1222C32.0144 3.41806 32.2227 3.77232 32.3453 4.1577L34.5565 8.82141C34.5195 8.82141 34.4864 8.80973 34.4494 8.80973L14.5452 8.81168C14.5101 8.81168 14.479 8.82141 14.4439 8.82141L16.6937 4.03512Z"
        fill={color}
      />
      <path
        d="M32.5049 14.0773C31.6816 14.0773 30.938 14.5756 30.6227 15.3367C30.3074 16.0977 30.4826 16.9756 31.0646 17.5576C31.6485 18.1415 32.5244 18.3147 33.2854 17.9994C34.0484 17.6821 34.5448 16.9386 34.5428 16.1153C34.5428 15.5742 34.3287 15.0564 33.9472 14.6749C33.5638 14.2915 33.046 14.0773 32.5049 14.0773Z"
        fill={color}
      />
      <path
        d="M16.4964 14.0773C15.6712 14.0773 14.9276 14.5737 14.6123 15.3347C14.295 16.0977 14.4702 16.9737 15.0541 17.5576C15.6361 18.1396 16.514 18.3147 17.275 17.9994C18.0361 17.6821 18.5324 16.9386 18.5324 16.1153C18.5324 15.5742 18.3183 15.0564 17.9368 14.6749C17.5533 14.2934 17.0356 14.0773 16.4964 14.0773Z"
        fill={color}
      />
      <path
        d="M26.6354 14.8692H22.3649C21.6758 14.8692 21.1191 15.4259 21.1191 16.115C21.1191 16.8021 21.6758 17.3607 22.3649 17.3607H26.6373H26.6354C27.3244 17.3607 27.8811 16.8021 27.8811 16.115C27.8811 15.4259 27.3244 14.8692 26.6354 14.8692Z"
        fill={color}
      />
    </svg>
  );
};
