export const ShoppingCartIcon = ({ className = 'h-8 w-8' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.62368 22.0965L8.2361 22.8704C7.21485 24.9101 8.70017 27.3099 10.984 27.3099H13.8039C12.1114 27.3148 10.7409 28.6863 10.7409 30.3774C10.7409 32.0715 12.1164 33.445 13.8131 33.445C15.5097 33.445 16.8852 32.0715 16.8852 30.3774C16.8852 28.6863 15.5147 27.3148 13.8222 27.3099H24.1137C22.4212 27.3148 21.0507 28.6863 21.0507 30.3774C21.0507 32.0715 22.4262 33.445 24.1228 33.445C25.8195 33.445 27.195 32.0715 27.195 30.3774C27.195 28.6863 25.8245 27.3148 24.132 27.3099H28.2194C28.785 27.3099 29.2435 26.8521 29.2435 26.2873C29.2435 25.7226 28.785 25.2648 28.2194 25.2648H10.984C10.2228 25.2648 9.72758 24.465 10.068 23.785L10.068 23.7849L10.351 23.2198H28.2188C28.676 23.2198 29.0778 22.9172 29.2034 22.4782L33.2997 8.16296C33.3879 7.85444 33.3261 7.52245 33.1326 7.26629C32.9391 7.01014 32.6364 6.85953 32.315 6.85953H6.39399L5.43595 3.51119C5.31036 3.0722 4.90852 2.76953 4.45129 2.76953H2.40882C1.84325 2.76953 1.38477 3.22733 1.38477 3.79205C1.38477 4.35677 1.84325 4.81457 2.40882 4.81457H3.6788L4.62833 8.13316C4.63349 8.15354 4.63926 8.17369 4.64562 8.19357L6.75367 15.5612L8.15529 20.4596L8.62368 22.0965ZM10.49 21.1748H27.4463L30.9574 8.90457H6.97912L8.72298 14.9994L10.1246 19.8977L10.49 21.1748ZM13.8131 31.3999C13.2475 31.3999 12.789 30.9421 12.789 30.3774C12.789 29.8127 13.2475 29.3549 13.8131 29.3549C14.3786 29.3549 14.8371 29.8127 14.8371 30.3774C14.8371 30.9421 14.3786 31.3999 13.8131 31.3999ZM23.0988 30.3774C23.0988 30.9421 23.5573 31.3999 24.1228 31.3999C24.6884 31.3999 25.1469 30.9421 25.1469 30.3774C25.1469 29.8127 24.6884 29.3549 24.1228 29.3549C23.5573 29.3549 23.0988 29.8127 23.0988 30.3774Z"
        fill="#4C0055"
      />
    </svg>
  );
};
