export const AddIcon = ({ className = '', fill = '#4C0055' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0C9.2669 0 9.04335 0.092598 8.87852 0.257425C8.71369 0.422253 8.62109 0.645805 8.62109 0.878906L8.62109 17.1211C8.62109 17.3542 8.71369 17.5777 8.87852 17.7426C9.04335 17.9074 9.2669 18 9.5 18C9.7331 18 9.95665 17.9074 10.1215 17.7426C10.2863 17.5777 10.3789 17.3542 10.3789 17.1211L10.3789 0.878906C10.3789 0.645805 10.2863 0.422253 10.1215 0.257425C9.95665 0.092598 9.7331 0 9.5 0Z"
        fill={fill}
      />
      <path
        d="M17.6211 8.12109L1.37891 8.12109C1.14581 8.12109 0.922253 8.21369 0.757426 8.37852C0.592599 8.54335 0.5 8.7669 0.5 9C0.5 9.2331 0.592599 9.45665 0.757426 9.62148C0.922253 9.78631 1.14581 9.87891 1.37891 9.87891L17.6211 9.87891C17.8542 9.87891 18.0777 9.78631 18.2426 9.62148C18.4074 9.45665 18.5 9.2331 18.5 9C18.5 8.7669 18.4074 8.54335 18.2426 8.37852C18.0777 8.21369 17.8542 8.12109 17.6211 8.12109Z"
        fill={fill}
      />
    </svg>
  );
};
