/** @enum {string} */
export enum LabelConstants {
  CustomerNationalID = 'CustomerNationalID',
  KEEP_IN_TOUCH = 'KEEP_IN_TOUCH',
  ABOUT_GOGO_MOTOR = 'ABOUT_GOGO_MOTOR',
  COPYRIGHT = 'COPYRIGHT',
  COPYRIGHT_OTHER = 'COPYRIGHT_OTHER',
  ABOUT_US = 'ABOUT_US',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  CAREERS = 'CAREERS',
  CONTACT_US = 'CONTACT_US',
  FAQ = 'FAQ',
  ENTER_FIRST_NAME = 'ENTER_FIRST_NAME',
  ENTER_LAST_NAME = 'ENTER_LAST_NAME',
  ENTER_MIDDLE_NAME = 'ENTER_MIDDLE_NAME',
  ENTER_ADDRESS_LINE_1 = 'ENTER_ADDRESS_LINE_1',
  ENTER_ADDRESS_LINE_2 = 'ENTER_ADDRESS_LINE_2',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  STATE = 'STATE',
  CITY = 'CITY',
  ENTER_ZIP = 'ENTER_ZIP',
  ENTER_EMAIL = 'ENTER_EMAIL',
  MOBILE_NUMBER = 'MOBILE_NUMBER',
  CHANGE = 'CHANGE',
  PREFERRED_MODE_OF_COMMUNICATION = 'PREFERRED_MODE_OF_COMMUNICATION',
  PROFESSION = 'PROFESSION',
  WHATSAPP = 'WHATSAPP',
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  GENDER = 'GENDER',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  SELECT_PREFERRED_LANGUAGE = 'SELECT_PREFERRED_LANGUAGE',
  ENGLISH = 'ENGLISH',
  ARABIC = 'ARABIC',
  SUBMIT = 'SUBMIT',
  PLEASE_ENTER_FIRST_NAME = 'PLEASE_ENTER_FIRST_NAME',
  ALLOWED_MAX_100_CHAR = 'ALLOWED_MAX_100_CHAR',
  LETTERS_ALLOWED = 'LETTERS_ALLOWED',
  PLEASE_ENTER_LAST_NAME = 'PLEASE_ENTER_LAST_NAME',
  PLEASE_ENTER_ADDRESS_ONE = 'PLEASE_ENTER_ADDRESS_ONE',
  FRM_ERR_MSG_MAX250_LETTER = 'FRM_ERR_MSG_MAX250_LETTER',
  STATE_IS_REQUIRED = 'STATE_IS_REQUIRED',
  CITY_IS_REQUIRED = 'CITY_IS_REQUIRED',
  PLEASE_ENTER_ZIP = 'PLEASE_ENTER_ZIP',
  FRM_ERR_MSG_ZIP_NUMBER = 'FRM_ERR_MSG_ZIP_NUMBER',
  PLEASE_SELECT_GENDER = 'PLEASE_SELECT_GENDER',
  PLEASE_SELECT_PREFERRED_LANGUAGE = 'PLEASE_SELECT_PREFERRED_LANGUAGE',
  EMAIL_IS_REQUIRED = 'EMAIL_IS_REQUIRED',
  ALLOWED_MAX_150_CHAR = 'ALLOWED_MAX_150_CHAR',
  SELECT_ATLEAST_ONE_MODE_OF_COMMUNICATION = 'SELECT_ATLEAST_ONE_MODE_OF_COMMUNICATION',
  START_WITH_WHITE_SPACES_NOT_ALLOWED = 'START_WITH_WHITE_SPACES_NOT_ALLOWED',
  SEARCH = 'SEARCH',
  SELECT_STATE = 'SELECT_STATE',
  SELECT_CITY = 'SELECT_CITY',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  FIND_YOUR_DREAM_WHEEL = 'FIND_YOUR_DREAM_WHEEL',
  GO_LBL = 'GO_LBL',
  BUY_PREOWNED_CAR = 'BUY_PREOWNED_CAR',
  LIST_MY_CAR = 'LIST_MY_CAR',
  BUY_A_PREOWNED_VEHICLE = 'BUY_A_PREOWNED_VEHICLE',
  FEW_MINUTES_AND_YOUR_VEHICLE_IS_HERE = 'FEW_MINUTES_AND_YOUR_VEHICLE_IS_HERE',
  KNOW_MORE = 'KNOW_MORE',
  NAME = 'NAME',
  SHARE_YOUR_FEEDBACK = 'SHARE_YOUR_FEEDBACK',
  MOBILE_NUMBER_REQUIRED = 'MOBILE_NUMBER_REQUIRED',
  FEEDBACK_IS_REQUIRED = 'FEEDBACK_IS_REQUIRED',
  MAX_20_CHARCTERS_ALLOWED = 'MAX_20_CHARCTERS_ALLOWED',
  SELLER_DETAILS = 'SELLER_DETAILS',
  SAR = 'SAR',
  VEHICLE_PRICE = 'VEHICLE_PRICE',
  DOWN_PAYMENT = 'DOWN_PAYMENT',
  INTEREST_RATE = 'INTEREST_RATE',
  LOAN_TERM = 'LOAN_TERM',
  CALCULATE = 'CALCULATE',
  EMI_CALCULATOR_DISCLAIMER = 'EMI_CALCULATOR_DISCLAIMER',
  VEHICLE_HISTORY = 'VEHICLE_HISTORY',
  DOWNLOAD_VIEW_INSPECTION_REPORT = 'DOWNLOAD_VIEW_INSPECTION_REPORT',
  VEHICLE_DISCLAIMER = 'VEHICLE_DISCLAIMER',
  NOT_RESPONSIBLE = 'NOT_RESPONSIBLE',
  MODEL = 'MODEL',
  SELLER = 'SELLER',
  MANUFACTURING_YEAR = 'MANUFACTURING_YEAR',
  KILOMETERS = 'KILOMETERS',
  OWNER = 'OWNER',
  FUEL = 'FUEL',
  TRANSMISSION = 'TRANSMISSION',
  BODY_STYLE = 'BODY_STYLE',
  EXTERIOR_COLOR = 'EXTERIOR_COLOR',
  INTERIOR_COLOR = 'INTERIOR_COLOR',
  ENGINE = 'ENGINE',
  MAX_POWER = 'MAX_POWER',
  DRIVE_TYPE = 'DRIVE_TYPE',
  DOORS = 'DOORS',
  SEATS = 'SEATS',
  CYLINDERS = 'CYLINDERS',
  LOCATION = 'LOCATION',
  ID = 'ID',
  INDICATIVE_MONTHLY_EMI = 'INDICATIVE_MONTHLY_EMI',
  EMI_CALCULATOR = 'EMI_CALCULATOR',
  DETAILS = 'DETAILS',
  OVERVIEW = 'OVERVIEW',
  SPECIFICATION = 'SPECIFICATION',
  VEHICLE_JOURNEY = 'VEHICLE_JOURNEY',
  SUCCESS = 'SUCCESS',
  OK = 'OK',
  CHANGE_EMAIL_ID = 'CHANGE_EMAIL_ID',
  OLD_EMAIL_ID = 'OLD_EMAIL_ID',
  NEW_EMAIL_ID = 'NEW_EMAIL_ID',
  CANCEL = 'CANCEL',
  SEND_VERIFICATION_LINK = 'SEND_VERIFICATION_LINK',
  WE_HAVE_SENT_VERIFICATION_LINK = 'WE_HAVE_SENT_VERIFICATION_LINK',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  EMAIL_VERIFIED = 'EMAIL_VERIFIED',
  EMAIL_LINK_EXPIRED = 'EMAIL_LINK_EXPIRED',
  EMAIL_LINK_INVALID = 'EMAIL_LINK_INVALID',
  BROWSE_BY_POPULAR_MAKE = 'BROWSE_BY_POPULAR_MAKE',
  VIEW_MORE = 'VIEW_MORE',
  VIEW_LESS = 'VIEW_LESS',
  THANKS_FOR_SHARE_DETAILS = 'THANKS_FOR_SHARE_DETAILS',
  NAME_IS_REQUIRED = 'NAME_IS_REQUIRED',
  ALLOWED_MAX_2000_CHAR = 'ALLOWED_MAX_2000_CHAR',
  CAPTCHA_REQUIRED = 'CAPTCHA_REQUIRED',
  BROWSE_BY_STYLES = 'BROWSE_BY_STYLES',
  USED = 'USED',
  ADD_TO_COMPARE = 'ADD_TO_COMPARE',
  HIDE_COMMON_FEATURES = 'HIDE_COMMON_FEATURES',
  YES = 'YES',
  FEATURES = 'FEATURES',
  COMPARE_VEHICLES = 'COMPARE_VEHICLES',
  BROWSE_BY_LOCATION = 'BROWSE_BY_LOCATION',
  USED_CAR_IN = 'USED_CAR_IN',
  LET_US_HELP = 'LET_US_HELP',
  CONCIERGE_LABEL = 'CONCIERGE_LABEL',
  GO_GET_STARTED = 'GO_GET_STARTED',
  EMAIL_SENT = 'EMAIL_SENT',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  MY_CONCIERGE = 'MY_CONCIERGE',
  ADD_CONCIERGE = 'ADD_CONCIERGE',
  NO_CONCIERGE = 'NO_CONCIERGE',
  DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT',
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  DELETE_ACCOUNT_NOTIFICATION = 'DELETE_ACCOUNT_NOTIFICATION',
  ACCT_ALREADY_ACTIVE = 'ACCT_ALREADY_ACTIVE',
  ACCT_ALREADY_DEACTIVATED = 'ACCT_ALREADY_DEACTIVATED',
  ACCT_ACTIVATION_SUCCESS = 'ACCT_ACTIVATION_SUCCESS',
  ACCT_ACTIVATION_FAILURE = 'ACCT_ACTIVATION_FAILURE',
  ACCT_DEACTIVATION_SUCCESS = 'ACCT_DEACTIVATION_SUCCESS',
  ACCT_DEACTIVATION_FAILURE = 'ACCT_DEACTIVATION_FAILURE',
  EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS',
  PROFILE_SAVED = 'PROFILE_SAVED',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  PROFILE_ADD_UPDATE_FAILURE = 'PROFILE_ADD_UPDATE_FAILURE',
  VERIFICATION_LINK_SENT = 'VERIFICATION_LINK_SENT',
  VERIFICATION_LINK_SENT_FAILURE = 'VERIFICATION_LINK_SENT_FAILURE',
  NO = 'NO',
  SAUDI_ARABIA = 'SAUDI_ARABIA',
  IDENTITY_VERIFICATION_MESSAGE = 'IDENTITY_VERIFICATION_MESSAGE',
  IDENTITY_VERIFICATION_LINK = 'IDENTITY_VERIFICATION_LINK',
  IDENTITY_VERIFICATION_SUCCESSFUL = 'IDENTITY_VERIFICATION_SUCCESSFUL',
  SAVE = 'SAVE',
  SAVE_CHANGES = 'SAVE_CHANGES',
  RESEND_LINK_FOR_EMAIL_VERIFICATION = 'RESEND_LINK_FOR_EMAIL_VERIFICATION',
  SHARE_WITH = 'SHARE_WITH',
  COPY = 'COPY',
  COPY_THIS_LINK = 'COPY_THIS_LINK',
  LINK_COPIED = 'LINK_COPIED',
  LINK_COPIED_SUCCESS = 'LINK_COPIED_SUCCESS',
  SIGN_IN_OR_SIGN_UP_TO_GET_STARTED = 'SIGN_IN_OR_SIGN_UP_TO_GET_STARTED',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  BOOKMARK_NOW = 'BOOKMARK_NOW',
  SELLER_NAME = 'SELLER_NAME',
  CONTACT_NUMBER = 'CONTACT_NUMBER',
  SELECT_DATE_MSG = 'SELECT_DATE_MSG',
  PREFERRED_TEST_DRIVE_DATE_OF_SELLER = 'PREFERRED_TEST_DRIVE_DATE_OF_SELLER',
  PREFERRED_DATE_OF_TEST_DRIVE = 'PREFERRED_DATE_OF_TEST_DRIVE',
  SELECT_DRIVE_DATE_CONFIRMATION = 'SELECT_DRIVE_DATE_CONFIRMATION',
  MAKE = 'MAKE',
  SEARCH_BOX_PLACEHOLDER = 'SEARCH_BOX_PLACEHOLDER',
  MORE = 'MORE',
  SPEC = 'SPEC',
  HIDE = 'HIDE',
  NOTIFY_ME = 'NOTIFY_ME',
  CLEAR_FILTER = 'CLEAR_FILTER',
  APPLY = 'APPLY',
  SEARCH_MAKE = 'SEARCH_MAKE',
  SEARCH_MODEL = 'SEARCH_MODEL',
  SEARCH_SPEC = 'SEARCH_SPEC',
  BODY_TYPE = 'BODY_TYPE',
  FEATURE = 'FEATURE',
  MILEAGE_YEAR_PRICE_EMI = 'MILEAGE_YEAR_PRICE_EMI',
  MILEAGE = 'MILEAGE',
  KM = 'KM',
  PRICE = 'PRICE',
  YEAR = 'YEAR',
  FUEL_TYPE = 'FUEL_TYPE',
  OWNERSHIP = 'OWNERSHIP',
  MORE_FILTER = 'MORE_FILTER',
  PRE_OWNED_CARS_WITH_SEARCH_OPTIONS = 'PRE_OWNED_CARS_WITH_SEARCH_OPTIONS',
  RESULTS = 'RESULTS',
  ASC = 'ASC',
  DESC = 'DESC',
  ROWS = 'ROWS',
  SORT_BY = 'SORT_BY',
  FIRST = 'FIRST',
  LAST = 'LAST',
  NEXT = 'NEXT',
  PREV = 'PREV',
  PAGE_INDICATOR = 'PAGE_INDICATOR',
  LISTING_DATE = 'LISTING_DATE',
  PROFILE = 'PROFILE',
  MY_BOOKMARKS = 'MY_BOOKMARKS',
  MY_VEHICLES = 'MY_VEHICLES',
  LOGOUT = 'LOGOUT',
  CARS_UNDER_20000 = 'CARS_UNDER_20000',
  SHOP_BY_CAR_FEATURES = 'SHOP_BY_CAR_FEATURES',
  SHOP_GREAT_DEAL = 'SHOP_GREAT_DEAL',
  AUTO_PAINT_NOTE = 'AUTO_PAINT_NOTE',
  CONNECT_BODY_PAINT = 'CONNECT_BODY_PAINT',
  GIVE_CAR_NEW_LOOK = 'GIVE_CAR_NEW_LOOK',
  BOOK_NOW = 'BOOK_NOW',
  DAMAGE_DESCRIPTION = 'DAMAGE_DESCRIPTION',
  DAMAGE = 'DAMAGE',
  PRICES = 'PRICES',
  PRODUCT = 'PRODUCT',
  UNIT = 'UNIT',
  PER_ITEM = 'PER_ITEM',
  ALLOY_REPAIR = 'ALLOY_REPAIR',
  ALLOY_REPAIR_WHEEL = 'ALLOY_REPAIR_WHEEL',
  BUMPER_PAINT_REPAIR = 'BUMPER_PAINT_REPAIR',
  REPAIR_FULL = 'REPAIR_FULL',
  REPAIR_PARTIAL = 'REPAIR_PARTIAL',
  DIAMOND_CUT = 'DIAMOND_CUT',
  CORNER_CENTER = 'CORNER_CENTER',
  REPAIR = 'REPAIR',
  DOOR = 'DOOR',
  EXTERNAL_TRIM_ZONE_C = 'EXTERNAL_TRIM_ZONE_C',
  EXTERNAL_TRIM_ZONE_B = 'EXTERNAL_TRIM_ZONE_B',
  EXTERNAL_TRIM_ZONE_A = 'EXTERNAL_TRIM_ZONE_A',
  BOOT_TAILGATE = 'BOOT_TAILGATE',
  ZONE_ABC = 'ZONE_ABC',
  WING_REAR_QUARTER = 'WING_REAR_QUARTER',
  SILL_FULL = 'SILL_FULL',
  SILL_PART = 'SILL_PART',
  BONNET = 'BONNET',
  WHOLE_CAR = 'WHOLE_CAR',
  GRILL = 'GRILL',
  BOOT_HANDLE = 'BOOT_HANDLE',
  DOOR_MIRROR_COVER = 'DOOR_MIRROR_COVER',
  REAR_PARKING_SENSOR = 'REAR_PARKING_SENSOR',
  PETROL_CAP = 'PETROL_CAP',
  DOOR_HANDLE = 'DOOR_HANDLE',
  FRONT_HEADLAMP = 'FRONT_HEADLAMP',
  RUST_TREATMENT_COAT = 'RUST_TREATMENT_COAT',
  SCUFF_SCRATCH = 'SCUFF_SCRATCH',
  ADDITIONAL_SCUFF_SCRATCH = 'ADDITIONAL_SCUFF_SCRATCH',
  SPLIT_GAUGE_HOLE = 'SPLIT_GAUGE_HOLE',
  SPLIT_GAUGE_HOLED = 'SPLIT_GAUGE_HOLED',
  SCRATCH = 'SCRATCH',
  STONE_CHIPS = 'STONE_CHIPS',
  FULL_BODY_BUFF = 'FULL_BODY_BUFF',
  FADED_SUN_UV_DAMAGE = 'FADED_SUN_UV_DAMAGE',
  RUST_TREATMENT = 'RUST_TREATMENT',
  REPLACE = 'REPLACE',
  ZONE_C = 'ZONE_C',
  ZONE_A = 'ZONE_A',
  ZONE_B = 'ZONE_B',
  MISC_PAINT_REPAIR = 'MISC_PAINT_REPAIR',
  PANEL_PAINT_REPAIR = 'PANEL_PAINT_REPAIR',
  WHEEL_ARC_REPAIR = 'WHEEL_ARC_REPAIR',
  BUFF_POLISH = 'BUFF_POLISH',
  TOUCH_UP = 'TOUCH_UP',
  BUFF_POLISH_WAX = 'BUFF_POLISH_WAX',
  HEADLAMP_REFURBISHMENT = 'HEADLAMP_REFURBISHMENT',
  HEADLAMP_REFURBISHMENT_PER_UNIT = 'HEADLAMP_REFURBISHMENT_PER_UNIT',
  ROOF_RACK = 'ROOF_RACK',
  UNDERNEARTH = 'UNDERNEARTH',
  SERVICE_DISCLAIMER = 'SERVICE_DISCLAIMER',
  SERVICE_DIS_DESCRIPTION = 'SERVICE_DIS_DESCRIPTION',
  TRISTAR_NOTE = 'TRISTAR_NOTE',
  RENEWAL_REPAIRS = 'RENEWAL_REPAIRS',
  REPAIR_ELECTRICAL = 'REPAIR_ELECTRICAL',
  MAINTENANCE_REPAIR = 'MAINTENANCE_REPAIR',
  QUICK_SERVICE_JOB = 'QUICK_SERVICE_JOB',
  FULL_VEHICLE_CHECKUP = 'FULL_VEHICLE_CHECKUP',
  CYLINDER_HEADS = 'CYLINDER_HEADS',
  CYLINDER_HEAD_COVERS = 'CYLINDER_HEAD_COVERS',
  CRANCK_SHAFTS = 'CRANCK_SHAFTS',
  CRANK_SHAFT_MOTOR = 'CRANK_SHAFT_MOTOR',
  PISTONS = 'PISTONS',
  ENGINE_OVERHAUL_GASKET = 'ENGINE_OVERHAUL_GASKET',
  OIL_PUMPS = 'OIL_PUMPS',
  INTAKE_MONIFOLF_VALVES = 'INTAKE_MONIFOLF_VALVES',
  OILS = 'OILS',
  TORQUE_CONVERTERS = 'TORQUE_CONVERTERS',
  COOLERS = 'COOLERS',
  COMPLETE_REPAIR_KIT = 'COMPLETE_REPAIR_KIT',
  BUSHINGS = 'BUSHINGS',
  CLUTCHS = 'CLUTCHS',
  FILTERS = 'FILTERS',
  FRICTIONS = 'FRICTIONS',
  P_RUBBER_KIT = 'P_RUBBER_KIT',
  PISTON_KIT = 'PISTON_KIT',
  FORCE_MOTOR = 'FORCE_MOTOR',
  SELLING_PARTS = 'SELLING_PARTS',
  ENGINE_PARTS = 'ENGINE_PARTS',
  TRANSMISSION_PARTS = 'TRANSMISSION_PARTS',
  TITLE_TRANSFER_CONTENT = 'TITLE_TRANSFER_CONTENT',
  TITLE_TRANSFER_URL = 'TITLE_TRANSFER_URL',
  VEHICLE_MUST_REGISTERED = 'VEHICLE_MUST_REGISTERED',
  VALID_REGISTRATION = 'VALID_REGISTRATION',
  VEHICLE_INSPECTION = 'VEHICLE_INSPECTION',
  VEHICLE_INSURANCE = 'VEHICLE_INSURANCE',
  NO_TRAFFIC_VIOLATION = 'NO_TRAFFIC_VIOLATION',
  NO_TRAFFIC_VIOLATION_BUYER = 'NO_TRAFFIC_VIOLATION_BUYER',
  NO_TRAFFIC_VIOLATION_SELLER = 'NO_TRAFFIC_VIOLATION_SELLER',
  GOVERNMENT_FEES = 'GOVERNMENT_FEES',
  INSPECTION = 'INSPECTION',
  LUBRICATE = 'LUBRICATE',
  ADJUST = 'ADJUST',
  TOP_UP = 'TOP_UP',
  CLEAN = 'CLEAN',
  I_LABEL = 'I_LABEL',
  L_LABEL = 'L_LABEL',
  A_LABEL = 'A_LABEL',
  TU_LABEL = 'TU_LABEL',
  C_LABEL = 'C_LABEL',
  R_LABEL = 'R_LABEL',
  R_LABEL_100K = 'R_LABEL_100K',
  DO = 'DO',
  AUTO_CARE_NOTE = 'AUTO_CARE_NOTE',
  STANDARD_CHECK = 'STANDARD_CHECK',
  MINOR_SERVICES = 'MINOR_SERVICES',
  MAJOR_SERVICES = 'MAJOR_SERVICES',
  FRONT_REAR_WINDSHIELD = 'FRONT_REAR_WINDSHIELD',
  ALL_DOOR_HINGES = 'ALL_DOOR_HINGES',
  HOOD_STAY_LOCK = 'HOOD_STAY_LOCK',
  TRUNK_STAY_LOCK = 'TRUNK_STAY_LOCK',
  SMOKE = 'SMOKE',
  FRONT_REAR_WIPER = 'FRONT_REAR_WIPER',
  SEAT_BELT = 'SEAT_BELT',
  AIR_BAGS = 'AIR_BAGS',
  POWER_WINDOW = 'POWER_WINDOW',
  INTERIOR_LIGHTS = 'INTERIOR_LIGHTS',
  PARKING_BRAKE = 'PARKING_BRAKE',
  WINDSHIELD_WASHER = 'WINDSHIELD_WASHER',
  AC_OPERATION = 'AC_OPERATION',
  AC_GAS = 'AC_GAS',
  AC_FILTER = 'AC_FILTER',
  ENGINE_OIL = 'ENGINE_OIL',
  ENGINE_NOISE = 'ENGINE_NOISE',
  AUTOMATIC_TRANSMISSION = 'AUTOMATIC_TRANSMISSION',
  CVT_FLUID = 'CVT_FLUID',
  COOLENT = 'COOLENT',
  GEAR_SELECTOR = 'GEAR_SELECTOR',
  BREAK_PIPE = 'BREAK_PIPE',
  FUEL_LINES = 'FUEL_LINES',
  STEERING_BOOTS = 'STEERING_BOOTS',
  PROPELLER_SHAFTS = 'PROPELLER_SHAFTS',
  DRIVE_SHAFT = 'DRIVE_SHAFT',
  BALL_JOINT = 'BALL_JOINT',
  FRONT_REAR_SHOCK = 'FRONT_REAR_SHOCK',
  FRONT_REAR_SUSPENSION = 'FRONT_REAR_SUSPENSION',
  WHEEL_BEARING = 'WHEEL_BEARING',
  EXHAUST_PIPE = 'EXHAUST_PIPE',
  ENGINE_TRANSMISSION = 'ENGINE_TRANSMISSION',
  TRANSFER_BOX = 'TRANSFER_BOX',
  TYRE_CONDITION = 'TYRE_CONDITION',
  BRAKE_PADEL = 'BRAKE_PADEL',
  FRONT_BRAKE_PAD = 'FRONT_BRAKE_PAD',
  REAR_BRAKE_PAD = 'REAR_BRAKE_PAD',
  BATTERY = 'BATTERY',
  AIR_FILTER = 'AIR_FILTER',
  SPARK_PLUGS = 'SPARK_PLUGS',
  BRAKE_FLUID = 'BRAKE_FLUID',
  FUEL_FILTER = 'FUEL_FILTER',
  POWER_STEERING = 'POWER_STEERING',
  WINDSHIELD_WASHER_FLUID = 'WINDSHIELD_WASHER_FLUID',
  DRIVE_BELTS = 'DRIVE_BELTS',
  TIMING_BELT = 'TIMING_BELT',
  SCANNING_OBD = 'SCANNING_OBD',
  ALL_FLUID_LEVEL = 'ALL_FLUID_LEVEL',
  ALL_COVER_CONNECTOR = 'ALL_COVER_CONNECTOR',
  CLEANING_POLISHING_VALLETING = 'CLEANING_POLISHING_VALLETING',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  COMMENT_REQUIRED = 'COMMENT_REQUIRED',
  TELL_US_ABOUT = 'TELL_US_ABOUT',
  AUTO_CARE = 'AUTO_CARE',
  BODY_PAINT = 'BODY_PAINT',
  TRISTAR = 'TRISTAR',
  TITLE_TRANSFER = 'TITLE_TRANSFER',
  EMAIL_FORMAT_SHOULD_BE_ABC = 'EMAIL_FORMAT_SHOULD_BE_ABC',
  INVALID_MOBILE_NUMBER = 'INVALID_MOBILE_NUMBER',
  BOOK_A_SERVICE_NOW = 'BOOK_A_SERVICE_NOW',
  CONNECT_AUTO_CARE = 'CONNECT_AUTO_CARE',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  UNDER_CHASIS = 'UNDER_CHASIS',
  ENGINE_ROOM = 'ENGINE_ROOM',
  FINAL_CHECK = 'FINAL_CHECK',
  GENERAL_SERVICE = 'GENERAL_SERVICE',
  FILLER = 'FILLER',
  COMPLETE_OWNERSHIP = 'COMPLETE_OWNERSHIP',
  PETROMIN_TRISTAR = 'PETROMIN_TRISTAR',
  SERVICE_QUERY_SUBMISSION = 'SERVICE_QUERY_SUBMISSION',
  CAPTCHAINVALID_MSG = 'CAPTCHAINVALID_MSG',
  SERVICE = 'SERVICE',
  NUM_1 = 'NUM_1',
  NUM_2 = 'NUM_2',
  NUM_3 = 'NUM_3',
  NUM_4 = 'NUM_4',
  NUM_5 = 'NUM_5',
  NUM_6 = 'NUM_6',
  NUM_7 = 'NUM_7',
  HOW_IT_WORKS = 'HOW_IT_WORKS',
  SELLER_PROCESS = 'SELLER_PROCESS',
  LAUNCH = 'LAUNCH',
  FREE_INSPECTION = 'FREE_INSPECTION',
  VEHICLE_DETAILS = 'VEHICLE_DETAILS',
  ENTER_ADDITIONAL_INFORMATION = 'ENTER_ADDITIONAL_INFORMATION',
  IMAGES = 'IMAGES',
  DOCUMENTS = 'DOCUMENTS',
  CONFIRM_DETAILS = 'CONFIRM_DETAILS',
  INSPECTION_APPOINTMENT = 'INSPECTION_APPOINTMENT',
  REQUIRED_DOCUMENT = 'REQUIRED_DOCUMENT',
  PETROMIN_ONLY_ALLOWS_PERSONAL_VEHICLE_LISTING = 'PETROMIN_ONLY_ALLOWS_PERSONAL_VEHICLE_LISTING',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
  DO_YOU_HAVE_VIN_NUMBER = 'DO_YOU_HAVE_VIN_NUMBER',
  VIN_NUMBER = 'VIN_NUMBER',
  GET_DETAILS = 'GET_DETAILS',
  VIN_NUMBER_DESCRIPTION = 'VIN_NUMBER_DESCRIPTION',
  SELECT_OWNERSHIP = 'SELECT_OWNERSHIP',
  OUTSTANDING_FINANCE = 'OUTSTANDING_FINANCE',
  SELECT_OUTSTANDING_FINANCE = 'SELECT_OUTSTANDING_FINANCE',
  VEHICLE_CONDITION = 'VEHICLE_CONDITION',
  EXTERIOR_COLOR_GROUP = 'EXTERIOR_COLOR_GROUP',
  INTERIOR_COLOR_GROUP = 'INTERIOR_COLOR_GROUP',
  LISTING_SUMMARY = 'LISTING_SUMMARY',
  NOTES = 'NOTES',
  ADDITIONAL_FEATURES = 'ADDITIONAL_FEATURES',
  JPEG_PNG_JPG_FILES_ALLOWED = 'JPEG_PNG_JPG_FILES_ALLOWED',
  JPEG_PNG_JPG_PDF_FILES_ALLOWED = 'JPEG_PNG_JPG_PDF_FILES_ALLOWED',
  ASKING_PRICE = 'ASKING_PRICE',
  PREFERRED_TEST_DRIVE_DATE = 'PREFERRED_TEST_DRIVE_DATE',
  ACKNOWLEDGMENT = 'ACKNOWLEDGMENT',
  ACKNOWLEDGEMENT_DATA = 'ACKNOWLEDGEMENT_DATA',
  ACKNOWLEDGEMENT_TEXT = 'ACKNOWLEDGEMENT_TEXT',
  I_AGREE = 'I_AGREE',
  SIGNATURE = 'SIGNATURE',
  SELECT_YEAR = 'SELECT_YEAR',
  SELECT_MAKE = 'SELECT_MAKE',
  SELECT_MODEL = 'SELECT_MODEL',
  TRIM = 'TRIM',
  SELECT_STYLE = 'SELECT_STYLE',
  SEQUENCE_NUMBER = 'SEQUENCE_NUMBER',
  OWNER_ID = 'OWNER_ID',
  PLATE_TEXT_ONE = 'PLATE_TEXT_ONE',
  PLATE_TEXT_TWO = 'PLATE_TEXT_TWO',
  PLATE_TEXT_THREE = 'PLATE_TEXT_THREE',
  PLATE_NUMBER = 'PLATE_NUMBER',
  CANNOT_FIND_MY_VEHICLE = 'CANNOT_FIND_MY_VEHICLE',
  DATE = 'DATE',
  PE_STATION_LOCATION = 'PE_STATION_LOCATION',
  SELECT_DROPDOWN_LITERAL = 'SELECT_DROPDOWN_LITERAL',
  TIME = 'TIME',
  NO_VIN_DETAILS = 'NO_VIN_DETAILS',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  PROFILE_INACTIVE_ACTIVATE_TO_PROCEED = 'PROFILE_INACTIVE_ACTIVATE_TO_PROCEED',
  PROFILE_VALIDATION_REMINDER = 'PROFILE_VALIDATION_REMINDER',
  UPLOAD_FILE = 'UPLOAD_FILE',
  STEP_1 = 'STEP_1',
  STEP_2 = 'STEP_2',
  BIRTH_DATE_IN_HIJRI = 'BIRTH_DATE_IN_HIJRI',
  BIRTH_DATE_IN_GREGORIAN = 'BIRTH_DATE_IN_GREGORIAN',
  IQAMA_NUMBER = 'IQAMA_NUMBER',
  NIN = 'NIN',
  ADDRESS_OF_IQAMA = 'ADDRESS_OF_IQAMA',
  ADDRESS_OF_NIN = 'ADDRESS_OF_NIN',
  ADDITIONAL_NUMBER = 'ADDITIONAL_NUMBER',
  BUILDING_NUMBER = 'BUILDING_NUMBER',
  DISTRICT = 'DISTRICT',
  STREET_NUMBER = 'STREET_NUMBER',
  UNIT_NUMBER = 'UNIT_NUMBER',
  POST_CODE = 'POST_CODE',
  QUESTION_HEADING = 'QUESTION_HEADING',
  PROFILE_VERIFICATION_TITLE = 'PROFILE_VERIFICATION_TITLE',
  ABSHER_VERIFIED_PROFILE = 'ABSHER_VERIFIED_PROFILE',
  GO_BACK_BUTTON_MSG = 'GO_BACK_BUTTON_MSG',
  VERIFICATION_CODE = 'VERIFICATION_CODE',
  GET_OTP = 'GET_OTP',
  ABSHER_VERIFICATION_SUCCESS_MSG = 'ABSHER_VERIFICATION_SUCCESS_MSG',
  VERIFY = 'VERIFY',
  NOTE = 'NOTE',
  OTP_VALIDATION_MESSAGE = 'OTP_VALIDATION_MESSAGE',
  USER_ATTEMPT_MESSAGE = 'USER_ATTEMPT_MESSAGE',
  ENTER_NUMBERS_ONLY = 'ENTER_NUMBERS_ONLY',
  ENTER_TEN_DIGITS = 'ENTER_TEN_DIGITS',
  ABSHER_VERIFICATION_CODE_NOT_FOUND = 'ABSHER_VERIFICATION_CODE_NOT_FOUND',
  ABSHER_VERIFICATION_CODE_EXPIRED = 'ABSHER_VERIFICATION_CODE_EXPIRED',
  ABSHER_OTP_ATTEMPTS_EXCEEDED = 'ABSHER_OTP_ATTEMPTS_EXCEEDED',
  ABSHER_VERIFICATION_FAILED = 'ABSHER_VERIFICATION_FAILED',
  MAX_5_DIGITS_ALLOWED_NUMBERS_ONLY = 'MAX_5_DIGITS_ALLOWED_NUMBERS_ONLY',
  YAKEEN_SUCCESS_MESSAGE = 'YAKEEN_SUCCESS_MESSAGE',
  YAKEEN_FAILED_MESSAGE = 'YAKEEN_FAILED_MESSAGE',
  DATE_OF_BIRTH_PLACEHOLDER = 'DATE_OF_BIRTH_PLACEHOLDER',
  FILE_SIZE_EXCEEDS_5_MB = 'FILE_SIZE_EXCEEDS_5_MB',
  ADVISE_WHAT_CAN_BE_IMPROVED = 'ADVISE_WHAT_CAN_BE_IMPROVED',
  ALLOWED_MAX_200_CHAR = 'ALLOWED_MAX_200_CHAR',
  ADVISE_FOR_IMPROVEMENT = 'ADVISE_FOR_IMPROVEMENT',
  IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS',
  SOMETHING_WENT_WRONG_ERROR = 'SOMETHING_WENT_WRONG_ERROR',
  VIDEO_UPLOAD_SUCCESS = 'VIDEO_UPLOAD_SUCCESS',
  ADDITIONAL_INPUTS = 'ADDITIONAL_INPUTS',
  MAXIMUM_200_CHARACTERS = 'MAXIMUM_200_CHARACTERS',
  UPLOAD_IMAGES = 'UPLOAD_IMAGES',
  UPLOAD_TWO_IMAGES = 'UPLOAD_TWO_IMAGES',
  UPLOAD_VIDEOS = 'UPLOAD_VIDEOS',
  UPLOAD_ONE_VIDEO = 'UPLOAD_ONE_VIDEO',
  THANK_YOU_SPENDING_VALUABLE_TIME = 'THANK_YOU_SPENDING_VALUABLE_TIME',
  ALREADY_SUBMITTED_FEEDBACK = 'ALREADY_SUBMITTED_FEEDBACK',
  LINK_EXPIRED = 'LINK_EXPIRED',
  UPLOAD_ONLY_TWO_IMAGES = 'UPLOAD_ONLY_TWO_IMAGES',
  IMAGE_SELECTION_FORMAT = 'IMAGE_SELECTION_FORMAT',
  UPLOAD_JPJ_PNG = 'UPLOAD_JPJ_PNG',
  FILE_SIZE_EXCEEDS_10_MB = 'FILE_SIZE_EXCEEDS_10_MB',
  FILE_SIZE_EXCEEDS = 'FILE_SIZE_EXCEEDS',
  VIDEO_SELECTION_FORMAT = 'VIDEO_SELECTION_FORMAT',
  UPLOAD_AVI_MPG_FILES = 'UPLOAD_AVI_MPG_FILES',
  DOCUMENT_DELETED = 'DOCUMENT_DELETED',
  RATING_REQUIRED = 'RATING_REQUIRED',
  FEEDBACK_THANK_YOU_MSG = 'FEEDBACK_THANK_YOU_MSG',
  FEEDBACK_ALREADY_SUBMITTED = 'FEEDBACK_ALREADY_SUBMITTED',
  REQUIRED_TEST_DRIVE_DATES = 'REQUIRED_TEST_DRIVE_DATES',
  BUY_BANNER_TEXT = 'BUY_BANNER_TEXT',
  INSPECTED_VEHICLES = 'INSPECTED_VEHICLES',
  INSPECTED_VEHICLES_TEXT = 'INSPECTED_VEHICLES_TEXT',
  RIGHT_PRICE = 'RIGHT_PRICE',
  RIGHT_PRICE_TEXT = 'RIGHT_PRICE_TEXT',
  EASY_SEARCH = 'EASY_SEARCH',
  EASY_SEARCH_TEXT = 'EASY_SEARCH_TEXT',
  VERIFIED_SELLER = 'VERIFIED_SELLER',
  VERIFIED_SELLER_TEXT = 'VERIFIED_SELLER_TEXT',
  GREAT_CHOICE = 'GREAT_CHOICE',
  GREAT_CHOICE_TEXT = 'GREAT_CHOICE_TEXT',
  HAVE_QUESTIONS = 'HAVE_QUESTIONS',
  BROWSE = 'BROWSE',
  HERE_TO_HELP = 'HERE_TO_HELP',
  FEEDBACK = 'FEEDBACK',
  SUPPORT_CONTACT = 'SUPPORT_CONTACT',
  FEEDBACK_VALUABLE = 'FEEDBACK_VALUABLE',
  OR = 'OR',
  PLEASE_GIVE_US_A_CALL = 'PLEASE_GIVE_US_A_CALL',
  VERIFY_BELOW_DETAIL = 'VERIFY_BELOW_DETAIL',
  MULTIPLE_SPECIFICATIONS_FOUND = 'MULTIPLE_SPECIFICATIONS_FOUND',
  NO_VEHICLE_FOUND_FOR_CRITERIA = 'NO_VEHICLE_FOUND_FOR_CRITERIA',
  VEHICLE_OLDER_AGE_ERROR = 'VEHICLE_OLDER_AGE_ERROR',
  SAME_VEHICLE_DETAILS_FOUND = 'SAME_VEHICLE_DETAILS_FOUND',
  VEHICLE_ALREADY_UPLOADED = 'VEHICLE_ALREADY_UPLOADED',
  VEHICLE_OWNERSHIP_DETAILS_ALREADY_EXISTS = 'VEHICLE_OWNERSHIP_DETAILS_ALREADY_EXISTS',
  INVALID_VEHICLE_INFORMATION = 'INVALID_VEHICLE_INFORMATION',
  SAVE_VEHICLE_SOMETHING_WENT_WRONG = 'SAVE_VEHICLE_SOMETHING_WENT_WRONG',
  REQUIRED_FIELD = 'REQUIRED_FIELD',
  ENTER_VALID_TEXT = 'ENTER_VALID_TEXT',
  ENTER_TEN_DIGIT_NUMBER = 'ENTER_TEN_DIGIT_NUMBER',
  ENTER_FOUR_DIGIT_NUMBER = 'ENTER_FOUR_DIGIT_NUMBER',
  VEHICLE_MORE_THAN_KM_ERROR = 'VEHICLE_MORE_THAN_KM_ERROR',
  UPLOAD_REQUIRED_IMAGES = 'UPLOAD_REQUIRED_IMAGES',
  UPLOAD_REQUIRED_DOCUMENTS = 'UPLOAD_REQUIRED_DOCUMENTS',
  FIRST_NAME_REQUIRED = 'FIRST_NAME_REQUIRED',
  LAST_NAME_REQUIRED = 'LAST_NAME_REQUIRED',
  ENTER_YOUR_QUERY = 'ENTER_YOUR_QUERY',
  THANK_YOU_FOR_SUBMITTING_YOUR_QUERY = 'THANK_YOU_FOR_SUBMITTING_YOUR_QUERY',
  REQUEST_FROM_OUR_SUPPORT_TEAMS = 'REQUEST_FROM_OUR_SUPPORT_TEAMS',
  CHOOSE_A_QUERY = 'CHOOSE_A_QUERY',
  VEHICLE_URL = 'VEHICLE_URL',
  VEHICLE_ID = 'VEHICLE_ID',
  QUESTION = 'QUESTION',
  MAXIMUM_2_ATTACHMENTS = 'MAXIMUM_2_ATTACHMENTS',
  MAXIMUM_5_DATES = 'MAXIMUM_5_DATES',
  THANKS_FOR_TEST_DRIVE_SELECTION = 'THANKS_FOR_TEST_DRIVE_SELECTION',
  VEHICLE_ALREADY_SOLD_DELISTED = 'VEHICLE_ALREADY_SOLD_DELISTED',
  SELLER_DATES_ARE_NOT_AVAILABLE = 'SELLER_DATES_ARE_NOT_AVAILABLE',
  PROFILE_TO_CONTACT_SELLER = 'PROFILE_TO_CONTACT_SELLER',
  VERIFY_PROFILE = 'VERIFY_PROFILE',
  PLEASE_COMPLETE_IDENTITY_VERIFICATION = 'PLEASE_COMPLETE_IDENTITY_VERIFICATION',
  FEATURED = 'FEATURED',
  FINISH = 'FINISH',
  SELECTED_LOCATION_DOES_NOT_HAVE_TIME_SLOT = 'SELECTED_LOCATION_DOES_NOT_HAVE_TIME_SLOT',
  PLEASE_COMPLETE = 'PLEASE_COMPLETE',
  IDENTIFICATION_URL = 'IDENTIFICATION_URL',
  ABLE_TO_LIST = 'ABLE_TO_LIST',
  AVAILED_FREE_INSPECTION_MESSAGE = 'AVAILED_FREE_INSPECTION_MESSAGE',
  INSPECTION_BOOKING_RECEIVED = 'INSPECTION_BOOKING_RECEIVED',
  NOT_SELECTED_DATES = 'NOT_SELECTED_DATES',
  SELECT_PRICE_RANGE = 'SELECT_PRICE_RANGE',
  ENTER_NUMBER_ONLY = 'ENTER_NUMBER_ONLY',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  BUDGET = 'BUDGET',
  TRANSMISSION_FUEL_TYPE = 'TRANSMISSION_FUEL_TYPE',
  USER_DETAILS = 'USER_DETAILS',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMMUNICATION_FREQUENCY = 'COMMUNICATION_FREQUENCY',
  PETROMIN_DIGITAL_CONCIERGE_SUMMARY = 'PETROMIN_DIGITAL_CONCIERGE_SUMMARY',
  BACK = 'BACK',
  SUBMIT_CONCIERGE = 'SUBMIT_CONCIERGE',
  MAX_LIMIT_REACHED = 'MAX_LIMIT_REACHED',
  SELECT_BODY_TYPE_MSG = 'SELECT_BODY_TYPE_MSG',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  SELECT_ATLEAST_ONE_BODY_TYPE_BUDGET = 'SELECT_ATLEAST_ONE_BODY_TYPE_BUDGET',
  SELECT_ONE_COMMUNICATION_FREQUENCY = 'SELECT_ONE_COMMUNICATION_FREQUENCY',
  DEALER_NAME_REQUIRED = 'DEALER_NAME_REQUIRED',
  OWNER_NAME_REQUIRED = 'OWNER_NAME_REQUIRED',
  REGISTRATION_ID_REQUIRED = 'REGISTRATION_ID_REQUIRED',
  FRM_ERR_MSG_MAX50_CHARACTER = 'FRM_ERR_MSG_MAX50_CHARACTER',
  FRM_ERR_MSG_ONLY_LETTER_AND_DIGITS = 'FRM_ERR_MSG_ONLY_LETTER_AND_DIGITS',
  PRIMARY_FIRST_NAME_REQUIRED = 'PRIMARY_FIRST_NAME_REQUIRED',
  PRIMARY_LAST_NAME_REQUIRED = 'PRIMARY_LAST_NAME_REQUIRED',
  PRIMARY_PHONE_NUMBER_REQUIRED = 'PRIMARY_PHONE_NUMBER_REQUIRED',
  ADDRESS_REQUIRED = 'ADDRESS_REQUIRED',
  FRM_ERR_MSG_MAX500_CHARACTER = 'FRM_ERR_MSG_MAX500_CHARACTER',
  PIN_CODE_REQUIRED = 'PIN_CODE_REQUIRED',
  DEALER_REGISTRATION = 'DEALER_REGISTRATION',
  ERROR_DEALER_REGISTRATION_ID_EXIST = 'ERROR_DEALER_REGISTRATION_ID_EXIST',
  ERROR_DEALER_PRIMARY_EMAIL_EXIST = 'ERROR_DEALER_PRIMARY_EMAIL_EXIST',
  ERROR_DEALER_SECONDARY_EMAIL_EXIST = 'ERROR_DEALER_SECONDARY_EMAIL_EXIST',
  ERROR_DEALER_PRIMARY_PHONE_EXIST = 'ERROR_DEALER_PRIMARY_PHONE_EXIST',
  ERROR_DEALER_SECONDARY_PHONE_EXIST = 'ERROR_DEALER_SECONDARY_PHONE_EXIST',
  DEALER_APPLICATION_SAVED = 'DEALER_APPLICATION_SAVED',
  ERROR_DEALER_SAVE = 'ERROR_DEALER_SAVE',
  FRM_DEALER_REGISTRATION_SUCCESS = 'FRM_DEALER_REGISTRATION_SUCCESS',
  DEALER_NAME = 'DEALER_NAME',
  OWNER_NAME = 'OWNER_NAME',
  REGISTRATION_ID = 'REGISTRATION_ID',
  PRIMARY_CONTACT_FIRSTNAME = 'PRIMARY_CONTACT_FIRSTNAME',
  PRIMARY_CONTACT_LASTNAME = 'PRIMARY_CONTACT_LASTNAME',
  PRIMARY_CONTACT_NUMBER = 'PRIMARY_CONTACT_NUMBER',
  PRIMARY_EMAIL_ID = 'PRIMARY_EMAIL_ID',
  SECONDARY_CONTACT_NUMBER = 'SECONDARY_CONTACT_NUMBER',
  SECONDARY_EMAIL_ID = 'SECONDARY_EMAIL_ID',
  ENTER_ADDRESS_LINE3 = 'ENTER_ADDRESS_LINE3',
  PIN_CODE = 'PIN_CODE',
  FRM_ERR_MSG_CITY_REG = 'FRM_ERR_MSG_CITY_REG',
  FRM_ERR_MSG_STATE = 'FRM_ERR_MSG_STATE',
  INTEREST_RATE_REQUIRED = 'INTEREST_RATE_REQUIRED',
  DOWN_PAYMENT_REQUIRED = 'DOWN_PAYMENT_REQUIRED',
  DOWN_PAYMENT_NOT_NEGATIVE = 'DOWN_PAYMENT_NOT_NEGATIVE',
  DOWN_PAYMENT_MORE_ERROR = 'DOWN_PAYMENT_MORE_ERROR',
  DOWN_PAYMENT_LESS_ERROR = 'DOWN_PAYMENT_LESS_ERROR',
  ERR_MSG_NUMBER = 'ERR_MSG_NUMBER',
  INTEREST_RATE_NEGATIVE = 'INTEREST_RATE_NEGATIVE',
  INTEREST_RATE_MORE_THAN_100 = 'INTEREST_RATE_MORE_THAN_100',
  PLEASE_ENTER_YOUR_CAR_DETAILS = 'PLEASE_ENTER_YOUR_CAR_DETAILS',
  FRM_ERR_MSG_ONLY_DIGITS = 'FRM_ERR_MSG_ONLY_DIGITS',
  SUBMIT_THANK_YOU_MESSAGE = 'SUBMIT_THANK_YOU_MESSAGE',
  COMPARE = 'COMPARE',
  MARK_AS_SOLD = 'MARK_AS_SOLD',
  DAYS = 'DAYS',
  REASON = 'REASON',
  DELETION_CONFIRMATION = 'DELETION_CONFIRMATION',
  CONFIRM = 'CONFIRM',
  VEHICLE_DELETED = 'VEHICLE_DELETED',
  MY_CAR_VALUE = 'MY_CAR_VALUE',
  MY_CAR_VALUE_DESCRIPTION = 'MY_CAR_VALUE_DESCRIPTION',
  EVALUATE = 'EVALUATE',
  YOUR_ASKING_PRICE = 'YOUR_ASKING_PRICE',
  THE_REQUESTED_VALUE_IN_RANGE_MARKET_VALUE = 'THE_REQUESTED_VALUE_IN_RANGE_MARKET_VALUE',
  YOUR_VEHICLE_PRICE_IS = 'YOUR_VEHICLE_PRICE_IS',
  LESS_THAN_MARKET_PRICE = 'LESS_THAN_MARKET_PRICE',
  MORE_THAN_MARKET_PRICE = 'MORE_THAN_MARKET_PRICE',
  CHANGE_INSPECTION_BOOKING = 'CHANGE_INSPECTION_BOOKING',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  DOCUMENT_SELLER = 'DOCUMENT_SELLER',
  SOLD_PRICE = 'SOLD_PRICE',
  SOLD_TO = 'SOLD_TO',
  SOLD_PRICE_REQUIRED = 'SOLD_PRICE_REQUIRED',
  SOLD_PRICE_SHOULD_NOT_ZERO = 'SOLD_PRICE_SHOULD_NOT_ZERO',
  VEHICLE_SUCCESSFULLY_MARKED_AS_SOLD = 'VEHICLE_SUCCESSFULLY_MARKED_AS_SOLD',
  SELLVEHICLE_SELLER_TO_SELLER_SELL_NOTALLOWED = 'SELLVEHICLE_SELLER_TO_SELLER_SELL_NOTALLOWED',
  SELLVEHICLE_SELLERBUYERSEARCH_BUYER_NOTFOUND = 'SELLVEHICLE_SELLERBUYERSEARCH_BUYER_NOTFOUND',
  AFFORDABLE_CARS_AVAILABLE_NOW = 'AFFORDABLE_CARS_AVAILABLE_NOW',
  SHOP_GREAT_VALUES = 'SHOP_GREAT_VALUES',
  VEHICLE_RECOMMENDATION = 'VEHICLE_RECOMMENDATION',
  STEP_TELL_WHAT_YOU_LOOKING = 'STEP_TELL_WHAT_YOU_LOOKING',
  VIEW_ALL = 'VIEW_ALL',
  WE_WILL_NOTIFY_THANK_YOU = 'WE_WILL_NOTIFY_THANK_YOU',
  CONCIERGE_DELETED = 'CONCIERGE_DELETED',
  NOTIFICATION = 'NOTIFICATION',
  NO_NOTIFICATIONS_CONFIGURED = 'NO_NOTIFICATIONS_CONFIGURED',
  LOADING_LBL = 'LOADING_LBL',
  NOTIFICATIONS = 'NOTIFICATIONS',
  SEE_ALL = 'SEE_ALL',
  ALL = 'ALL',
  BOOKMARKED = 'BOOKMARKED',
  HOME = 'HOME',
  VEHICLE_UPDATED = 'VEHICLE_UPDATED',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  INVALID_VIN = 'INVALID_VIN',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE',
  MAXIMUM_CONCIERGE_REACHED = 'MAXIMUM_CONCIERGE_REACHED',
  YOU_HAVE_NOT_BOOKMARKED_ANY_VEHICLE = 'YOU_HAVE_NOT_BOOKMARKED_ANY_VEHICLE',
  SELECT_JPEG_OR_PNG_IMAGE = 'SELECT_JPEG_OR_PNG_IMAGE',
  SAVED_SEARCH = 'SAVED_SEARCH',
  SAVE_SEARCH = 'SAVE_SEARCH',
  NAME_OF_SEARCH = 'NAME_OF_SEARCH',
  VEHICLE_SEARCHNAME_ALREADY_EXISTS = 'VEHICLE_SEARCHNAME_ALREADY_EXISTS',
  MAXIMUM_3_SEARCHED_CAN_BE_SAVED = 'MAXIMUM_3_SEARCHED_CAN_BE_SAVED',
  SEARCH_LIMIT = 'SEARCH_LIMIT',
  UNAUTHORIZED_MESSAGE = 'UNAUTHORIZED_MESSAGE',
  BACK_TO_HOME = 'BACK_TO_HOME',
  UNAUTHORIZED_LABEL = 'UNAUTHORIZED_LABEL',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  PAGE_NOT_FOUND_LABEL = 'PAGE_NOT_FOUND_LABEL',
  ORIGIN = 'ORIGIN',
  HOW_CAN_I_HELP = 'HOW_CAN_I_HELP',
  PLEASE_WAIT = 'PLEASE_WAIT',
  SPIN_TO_EXPLORE = 'SPIN_TO_EXPLORE',
  UPLOAD_BTN = 'UPLOAD_BTN',
  FILTER_BY = 'FILTER_BY',
  ENTER_100_CHAR = 'ENTER_100_CHAR',
  PREFERRED_MAKE = 'PREFERRED_MAKE',
  NO_RECORDS = 'NO_RECORDS',
  HIGH_TO_LOW = 'HIGH_TO_LOW',
  LOW_TO_HIGH = 'LOW_TO_HIGH',
  SHOW = 'SHOW',
  GIVE_YOUR_FEEDBACK = 'GIVE_YOUR_FEEDBACK',
  CLEAR = 'CLEAR',
  RESULT_NOT_FOUND = 'RESULT_NOT_FOUND',
  TO_150_MM = 'TO_150_MM',
  TO_100_MM = 'TO_100_MM',
  TO_50_MM = 'TO_50_MM',
  AM = 'AM',
  PM = 'PM',
  GO_GO_MOTOR_PAGE_META_TITLE_GLOBAL = 'GO_GO_MOTOR_PAGE_META_TITLE_GLOBAL',
  GO_GO_MOTOR_PAGE_META_DESC_GLOBAL = 'GO_GO_MOTOR_PAGE_META_DESC_GLOBAL',
  MILEAGE_ZERO_ERROR = 'MILEAGE_ZERO_ERROR',
  PACKAGE_BENEFIT = 'PACKAGE_BENEFIT',
  VEHICLE_PICKUP = 'VEHICLE_PICKUP',
  WASHING_VALETING = 'WASHING_VALETING',
  POINTS_VEHICLE_INSPECTION = 'POINTS_VEHICLE_INSPECTION',
  PHOTO_SHOOT = 'PHOTO_SHOOT',
  VEHICLE_DROP_OFF = 'VEHICLE_DROP_OFF',
  VEHICLE_LISTING_ON_GO_GO_MOTOR = 'VEHICLE_LISTING_ON_GO_GO_MOTOR',
  VEHICLE_HIGHLIGHT = 'VEHICLE_HIGHLIGHT',
  COMING_SOON = 'COMING_SOON',
  DRY_CLEAN_ONLY = 'DRY_CLEAN_ONLY',
  CUSTOMER_INSPECTION_POINT = 'CUSTOMER_INSPECTION_POINT',
  CUSTOMER_INSURE = 'CUSTOMER_INSURE',
  STATIC_PHOTOS_PROVIDED = 'STATIC_PHOTOS_PROVIDED',
  CUSTOMER_ENLIST = 'CUSTOMER_ENLIST',
  DAYS_15 = 'DAYS_15',
  DAYS_7 = 'DAYS_7',
  VEHICLE_PICKUP_DROP = 'VEHICLE_PICKUP_DROP',
  PHOTO_SHOOT_TEXT = 'PHOTO_SHOOT_TEXT',
  VEHICLE_LISTING_QUALITY_CONTROL = 'VEHICLE_LISTING_QUALITY_CONTROL',
  DIAMOND = 'DIAMOND',
  PLATINUM = 'PLATINUM',
  GOLD = 'GOLD',
  SILVER = 'SILVER',
  SELECT_PACKAGE = 'SELECT_PACKAGE',
  GOLD_PACKAGE = 'GOLD_PACKAGE',
  MOST_RECOMMENDED = 'MOST_RECOMMENDED',
  INVALID_DATA = 'INVALID_DATA',
  BUY_PREOWNED_TAB = 'BUY_PREOWNED_TAB',
  REGION = 'REGION',
  SELECT_REGION = 'SELECT_REGION',
  FRM_ERR_MSG_REGION = 'FRM_ERR_MSG_REGION',
  ACCOUNT_DELETE_SUCCESS = 'ACCOUNT_DELETE_SUCCESS',
  ACCOUNT_DELETE_FAILURE = 'ACCOUNT_DELETE_FAILURE',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  PASSWORD = 'PASSWORD',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  PASSWORD_NOT_MATCHES = 'PASSWORD_NOT_MATCHES',
  AGREE_GOGO_TEXT = 'AGREE_GOGO_TEXT',
  ALREADY_HAVE_ACCOUNT = 'ALREADY_HAVE_ACCOUNT',
  EMAIL_ID = 'EMAIL_ID',
  MSG_VERIFICATION_LINK_SENT = 'MSG_VERIFICATION_LINK_SENT',
  MSG_EMAIL_ALREADY_EXISTS = 'MSG_EMAIL_ALREADY_EXISTS',
  MSG_VERIFICATION_LINK_SENT_FAILURE = 'MSG_VERIFICATION_LINK_SENT_FAILURE',
  CHECK_YOUR_INBOX_HEAD = 'CHECK_YOUR_INBOX_HEAD',
  CHECK_YOUR_INBOX_MSG = 'CHECK_YOUR_INBOX_MSG',
  RESEND_VERIFICATION_LINK = 'RESEND_VERIFICATION_LINK',
  MSG_EMAIL_VERIFICATION_LINK_INVALID = 'MSG_EMAIL_VERIFICATION_LINK_INVALID',
  MSG_EMAIL_VERIFICATION_LINK_EXPIRED = 'MSG_EMAIL_VERIFICATION_LINK_EXPIRED',
  RESEND_OTP = 'RESEND_OTP',
  SEND_OTP = 'SEND_OTP',
  OTP = 'OTP',
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  VERIFY_MOBILE = 'VERIFY_MOBILE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ACCOUNT_CREATED_SUCCESSFULLY_MSG = 'ACCOUNT_CREATED_SUCCESSFULLY_MSG',
  TITLE = 'TITLE',
  PASSWORD_MIN_CHARACTER_MSG = 'PASSWORD_MIN_CHARACTER_MSG',
  PASSWORD_ATLEAST_NUMBER = 'PASSWORD_ATLEAST_NUMBER',
  PASSWORD_MSG_SPECIAL_CHARACTER = 'PASSWORD_MSG_SPECIAL_CHARACTER',
  PASSWORD_MSG_UPPERCASE_LOWERCASE = 'PASSWORD_MSG_UPPERCASE_LOWERCASE',
  MIDDLE_NAME = 'MIDDLE_NAME',
  ADDRESS_LINE_1 = 'ADDRESS_LINE_1',
  ADDRESS_LINE_2 = 'ADDRESS_LINE_2',
  COUNTRY = 'COUNTRY',
  POSTAL_CODE = 'POSTAL_CODE',
  WRONG_OTP = 'WRONG_OTP',
  OTP_VERIFIED_MSG = 'OTP_VERIFIED_MSG',
  PLEASE_ENTER_FIRST_NAME_MSG = 'PLEASE_ENTER_FIRST_NAME_MSG',
  PLEASE_ENTER_LAST_NAME_MSG = 'PLEASE_ENTER_LAST_NAME_MSG',
  PLEASE_SELECT_TITLE = 'PLEASE_SELECT_TITLE',
  PLEASE_ENTER_PASSWORD_MSG = 'PLEASE_ENTER_PASSWORD_MSG',
  CONFIRM_TERMS_CONDITION_MSG = 'CONFIRM_TERMS_CONDITION_MSG',
  WEAK_PASS_LBL = 'WEAK_PASS_LBL',
  AVERAGE_PASS_LBL = 'AVERAGE_PASS_LBL',
  STRONG_PASS_LBL = 'STRONG_PASS_LBL',
  NUMBER_ERR_MSG = 'NUMBER_ERR_MSG',
  ENTER_MOBILE_NUMBER = 'ENTER_MOBILE_NUMBER',
  SHOULD_NOT_START_WITH_ZERO = 'SHOULD_NOT_START_WITH_ZERO',
  MSG_ALREADY_VERIFIED = 'MSG_ALREADY_VERIFIED',
  MSG_INVALID_REQUEST = 'MSG_INVALID_REQUEST',
  MSG_OTP_SENT_SUCCESSFULLY = 'MSG_OTP_SENT_SUCCESSFULLY',
  MSG_MOBILE_ALREADY_EXISTS = 'MSG_MOBILE_ALREADY_EXISTS',
  MSG_EMAIL_NOT_VERIFIED = 'MSG_EMAIL_NOT_VERIFIED',
  MSG_USER_ALREADY_EXIST = 'MSG_USER_ALREADY_EXIST',
  COUNTRY_CODE_LABEL = 'COUNTRY_CODE_LABEL',
  SELECT_COUNTRY = 'SELECT_COUNTRY',
  NEW = 'NEW',
  NEW_CARS = 'NEW_CARS',
  PRE_OWNED_CARS = 'PRE_OWNED_CARS',
  ALL_CARS = 'ALL_CARS',
  OLD_MOBILE_MSG = 'OLD_MOBILE_MSG',
  NEW_MOBILE_MSG = 'NEW_MOBILE_MSG',
  OLD_MOBILE_NUMBER = 'OLD_MOBILE_NUMBER',
  NEW_MOBILE_NUMBER = 'NEW_MOBILE_NUMBER',
  SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
  RESEND_VERIFICATION_CODE = 'RESEND_VERIFICATION_CODE',
  CHANGE_MOBILE_NUMBER_LBL = 'CHANGE_MOBILE_NUMBER_LBL',
  VERIFY_PHONE_NUMBER_LBL = 'VERIFY_PHONE_NUMBER_LBL',
  MSG_OLD_MOBILE_NUMBER_ALREADY_UPDATED = 'MSG_OLD_MOBILE_NUMBER_ALREADY_UPDATED',
  MOBILE_CHANGED_SUCCESSFULLY_MSG = 'MOBILE_CHANGED_SUCCESSFULLY_MSG',
  PASSWORD_LIMITATION_ERROR = 'PASSWORD_LIMITATION_ERROR',
  CONFIRM_PASSWORD_ERROR_MSG = 'CONFIRM_PASSWORD_ERROR_MSG',
  EMAIL_FORMAT_SHOULD_BE = 'EMAIL_FORMAT_SHOULD_BE',
  MSG_ACCOUNT_CREATED = 'MSG_ACCOUNT_CREATED',
  INVALID_PLATE_NUMBER = 'INVALID_PLATE_NUMBER',
  BTN_FINANCE_IT = 'BTN_FINANCE_IT',
  FINANCE_REQUEST_SUCCESS_MSG = 'FINANCE_REQUEST_SUCCESS_MSG',
  FINANCE_REQUEST_ERROR_MSG = 'FINANCE_REQUEST_ERROR_MSG',
  HELLO = 'HELLO',
  FINANCE_REQUESTED = 'FINANCE_REQUESTED',
  YOU_HAVE_NOT_FINANCE_REQUESTED_ANY_VEHICLE = 'YOU_HAVE_NOT_FINANCE_REQUESTED_ANY_VEHICLE',
  QUOTATIONS = 'QUOTATIONS',
  MY_CART = 'MY_CART',
  CREDIT_COUNT = 'CREDIT_COUNT',
  FEATURED_COUNT = 'FEATURED_COUNT',
  AMOUNT = 'AMOUNT',
  QUANTITY = 'QUANTITY',
  SUBSCRIPTION = 'SUBSCRIPTION',
  CHECKOUT = 'CHECKOUT',
  CONTINUE = 'CONTINUE',
  ITEM_REMOVE_SUCCESSFULLY = 'ITEM_REMOVE_SUCCESSFULLY',
  ERROR_REMOVE_ITEM = 'ERROR_REMOVE_ITEM',
  TOTAL_MRP = 'TOTAL_MRP',
  VAT = 'VAT',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  CONFIRMATION_ITEM = 'CONFIRMATION_ITEM',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PURCHASE_DETAILS = 'PURCHASE_DETAILS',
  VAT_AMOUNT = 'VAT_AMOUNT',
  TOTAL = 'TOTAL',
  PACKAGE_NAME = 'PACKAGE_NAME',
  DESCRIPTION = 'DESCRIPTION',
  PAYMENT_FAILURE = 'PAYMENT_FAILURE',
  TRANSACTION_FAILED_MSG = 'TRANSACTION_FAILED_MSG',
  REFUND_MONEY_MSG = 'REFUND_MONEY_MSG',
  CONTINUE_LBL = 'CONTINUE_LBL',
  PACKAGE_BUY_CONFIRM = 'PACKAGE_BUY_CONFIRM',
  EMPTY_CART = 'EMPTY_CART',
  MY_ORDERS = 'MY_ORDERS',
  ORDER_NO = 'ORDER_NO',
  ORDER_DATE = 'ORDER_DATE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE',
  ITEMS = 'ITEMS',
  ITEM_ID = 'ITEM_ID',
  LIST_CAR = 'LIST_CAR',
  CANCEL_ORDER = 'CANCEL_ORDER',
  SERVICE_NOTE = 'SERVICE_NOTE',
  ORDER_SUMMARY = 'ORDER_SUMMARY',
  APPLY_PROMO = 'APPLY_PROMO',
  PROMO_CODE_APPLIED = 'PROMO_CODE_APPLIED',
  INVALID_PROMO = 'INVALID_PROMO',
  INVOICE_NUMBER = 'INVOICE_NUMBER',
  INCLUSIVE_VAT = 'INCLUSIVE_VAT',
  PAYMENT_DATE = 'PAYMENT_DATE',
  LIST_VEHICLE = 'LIST_VEHICLE',
  REQUEST_REFUND = 'REQUEST_REFUND',
  EMPTY_ORDER_LIST = 'EMPTY_ORDER_LIST',
  PAYMENT_FAILURE_DATE = 'PAYMENT_FAILURE_DATE',
  ELIGIBILITY_TEXT = 'ELIGIBILITY_TEXT',
  AVAIL_OFFER_BUTTON_TEXT = 'AVAIL_OFFER_BUTTON_TEXT',
  ADDITIONAL_PROMOTION_DETAILS = 'ADDITIONAL_PROMOTION_DETAILS',
  PROMOTION_TERMS_CONDITIONS = 'PROMOTION_TERMS_CONDITIONS',
  PAYMENT_FAILURE_TEXT = 'PAYMENT_FAILURE_TEXT',
  PACKAGE = 'PACKAGE',
  BTN_BUY_CAR = 'BTN_BUY_CAR',
  YOU_HAVE_NOT_SELECT_ANY_VEHICLE = 'YOU_HAVE_NOT_SELECT_ANY_VEHICLE',
  SHOPPING_CART = 'SHOPPING_CART',
  MY_QUOTATIONS = 'MY_QUOTATIONS',
  LBL_BUY = 'LBL_BUY',
  FINANCE_IT_CONFIRMATION_TEXT = 'FINANCE_IT_CONFIRMATION_TEXT',
  LBL_CONFIRMATION = 'LBL_CONFIRMATION',
  LBL_THANK_YOU = 'LBL_THANK_YOU',
  FINANCE_IT_SUCCESS_MSG_1 = 'FINANCE_IT_SUCCESS_MSG_1',
  LBL_VEHICLES = 'LBL_VEHICLES',
  FINANCE_DELETE_CONFIRMATION_TEXT = 'FINANCE_DELETE_CONFIRMATION_TEXT',
  DELETE_SUCCESS = 'DELETE_SUCCESS',
  YOU_CANNOT_PERFORM_ACTION_MSG = 'YOU_CANNOT_PERFORM_ACTION_MSG',
  VISIT_SHOPPING_CART = 'VISIT_SHOPPING_CART',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  PACKAGES = 'PACKAGES',
  REMOVE = 'REMOVE',
  INVOICE_NOT_GENERATE_MSG = 'INVOICE_NOT_GENERATE_MSG',
  PLEASE_SCAN = 'PLEASE_SCAN',
  INVOICE_NOT_GENERATE_PAYMENT_MSG = 'INVOICE_NOT_GENERATE_PAYMENT_MSG',
  COULD_NOT_PROCESS_PAYMENT = 'COULD_NOT_PROCESS_PAYMENT',
  CLICK_APPLY = 'CLICK_APPLY',
  SALES_AND_PURCHASE = 'SALES_AND_PURCHASE',
  REFUND_AND_CANCELLATION = 'REFUND_AND_CANCELLATION',
  PLEASE_VISIT_APP_STORE = 'PLEASE_VISIT_APP_STORE',
  NEED_HELP = 'NEED_HELP',
  GOGOMOTOR_WORK_TIME1 = 'GOGOMOTOR_WORK_TIME1',
  GOGOMOTOR_WORK_TIME2 = 'GOGOMOTOR_WORK_TIME2',
  GOGOMOTOR_ADDRESS = 'GOGOMOTOR_ADDRESS',
  PAYMENT_METHODS = 'PAYMENT_METHODS',
  CANNOT_PROCESS_REQUEST = 'CANNOT_PROCESS_REQUEST',
  CONFIRM_DELETE_PROMO_CODE = 'CONFIRM_DELETE_PROMO_CODE',
  VEHICLES_FOUND = 'VEHICLES_FOUND',
  PLEASE_ENTER_OTP = 'PLEASE_ENTER_OTP',
  CONCIERGE_NAME = 'CONCIERGE_NAME',
  ENTER_CONCIERGE_NAME = 'ENTER_CONCIERGE_NAME',
  QUOTATION = 'QUOTATION',
  STATUS = 'STATUS',
  STREET = 'STREET',
  SELECT_DISTRICT = 'SELECT_DISTRICT',
  STREET_REQUIRED = 'STREET_REQUIRED',
  BUILDING_NUMBER_REQUIRED = 'BUILDING_NUMBER_REQUIRED',
  ADDITIONAL_NUMBER_REQUIRED = 'ADDITIONAL_NUMBER_REQUIRED',
  DISTRICT_REQUIRED = 'DISTRICT_REQUIRED',
  PROVINCE = 'PROVINCE',
  SELECT_PROVINCE = 'SELECT_PROVINCE',
  PROVINCE_REQUIRED = 'PROVINCE_REQUIRED',
  MAX_50_CHARCTERS_ALLOWED = 'MAX_50_CHARCTERS_ALLOWED',
  SCHEDULE_INSPECTION = 'SCHEDULE_INSPECTION',
  INSPECTION_BOOKING_MSG = 'INSPECTION_BOOKING_MSG',
  LBL_STREET_NAME = 'LBL_STREET_NAME',
  LBL_BUILDING_NUMBER = 'LBL_BUILDING_NUMBER',
  CHKBOX_SAME_AS_PROFILE_ADDRESS = 'CHKBOX_SAME_AS_PROFILE_ADDRESS',
  ENTER_ADDRESS_FOR_INSPECTION = 'ENTER_ADDRESS_FOR_INSPECTION',
  ENTER_VEHICLE_PICKUP_ADDRESS_FOR_INSPECTION = 'ENTER_VEHICLE_PICKUP_ADDRESS_FOR_INSPECTION',
  INSPECTION_NOTE = 'INSPECTION_NOTE',
  MONTHLY_EMI = 'MONTHLY_EMI',
  LBL_MONTH = 'LBL_MONTH',
  LBL_EMI_MONTH = 'LBL_EMI_MONTH',
  UPDATE_QC_REJECTED_IMAGES = 'UPDATE_QC_REJECTED_IMAGES',
  CONFIRM_DETAILS_MSG1 = 'CONFIRM_DETAILS_MSG1',
  CONFIRM_DETAILS_MSG2 = 'CONFIRM_DETAILS_MSG2',
  FAILED_OPERATION = 'FAILED_OPERATION',
  INSPECTION_BOOKING_MSG1 = 'INSPECTION_BOOKING_MSG1',
  SELECT_PRICE_RANGE_MAX = 'SELECT_PRICE_RANGE_MAX',
  FINANCE_IT_NOTE = 'FINANCE_IT_NOTE',
  ADD_SERVICES = 'ADD_SERVICES',
  ALSO_CONSIDER = 'ALSO_CONSIDER',
  VIEW_DETAILS = 'VIEW_DETAILS',
  PROCEED = 'PROCEED',
  ITEM = 'ITEM',
  MOTOR_INSURANCE_WITH_FINANCE_WARNING_MSG = 'MOTOR_INSURANCE_WITH_FINANCE_WARNING_MSG',
  HOW_TO_BUY = 'HOW_TO_BUY',
  FINANCE_IT = 'FINANCE_IT',
  CASH = 'CASH',
  TOTAL_QUOTATION_AMOUNT = 'TOTAL_QUOTATION_AMOUNT',
  MOTOR_INSURANCE = 'MOTOR_INSURANCE',
  EXTENDED_WARRANTY = 'EXTENDED_WARRANTY',
  ROADSIDE_ASSISTANCE = 'ROADSIDE_ASSISTANCE',
  CAR_CARE = 'CAR_CARE',
  RECONDITIONING = 'RECONDITIONING',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  PLEASE_CHOOSE_OPTION = 'PLEASE_CHOOSE_OPTION',
  LBL_VEHICLE_ID = 'LBL_VEHICLE_ID',
  DONE = 'DONE',
  CASH_BUY_OPTION = 'CASH_BUY_OPTION',
  FINANCE_BUY_OPTION = 'FINANCE_BUY_OPTION',
  BUY_OPTION_HEADING = 'BUY_OPTION_HEADING',
  RESIDUAL_VALUE = 'RESIDUAL_VALUE',
  BTN_BUY_INSPECTION = 'BTN_BUY_INSPECTION',
  BTN_BOOK_APPOINTMENT = 'BTN_BOOK_APPOINTMENT',
  INSPECTION_DECLAIMER_MSG = 'INSPECTION_DECLAIMER_MSG',
  SERVICE_EXIST_CART = 'SERVICE_EXIST_CART',
  BUY_INSPECTION_NO_SERVICE_MSG = 'BUY_INSPECTION_NO_SERVICE_MSG',
  ADD_TO_CART = 'ADD_TO_CART',
  ENTER_VALID_BUYER_NUMBER = 'ENTER_VALID_BUYER_NUMBER',
  ALREADY_FINANCE_AVAILED_MESSAGE = 'ALREADY_FINANCE_AVAILED_MESSAGE',
  MSG_ACCOUNT_CREATED_SIGN_IN = 'MSG_ACCOUNT_CREATED_SIGN_IN',
  CLICK_BELOW_TO_CONTINUE_MSG = 'CLICK_BELOW_TO_CONTINUE_MSG',
  INSPECTION_DETAILS = 'INSPECTION_DETAILS',
  OUTLET = 'OUTLET',
  TERMS_1 = 'TERMS_1',
  TERMS_2 = 'TERMS_2',
  TERMS_3 = 'TERMS_3',
  TERMS_4 = 'TERMS_4',
  TIPS_ADVICE = 'TIPS_ADVICE',
  ERR_DUPLICATE_SHOPPING_CART_ITEM = 'ERR_DUPLICATE_SHOPPING_CART_ITEM',
  ERR_ZERO_PRICE_SHOPPING_CART_ITEM = 'ERR_ZERO_PRICE_SHOPPING_CART_ITEM',
  TOO_LOW_THAN_MARKET_PRICE = 'TOO_LOW_THAN_MARKET_PRICE',
  TOO_HIGH_THAN_MARKET_PRICE = 'TOO_HIGH_THAN_MARKET_PRICE',
  GENERIC_ERROR_MY_CAR_VALUE = 'GENERIC_ERROR_MY_CAR_VALUE',
  CONTINUE_SHOPPING = 'CONTINUE_SHOPPING',
  MFG_IMAGE_DISCLAIMER_TEXT = 'MFG_IMAGE_DISCLAIMER_TEXT',
  LISTING_PAGE_ERROR_MESSAGE = 'LISTING_PAGE_ERROR_MESSAGE',
  INVALID_PROMO_CODE = 'INVALID_PROMO_CODE',
  DISCLAIMER = 'DISCLAIMER',
  PE_LOCATION_ADDRESS = 'PE_LOCATION_ADDRESS',
  EXHAUSTED_PROMO_CODE = 'EXHAUSTED_PROMO_CODE',
  LISTING_SUMMARY_PLACEHOLDER = 'LISTING_SUMMARY_PLACEHOLDER',
  PLATE_NUMBER_HELP_TEXT = 'PLATE_NUMBER_HELP_TEXT',
  CAMPAIGN_MAIN_HEADING = 'CAMPAIGN_MAIN_HEADING',
  SELECT_GOLD_PACKAGE = 'SELECT_GOLD_PACKAGE',
  GOLD_PACKAGE_HEADING = 'GOLD_PACKAGE_HEADING',
  APPLY_PROMO_CODE = 'APPLY_PROMO_CODE',
  APPLY_PROMO_CODE_HEADING = 'APPLY_PROMO_CODE_HEADING',
  ADD_CAR_DETAILS = 'ADD_CAR_DETAILS',
  ADD_CAR_DETAILS_HEADING = 'ADD_CAR_DETAILS_HEADING',
  CAR_FOR_INSPECTION = 'CAR_FOR_INSPECTION',
  CAR_FOR_INSPECTION_HEADING = 'CAR_FOR_INSPECTION_HEADING',
  NEED_TO_KNOW_TEXT = 'NEED_TO_KNOW_TEXT',
  ONE_CLICK_AWAY = 'ONE_CLICK_AWAY',
  GET_STARTED_NOW = 'GET_STARTED_NOW',
  INSPECTION_MAIN_HEADING = 'INSPECTION_MAIN_HEADING',
  INSPECTION_ITEM_1 = 'INSPECTION_ITEM_1',
  INSPECTION_ITEM_2 = 'INSPECTION_ITEM_2',
  INSPECTION_ITEM_3 = 'INSPECTION_ITEM_3',
  INSPECTION_ITEM_4 = 'INSPECTION_ITEM_4',
  MY_CAR_OFFER_HEADING = 'MY_CAR_OFFER_HEADING',
  MY_CAR_OFFER_PARAGRAPH = 'MY_CAR_OFFER_PARAGRAPH',
  GOGO_MOTOR_CRITERIA = 'GOGO_MOTOR_CRITERIA',
  GOGO_MOTOR_CRITERIA_PARAGRAPH = 'GOGO_MOTOR_CRITERIA_PARAGRAPH',
  GOT_ANSWER = 'GOT_ANSWER',
  GOT_ANSWER_HEADING = 'GOT_ANSWER_HEADING',
  PLEASE_CLICK_TO_FIND_LOCATION = 'PLEASE_CLICK_TO_FIND_LOCATION',
  INVALID_SEQUENCE_NUMBER = 'INVALID_SEQUENCE_NUMBER',
  INVALID_OWNER_ID = 'INVALID_OWNER_ID',
  INVALID_IQAMA_NUMBER = 'INVALID_IQAMA_NUMBER',
  INVALID_NATIONAL_ID = 'INVALID_NATIONAL_ID',
  SIX_STEPS_AWAY_MSG = 'SIX_STEPS_AWAY_MSG',
  EXCL_VAT = 'EXCL_VAT',
  SOLD = 'SOLD',
  CHANGE_PRICE = 'CHANGE_PRICE',
  INSPECTED_LBL = 'INSPECTED_LBL',
  MILEAGE_YEAR_PRICE = 'MILEAGE_YEAR_PRICE',
  DO_YOU_WANT_TO_CHANGE_PRICE_MSG = 'DO_YOU_WANT_TO_CHANGE_PRICE_MSG',
  AND = 'AND',
  MOJAZ_LITE_REPORT = 'MOJAZ_LITE_REPORT',
  BUY_FULL_MOJAZ_REPORT = 'BUY_FULL_MOJAZ_REPORT',
  UPGRADE_PACKAGE = 'UPGRADE_PACKAGE',
  CAR_DESCRIPTION = 'CAR_DESCRIPTION',
  MOJAZ_FULL_REPORT = 'MOJAZ_FULL_REPORT',
  VIN_NUMBER_FAILURE = 'VIN_NUMBER_FAILURE',
  VIN_NUMBER_CONFIRMATION = 'VIN_NUMBER_CONFIRMATION',
  MILAGE_REDUCE_ERROR = 'MILAGE_REDUCE_ERROR',
  UPDATE_VIN_NUMBER = 'UPDATE_VIN_NUMBER',
  SHOW_SELF_LISTED = 'SHOW_SELF_LISTED',
  UPLOAD_MOJAZ_SUCCESS = 'UPLOAD_MOJAZ_SUCCESS',
  UPLOAD_MOJAZ_REPORT = 'UPLOAD_MOJAZ_REPORT',
  SURVEY_DETAILS = 'SURVEY_DETAILS',
  PAID_PURCHASE = 'PAID_PURCHASE',
  I_ACKNOWLEDGE = 'I_ACKNOWLEDGE',
  PICK_YOUR_SELECTION = 'PICK_YOUR_SELECTION',
  USED_CARS = 'USED_CARS',
  OUTLET_CARS = 'OUTLET_CARS',
  PLEASE_SELECT_THE_CHECKBOX = 'PLEASE_SELECT_THE_CHECKBOX',
  AFTER_REPAIR = 'AFTER_REPAIR',
  BEFORE_REPAIR = 'BEFORE_REPAIR',
  REFUND_ALREADY_REQUESTED = 'REFUND_ALREADY_REQUESTED',
  OUTLET_OVERVIEW = 'OUTLET_OVERVIEW',
  TOTAL_OWNERS = 'TOTAL_OWNERS',
  LAST_ODOMETER_READING = 'LAST_ODOMETER_READING',
  WARRANTY_STATUS = 'WARRANTY_STATUS',
  NEW_CAR_BRAND_SEARCH = 'NEW_CAR_BRAND_SEARCH',
  EXPLORE = 'EXPLORE',
  BY_BRANDS = 'BY_BRANDS',
  REGISTERED_NAME = 'REGISTERED_NAME',
  DEALER_VAT_NUMBER = 'DEALER_VAT_NUMBER',
  FILE_UPLOADED_SUCCESSFULLY = 'FILE_UPLOADED_SUCCESSFULLY',
  VIN_NUMBER_VALIDATION = 'VIN_NUMBER_VALIDATION',
  FEATURES_SPECIFICATION = 'FEATURES_SPECIFICATION',
  OUTLET_IN_STOCK = 'OUTLET_IN_STOCK',
  SHARE = 'SHARE',
  CHECK_YOUR_PERSONALIZED_OFFER = 'CHECK_YOUR_PERSONALIZED_OFFER',
  EMI_START_FROM = 'EMI_START_FROM',
  PRICE_START_FROM = 'PRICE_START_FROM',
  DOCUMENT = 'DOCUMENT',
  VARIANT = 'VARIANT',
  COLOR = 'COLOR',
  SEARCH_FEATURES_SPECIFICATION = 'SEARCH_FEATURES_SPECIFICATION',
  EMI_MESSAGE = 'EMI_MESSAGE',
  AFTER = 'AFTER',
  BEFORE = 'BEFORE',
  SUMMARY = 'SUMMARY',
  IMPORTED_BY = 'IMPORTED_BY',
  SUPPLIED_BY = 'SUPPLIED_BY',
  FULFILLED_BY = 'FULFILLED_BY',
  BUY_NOW = 'BUY_NOW',
  EXPLORE_MORE = 'EXPLORE_MORE',
  INDICATIVE_EMI = 'INDICATIVE_EMI',
  HOW_WOULD_YOU_LIKE_TO_PROCEED_MSG = 'HOW_WOULD_YOU_LIKE_TO_PROCEED_MSG',
  CASH_FINANCE_OPTION_MSG = 'CASH_FINANCE_OPTION_MSG',
  PAY_BY_LBL = 'PAY_BY_LBL',
  PAY_FULL_AMOUNT_MSG = 'PAY_FULL_AMOUNT_MSG',
  FINANCE_IT_MSG = 'FINANCE_IT_MSG',
  MULTIPLE_FINANCE_MSG = 'MULTIPLE_FINANCE_MSG',
  FINANCE_SERVICE_MSG = 'FINANCE_SERVICE_MSG',
  IN_STOCK = 'IN_STOCK',
  PRE_OWNED = 'PRE_OWNED',
  EMI_LABEL = 'EMI_LABEL',
  ELIGIBLE_EXTENDED_WARRANTY_LABEL = 'ELIGIBLE_EXTENDED_WARRANTY_LABEL',
  YEAR_PRICE_EMI_LABEL = 'YEAR_PRICE_EMI_LABEL',
  YEAR_PRICE_LABEL = 'YEAR_PRICE_LABEL',
  SELF_LISTED = 'SELF_LISTED',
  DO_YOU_WISH_MESSAGE = 'DO_YOU_WISH_MESSAGE',
  HOME_DELIVERY_LABEL = 'HOME_DELIVERY_LABEL',
  ENTER_STREET = 'ENTER_STREET',
  CONFIRM_AND_PROCEED = 'CONFIRM_AND_PROCEED',
  ADD = 'ADD',
  VIEW_DETAILS_LABEL = 'VIEW_DETAILS_LABEL',
  CUSTOMIZE_NOW = 'CUSTOMIZE_NOW',
  PROVIDED_BY_LABEL = 'PROVIDED_BY_LABEL',
  ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS',
  ADD_ADDRESS = 'ADD_ADDRESS',
  BOOKING_TEXT = 'BOOKING_TEXT',
  SECURE_BOOKING = 'SECURE_BOOKING',
  BOOKING_AMOUNT_TEXT = 'BOOKING_AMOUNT_TEXT',
  PAY_FULL_AMOUNT = 'PAY_FULL_AMOUNT',
  FINAL_AMOUNT = 'FINAL_AMOUNT',
  CONSIDER_FOLLOWING_TEXT = 'CONSIDER_FOLLOWING_TEXT',
  FINANCE_HEADING = 'FINANCE_HEADING',
  FINANCE_TEXT = 'FINANCE_TEXT',
  FREE = 'FREE',
  LOGISTIC_TEXT = 'LOGISTIC_TEXT',
  BOOKING_AMOUNT = 'BOOKING_AMOUNT',
  MODEL_YEAR = 'MODEL_YEAR',
  UPLOAD_VALID_PDF_FILE = 'UPLOAD_VALID_PDF_FILE',
  INSTANT_REFUND = 'INSTANT_REFUND',
  INSTANT_REFUND_SUCCESS = 'INSTANT_REFUND_SUCCESS',
  GOGOMOTOR_LIST_CAR_TEXT = 'GOGOMOTOR_LIST_CAR_TEXT',
  GOGOMOTOR_SALE_HEADING = 'GOGOMOTOR_SALE_HEADING',
  GOGOMOTOR_SALE_ITEM_1 = 'GOGOMOTOR_SALE_ITEM_1',
  GOGOMOTOR_SALE_ITEM_2 = 'GOGOMOTOR_SALE_ITEM_2',
  GOGOMOTOR_SALE_ITEM_3 = 'GOGOMOTOR_SALE_ITEM_3',
  TERM_AND_CONDITION_TEXT = 'TERM_AND_CONDITION_TEXT',
  TERM_AND_CONDITION_ITEM_1 = 'TERM_AND_CONDITION_ITEM_1',
  TERM_AND_CONDITION_ITEM_2 = 'TERM_AND_CONDITION_ITEM_2',
  SILVER_PACKAGE_TEXT = 'SILVER_PACKAGE_TEXT',
  PROMO_CODE_TEXT = 'PROMO_CODE_TEXT',
  CAR_DETAILS_TEXT = 'CAR_DETAILS_TEXT',
  UPLOAD_PICTURE_TEXT = 'UPLOAD_PICTURE_TEXT',
  DOWNLOAD_APP = 'DOWNLOAD_APP',
  DOWNLOAD_APP_TEXT = 'DOWNLOAD_APP_TEXT',
  SILVER_PACKAGE_HEADING = 'SILVER_PACKAGE_HEADING',
  CAR_DETAILS_HEADING = 'CAR_DETAILS_HEADING',
  UPLOAD_PICTURE_HEADING = 'UPLOAD_PICTURE_HEADING',
  PROMO_CODE_HEADING = 'PROMO_CODE_HEADING',
  MAIN_HEADING = 'MAIN_HEADING',
  REFUND_REQUEST_REASON_QUESTION = 'REFUND_REQUEST_REASON_QUESTION',
  REFUND_REQUEST_PROCESS_SUCCESS = 'REFUND_REQUEST_PROCESS_SUCCESS',
  REFUND_REQUEST_FAIL_MSG = 'REFUND_REQUEST_FAIL_MSG',
  CANCELLED_LABEL = 'CANCELLED_LABEL',
  SELECT_VARIANT_LABEL = 'SELECT_VARIANT_LABEL',
  OUT_OF_STOCK_LABEL = 'OUT_OF_STOCK_LABEL',
  STOCK_AVAILABILITY_MSG = 'STOCK_AVAILABILITY_MSG',
  WISHLIST_IT_BTN = 'WISHLIST_IT_BTN',
  VEHICLE_UNAVAILABLE_MSG = 'VEHICLE_UNAVAILABLE_MSG',
  IMPORTER = 'IMPORTER',
  SUPPLIER = 'SUPPLIER',
  OUTLET_OVERVIEW_LABEL = 'OUTLET_OVERVIEW_LABEL',
  LISTING_LABEL = 'LISTING_LABEL',
  THANK_YOU_FINANCE_IT = 'THANK_YOU_FINANCE_IT',
  FINANCE_IT_SUCCESS_MSG = 'FINANCE_IT_SUCCESS_MSG',
  FINANCE_IT_SUCCESS_INFO_MSG = 'FINANCE_IT_SUCCESS_INFO_MSG',
  SHOW_MY_ORDERS_BUTTON = 'SHOW_MY_ORDERS_BUTTON',
  BOOKED = 'BOOKED',
  QR_SCAN = 'QR_SCAN',
  DOWNLOAD_OUR_APP = 'DOWNLOAD_OUR_APP',
  GET_STARTED = 'GET_STARTED',
  SCAN_QR_TO_DOWNLOAD_APP = 'SCAN_QR_TO_DOWNLOAD_APP',
  BALANCE_AMOUNT = 'BALANCE_AMOUNT',
  DAYS_REMAINING_TO_COMPLETE_PAYMENT = 'DAYS_REMAINING_TO_COMPLETE_PAYMENT',
  VIEW_BREAK_UP = 'VIEW_BREAK_UP',
  EXISTING_ITEM_MESSAGE = 'EXISTING_ITEM_MESSAGE',
  BOOKING_ID = 'BOOKING_ID',
  ENTER_APPLY_PROMO = 'ENTER_APPLY_PROMO',
  VAS_SERVICE_ERROR_MESSAGE = 'VAS_SERVICE_ERROR_MESSAGE',
  DISTRICT_REQUIRED_MESSAGE = 'DISTRICT_REQUIRED_MESSAGE',
  STREET_REQUIRED_MESSAGE = 'STREET_REQUIRED_MESSAGE',
  BACK_TO_SHOPPING_CART_LABEL = 'BACK_TO_SHOPPING_CART_LABEL',
  BUYER_UPLOAD_DOC_LABEL = 'BUYER_UPLOAD_DOC_LABEL',
  BOOKING_UNAVAILABLE = 'BOOKING_UNAVAILABLE',
  FILE_TYPE_ERROR_MSG = 'FILE_TYPE_ERROR_MSG',
  COMPLETE_YOUR_VEHICLE_PURCHASE = 'COMPLETE_YOUR_VEHICLE_PURCHASE',
  TIME_TICKING_LABEL = 'TIME_TICKING_LABEL',
  MINUTE_LABEL = 'MINUTE_LABEL',
  HOURS_LABEL = 'HOURS_LABEL',
  PAY_FULL_AMOUNT_TEXT = 'PAY_FULL_AMOUNT_TEXT',
  COMMENTS = 'COMMENTS',
  DELETE_DOC_CONFIRM_MESSAGE = 'DELETE_DOC_CONFIRM_MESSAGE',
  DOCUMENT_UPLOADED_SUCCESSFULLY = 'DOCUMENT_UPLOADED_SUCCESSFULLY',
  DOC_TYPE_SUBSCRIPTION_INVOICE = 'DOC_TYPE_SUBSCRIPTION_INVOICE',
  DOC_TYPE_RECEIPT_INVOICE = 'DOC_TYPE_RECEIPT_INVOICE',
  DOC_TYPE_QUOTATION_DOCUMENT = 'DOC_TYPE_QUOTATION_DOCUMENT',
  DOC_TYPE_GENERIC_INVOICE = 'DOC_TYPE_GENERIC_INVOICE',
  DOC_TYPE_DEBIT_NOTE = 'DOC_TYPE_DEBIT_NOTE',
  DOC_TYPE_CREDIT_NOTE = 'DOC_TYPE_CREDIT_NOTE',
  DOC_TYPE_QUOTATION = 'DOC_TYPE_QUOTATION',
  DOC_TYPE_DELIVERY_NOTE = 'DOC_TYPE_DELIVERY_NOTE',
  DOC_TYPE_VEHICLE_INVOICE = 'DOC_TYPE_VEHICLE_INVOICE',
  DOC_TYPE_PO_DOCUMENT = 'DOC_TYPE_PO_DOCUMENT',
  DOC_TYPE_PROFORMA_INVOICE = 'DOC_TYPE_PROFORMA_INVOICE',
  DOC_TYPE_FINAL_INVOICE = 'DOC_TYPE_FINAL_INVOICE',
  DOC_TYPE_PURCHASE_ORDER = 'DOC_TYPE_PURCHASE_ORDER',
  IM_INTERESTED = 'IM_INTERESTED',
  PLEASE_FILL_FORM = 'PLEASE_FILL_FORM',
  FINANCE = 'FINANCE',
  YOUR_REQUEST_SUCCESS = 'YOUR_REQUEST_SUCCESS',
  BACK_TO_DETAILS = 'BACK_TO_DETAILS',
  YOUR_NUMBER_VERIFIED = 'YOUR_NUMBER_VERIFIED',
  FINANCE_REQUIRED = 'FINANCE_REQUIRED',
  ARE_YOU_LOOKING = 'ARE_YOU_LOOKING',
  PLAN_TO_BUY_CAR = 'PLAN_TO_BUY_CAR',
  FIVE_STEPS_AWAY_MSG = 'FIVE_STEPS_AWAY_MSG',
  UPDATE_QC_REJECTED_DOCUMENT = 'UPDATE_QC_REJECTED_DOCUMENT',
  ENTER_EMAIL_ADDRESS = 'ENTER_EMAIL_ADDRESS',
  EMAIL_UPDATE_SUCCESS_MSG = 'EMAIL_UPDATE_SUCCESS_MSG',
  VERIFY_EMAIL_LABEL = 'VERIFY_EMAIL_LABEL',
  INVALID_OTP = 'INVALID_OTP',
  PLEASE_VERIFY_NUMBER = 'PLEASE_VERIFY_NUMBER',
  MONTHS = 'MONTHS',
  SIGN_IN_TO_GET_STARTED = 'SIGN_IN_TO_GET_STARTED',
  ZIP_NOT_ALL_ZERO = 'ZIP_NOT_ALL_ZERO',
  INTEREST_RATE_LESS_THAN_CONFIGURATION = 'INTEREST_RATE_LESS_THAN_CONFIGURATION',
  DEALER_NAME_AR = 'DEALER_NAME_AR',
  BROCHURES = 'BROCHURES',
  GET_PROSHIELD_QUOTES = 'GET_PROSHIELD_QUOTES',
  ADD_YOUR_CAR = 'ADD_YOUR_CAR',
  META_TITLE_NEW_CARS = 'META_TITLE_NEW_CARS',
  META_DESCRIPTION_NEW_CARS = 'META_DESCRIPTION_NEW_CARS',
  META_TITLE_CARS = 'META_TITLE_CARS',
  META_DESCRIPTION_CARS = 'META_DESCRIPTION_CARS',
  META_TITLE_SELL_FOR_ME = 'META_TITLE_SELL_FOR_ME',
  META_DESCRIPTION_SELL_FOR_ME = 'META_DESCRIPTION_SELL_FOR_ME',
  META_TITLE_ABOUT_US = 'META_TITLE_ABOUT_US',
  META_DESCRIPTION_ABOUT_US = 'META_DESCRIPTION_ABOUT_US',
  META_TITLE_MY_CARS_VALUE = 'META_TITLE_MY_CARS_VALUE',
  META_DESCRIPTION_MY_CARS_VALUE = 'META_DESCRIPTION_MY_CARS_VALUE',
  META_TITLE_CONTACT_US = 'META_TITLE_CONTACT_US',
  META_DESCRIPTION_CONTACT_US = 'META_DESCRIPTION_CONTACT_US',
  BRANDS = 'BRANDS',
  ENTER_VIN = 'ENTER_VIN',
  OR_SELECT_YOUR_CAR_BRAND = 'OR_SELECT_YOUR_CAR_BRAND',
  SEARCH_THE_BRAND = 'SEARCH_THE_BRAND',
  BankApproved = 'BankApproved',
  PoBoxNo = 'PoBoxNo',
  CityCode = 'CityCode',
  SPECIFICATIONS = 'SPECIFICATIONS',
  SEARCH_SPECIFICATIONS = 'SEARCH_SPECIFICATIONS',
  KEY_SPECIFICATIONS = 'KEY_SPECIFICATIONS',
  MIN_NUMBER_ERR_MSG = 'MIN_NUMBER_ERR_MSG',
  MAX_NUMBER_ERR_MSG = 'MAX_NUMBER_ERR_MSG',
  META_DESCRIPTION_LATEST_MODEL = 'META_DESCRIPTION_LATEST_MODEL',
  META_TITLE_LATEST_MODEL = 'META_TITLE_LATEST_MODEL',
  META_TITLE_LATEST_MODEL_LISTING = 'META_TITLE_LATEST_MODEL_LISTING',
  META_DESCRIPTION_LATEST_MODEL_LISTING = 'META_DESCRIPTION_LATEST_MODEL_LISTING',
  ALLOWED_MAX_5000_CHAR = 'ALLOWED_MAX_5000_CHAR',
  SEARCH_THE_BRAND_PLACEHOLDER = 'SEARCH_THE_BRAND_PLACEHOLDER',
  SEARCH_MODEL_PLACEHOLDER = 'SEARCH_MODEL_PLACEHOLDER',
  ALL_BRANDS = 'ALL_BRANDS',
  CAR_IS_IN_HIGH_DEMAND = 'CAR_IS_IN_HIGH_DEMAND',
  TIPS_OF_PHOTOS = 'TIPS_OF_PHOTOS',
  UPLOAD_CAR_PHOTOS = 'UPLOAD_CAR_PHOTOS',
  UPLOAD_MORE_PHOTOS = 'UPLOAD_MORE_PHOTOS',
  INCORRECT_INPUTS = 'INCORRECT_INPUTS',
  OTHER_POPULAR_MODELS = 'OTHER_POPULAR_MODELS',
  SELL_YOUR_CAR_EFFORTLESSLY = 'SELL_YOUR_CAR_EFFORTLESSLY',
  VIEW_ALL_BRANDS = 'VIEW_ALL_BRANDS',
  UPLOADED = 'UPLOADED',
  SET_IT_AS_COVER_IMAGE = 'SET_IT_AS_COVER_IMAGE',
  COVER_IMAGE = 'COVER_IMAGE',
  CHANGE_COVER_IMAGE = 'CHANGE_COVER_IMAGE',
  USE_AS_COVER = 'USE_AS_COVER',
  ADD_PRICE_TO_CONTINUE = 'ADD_PRICE_TO_CONTINUE',
  PRICE_IS_NEGOTIABLE = 'PRICE_IS_NEGOTIABLE',
  ALLOW_BUYERS_TO_REACH_OUT_TO_YOU_VIA = 'ALLOW_BUYERS_TO_REACH_OUT_TO_YOU_VIA',
  PHONE_CALL = 'PHONE_CALL',
  ENTER_YOUR_EMAIL = 'ENTER_YOUR_EMAIL',
  ALLOW_GOGOMOTOR_TO_SEND_EMAILS_AND_COMMUNICATION = 'ALLOW_GOGOMOTOR_TO_SEND_EMAILS_AND_COMMUNICATION',
  BY_CONTINUING_I_AGREE_TO_GOGOMOTORS = 'BY_CONTINUING_I_AGREE_TO_GOGOMOTORS',
  CONFIRM_AND_PUBLISH = 'CONFIRM_AND_PUBLISH',
  INVALID_EMAIL = 'INVALID_EMAIL',
  SELECT_A_COVER_IMAGE_FOR_YOUR_POST = 'SELECT_A_COVER_IMAGE_FOR_YOUR_POST',
  SELL_YOUR_CAR_QUICKLY = 'SELL_YOUR_CAR_QUICKLY',
  GET_UPTO_40_PERCENT_MORE_ENGAGEMENT = 'GET_UPTO_40_PERCENT_MORE_ENGAGEMENT',
  GET_BETTER_VALUATION_OF_YOUR_CAR = 'GET_BETTER_VALUATION_OF_YOUR_CAR',
  CHANCE_TO_SELL_YOUR_CAR_FASTER = 'CHANCE_TO_SELL_YOUR_CAR_FASTER',
  SEE_ALL_PLANS = 'SEE_ALL_PLANS',
  TIPS_ON_GETTING_GOOD_PHOTOS = 'TIPS_ON_GETTING_GOOD_PHOTOS',
  CLICK_MULTIPLE_PHOTOS_AND_COVER_360 = 'CLICK_MULTIPLE_PHOTOS_AND_COVER_360',
  USE_GOOD_HIGH_RESOLUTION_CAMERA = 'USE_GOOD_HIGH_RESOLUTION_CAMERA',
  MAKE_SURE_ITS_UNDER_NATURAL_LIGHT = 'MAKE_SURE_ITS_UNDER_NATURAL_LIGHT',
  TRY_AVOIDING_ANOTHER_CAR_IN_PHOTO = 'TRY_AVOIDING_ANOTHER_CAR_IN_PHOTO',
  AVOID_ANY_REFLECTION_OR_SHADOW = 'AVOID_ANY_REFLECTION_OR_SHADOW',
  RECOMMENDED = 'RECOMMENDED',
  SEE_DETAILS = 'SEE_DETAILS',
  PACK = 'PACK',
  PLAN = 'PLAN',
  APPLY_PROMO_CODE_V1 = 'APPLY_PROMO_CODE_V1',
  AMOUNT_DETAILS = 'AMOUNT_DETAILS',
  TOTAL_COST = 'TOTAL_COST',
  VATS = 'VATS',
  INCORRECT_COUPON_CODE = 'INCORRECT_COUPON_CODE',
  HOW_IT_WORKS_V1 = 'HOW_IT_WORKS_V1',
  PROMOTION_PACKAGE = 'PROMOTION_PACKAGE',
  PAID = 'PAID',
  CHECK_RECEIPT = 'CHECK_RECEIPT',
  WHATS_NEXT = 'WHATS_NEXT',
  TEAM_WILL_CONTACT_AND_ASSIST = 'TEAM_WILL_CONTACT_AND_ASSIST',
  EXPLORE_NEW_CARS_BY_BUDGET = 'EXPLORE_NEW_CARS_BY_BUDGET',
  EXPLORE_UNDER_75K = 'EXPLORE_UNDER_75K',
  EXPLORE_75K_130K = 'EXPLORE_75K_130K',
  EXPLORE_130K_200K = 'EXPLORE_130K_200K',
  EXPLORE_200K_250K = 'EXPLORE_200K_250K',
  EXPLORE_250K_PLUS = 'EXPLORE_250K_PLUS',
  IMPROPER_IMAGE = 'IMPROPER_IMAGE',
  COUPON_OFFERS = 'COUPON_OFFERS',
  ACCEPTED_PAYMENT_METHODS = 'ACCEPTED_PAYMENT_METHODS',
  COMPANY = 'COMPANY',
  AVAILABLE_CITIES = 'AVAILABLE_CITIES',
  SELL_YOUR_CAR = 'SELL_YOUR_CAR',
  DEALS = 'DEALS',
  CAR_SERVICES = 'CAR_SERVICES',
  LOGIN = 'LOGIN',
  NEW_CAR = 'NEW_CAR',
  PRE_OWNED_CAR = 'PRE_OWNED_CAR',
  EXPLORE_BY_BRAND = 'EXPLORE_BY_BRAND',
  PRICE_RANGE = 'PRICE_RANGE',
  SEARCH_MAKE_MODEL_TYPE = 'SEARCH_MAKE_MODEL_TYPE',
  TOP_RATED_PRE_OWNED_CARS = 'TOP_RATED_PRE_OWNED_CARS',
  VIEW_ALL_RECOMMEDED_CARS = 'VIEW_ALL_RECOMMEDED_CARS',
  IN_DEMAND_NEW_CARS = 'IN_DEMAND_NEW_CARS',
  VIEW_ALL_NEW_CARS = 'VIEW_ALL_NEW_CARS',
  NEW_LAUNCH = 'NEW_LAUNCH',
  EXCLUSIVE_CAR_DEALS = 'EXCLUSIVE_CAR_DEALS',
  DRIVE_AWAY_WITH_ZERO_DOWN_PAYMENT_TEXT = 'DRIVE_AWAY_WITH_ZERO_DOWN_PAYMENT_TEXT',
  VIEW_ALL_CARS_ON_DEAL = 'VIEW_ALL_CARS_ON_DEAL',
  TOP_SEARCHED_USED_CARS = 'TOP_SEARCHED_USED_CARS',
  VIEW_ALL_CARS = 'VIEW_ALL_CARS',
  LOOKING_TO_SELL_YOUR_CAR = 'LOOKING_TO_SELL_YOUR_CAR',
  LOOKING_TO_SELL_YOUR_CAR_CONTENT = 'LOOKING_TO_SELL_YOUR_CAR_CONTENT',
  CHECK_VALUATION = 'CHECK_VALUATION',
  ON_THE_HORIZON_UPCOMING_LAUNCHER = 'ON_THE_HORIZON_UPCOMING_LAUNCHER',
  EXPECTED_LAUNCH = 'EXPECTED_LAUNCH',
  DISCOUNTED_NEW_CARS_FOR_YOUR_BUDGET = 'DISCOUNTED_NEW_CARS_FOR_YOUR_BUDGET',
  COMPARE_TO_BUY_THE_RIGHT_CAR = 'COMPARE_TO_BUY_THE_RIGHT_CAR',
  EXPLORE_BY_LOCATION = 'EXPLORE_BY_LOCATION',
  VIEW_ALL_CITIES = 'VIEW_ALL_CITIES',
  EXPLORE_BY_BODY_TYPE = 'EXPLORE_BY_BODY_TYPE',
  VIEW_ALL_COMPARES = 'VIEW_ALL_COMPARES',
  CAR_KINGDOM_NEWS = 'CAR_KINGDOM_NEWS',
  VIEW_ALL_LATEST_NEWS = 'VIEW_ALL_LATEST_NEWS',
  CERTIFIED = 'CERTIFIED',
  ESTIMATED_PRICE = 'ESTIMATED_PRICE',
  CHECK_ON_ROAD_PRICE = 'CHECK_ON_ROAD_PRICE',
  GET_NOTIFIED = 'GET_NOTIFIED',
  EMI_STARTING_FROM = 'EMI_STARTING_FROM',
  ZERO_DOWN_PAYMENT = 'ZERO_DOWN_PAYMENT',
  YEARS_ROAD_SIDE_ASSISTANCE = 'YEARS_ROAD_SIDE_ASSISTANCE',
  GOGO_MOTOR_CAR_SERVICES = 'GOGO_MOTOR_CAR_SERVICES',
  CAR_INSPECTION = 'CAR_INSPECTION',
  CAR_INSPECTION_CONTENT = 'CAR_INSPECTION_CONTENT',
  CAR_INSPECTION_LINK_TEXT = 'CAR_INSPECTION_LINK_TEXT',
  EXTENDED_WARRANTY_CONTENT = 'EXTENDED_WARRANTY_CONTENT',
  EXTENDED_WARRANTY_LINK_TEXT = 'EXTENDED_WARRANTY_LINK_TEXT',
  ROADSIDE_ASSISTANCE_CONTENT = 'ROADSIDE_ASSISTANCE_CONTENT',
  ROADSIDE_ASSISTANCE_LINK_TEXT = 'ROADSIDE_ASSISTANCE_LINK_TEXT',
  EXPLORE_ALL_VALUE_ADDED_SERVICES = 'EXPLORE_ALL_VALUE_ADDED_SERVICES',
  ALL_OFFERS = 'ALL_OFFERS',
  CHECK_COMPARISON_IN_DETAIL = 'CHECK_COMPARISON_IN_DETAIL',
  KNOW_YOUR_BUYING_POWER = 'KNOW_YOUR_BUYING_POWER',
  KNOW_YOUR_BUYING_POWER_CONTENT = 'KNOW_YOUR_BUYING_POWER_CONTENT',
  EST_BUYING_POWER = 'EST_BUYING_POWER',
  EMI_PER_MONTH = 'EMI_PER_MONTH',
  TENURE = 'TENURE',
  YEARS = 'YEARS',
  INTEREST = 'INTEREST',
  SEARCH_YOUR_FAVOURITE_CAR = 'SEARCH_YOUR_FAVOURITE_CAR',
  SEARCH_CITY = 'SEARCH_CITY',
  USE_MY_CURRENT_LOCATION = 'USE_MY_CURRENT_LOCATION',
  ALL_CITIES = 'ALL_CITIES',
  EXPLORE_USED_CAR_BY_LOCATION = 'EXPLORE_USED_CAR_BY_LOCATION',
  GO_GO_PRE_SHIELD = 'GO_GO_PRE_SHIELD',
  ONWARDS = 'ONWARDS',
  LIST_MY_CAR_NEW = 'LIST_MY_CAR_NEW',
  LIST_YOUR_CAR = 'LIST_YOUR_CAR',
  POPULAR_NEW_CARS = 'POPULAR_NEW_CARS',
  LATEST_PRE_OWNED_CARS = 'LATEST_PRE_OWNED_CARS',
  PRE_OWNED_CARS_BY_CATEGORY = 'PRE_OWNED_CARS_BY_CATEGORY',
  GUEST_USER = 'GUEST_USER',
  VIEW_PROFILE = 'VIEW_PROFILE',
  BUY_NEW_CARS = 'BUY_NEW_CARS',
  BUY_USED_CARS = 'BUY_USED_CARS',
  ACCOUNT = 'ACCOUNT',
  UNDER = 'UNDER',
  YEAR_ROADSIDE_ASSISTANCE = 'YEAR_ROADSIDE_ASSISTANCE',
  ADMIN_FEE = 'ADMIN_FEE',
  VIEW_ALL_POPULAR_NEW_CARS = 'VIEW_ALL_POPULAR_NEW_CARS',
  VIEW_ALL_DISCOUNTED_CARS = 'VIEW_ALL_DISCOUNTED_CARS',
  MUST_BE_A_NUMBER = 'MUST_BE_A_NUMBER',
  NOT_ABLE_TO_ADD_ITEM = 'NOT_ABLE_TO_ADD_ITEM',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  POPULAR_BRANDS = 'POPULAR_BRANDS',
  NO_CARS_TEXT = 'NO_CARS_TEXT',
  NO_CARS_PRICE_TEXT = 'NO_CARS_PRICE_TEXT',
  VIEW_SELLER_DETAILS = 'VIEW_SELLER_DETAILS',
  FILTERS_LBL = 'FILTERS_LBL',
  CARS = 'CARS',
  MINIMUM = 'MINIMUM',
  MAXIMUM = 'MAXIMUM',
  FROM = 'FROM',
  TO = 'TO',
  SET_PRICE_RANGE = 'SET_PRICE_RANGE',
  SET_EMI_RANGE = 'SET_EMI_RANGE',
  SET_YEAR_RANGE = 'SET_YEAR_RANGE',
  SET_RANGE = 'SET_RANGE',
  SEARCH_BRAND_NAME = 'SEARCH_BRAND_NAME',
  PREVIOUS = 'PREVIOUS',
  PAGE_INDICATOR_V2 = 'PAGE_INDICATOR_V2',
  USED_CARS_LISTING_SEO_CONTENT_HEADING = 'USED_CARS_LISTING_SEO_CONTENT_HEADING',
  USED_CARS_LISTING_SEO_CONTENT_TEXT = 'USED_CARS_LISTING_SEO_CONTENT_TEXT',
  SEARCH_PRE_OWNED_CAR = 'SEARCH_PRE_OWNED_CAR',
  OOPS_NO_CARS_FOUND = 'OOPS_NO_CARS_FOUND',
  COULD_NOT_FIND_ANY_CARS_MATCHING_YOUR_SEARCH_CRITERIA = 'COULD_NOT_FIND_ANY_CARS_MATCHING_YOUR_SEARCH_CRITERIA',
  CAR_VALUATION = 'CAR_VALUATION',
}

export enum WarrantyConstants {
  VerifyYourCar = 'VerifyYourCar',
  ConfirmYourCar = 'ConfirmYourCar',
  SelectBrand = 'SelectBrand',
  SelectModel = 'SelectModel',
  SelectVariant = 'SelectVariant',
  Authentication = 'Authentication',
  SelectManufactureYear = 'SelectManufactureYear',
  EnterKmDriven = 'EnterKmDriven',
  WarrantyBuyPackage = 'WarrantyBuyPackage',
  EnterCylinders = 'EnterCylinders',
  EnterCubicCapacity = 'EnterCubicCapacity',
  EnterVehicleSpecType = 'EnterVehicleSpecType',
  WarrantyPackages = 'WarrantyPackages',
  UserId = 'UserId',
  SequenceNo = 'SequenceNo',
  Consent = 'Consent',
  EnterPromoCode = 'EnterPromoCode',
  PreWarrantyCheck = 'PreWarrantyCheck',
  THIRD_PARTY_INSPECTION_QUALTIY_STATUS_UPDATE_SUCCESS = 'THIRD_PARTY_INSPECTION_QUALTIY_STATUS_UPDATE_SUCCESS',
}
export enum WarrantyTypeConstants {
  NewCar = 'NEW',
  PreOwnedCar = 'PRE OWNED',
}
export enum CMSConstants {
  FinanceItAlert = 'FinanceItAlert',
  FinanceItSuccessMessage1 = 'FinanceItSuccessMessage1',
  FinanceItSuccessMessage2 = 'FinanceItSuccessMessage2',
  ManufactureVehicleImageDisclaimer = 'ManufactureVehicleImageDisclaimer',
  NoInspectionDisclaimer = 'NoInspectionDisclaimer',
  VehicleImagesNotShotByGoGoMotor = 'VehicleImagesNotShotByGoGoMotor',
  MojazFullReportDisclaimer = 'MojazFullReportDisclaimer',
  UploadDocumentNote = 'UploadDocumentNote',
  UploadImageNote = 'UploadImageNote',
  ONBOARD_VEHICLE = 'OnboardVehicleDisclaimer',
  RESTRICT_ONBOARDING = 'RestrictOnBoardingFromWebForSelfListed',
  UPGRADE_PACKAGE_SCHEDULE = 'UpgradePackageScheduleAppointment',
  VehicleJourney = 'VehicleJourney',
  OutletVehiclePurchaseDisclaimer = 'OutletVehiclePurchaseDisclaimer',
  QCRequestForChangeEdit = 'QCRequestForChangeEdit',
  UPGRADE_PACKAGE_SUCCESS = 'UpgradePackageSuccess',
  OUTLET_INTRODUCTION = 'OutletVehiclesIntroduction',
  EMI_DISCLAIMER = 'EMIDisclaimer',
  MANUFACTURER_VEHICLE_IMAGE_SLIDER = 'ManufactureVehicleImageDisclaimer',
}

export enum SelfListingConstants {
  SelfListingVerifyYourCar = 'SelfListingVerifyYourCar',
  SelfListingVehicleConfirmation = 'SelfListingVehicleConfirmation',
  SelfListingSelectBrand = 'SelfListingSelectBrand',
  SelfListingSelectManufactureYear = 'SelfListingSelectManufactureYear',
  SelfListingSelectModel = 'SelfListingSelectModel',
  SelfListingSelectVariant = 'SelfListingSelectVariant',
  SelfListingSelectCity = 'SelfListingSelectCity',
  SelfListingAuthentication = 'SelfListingAuthentication',
  SelfListingEnterKmDriven = 'SelfListingEnterKmDriven',
  SelfListingUploadCarImages = 'SelfListingUploadCarImages',
  SelfListingSelectCoverImage = 'SelfListingSelectCoverImage',
  SelfListingEnterExpectedPrice = 'SelfListingEnterExpectedPrice',
  SelfListingCarListingReady = 'SelfListingCarListingReady',
  SelfListingCarSuccessfullyListed = 'SelfListingCarSuccessfullyListed',
  SelfListingBuyPackage = 'SelfListingBuyPackage',
  SelfListingConfirmYourDetails = 'SelfListingConfirmYourDetails',
  SelfListingPaymentRecieved = 'SelfListingPaymentRecieved',
  OwnerId = 'OwnerId',
  StartWithOwnerId = 'StartWithOwnerId',
  SequenceNo = 'SequenceNo',
  GetCarDetails = 'GetCarDetails',
  AddCarManually = 'AddCarManually',
}

export enum UsedCarFilterTypeConstants {
  AllFilters = 'AllFilters',
  SortByFilter = 'SortByFilter',
  BudgetFilter = 'BudgetFilter',
  EMIFilter = 'EMIFilter',
  MakeAndModelsFilter = 'MakeAndModelsFilter',
  ModelYearFilter = 'ModelYearFilter',
  BodyTypeFilter = 'BodyTypeFilter',
  FuelTypeFilter = 'FuelTypeFilter',
  TransmissionFilter = 'TransmissionFilter',
  ExteriorColourFilter = 'ExteriorColourFilter',
  InteriorColourFilter = 'InteriorColourFilter',
  OwnershipFilter = 'OwnershipFilter',
  KilometresDrivenFilter = 'KilometresDrivenFilter',
  FeaturesFilter = 'FeaturesFilter',
  ListingTypeFilter = 'ListingTypeFilter',
}

export enum ReportListingConstants {
  SELECT_REASON_FOR_REPORTING = 'SELECT_REASON_FOR_REPORTING',
  LISTING_REPORTED = 'LISTING_REPORTED',
}

export enum DealsConstants {
  DEAL_SELECT_CITY = 'DEAL_SELECT_CITY',
  DEAL_AUTHENTICATION = 'DEAL_AUTHENTICATION',
  DEAL_CLAIM_OFFER = 'DEAL_CLAIM_OFFER',
  DEAL_OFFER = 'DEAL_OFFER',
}

export enum OffersConstants {
  OFFER_SHARE = 'OFFER_SHARE',
  OFFER_VARIANT = 'OFFER_VARIANT',
  OFFER_TERMS_CONDITIONS = 'OFFER_TERMS_CONDITIONS',
}
