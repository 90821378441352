export const ExpandFilledArrowIcon = ({ className = '' }) => {
  return (
    // <svg
    //   width="11"
    //   height="7"
    //   viewBox="0 0 11 7"
    //   fill="currentColor"
    //   xmlns="http://www.w3.org/2000/svg"
    //   className={className}
    // >
    //   <path d="M5.5 7L0 0H11L5.5 7Z" fill="currentColor" />
    // </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="6"
      viewBox="0 0 7 6"
      fill="none"
      className={className}
    >
      <path
        d="M5.73646 6H1.07443C0.348604 6 -0.00210452 5.11115 0.528171 4.61553L5.1902 0.258257C5.70144 -0.219563 6.53646 0.142954 6.53646 0.842722V5.2C6.53646 5.64183 6.17829 6 5.73646 6Z"
        fill="#3E3E3E"
      />
    </svg>
  );
};
