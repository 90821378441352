import React from 'react';

export const WhatsAppIconFilledV2 = ({ className = '' }) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0066 31.7896C13.9586 31.7896 11.1325 30.8724 8.75304 29.3234L3.00384 31.1452L4.86277 25.606C3.06591 23.1774 2 20.1289 2 16.87C2 16.3866 2.03706 15.9282 2.09909 15.4449C2.83027 7.89829 9.24929 2 17.0066 2C24.8875 2 31.3684 8.05944 31.9751 15.7175C32 16.114 32 16.5104 32 16.8699C32 25.0856 25.2722 31.7895 17.0066 31.7895L17.0066 31.7896Z"
        fill="#33CC33"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2113 18.8897C21.7767 18.7782 21.5048 18.679 21.1949 19.1376C20.7484 19.8439 19.8068 21.3928 18.8901 20.9344C18.4318 20.7238 17.0318 20.2652 15.3588 18.7782C14.0574 17.6133 13.1656 16.2131 12.9294 15.7918C12.5458 15.0731 13.7594 14.3172 14.2058 13.4126C14.3543 13.1152 14.2925 12.8674 14.169 12.6567C14.0823 12.4461 13.2024 10.29 12.8308 9.43491C12.471 8.55504 12.0994 8.70384 11.8642 8.70384C9.4348 8.70384 8.29492 10.4263 8.29492 12.8425C8.29492 13.3258 8.39465 13.8464 8.54307 14.3048C8.97647 15.8538 9.94303 17.1053 10.0926 17.316C10.3028 17.6133 13.1039 22.1239 17.5519 23.8589C22.0249 25.5935 22.0249 25.0112 22.8181 24.9491C23.5994 24.8874 25.3959 23.9205 25.7676 22.8797C26.1274 21.8761 26.1274 20.9961 26.0027 20.8104C25.8543 20.5502 22.2113 18.9021 22.2113 18.8896V18.8897Z"
        fill="white"
      />
    </svg>
  );
};
