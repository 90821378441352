export const LpgIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.8155 12.548L46.4431 12.3051L44.9535 11.3071L43.3597 10.2541H39.3607V12.1183H42.7914L44.9535 13.5541V25.1694H50.5464V44.7459C50.5464 45.4877 50.2517 46.1992 49.7271 46.7237C49.2026 47.2482 48.4912 47.5429 47.7494 47.5429H46.8167C46.0755 47.542 45.3649 47.2469 44.8411 46.7225C44.3173 46.1981 44.0231 45.4871 44.0231 44.7459V30.7668C44.0231 28.1382 41.0119 26.1055 38.4303 26.1055H36.5615V5.5951C36.5618 4.86045 36.4173 4.13294 36.1364 3.45412C35.8555 2.77531 35.4435 2.15849 34.9242 1.63891C34.4048 1.11932 33.7882 0.707155 33.1095 0.425947C32.4308 0.144739 31.7033 -6.16728e-08 30.9686 0H10.4582C8.97492 0 7.55237 0.589242 6.50351 1.6381C5.45466 2.68695 4.86542 4.10951 4.86542 5.59281V48.4745H3.93271C3.68534 48.4745 3.4481 48.5727 3.27318 48.7477C3.09827 48.9226 3 49.1598 3 49.4072V54.0684C3 54.3158 3.09827 54.553 3.27318 54.728C3.4481 54.9029 3.68534 55.0011 3.93271 55.0011H37.4919C37.7392 55.0011 37.9765 54.9029 38.1514 54.728C38.3263 54.553 38.4246 54.3158 38.4246 54.0684V49.4072C38.4246 49.1598 38.3263 48.9226 38.1514 48.7477C37.9765 48.5727 37.7392 48.4745 37.4919 48.4745H36.5592V27.9641H38.4234C40.0551 27.9641 42.152 29.3161 42.152 30.761V44.7402C42.152 45.9765 42.6431 47.1621 43.5172 48.0362C44.3914 48.9104 45.577 49.4015 46.8132 49.4015H47.7459C48.9822 49.4015 50.1678 48.9104 51.0419 48.0362C51.9161 47.1621 52.4072 45.9765 52.4072 44.7402V16.2708L46.8155 12.548ZM36.5615 53.1357H4.86656V50.3388H36.5615V53.1357ZM6.73083 48.4745V5.59281C6.73083 4.60394 7.12366 3.65557 7.8229 2.95634C8.52214 2.2571 9.4705 1.86427 10.4594 1.86427H30.9698C31.9587 1.86427 32.907 2.2571 33.6063 2.95634C34.3055 3.65557 34.6983 4.60394 34.6983 5.59281V48.4745H6.73083ZM46.8155 23.3051V14.7939L50.5441 17.2826V23.3051H46.8155Z"
        fill="currentColor"
      />
      <path
        d="M20.7141 4.74609L19.9212 6.03286C19.4961 6.72266 9.52734 22.9614 9.52734 28.82C9.52734 34.4129 13.7039 38.3843 19.7814 38.7475V43.269L18.8487 45.6833H22.5784L21.6457 43.277V38.7441C27.7186 38.3717 31.8997 34.4003 31.8997 28.8166C31.8997 22.9626 21.931 6.73297 21.5059 6.04318L20.7141 4.74609ZM21.6468 36.8787V29.4216L26.0273 25.0491C26.1166 24.9634 26.188 24.8607 26.2372 24.7471C26.2864 24.6335 26.3125 24.5112 26.314 24.3874C26.3155 24.2636 26.2923 24.1408 26.2458 24.026C26.1993 23.9113 26.1304 23.807 26.0432 23.7191C25.9559 23.6313 25.8521 23.5617 25.7376 23.5144C25.6232 23.4671 25.5005 23.443 25.3767 23.4436C25.2529 23.4443 25.1305 23.4695 25.0165 23.5179C24.9026 23.5663 24.7994 23.637 24.7131 23.7257L21.6457 26.7828V17.9736C21.6501 17.8484 21.6292 17.7237 21.5843 17.6067C21.5395 17.4898 21.4715 17.3831 21.3845 17.293C21.2975 17.2029 21.1933 17.1313 21.078 17.0824C20.9627 17.0334 20.8388 17.0082 20.7135 17.0082C20.5883 17.0082 20.4643 17.0334 20.3491 17.0824C20.2338 17.1313 20.1296 17.2029 20.0426 17.293C19.9556 17.3831 19.8876 17.4898 19.8427 17.6067C19.7979 17.7237 19.777 17.8484 19.7814 17.9736V21.9726L17.3385 19.5766C17.2654 19.4559 17.1658 19.3534 17.0473 19.2768C16.9288 19.2002 16.7945 19.1515 16.6544 19.1343C16.5143 19.1171 16.3722 19.1319 16.2387 19.1776C16.1052 19.2233 15.9838 19.2988 15.8837 19.3982C15.7836 19.4976 15.7073 19.6184 15.6606 19.7516C15.614 19.8848 15.5981 20.0268 15.6143 20.167C15.6305 20.3072 15.6782 20.4418 15.754 20.5609C15.8298 20.6799 15.9316 20.7802 16.0517 20.8542L19.7814 24.5805V31.9734L14.8784 27.0818C14.7019 26.9124 14.4659 26.819 14.2213 26.822C13.9767 26.8249 13.743 26.9238 13.5706 27.0974C13.3982 27.271 13.3009 27.5053 13.2997 27.75C13.2985 27.9946 13.3934 28.23 13.5641 28.4053L19.7161 34.5492H19.7814V36.8145C14.7936 36.4605 11.3916 33.2441 11.3916 28.7513C11.3916 24.6962 17.5253 13.6034 20.7141 8.24088C23.9018 13.5805 30.0378 24.6767 30.0378 28.7502C30.0378 33.3083 26.6335 36.5246 21.6457 36.8787H21.6468Z"
        fill="currentColor"
      />
    </svg>
  );
};
