import React from 'react';

export const CircularOrangeTickMarkIcon = ({
  className = '',
  fill = '#FE5000',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      className={className}
    >
      <circle cx="6" cy="6.5" r="6" fill={fill} />
      <path
        d="M4.97915 7.63374L3.48484 6.16741C3.43127 6.11487 3.36767 6.0732 3.29768 6.04477C3.22768 6.01634 3.15266 6.00171 3.0769 6.00171C3.00114 6.00171 2.92613 6.01634 2.85613 6.04477C2.78614 6.0732 2.72254 6.11487 2.66897 6.16741C2.56078 6.2735 2.5 6.4174 2.5 6.56744C2.5 6.71749 2.56078 6.86139 2.66897 6.96748L4.5717 8.83338C4.62512 8.88618 4.68867 8.92808 4.75868 8.95668C4.82869 8.98528 4.90379 9 4.97963 9C5.05548 9 5.13057 8.98528 5.20058 8.95668C5.2706 8.92808 5.33415 8.88618 5.38756 8.83338L9.32756 4.96871C9.38184 4.91639 9.42503 4.85405 9.45462 4.78529C9.48422 4.71652 9.49964 4.64269 9.49999 4.56805C9.50035 4.49342 9.48563 4.41945 9.45669 4.35042C9.42774 4.28138 9.38515 4.21865 9.33136 4.16584C9.27758 4.11304 9.21366 4.07119 9.1433 4.04273C9.07294 4.01427 8.99753 3.99974 8.92141 4C8.8453 4.00026 8.77 4.0153 8.69984 4.04424C8.62968 4.07318 8.56606 4.11546 8.51265 4.16863L4.97915 7.63374Z"
        fill="white"
      />
    </svg>
  );
};
