import React from 'react';

export const CircularArrowIconV2 = ({ stroke = 'white', className = '' }) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.06 9.29668C3.06 10.4359 3.39782 11.5495 4.03073 12.4968C4.66365 13.444 5.56324 14.1823 6.61574 14.6182C7.66824 15.0542 8.82639 15.1683 9.94372 14.946C11.061 14.7238 12.0874 14.1752 12.8929 13.3696C13.6985 12.5641 14.2471 11.5377 14.4693 10.4204C14.6916 9.30307 14.5775 8.14493 14.1415 7.09243C13.7056 6.03992 12.9673 5.14033 12.0201 4.50742C11.0729 3.8745 9.95922 3.53668 8.82 3.53668C7.20973 3.54274 5.66414 4.17107 4.5064 5.29028L3.06 6.73668"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06 3.53668V6.73668H6.26"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
