export const PetrolFuelTypeIcon = ({ className = 'w-[0.625rem]' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 46 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_927_29)">
        <path
          d="M0.0329164 53.3125H45.1951C45.331 53.3124 45.4656 53.3211 45.5912 53.3381C45.7168 53.3551 45.8309 53.3801 45.927 53.4115C46.0231 53.443 46.0992 53.4804 46.1511 53.5216C46.2029 53.5628 46.2295 53.6069 46.2292 53.6514V54.6626C46.2289 54.7069 46.2019 54.7509 46.1497 54.7918C46.0976 54.8328 46.0213 54.8699 45.9252 54.9012C45.8292 54.9325 45.7153 54.9572 45.59 54.9741C45.4647 54.9909 45.3305 54.9994 45.1951 54.9993H0.0329164C-0.102379 54.9994 -0.236434 54.9908 -0.361586 54.974C-0.486738 54.9571 -0.600532 54.9323 -0.696461 54.9011C-0.79239 54.8698 -0.868575 54.8326 -0.920657 54.7917C-0.97274 54.7508 -0.999699 54.7069 -0.999994 54.6626V53.6514C-1.00044 53.6069 -0.974034 53.5628 -0.922297 53.5216C-0.87056 53.4805 -0.794512 53.4431 -0.698523 53.4116C-0.602535 53.3801 -0.488498 53.3551 -0.362972 53.3381C-0.237446 53.3211 -0.102905 53.3124 0.0329164 53.3125Z"
          fill="currentColor"
        />
        <mask id="path-2-inside-1_927_29" fill="white">
          <path d="M34.2005 51.5086H0.864258V4.49275C0.86496 3.30142 1.33853 2.15908 2.18093 1.31667C3.02333 0.47427 4.16567 0.000701819 5.35701 0H29.7069C30.8983 0.000701568 32.0407 0.474241 32.8833 1.31662C33.7258 2.159 34.1996 3.30134 34.2005 4.49275V51.5086ZM28.5192 18.1679V9.08264C28.5188 8.63612 28.4304 8.19407 28.259 7.78174C28.0877 7.3694 27.8368 6.99485 27.5207 6.67949C27.2046 6.36412 26.8295 6.11412 26.4167 5.94375C26.004 5.77339 25.5618 5.686 25.1152 5.68658H9.9469C9.50108 5.6867 9.05965 5.77464 8.64782 5.94538C8.23599 6.11612 7.86184 6.36632 7.54672 6.68168C7.2316 6.99705 6.98169 7.3714 6.81127 7.78336C6.64085 8.19532 6.55326 8.63682 6.55349 9.08264V18.1679C6.55337 18.6142 6.64118 19.0561 6.8119 19.4684C6.98261 19.8806 7.23289 20.2553 7.54843 20.5708C7.86397 20.8863 8.23859 21.1366 8.65088 21.3073C9.06318 21.4781 9.50507 21.5659 9.95131 21.5657H25.1152C25.5619 21.5662 26.0042 21.4787 26.417 21.3082C26.8298 21.1377 27.205 20.8875 27.5211 20.572C27.8372 20.2564 28.088 19.8817 28.2593 19.4692C28.4306 19.0567 28.5189 18.6146 28.5192 18.1679V18.1679Z" />
        </mask>
        <path
          d="M34.2005 51.5086H0.864258V4.49275C0.86496 3.30142 1.33853 2.15908 2.18093 1.31667C3.02333 0.47427 4.16567 0.000701819 5.35701 0H29.7069C30.8983 0.000701568 32.0407 0.474241 32.8833 1.31662C33.7258 2.159 34.1996 3.30134 34.2005 4.49275V51.5086ZM28.5192 18.1679V9.08264C28.5188 8.63612 28.4304 8.19407 28.259 7.78174C28.0877 7.3694 27.8368 6.99485 27.5207 6.67949C27.2046 6.36412 26.8295 6.11412 26.4167 5.94375C26.004 5.77339 25.5618 5.686 25.1152 5.68658H9.9469C9.50108 5.6867 9.05965 5.77464 8.64782 5.94538C8.23599 6.11612 7.86184 6.36632 7.54672 6.68168C7.2316 6.99705 6.98169 7.3714 6.81127 7.78336C6.64085 8.19532 6.55326 8.63682 6.55349 9.08264V18.1679C6.55337 18.6142 6.64118 19.0561 6.8119 19.4684C6.98261 19.8806 7.23289 20.2553 7.54843 20.5708C7.86397 20.8863 8.23859 21.1366 8.65088 21.3073C9.06318 21.4781 9.50507 21.5659 9.95131 21.5657H25.1152C25.5619 21.5662 26.0042 21.4787 26.417 21.3082C26.8298 21.1377 27.205 20.8875 27.5211 20.572C27.8372 20.2564 28.088 19.8817 28.2593 19.4692C28.4306 19.0567 28.5189 18.6146 28.5192 18.1679V18.1679Z"
          stroke="currentColor"
          strokeWidth="4"
          mask="url(#path-2-inside-1_927_29)"
        />
        <path
          d="M38.5808 47.685C38.5808 48.4246 38.8746 49.1339 39.3975 49.6568C39.9205 50.1798 40.6298 50.4736 41.3693 50.4736C42.1089 50.4736 42.8182 50.1798 43.3411 49.6568C43.8641 49.1339 44.1579 48.4246 44.1579 47.685V23.4844H45.8559V47.685C45.8559 48.8762 45.3827 50.0186 44.5404 50.8609C43.6981 51.7032 42.5557 52.1764 41.3645 52.1764C40.1733 52.1764 39.0309 51.7032 38.1886 50.8609C37.3462 50.0186 36.873 48.8762 36.873 47.685V35.5878H38.579V47.685H38.5808Z"
          fill="currentColor"
        />
        <path
          d="M36.8973 7.2593L43.3062 13.8651V19.3336C43.3062 19.785 43.4855 20.2179 43.8047 20.5371C44.1239 20.8563 44.5568 21.0356 45.0082 21.0356C45.4596 21.0356 45.8925 20.8563 46.2117 20.5371C46.5309 20.2179 46.7102 19.785 46.7102 19.3336V12.4858L39.3406 4.89195C39.0263 4.56795 38.5962 4.38207 38.1449 4.3752C37.6935 4.36833 37.2579 4.54102 36.9339 4.85531C36.6099 5.16959 36.4241 5.59971 36.4172 6.05104C36.4103 6.50237 36.583 6.93795 36.8973 7.26195V7.2593Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_927_29">
          <rect width="45.7543" height="55" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
