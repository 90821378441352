import { createSlice } from '@reduxjs/toolkit';

type intialStateType = {
  canonicalUrl: string;
};

const initialState: intialStateType = {
  canonicalUrl: '',
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setCanonicalUrl: (state, action) => {
      return { ...state, canonicalUrl: action.payload };
    },
  },
});

export const { setCanonicalUrl } = globalSlice.actions;

export default globalSlice.reducer;
