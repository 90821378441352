export default class IdentifierService {
  static getClarityUserId = async () => {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      const clckValue = cookies['_clck'];
      return clckValue ? clckValue.split('|')[0] : null;
    }
  };

  static getGAId = async () => {
    if (typeof window !== 'undefined') {
      const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});
      const gaValue = cookies['_ga'];
      return gaValue ? gaValue.split('.')[2] : null;
    }
  };
}
