export const ImportedByIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6867 6.08633C2.43561 6.38934 3.18369 6.6936 3.93345 6.99451C5.14327 7.47982 6.35351 7.96387 7.56458 8.44625C7.65291 8.48145 7.70691 8.51414 7.7065 8.63065C7.70189 10.7584 7.70315 12.8865 7.70273 15.0147C7.70273 15.0457 7.70022 15.0767 7.6977 15.1266C7.64245 15.1094 7.59472 15.0968 7.54826 15.0797C5.68455 14.3928 3.82 13.7071 1.9588 13.0135C1.85582 12.975 1.7767 12.8727 1.68628 12.7998C1.6867 10.5618 1.6867 8.32429 1.6867 6.08633Z"
        fill="#43474D"
      />
      <path
        d="M1.70264 4.65286C1.76627 4.62395 1.81232 4.60132 1.8592 4.58246C3.98329 3.73212 6.10781 2.88303 8.23064 2.02976C8.33028 1.98953 8.41023 1.99036 8.51028 2.0306C10.6377 2.88471 12.7664 3.73505 14.8951 4.58623C14.9378 4.60341 14.9788 4.62478 15.0454 4.65622C14.6138 4.8293 14.2056 4.99233 13.7975 5.15619C12.0414 5.86027 10.2852 6.56435 8.52996 7.27052C8.42865 7.31117 8.34074 7.32165 8.23274 7.27848C6.10529 6.42102 3.9766 5.56858 1.84832 4.71531C1.80604 4.69813 1.76417 4.67969 1.70264 4.65328V4.65286Z"
        fill="#43474D"
      />
      <path
        d="M9.05381 9.99079C9.04962 9.94511 9.04418 9.91116 9.04418 9.87722C9.04376 9.45938 9.04543 9.04113 9.0425 8.62329C9.04167 8.53989 9.0626 8.49421 9.14716 8.46068C11.0875 7.68662 13.0265 6.90878 14.966 6.1322C14.9886 6.12298 15.0137 6.11963 15.0502 6.11082V8.2306C12.7071 7.63339 10.7149 8.2197 9.05381 9.99079Z"
        fill="#43474D"
      />
      <path
        d="M17.667 12.971C17.3103 10.6547 15.028 9.00302 12.7147 9.38733C10.3742 9.77625 8.79387 11.8788 9.07477 14.2295C9.27822 15.9311 10.5119 17.387 12.1588 17.8551C12.443 17.936 12.7377 17.9779 13.0278 18.0374H13.7487C13.8969 18.0119 14.0455 17.9901 14.1924 17.9603C16.5325 17.4926 18.0316 15.3393 17.667 12.9706V12.971ZM15.3759 14.5975C15.3604 14.6947 15.3085 14.7987 15.2432 14.8728C14.7559 15.4256 14.2636 15.9734 13.7679 16.5182C13.5431 16.765 13.2237 16.7642 12.9985 16.5169C12.5024 15.9721 12.0114 15.4231 11.5199 14.8745C11.3747 14.7123 11.3328 14.5267 11.4237 14.3234C11.517 14.1143 11.6857 14.0217 11.9109 14.0221C12.1713 14.0225 12.4321 14.0221 12.7151 14.0221C12.7151 13.9307 12.7151 13.8582 12.7151 13.7861C12.7151 12.9706 12.7126 12.155 12.7168 11.3395C12.7185 10.9975 12.9562 10.736 13.2953 10.6832C13.6009 10.6358 13.9086 10.8181 14.0082 11.1203C14.0417 11.2221 14.0526 11.3353 14.053 11.4434C14.0568 12.233 14.0551 13.0221 14.0551 13.8117V14.0221C14.3377 14.0221 14.6123 14.0175 14.8869 14.0233C15.203 14.0296 15.4269 14.2865 15.3771 14.5975H15.3759Z"
        fill="#43474D"
      />
    </svg>
  );
};
