import { Locales } from '../../types/enums';
import {
  CreateContactSellerLeadPayload,
  NodeApiResponse,
  UserInterestPayload,
  UserLeadOnSellerDetailsClickPayload,
  WebHookPayload,
} from '../../types/models';
import { axiosInstance } from '../api';
import { axiosNodeInstance } from '../api/interceptors';
import { CommonUtils } from '../utilities';

export default class LeadService {
  /**
   * This service is use to save User Interest Form.
   * @param {UserInterestPayload}  [payload] - Interest application payload.
   */
  static saveUserInterestApplication = async (payload: UserInterestPayload) => {
    try {
      const response = await axiosInstance.post(
        `/api/Lead/vehiclelead`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in InspectionService:saveDealerApplication`, error);
      throw error;
    }
  };
  /**
   * This service is use to save User Interest Form on dynamic webhook.
   * @param {WebHookPayload}  [payload] - Interest application payload.
   */
  static saveUserApplication = async (payload: WebHookPayload) => {
    try {
      const response = await axiosNodeInstance.post(
        `/common/zoho-create-lead`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(
        `Error in InspectionService:saveUserInterestApplicationDynamicWebHook`,
        error
      );
      return { status: false };
    }
  };
  /**
   * This service is use to save User Interest Form.
   * @param {UserLeadOnSellerDetailsClickPayload}  [payload] - Interest application payload.
   */
  static saveLeadOnSellerDetailsClick = async (
    payload: UserLeadOnSellerDetailsClickPayload
  ) => {
    try {
      const response = await axiosInstance.post(
        `/api/Lead/vehiclelead`,
        payload
      );
      return await response.data;
    } catch (error) {
      console.error(`Error in InspectionService:saveDealerApplication`, error);
      throw error;
    }
  };

  /**
   * This service is use to save User Interest Form.
   * @param languageId - Interest application payload.
   */
  static getPurchasePlanDuration = async (
    currentLocale: string = Locales.EN
  ) => {
    try {
      const response = await axiosInstance.get(
        `/api/Lead/getPurchasePlanDuration?languageId=${CommonUtils.getLanguageId(
          currentLocale
        )}`
      );
      return await response.data;
    } catch (error) {
      console.error(
        `Error in InspectionService:getPurchasePlanDuration`,
        error
      );
      throw error;
    }
  };

  static createContactSellerLead = async (
    payload: CreateContactSellerLeadPayload
  ): Promise<NodeApiResponse<any>> => {
    try {
      const response = await axiosNodeInstance.post<NodeApiResponse<any>>(
        '/strapi/contact-seller-lead',
        payload
      );
      return response.data;
    } catch (error) {
      console.error(`Error in InspectionService:saveDealerApplication`, error);
      throw error;
    }
  };
}
