export const BuyIcon = ({ className = 'w-[0.625rem]' }) => {
  return (
    <svg
      viewBox="0 0 19 19"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.875 14.8281C13.9038 14.8281 13.125 15.6069 13.125 16.5781C13.125 17.0423 13.3094 17.4874 13.6376 17.8156C13.9658 18.1437 14.4109 18.3281 14.875 18.3281C15.3391 18.3281 15.7842 18.1437 16.1124 17.8156C16.4406 17.4874 16.625 17.0423 16.625 16.5781C16.625 16.114 16.4406 15.6689 16.1124 15.3407C15.7842 15.0125 15.3391 14.8281 14.875 14.8281ZM0.875 0.828125V2.57812H2.625L5.775 9.21938L4.585 11.3631C4.45375 11.6081 4.375 11.8969 4.375 12.2031C4.375 12.6673 4.55937 13.1124 4.88756 13.4406C5.21575 13.7688 5.66087 13.9531 6.125 13.9531H16.625V12.2031H6.4925C6.43448 12.2031 6.37884 12.1801 6.33782 12.1391C6.2968 12.098 6.27375 12.0424 6.27375 11.9844C6.27375 11.9406 6.2825 11.9056 6.3 11.8794L7.0875 10.4531H13.6062C14.2625 10.4531 14.84 10.0856 15.1375 9.55188L18.27 3.89062C18.3312 3.75062 18.375 3.60188 18.375 3.45312C18.375 3.22106 18.2828 2.9985 18.1187 2.83441C17.9546 2.67031 17.7321 2.57812 17.5 2.57812H4.55875L3.73625 0.828125M6.125 14.8281C5.15375 14.8281 4.375 15.6069 4.375 16.5781C4.375 17.0423 4.55937 17.4874 4.88756 17.8156C5.21575 18.1437 5.66087 18.3281 6.125 18.3281C6.58913 18.3281 7.03425 18.1437 7.36244 17.8156C7.69063 17.4874 7.875 17.0423 7.875 16.5781C7.875 16.114 7.69063 15.6689 7.36244 15.3407C7.03425 15.0125 6.58913 14.8281 6.125 14.8281Z"
        fill="url(#paint0_linear_13202_27614)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13202_27614"
          x1="0.875"
          y1="10.2088"
          x2="18.375"
          y2="10.2876"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
