export const EmailFooterIcon = ({ className = '', primaryColor = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className={className}
    >
      <path
        d="M16.5 5.50684L9.7725 9.78184C9.54095 9.92691 9.27324 10.0038 9 10.0038C8.72676 10.0038 8.45905 9.92691 8.2275 9.78184L1.5 5.50684M3 3.25684H15C15.8284 3.25684 16.5 3.92841 16.5 4.75684V13.7568C16.5 14.5853 15.8284 15.2568 15 15.2568H3C2.17157 15.2568 1.5 14.5853 1.5 13.7568V4.75684C1.5 3.92841 2.17157 3.25684 3 3.25684Z"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};
