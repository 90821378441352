export const MyOrdersIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20.775 21.009"
      fill="currentColor"
    >
      <mask
        id="path-1-outside-1_108_4531"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="20"
        fill="currentColor"
      >
        <rect fill="currentColor" width="16" height="20" />
        <path d="M15.2635 15.8436L14.4596 5.71452C14.4095 5.06195 13.8577 4.55075 13.2032 4.55075H11.6154V4.47938C11.6154 2.56083 10.0546 1 8.13605 1C6.2175 1 4.65667 2.56083 4.65667 4.47938V4.55075H3.06894C2.41444 4.55075 1.86252 5.06192 1.8126 5.71315L1.00851 15.845C0.946279 16.6562 1.2277 17.4638 1.78057 18.0607C2.33344 18.6576 3.11718 19 3.9308 19H12.3413C13.1548 19 13.9386 18.6576 14.4915 18.0607C15.0444 17.4638 15.3258 16.6562 15.2635 15.8436ZM5.71101 4.47938C5.71101 3.14221 6.79892 2.05434 8.13605 2.05434C9.47318 2.05434 10.5611 3.14225 10.5611 4.47938V4.55075H5.71101V4.47938ZM13.718 17.3443C13.3588 17.7321 12.8699 17.9457 12.3413 17.9457H3.93083C3.40222 17.9457 2.91334 17.7321 2.55411 17.3443C2.19492 16.9565 2.01935 16.4526 2.05971 15.927L2.86374 5.79514C2.87189 5.68854 2.96203 5.60508 3.06894 5.60508H4.65667V6.90358C4.65667 7.19471 4.89271 7.43075 5.18384 7.43075C5.47497 7.43075 5.71101 7.19471 5.71101 6.90358V5.60508H10.5611V6.90358C10.5611 7.19471 10.7971 7.43075 11.0883 7.43075C11.3794 7.43075 11.6154 7.19471 11.6154 6.90358V5.60508H13.2032C13.3101 5.60508 13.4002 5.68858 13.4085 5.79654L14.2123 15.9256C14.2528 16.4526 14.0772 16.9565 13.718 17.3443Z" />
      </mask>
      <path
        d="M15.2635 15.8436L14.4596 5.71452C14.4095 5.06195 13.8577 4.55075 13.2032 4.55075H11.6154V4.47938C11.6154 2.56083 10.0546 1 8.13605 1C6.2175 1 4.65667 2.56083 4.65667 4.47938V4.55075H3.06894C2.41444 4.55075 1.86252 5.06192 1.8126 5.71315L1.00851 15.845C0.946279 16.6562 1.2277 17.4638 1.78057 18.0607C2.33344 18.6576 3.11718 19 3.9308 19H12.3413C13.1548 19 13.9386 18.6576 14.4915 18.0607C15.0444 17.4638 15.3258 16.6562 15.2635 15.8436ZM5.71101 4.47938C5.71101 3.14221 6.79892 2.05434 8.13605 2.05434C9.47318 2.05434 10.5611 3.14225 10.5611 4.47938V4.55075H5.71101V4.47938ZM13.718 17.3443C13.3588 17.7321 12.8699 17.9457 12.3413 17.9457H3.93083C3.40222 17.9457 2.91334 17.7321 2.55411 17.3443C2.19492 16.9565 2.01935 16.4526 2.05971 15.927L2.86374 5.79514C2.87189 5.68854 2.96203 5.60508 3.06894 5.60508H4.65667V6.90358C4.65667 7.19471 4.89271 7.43075 5.18384 7.43075C5.47497 7.43075 5.71101 7.19471 5.71101 6.90358V5.60508H10.5611V6.90358C10.5611 7.19471 10.7971 7.43075 11.0883 7.43075C11.3794 7.43075 11.6154 7.19471 11.6154 6.90358V5.60508H13.2032C13.3101 5.60508 13.4002 5.68858 13.4085 5.79654L14.2123 15.9256C14.2528 16.4526 14.0772 16.9565 13.718 17.3443Z"
        fill="currentColor"
      />
      <path
        d="M15.2635 15.8436L14.4596 5.71452C14.4095 5.06195 13.8577 4.55075 13.2032 4.55075H11.6154V4.47938C11.6154 2.56083 10.0546 1 8.13605 1C6.2175 1 4.65667 2.56083 4.65667 4.47938V4.55075H3.06894C2.41444 4.55075 1.86252 5.06192 1.8126 5.71315L1.00851 15.845C0.946279 16.6562 1.2277 17.4638 1.78057 18.0607C2.33344 18.6576 3.11718 19 3.9308 19H12.3413C13.1548 19 13.9386 18.6576 14.4915 18.0607C15.0444 17.4638 15.3258 16.6562 15.2635 15.8436ZM5.71101 4.47938C5.71101 3.14221 6.79892 2.05434 8.13605 2.05434C9.47318 2.05434 10.5611 3.14225 10.5611 4.47938V4.55075H5.71101V4.47938ZM13.718 17.3443C13.3588 17.7321 12.8699 17.9457 12.3413 17.9457H3.93083C3.40222 17.9457 2.91334 17.7321 2.55411 17.3443C2.19492 16.9565 2.01935 16.4526 2.05971 15.927L2.86374 5.79514C2.87189 5.68854 2.96203 5.60508 3.06894 5.60508H4.65667V6.90358C4.65667 7.19471 4.89271 7.43075 5.18384 7.43075C5.47497 7.43075 5.71101 7.19471 5.71101 6.90358V5.60508H10.5611V6.90358C10.5611 7.19471 10.7971 7.43075 11.0883 7.43075C11.3794 7.43075 11.6154 7.19471 11.6154 6.90358V5.60508H13.2032C13.3101 5.60508 13.4002 5.68858 13.4085 5.79654L14.2123 15.9256C14.2528 16.4526 14.0772 16.9565 13.718 17.3443Z"
        stroke="currentColor"
        strokeWidth="0.2"
        mask="url(#path-1-outside-1_108_4531)"
      />
      <mask id="path-2-inside-2_108_4531" fill="currentColor">
        <path d="M11.1784 9.9737C10.9271 9.72231 10.5195 9.72231 10.2681 9.9737L7.39378 12.8481L6.28853 11.7428C6.03718 11.4914 5.62957 11.4914 5.37818 11.7428C5.12679 11.9942 5.12679 12.4018 5.37818 12.6532L6.9386 14.2136C7.0643 14.3393 7.22906 14.4022 7.39374 14.4022C7.55841 14.4022 7.72322 14.3393 7.84887 14.2136L11.1784 10.8841C11.4298 10.6327 11.4298 10.2251 11.1784 9.9737Z" />
      </mask>
      <path
        d="M11.1784 9.9737C10.9271 9.72231 10.5195 9.72231 10.2681 9.9737L7.39378 12.8481L6.28853 11.7428C6.03718 11.4914 5.62957 11.4914 5.37818 11.7428C5.12679 11.9942 5.12679 12.4018 5.37818 12.6532L6.9386 14.2136C7.0643 14.3393 7.22906 14.4022 7.39374 14.4022C7.55841 14.4022 7.72322 14.3393 7.84887 14.2136L11.1784 10.8841C11.4298 10.6327 11.4298 10.2251 11.1784 9.9737Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="2"
        mask="url(#path-2-inside-2_108_4531)"
      />
    </svg>
  );
};
