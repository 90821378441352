/** @enum {string} */
export enum GTMEvents {
  PageView = 'Page View',
  ViewedSellerDetails = 'Viewed Seller Details',
  ViewedCar = 'Viewed Used Car Details',
  ConciergeServiceRequest = 'Concierge Service Request',
  CustomerSupportRequest = 'Customer Support Request',
  AddBookmark = 'Vehicle Bookmarked',
  CompletedSearch = 'Completed Used Cars Search',
  AddedToCompare = 'Added to Compare',
  CompareClick = 'Clicked Compare',
  MyCarValueRequest = 'My Car Value Request',
  ListMyCar = 'List My Car',
  AutoCare = 'Auto Care',
  BodyPaint = 'Body & Paint',
  Tristar = 'Tristar',
  TitleTransfer = 'Title Transfer',
  LanguageSwitched = 'Language Switched',
  RemovedBookmark = 'Removed Bookmark',
  ApplyPromotion = 'Apply_Promotion',
  SignUp = 'Sign_Up',
  SignIn = 'Sign_In',
  ViewedCarGA = 'Viewed_Used_Car_Details',
  ViewedSellerDetailsGA = 'Viewed_Seller_Details',
  CampaignLeadGenerated = 'campaign_lead_generated',
  DataLayerUpdate = 'DATALAYERUPDATE',
  RequestQuoteClicked = 'REQUEST_QUOTE_CLICKED',
  UserIdentifier = 'PII'
}

/** @enum {string} */
export enum GTMSubEvents {
  Initiated = 'Initiated',
  Submitted = 'Submitted',
  ProcessInitiated = 'Process Initiated',
  VehicleDetails = 'Enter Your Vehicle Details',
  AdditionalForm = 'Additional Information',
  UploadImages = 'Upload Images',
  UploadDocs = 'Upload Documents',
  ConfirmDetails = 'Confirm Details',
  InspectionAppointment = 'Schedule An Inspection Appointment',
  ProcessCompleted = 'Process Completed',
  ViewedService = 'Viewed Service',
  BookServiceInitiated = 'Book a Service Request Initiated',
  BookServiceSubmitted = 'Book a Service Request Submitted',
  AppliedFilter = 'Applied A Search Filter',
  SearchedVehicle = 'Dashboard Search Clicked',
  Recommendations = 'Recommendations',
  ListMyCarError = 'Process Error',
  Click = 'WEBCLICK',
  PageView = 'PAGE VIEW',
  CampaignLeadGenerated = 'Campaign_Lead_Generated',
}

/** @enum {string} */
export enum SellMyCarEvents {
  CTA_CLICKED = 'sc_cta_clicked',
  REGISTRATION_FLOW_SELECTED = 'sc_registration_flow_selected',
  BRAND_CLICKED = 'sc_brand_clicked',
  VIEW_ALL_BRANDS_CLICKED = 'sc_view_all_brands_clicked',
  BRAND_SEARCH = 'sc_brand_search',
  BRAND_SELECTED = 'sc_brand_selected',
  YEAR_SELECTED = 'sc_year_selected',
  MODEL_SEARCH = 'sc_model_search',
  MODEL_SELECTED = 'sc_model_selected',
  FUEL_SELECTION = 'sc_fuel_selection',
  TRIM_SEARCH = 'sc_trim_search',
  TRIM_SELECTED = 'sc_trim_selected',
  CITY_DROPDOWN_CLICKED = 'city_dropdown_clicked',
  CITY_SEARCHED = 'city_searched',
  CITY_SELECTED = 'city_selected',
  DETECT_MY_LOCATION = 'detect_my_location',
  CITY_FOUND_IN_DB = 'sc_city_found',
  CITY_NOT_FOUND_IN_DB = 'sc_city_not_found',
  PREAUTHORISED = 'sc_check_user_login',
  PHONE_INPUT = 'sc_phone_input',
  OTP_TRIGGERED = 'sc_otp_triggered',
  OTP_DELIVERED = 'sc_otp_delivered',
  OTP_VERIFIED = 'sc_otp_verified',
  INCORRECT_OTP_ENTERED = 'sc_incorrect_otp_entered',
  OTP_RESENT = 'sc_otp_resent',
  KMS_DRIVEN = 'sc_kms_driven',
  PHOTOS_UPLOAD = 'sc_photos_upload',
  PHOTOS_IMPROPER_IMAGE = 'sc_photos_improper_image',
  LIST_PRICE = 'sc_list_price',
  SUBMIT_LISTING = 'sc_submit',
  CONFIRMATION_PAGE = 'sc_list_confirmation_page',
}

export enum SearchedViaOptions {
  TOP_CITIES = 'top_cities',
  CITY_DROPDOWN = 'city_dropdown',
  DETECT_MY_LOCATION = 'detect_my_location',
  SEARCH_CITY = 'search_city',
}

export type SellMyCarEventParams = {
  clarity_id: string;
  button_location?: string;
  make?: string;
  search_term?: string;
  model_year?: string;
  model?: string;
  select_fuel?: string;
  trim?: string;
  selected_city?: string;
  searched_via?: SearchedViaOptions;
  detected_city?: string;
  check_user_login?: boolean;
  mobile?: string;
  number_of_otp_sent?: number;
  incorrect_otp?: string;
  kms_driven?: number;
  number_of_photos?: number;
  price_range?: SellMyCarPriceRanges;
  selling_price?: number;
  is_price_negotiable?: boolean;
  city?: string;
  vehicle_listing_id?: number | null;
};

export enum SellMyCarPriceRanges {
  SAR_0_TO_5000 = 'SAR 0 - 5000',
  SAR_5000_TO_10000 = 'SAR 5000 - 10,000',
  SAR_10000_TO_15000 = 'SAR 10,000 - 15,000',
  SAR_15000_TO_20000 = 'SAR 15,000 - 20,000',
  SAR_20000_TO_25000 = 'SAR 20,000 - 25000',
  SAR_25000_TO_35000 = 'SAR 25,000 - 35000',
  SAR_35000_TO_45000 = 'SAR 35,000 - 45000',
  SAR_45000_TO_55000 = 'SAR 45,000 - 55000',
  SAR_55000_PLUS = 'SAR 55,000 +',
}
export type PageViewEventParams = {
  url: string;
  title?: string;
  userId?: string;
};

export type ViewedSellerDetailsEventParams = {
  dealerId?: string;
  sellerId?: string;
  userId?: string;
  languageId?: string;
};

export type ViewedCarEventParams = {
  vehicleListingId?: string;
  userId?: string;
  make?: string;
  model?: string;
};

export type StartConciergeEventParams = {
  userId?: string;
};

export type SubmitConciergeEventParams = {
  userId?: string;
};

export type RaiseQueryEventParams = {
  userId?: string;
  vehicleListingId?: string;
};

export type SubmitQueryEventParams = {
  userId?: string;
  vehicleListingId?: string;
};

export type AddBookmarkEventParams = {
  userId?: string;
  vehicleListingId?: string;
};

export type AppliedFilterEventParams = {
  userId?: string;
  make?: string;
  model?: string;
  trim?: string;
  bodyType?: string;
  priceRangeFrom?: string;
  priceRangeTo?: string;
  mileageFrom?: string;
  mileageTo?: string;
  yearFrom?: string;
  yearTo?: string;
  features?: string;
  city?: string;
  fuelType?: string;
  interiorColor?: string;
  exteriorColor?: string;
  owner?: string;
  transmissionType?: string;
};

export type SearchedVehicleEventParams = {
  userId?: string;
  searchKey?: string;
};

export type CompletedSearchEventParams = {
  bodyType?: string;
  priceRangeFrom?: string;
  priceRangeTo?: string;
  fuelType?: string;
  transmissionType?: string;
  userId?: string;
};

export type AddedToCompareEventParams = {
  make?: string;
  model?: string;
  fuelType?: string;
  transmissionType?: string;
  vehicleListingId?: string;
  userId?: string;
};

export type EvaluateMyCarValueEventParams = {
  userId?: string;
};

export type ClickedBookServiceEventParams = {
  userId?: string;
};

export type CompletedBookServiceEventParams = {
  userId?: string;
};

export type UCDetailEventParams = {
  fuelType?: string;
  languageId?: number;
  vehicleListingId?: string;
  brand: string;
  model?: string;
  variant?: string;
  modelYear: string;
  source?: string;
  city?: string;
  agreement?: string;
  kmsDriven?: number;
  vin?: string;
  sellingPrice?: string;
  exteriorColor?: string;
  interiorColor?: string;
  listedDate?: string;
  dealerId?: number;
  dealerName?: string;
  monthlyEmi?: number;
  ownerType?: string;
  seller?: string;
  isPriceNegotiable?: string | null;
  mileage?: number;
  seatingCapacity?: string;
  doors?: string;
  engineSize?: string;
  transmissionType?: string;
  cityId?: number;
  bodyType?: string;
  pageTitle: string;
  originalLocation?: string;
  userId?: string | null;
  carType: string;
  pageCategory: string;
  pageName: string;
  eventCategory: string;
};
 /** @enum {string} */
export enum PageNames {
Home_page = 'HOMEPAGE', 
Used_cars_listing = 'USEDCARLISTINGPAGE',
Used_car_details ="USEDCARDETAILPAGE",
Sell_my_car = 'SELLMYCAR',
campaigns = 'DEALS',
All_listings = 'ALLLISTINGPAGE'
};
 /** @enum {string} */
 export enum LeadForms {
  Sell_car_lead = 'SELLCARLEADFORM', 
  Used_cars_lead = 'USEDCARLEADFORM',
  New_car_lead ="NEWCARLEADFORM",
  Campaigns_lead = 'CAMPAIGNLEADFORM',
};

 /** @enum {string} */
 export enum PageCategories {
  Home_page = 'HOMEPAGE', 
  Used_cars_listing = 'USEDCARLISTING',
  Used_car_details = 'USEDCARDETAIL',
  Sell_my_car = 'SELLCAR',
  campaigns = 'CAMPAIGNS',
  All_listings = 'ALLLISTING'
};
 /** @enum {string} */
export enum EventLabels {
  CTA_CLICKED = 'CTA_CLICKED',
  REGISTRATION_FLOW_SELECTED = 'REGISTRATION_FLOW_SELECTED',
  BRAND_CLICKED = 'BRAND_CLICKED',
  VIEW_ALL_BRANDS_CLICKED = 'VIEW_ALL_BRANDS_CLICKED',
  BRAND_SEARCH = 'BRAND_SEARCH',
  BRAND_SELECTED = 'BRAND_SELECTED',
  YEAR_SELECTED = 'YEAR_SELECTED',
  MODEL_SEARCH = 'MODEL_SEARCH',
  MODEL_SELECTED = 'MODEL_SELECTED',
  FUEL_SELECTION = 'FUEL_SELECTION',
  TRIM_SEARCH = 'TRIM_SEARCH',
  TRIM_SELECTED = 'TRIM_SELECTED',
  CITY_DROPDOWN_CLICKED = 'CITY_DROPDOWN_CLICKED',
  CITY_SEARCHED = 'CITY_SEARCHED',
  CITY_SELECTED = 'CITY_SELECTED',
  DETECT_MY_LOCATION = 'DETECT_MY_LOCATION',
  CITY_FOUND_IN_DB = 'CITY_FOUND_IN_DB',
  CITY_NOT_FOUND_IN_DB = 'CITY_NOT_FOUND_IN_DB',
  PREAUTHORISED = 'PREAUTHORISED',
  PHONE_INPUT = 'PHONE_INPUT',
  OTP_TRIGGERED = 'OTP_TRIGGERED',
  OTP_DELIVERED = 'OTP_DELIVERED',
  OTP_VERIFIED = 'OTP_VERIFIED',
  INCORRECT_OTP_ENTERED = 'INCORRECT_OTP_ENTERED',
  OTP_RESENT = 'OTP_RESENT',
  KMS_DRIVEN = 'KMS_DRIVEN',
  PHOTOS_UPLOAD = 'PHOTOS_UPLOAD',
  PHOTOS_IMPROPER_IMAGE = 'PHOTOS_IMPROPER_IMAGE',
  LIST_PRICE = 'LIST_PRICE',
  SUBMIT_LISTING = 'SUBMIT_LISTING',
  CONFIRMATION_PAGE = 'CONFIRMATION_PAGE',
  SUBMIT = 'SUBMIT',
  CONTACTSELLER = 'CONTACT_SELLER'
};