import React from 'react';

export const FourNumber = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 130 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25605 120.783L0.856055 107.983C3.45606 102.383 5.92272 96.9495 8.25606 91.6828C10.6561 86.4161 12.9894 81.2828 15.2561 76.2828C17.5894 71.2828 19.8561 66.3495 22.0561 61.4828C24.2561 56.6161 26.4561 51.7495 28.6561 46.8828C30.8561 42.0161 33.0894 37.0828 35.3561 32.0828C37.6894 27.0828 40.0227 21.9495 42.3561 16.6828C44.7561 11.4161 47.2561 5.98281 49.8561 0.382801H61.2561L85.6561 13.7828C83.3894 17.9828 81.2227 21.9161 79.1561 25.5828C77.1561 29.1828 75.2227 32.6161 73.3561 35.8828C71.4894 39.1495 69.6227 42.3828 67.7561 45.5828C65.8894 48.7828 64.0227 52.0161 62.1561 55.2828C60.2894 58.5495 58.3227 62.0161 56.2561 65.6828C54.2561 69.2828 52.1227 73.1828 49.8561 77.3828L38.8561 95.1828H129.656V120.783H9.25605ZM73.8561 150.383V95.1828L77.4561 57.3828H108.056V150.383H73.8561Z"
        fill="url(#paint0_linear_12909_205721)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12909_205721"
          x1="-5.9502"
          y1="87.9279"
          x2="137.052"
          y2="88.2948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
