import React from 'react';

export const ChangeMobileIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 81 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.3814 9.41094C58.6078 4.03815 50.0387 0.828125 40.7227 0.828125C18.7861 0.828125 1.00391 18.6182 1.00391 40.5622C1.00391 49.4334 3.91172 57.6294 8.82544 64.2427C9.30292 64.8853 10.211 65.0192 10.8536 64.5417C11.4962 64.0643 11.6301 63.1562 11.1526 62.5136C6.59775 56.3832 3.90317 48.7896 3.90317 40.5622C3.90317 20.2183 20.3884 3.72738 40.7227 3.72738C49.1546 3.72738 56.9222 6.56136 63.1294 11.3308L57.2198 11.5118C56.4196 11.5363 55.7907 12.2049 55.8152 13.0051C55.8397 13.8054 56.5083 14.4342 57.3086 14.4097L66.9757 14.1137C67.7759 14.0892 68.4048 13.4206 68.3803 12.6203L68.0842 2.95321C68.0597 2.15297 67.3911 1.52412 66.5909 1.54863C65.7907 1.57314 65.1618 2.24172 65.1863 3.04195L65.3814 9.41094Z"
        fill="#4C0055"
        stroke="#4C0055"
        strokeWidth="0.75"
      />
      <path
        d="M73.1175 17.7148C72.6555 17.0609 71.751 16.9054 71.0971 17.3673C70.4432 17.8293 70.2876 18.7338 70.7496 19.3877C74.9747 25.3683 77.4559 32.6627 77.4559 40.5399C77.4559 60.8293 60.9822 77.2809 40.6561 77.2809C32.3591 77.2809 24.706 74.5409 18.5511 69.9167L24.4581 69.6726C25.258 69.6395 25.8797 68.9643 25.8466 68.1643C25.8136 67.3644 25.1383 66.7428 24.3384 66.7758L14.675 67.1751C13.875 67.2082 13.2534 67.8834 13.2864 68.6834L13.6857 78.3468C13.7188 79.1467 14.394 79.7684 15.194 79.7353C15.9939 79.7023 16.6156 79.027 16.5825 78.2271L16.3194 71.8603C23.0405 77.0745 31.4866 80.1802 40.6561 80.1802C62.5792 80.1802 80.3552 62.4347 80.3552 40.5399C80.3552 32.0439 77.6768 24.1684 73.1175 17.7148Z"
        fill="#4C0055"
        stroke="#4C0055"
        strokeWidth="0.75"
      />
      <path
        d="M59.033 61.2812C54.0695 61.2812 49.2307 60.1747 44.5166 57.9615C39.8024 55.7483 35.6277 52.8241 31.9925 49.1888C28.3572 45.5536 25.433 41.3789 23.2198 36.6647C21.0066 31.9506 19.9008 27.1125 19.9023 22.1506C19.9023 21.458 20.1332 20.8809 20.5949 20.4191C21.0566 19.9574 21.6338 19.7266 22.3264 19.7266H31.6762C32.2148 19.7266 32.6958 19.8997 33.119 20.246C33.5423 20.5923 33.7924 21.0348 33.8693 21.5734L35.3699 29.6535C35.4469 30.1922 35.4369 30.6831 35.3399 31.1264C35.243 31.5696 35.0221 31.9636 34.6773 32.3084L29.079 37.9645C30.695 40.7348 32.725 43.3319 35.1691 45.756C37.6131 48.18 40.2965 50.277 43.2191 52.0469L48.6443 46.6217C48.9906 46.2754 49.4431 46.0153 50.0018 45.8414C50.5605 45.6675 51.1084 45.6197 51.6455 45.6982L59.6102 47.3143C60.1488 47.4297 60.5913 47.6898 60.9376 48.0946C61.2839 48.4993 61.457 48.9703 61.457 49.5074V58.8572C61.457 59.5498 61.2262 60.127 60.7645 60.5887C60.3027 61.0504 59.7256 61.2812 59.033 61.2812ZM26.8858 33.5781L30.695 29.7689L29.7139 24.3438H24.5772C24.7696 25.9213 25.039 27.4796 25.3853 29.0187C25.7315 30.5577 26.2317 32.0775 26.8858 33.5781ZM47.5478 54.24C49.0483 54.8941 50.5782 55.4136 52.1372 55.7983C53.6963 56.1831 55.2638 56.4332 56.8398 56.5486V51.4697L51.4146 50.3731L47.5478 54.24Z"
        fill="#4C0055"
      />
    </svg>
  );
};
