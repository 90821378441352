import { createSlice } from '@reduxjs/toolkit';

type intialStateType = {
  list: [];
  toplist: [];
};

export const homeInitialState: intialStateType = {
  list: [],
  toplist: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState: homeInitialState,
  reducers: {
    usedCarsList: (state, action) => {
      return { ...state, list: action.payload };
    },
    topUsedCarsList: (state, action) => {
      return { ...state, toplist: action.payload };
    },
  },
});

export const { usedCarsList, topUsedCarsList } = homeSlice.actions;

export default homeSlice.reducer;
