export const FilledBookmarkIcon = ({ className = '' }) => {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.0363 15L6.91155 13.859C2.73246 10.1089 0.000403622 7.58179 0.000403622 4.48325C-0.00752253 3.89233 0.101362 3.30579 0.320625 2.75827C0.539889 2.21076 0.865085 1.71337 1.27701 1.29549C1.68893 0.877605 2.17922 0.547702 2.71893 0.325265C3.25863 0.102829 3.8368 -0.0076314 4.41929 0.000409463C5.1074 0.00804988 5.78596 0.164728 6.40955 0.459954C7.03314 0.75518 7.58736 1.18214 8.03512 1.71226C8.48302 1.18199 9.03743 0.75494 9.66123 0.459708C10.285 0.164476 10.9638 0.00788016 11.6521 0.000409463C12.2346 -0.0076314 12.8128 0.102829 13.3525 0.325265C13.8922 0.547702 14.3825 0.877605 14.7944 1.29549C15.2063 1.71337 15.5315 2.21076 15.7508 2.75827C15.9701 3.30579 16.0789 3.89233 16.071 4.48325C16.071 7.58119 13.339 10.1083 9.16047 13.8578L8.0363 15Z"
        fill="#DE2A00"
      />
    </svg>
  );
};
