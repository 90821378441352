import React from 'react';

export const SearchIconV2 = ({ stroke = '#8F90A6' }) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6667 18.8333C15.3486 18.8333 18.3333 15.8486 18.3333 12.1667C18.3333 8.48477 15.3486 5.5 11.6667 5.5C7.98477 5.5 5 8.48477 5 12.1667C5 15.8486 7.98477 18.8333 11.6667 18.8333Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0003 20.4998L16.417 16.9165"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
