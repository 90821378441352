export const TickIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.79468 1.67529C6.3526 1.22348 7.15051 1.22348 7.70842 1.67529C8.02377 1.93067 8.42811 2.04939 8.83146 2.00505C9.54507 1.92659 10.2163 2.35797 10.4414 3.03969C10.5686 3.42501 10.8446 3.74349 11.2079 3.92425C11.8506 4.24406 12.1821 4.96987 12.0029 5.66505C11.9016 6.05799 11.9615 6.47511 12.1694 6.82359C12.5373 7.44012 12.4237 8.22991 11.8971 8.71784C11.5994 8.99364 11.4244 9.37696 11.4109 9.78252C11.387 10.5 10.8644 11.1031 10.1576 11.2288C9.75813 11.2999 9.40362 11.5277 9.173 11.8616C8.76497 12.4523 7.99938 12.6771 7.33678 12.4008C6.96226 12.2446 6.54085 12.2446 6.16633 12.4008C5.50373 12.6771 4.73813 12.4523 4.33011 11.8616C4.09949 11.5277 3.74497 11.2999 3.34547 11.2288C2.63866 11.1031 2.11613 10.5 2.09224 9.78252C2.07873 9.37696 1.90367 8.99364 1.60602 8.71784C1.0794 8.22991 0.965847 7.44012 1.33366 6.82359C1.54156 6.47511 1.60153 6.05799 1.50023 5.66505C1.32102 4.96987 1.65248 4.24406 2.29523 3.92425C2.65853 3.74349 2.93449 3.42501 3.06171 3.03969C3.28679 2.35797 3.95804 1.92659 4.67165 2.00505C5.075 2.04939 5.47934 1.93067 5.79468 1.67529Z"
        fill="white"
      />
      <path
        d="M5.78928 8.0804L4.38116 6.69864C4.33068 6.64914 4.27075 6.60987 4.20479 6.58308C4.13884 6.55629 4.06814 6.5425 3.99675 6.5425C3.92536 6.5425 3.85467 6.55629 3.78872 6.58308C3.72276 6.60987 3.66283 6.64914 3.61235 6.69864C3.5104 6.79862 3.45312 6.93422 3.45312 7.07561C3.45312 7.217 3.5104 7.35259 3.61235 7.45257L5.40533 9.21085C5.45567 9.2606 5.51555 9.30009 5.58153 9.32703C5.6475 9.35398 5.71826 9.36785 5.78973 9.36785C5.8612 9.36785 5.93197 9.35398 5.99794 9.32703C6.06392 9.30009 6.1238 9.2606 6.17414 9.21085L9.88687 5.56908C9.93803 5.51978 9.97872 5.46104 10.0066 5.39624C10.0345 5.33144 10.049 5.26187 10.0494 5.19154C10.0497 5.12121 10.0358 5.0515 10.0086 4.98645C9.98128 4.9214 9.94115 4.86229 9.89046 4.81253C9.83978 4.76277 9.77955 4.72334 9.71324 4.69651C9.64694 4.66969 9.57588 4.65601 9.50416 4.65625C9.43244 4.6565 9.36147 4.67066 9.29536 4.69794C9.22925 4.72521 9.1693 4.76505 9.11897 4.81516L5.78928 8.0804Z"
        fill="url(#paint0_linear_13202_47568)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13202_47568"
          x1="3.45312"
          y1="7.18184"
          x2="10.0492"
          y2="7.22344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
