export const SelectedFemaleIcon = ({ className = 'h-8 w-8' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.0234"
        cy="20.5"
        r="19.5"
        fill="#4C0055"
        stroke="#4C0055"
        strokeWidth="2"
      />
      <path
        d="M8.45312 30.2466C8.45312 30.0356 8.45312 29.8246 8.45312 29.6135C8.51106 29.3123 8.54949 29.0055 8.6306 28.7109C9.26244 26.419 10.7512 24.9967 13.0437 24.4076C14.2366 24.1008 15.4338 23.8123 16.6286 23.5105C16.7152 23.4885 16.8451 23.4458 16.8652 23.3836C16.9549 23.1116 17.011 22.828 17.0805 22.5444C16.6139 22.4438 16.1773 22.3566 15.7443 22.2553C14.7861 22.0315 13.8573 21.7217 12.9803 21.2716C12.5393 21.0453 12.493 20.683 12.8345 20.3305C12.9693 20.1915 13.0968 20.0415 13.2065 19.8823C13.7591 19.0833 14.0787 18.1868 14.2982 17.2488C14.6129 15.9052 14.669 14.5379 14.6971 13.1644C14.7306 11.5196 15.1222 9.96561 16.0675 8.5946C17.0848 7.12052 18.4521 6.11543 20.2311 5.78549C21.4796 5.55373 22.678 5.69156 23.6495 6.65152C23.7117 6.71312 23.8392 6.73263 23.9368 6.73263C24.865 6.73202 25.5755 7.12235 26.0756 7.90238C26.6001 8.72023 26.8569 9.63688 26.9526 10.5828C27.0697 11.7428 27.1118 12.9113 27.1631 14.0774C27.2186 15.3454 27.3375 16.6023 27.6985 17.8239C27.9693 18.74 28.3255 19.6164 29.0006 20.3226C29.3495 20.6873 29.3135 21.0795 28.8488 21.2716C28.0645 21.5954 27.2673 21.8943 26.4599 22.1541C25.9165 22.3291 25.345 22.4182 24.7668 22.5505C24.8211 22.7756 24.8858 22.9927 24.9223 23.2134C24.9535 23.4007 25.048 23.4775 25.2303 23.5214C26.4275 23.8105 27.6217 24.1118 28.817 24.4088C31.4877 25.0724 33.3064 27.271 33.3918 29.9264C33.4021 30.2539 33.3052 30.4936 33.0033 30.6357H8.84284C8.71293 30.5058 8.58303 30.3759 8.45312 30.246V30.2466Z"
        fill="white"
      />
    </svg>
  );
};
