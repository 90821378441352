import React from 'react';

export const XMarkCircleIconV2 = ({
  circleColor = '#F0F0F0',
  xColor = '#212121',
  className = '',
}) => {
  return (
    <svg
      width="24"
      className={className}
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.1074" r="12" fill={circleColor} />
      <path
        d="M16 8.10742L8 16.1074"
        stroke={xColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 8.10742L16 16.1074"
        stroke={xColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
