export const BulletArrowIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M5.3032 1.29292L9.00281 5.00131C9.1451 5.14394 9.25182 5.29846 9.32297 5.46486C9.39411 5.63126 9.42969 5.80955 9.42969 5.99972C9.42969 6.18989 9.39411 6.36818 9.32297 6.53458C9.25182 6.70099 9.1451 6.8555 9.00281 6.99813L5.3032 10.7065C4.85261 11.1582 4.33704 11.259 3.75648 11.0089C3.17498 10.7598 2.88423 10.3143 2.88423 9.67245L2.88423 2.32699C2.88423 1.68515 3.17498 1.23967 3.75649 0.990544C4.33704 0.740466 4.85261 0.841258 5.3032 1.29292Z"
        fill="#43474D"
      />
    </svg>
  );
};
