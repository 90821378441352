export const StarIcon = ({ className = 'h-8 w-8',fillColor="white" }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.1891 5.51714L10.2219 4.94058L8.44847 1.34527C8.40003 1.24683 8.32034 1.16714 8.22191 1.11871C7.97503 0.996832 7.67503 1.09839 7.55159 1.34527L5.77815 4.94058L1.81097 5.51714C1.70159 5.53277 1.60159 5.58433 1.52503 5.66246C1.43247 5.75759 1.38146 5.88558 1.38322 6.0183C1.38498 6.15102 1.43936 6.27762 1.5344 6.37027L4.40472 9.16871L3.72659 13.1203C3.71069 13.2122 3.72086 13.3067 3.75595 13.3932C3.79105 13.4796 3.84966 13.5545 3.92514 13.6093C4.00062 13.6641 4.08995 13.6967 4.183 13.7033C4.27605 13.71 4.3691 13.6904 4.45159 13.6468L8.00003 11.7812L11.5485 13.6468C11.6453 13.6984 11.7578 13.7156 11.8657 13.6968C12.1375 13.65 12.3203 13.3921 12.2735 13.1203L11.5953 9.16871L14.4657 6.37027C14.5438 6.29371 14.5953 6.19371 14.611 6.08433C14.6532 5.81089 14.4625 5.55777 14.1891 5.51714Z"
        fill={fillColor}
      />
    </svg>
  );
};
