export const InspectionCartIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="110"
      height="110"
      viewBox="0 0 110 110"
      fill="none"
      className={className}
    >
      <rect
        x="0.5"
        y="0.5"
        width="109"
        height="109"
        rx="3.5"
        fill="white"
        stroke="#E0E0E0"
      />
      <path
        d="M80.6667 45.7391H77.5167L69.4142 27.7364C69.0475 26.9219 68.4495 26.2299 67.6927 25.7442C66.9358 25.2585 66.0526 25 65.15 25H30.85C29.9474 25 29.0642 25.2585 28.3073 25.7442C27.5505 26.2299 26.9525 26.9219 26.5858 27.7364L18.4833 45.7391H15.3333C14.7145 45.7391 14.121 45.9819 13.6834 46.4141C13.2458 46.8462 13 47.4323 13 48.0435C13 48.6546 13.2458 49.2407 13.6834 49.6729C14.121 50.105 14.7145 50.3478 15.3333 50.3478H17.6667V73.3913C17.6667 74.6136 18.1583 75.7858 19.0335 76.6501C19.9087 77.5144 21.0957 78 22.3333 78H29.3333C30.571 78 31.758 77.5144 32.6332 76.6501C33.5083 75.7858 34 74.6136 34 73.3913V68.7826H62V73.3913C62 74.6136 62.4917 75.7858 63.3668 76.6501C64.242 77.5144 65.429 78 66.6667 78H73.6667C74.9043 78 76.0913 77.5144 76.9665 76.6501C77.8417 75.7858 78.3333 74.6136 78.3333 73.3913V50.3478H80.6667C81.2855 50.3478 81.879 50.105 82.3166 49.6729C82.7542 49.2407 83 48.6546 83 48.0435C83 47.4323 82.7542 46.8462 82.3166 46.4141C81.879 45.9819 81.2855 45.7391 80.6667 45.7391ZM30.85 29.6087H65.15L72.4096 45.7391H23.5904L30.85 29.6087ZM29.3333 73.3913H22.3333V68.7826H29.3333V73.3913ZM66.6667 73.3913V68.7826H73.6667V73.3913H66.6667ZM73.6667 64.1739H22.3333V50.3478H73.6667V64.1739ZM27 57.2609C27 56.6497 27.2458 56.0636 27.6834 55.6314C28.121 55.1993 28.7145 54.9565 29.3333 54.9565H34C34.6188 54.9565 35.2123 55.1993 35.6499 55.6314C36.0875 56.0636 36.3333 56.6497 36.3333 57.2609C36.3333 57.872 36.0875 58.4581 35.6499 58.8903C35.2123 59.3224 34.6188 59.5652 34 59.5652H29.3333C28.7145 59.5652 28.121 59.3224 27.6834 58.8903C27.2458 58.4581 27 57.872 27 57.2609ZM59.6667 57.2609C59.6667 56.6497 59.9125 56.0636 60.3501 55.6314C60.7877 55.1993 61.3812 54.9565 62 54.9565H66.6667C67.2855 54.9565 67.879 55.1993 68.3166 55.6314C68.7542 56.0636 69 56.6497 69 57.2609C69 57.872 68.7542 58.4581 68.3166 58.8903C67.879 59.3224 67.2855 59.5652 66.6667 59.5652H62C61.3812 59.5652 60.7877 59.3224 60.3501 58.8903C59.9125 58.4581 59.6667 57.872 59.6667 57.2609Z"
        fill="url(#paint0_linear_1196_68753)"
      />
      <circle cx="77" cy="64" r="21" fill="white" />
      <path
        d="M89 75L83.2094 69.2094M83.2094 69.2094C84.1999 68.2189 84.9856 67.043 85.5217 65.7488C86.0577 64.4547 86.3336 63.0676 86.3336 61.6668C86.3336 60.266 86.0577 58.879 85.5217 57.5848C84.9856 56.2906 84.1999 55.1147 83.2094 54.1242C82.2189 53.1337 81.043 52.348 79.7488 51.812C78.4547 51.2759 77.0676 51 75.6668 51C74.266 51 72.879 51.2759 71.5848 51.812C70.2906 52.348 69.1147 53.1337 68.1242 54.1242C66.1238 56.1247 65 58.8378 65 61.6668C65 64.4958 66.1238 67.209 68.1242 69.2094C70.1247 71.2098 72.8378 72.3336 75.6668 72.3336C78.4958 72.3336 81.209 71.2098 83.2094 69.2094Z"
        stroke="url(#paint1_linear_1196_68753)"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1196_68753"
          x1="13"
          y1="53.4099"
          x2="82.9989"
          y2="53.8264"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1196_68753"
          x1="65"
          y1="63.8649"
          x2="89"
          y2="63.973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
