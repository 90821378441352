import { withApplicationInsights } from 'next-applicationinsights';
import { appWithTranslation, useTranslation } from 'next-i18next';
import type { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';
import DynamicMeta from '../components/DynamicMeta/DynamicMeta';
import { GlobalService } from '../helpers/services';
import ConfigurationService from '../helpers/services/configuration.service';
import { NextPageContext } from 'next';
import Script from 'next/script';
import ContinueListingToast from '../components/componentsV2/selfListing/ContinueListingToast';
import { AppProvider } from '../provider/AppProvider';
import { CityProvider } from '../provider/CityProvider';
import { GlobalDataProvider } from '../provider/GlobalDataProvider';
import { SeoTagProvider } from '../provider/SeoTagProvider';
import { TranslationProvider } from '../provider/TranslationProvider';
import '../styles/globals.scss';
import { AppTheme, LogoUrl } from '../types/constants';
import { ConfigurationKey } from '../types/enums';
import {
  AllConfigurationResponse,
  ConfigurationResponse,
  NewHeaderMenu,
  SocialMediaResponse,
} from '../types/models';
import StoreProvider from './StoreProvider';

interface AppProps extends NextAppProps {
  metaData: {
    title: string;
    description: string;
    keywords: string[];
  };
  canonicalUrl: string;
  hrefLangUrl: string;
  locale: string;
}
function MyApp({
  Component,
  pageProps: { session, ...pageProps },
  metaData,
  canonicalUrl,
  hrefLangUrl,
  locale,
}: AppProps) {
  const { i18n } = useTranslation();
  const router = useRouter();

  const [careerEmail, setCareerEmail] = useState<ConfigurationResponse>();
  const [socialMedia, setSocialMediaData] = useState<SocialMediaResponse>();
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [headerMenu, setHeaderMenu] = useState<Array<NewHeaderMenu>>([]);

  useEffect(() => {
    const id = process?.env?.NEXT_PUBLIC_CLARITY_ID!;
    if (id) {
      clarity.init(id);
    }
  }, []);

  useEffect(() => {
    const initialLoad = async () => {
      /* Load application header menu */
      const [
        configRes,
        socialMediaData,
        phoneNumberConfigurationData,
        headerData,
      ] = await Promise.all([
        ConfigurationService.fetchConfigurationValue(
          ConfigurationKey.CareersEmailId,
          router.locale
        ),
        GlobalService.fetchSocialMedia(),
        ConfigurationService.fetchConfigurationValue(
          ConfigurationKey.CustomerPhoneNumber
        ),
        GlobalService.getNewHeaderMenu(router.locale),
      ]);
      setCareerEmail(configRes);
      setSocialMediaData(socialMediaData);
      phoneNumberConfigurationData &&
        setPhoneNumber(phoneNumberConfigurationData.ConfigurationValue);
      setHeaderMenu(headerData);
    };

    if (router.locale) initialLoad();
  }, [router.locale]);

  const loadTheme = () => {
    switch (pageProps.applyTheme) {
      case AppTheme.V1:
        return 'theme-v1';
      default:
        return 'theme-default';
    }
  };

  useEffect(() => storePathValues, [router.asPath]);

  function storePathValues() {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath: any = storage.getItem('currentPath');
    storage.setItem('prevPath', prevPath ? prevPath : '/');
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis?.location?.pathname);
  }

  return (
    <GlobalDataProvider>
      <TranslationProvider>
        <StoreProvider>
          {/* <MoEngageProvider> */}
          <CityProvider>
            <SeoTagProvider>
              <AppProvider>
                <Head>
                  <link
                    rel="icon"
                    href={`${process.env.NEXT_PUBLIC_APP_CDN_URL}/b2cpages/favicon.ico`}
                  />
                  <link
                    rel="preconnect"
                    href={'https://po-cdn-nonprod.azureedge.net'}
                  />
                </Head>
                {process.env.NEXT_PUBLIC_ENABLE_ADSENSE === 'true' ? (
                  <Script
                    async
                    src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-${process.env.NEXT_PUBLIC_GOOGLE_PUBLISHER_ID}`}
                    crossOrigin="anonymous"
                    strategy="afterInteractive"
                  />
                ) : (
                  <></>
                )}
                <Script
                  id="gtm-base"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_IDENTIFIER}');`,
                  }}
                />
                <Script
                  id="google-tag-manager_1"
                  strategy="afterInteractive"
                  async
                  src="https://www.googletagmanager.com/gtag/js?id=AW-16637799978"
                />
                <Script id="google-tag-manager_2" strategy="afterInteractive">
                  {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-16637799978');gtag('event', 'conversion', {'send_to': 'AW-16637799978/xZaRCOShgcEZEKrcwv09'});`}
                </Script>
                {/* {loading ? (
            <GogoLoader />
          ) : */}
                <DynamicMeta
                  metaData={metaData}
                  hrefLangUrl={hrefLangUrl}
                  canonicalUrl={canonicalUrl}
                  locale={locale}
                  imageUrl={pageProps?.imageUrl || LogoUrl}
                />
                {router.pathname === '/petromin-auth' ||
                pageProps.hideLayout ? (
                  <main className={loadTheme()}>
                    <Component {...pageProps} />
                    <div id="app-message-box" />
                  </main>
                ) : (
                  <div className={loadTheme()}>
                    {!pageProps.hideHeader && (
                      <Header
                        careerEmail={careerEmail!}
                        socialMedia={socialMedia!}
                        phoneNumber={phoneNumber}
                        headerMenu={headerMenu}
                      />
                    )}
                    <main className="main">
                      <Component {...pageProps} />
                    </main>
                    <ContinueListingToast />
                    <Footer
                      careerEmail={careerEmail!}
                      socialMedia={socialMedia!}
                      phoneNumber={phoneNumber}
                      headerMenu={headerMenu}
                    />
                    <div id="app-message-box" />
                    <noscript>
                      <iframe
                        src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_IDENTIFIER}`}
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                      ></iframe>
                    </noscript>
                  </div>
                )}
                <ToastContainer
                  limit={1}
                  autoClose={2000}
                  rtl={i18n.language === 'ar' ? true : false}
                />
              </AppProvider>
            </SeoTagProvider>
          </CityProvider>
          {/* </MoEngageProvider> */}
        </StoreProvider>
      </TranslationProvider>
    </GlobalDataProvider>
  );
}
MyApp.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const { locale, asPath, pathname } = ctx;
  const host = process.env.NEXT_PUBLIC_APP_DOMAIN || 'www.gogomotor.com';
  const response: AllConfigurationResponse =
    await ConfigurationService.getSeoTag(locale);
  const translations: AllConfigurationResponse =
    await ConfigurationService.getTranslations(locale);
  const LabelConstants: any = translations;
  const seoList: any = response || [];
  // Default metadata
  let metaData = {
    title: LabelConstants.GO_GO_MOTOR_PAGE_META_TITLE_GLOBAL,
    description: LabelConstants.GO_GO_MOTOR_PAGE_META_DESC_GLOBAL,
    keywords: [],
  };
  // Fetch metadata based on the URL path or canonical URL
  const metatag = seoList.find((resp: any) => {
    // if (resp.attributes.CanonicalURL.includes('[[...filter-params]]')) {
    //   return pathname === resp.attributes.CanonicalURL ;
    // }
    if (asPath?.startsWith('/car-details')) {
      return resp.attributes.CanonicalURL === '/car-details';
    }
    if (resp.attributes.CanonicalURL === '/cars/[[...filter-params]]') {
      return pathname === '/used-cars/[[...filter-params]]';
    }
    return asPath === resp.attributes.CanonicalURL;
  });
  if (metatag) {
    metaData = {
      title: metatag.attributes.MetaTitle,
      description: metatag.attributes.MetaDescription,
      keywords: [],
    };
  }
  let hrefLangUrl = '';
  let canonicalUrl = '';
  if (locale === 'en') {
    canonicalUrl = `https://${host}/en${asPath}`;
    hrefLangUrl = `https://${host}${asPath}`;
  } else if (locale === 'ar') {
    hrefLangUrl = `https://${host}/en${asPath}`;
    canonicalUrl = `https://${host}${asPath}`;
  }
  return {
    pageProps: {},
    metaData,
    hrefLangUrl: hrefLangUrl.replace(/\/$/, ''),
    canonicalUrl: canonicalUrl.replace(/\/$/, ''),
    locale: locale || 'x-default',
  };
};

export default appWithTranslation(
  withApplicationInsights({
    instrumentationKey: process.env.NEXT_PUBLIC_INSTRUMENTATION_KEY,
    isEnabled: process.env.NEXT_PUBLIC_IS_LOCAL !== 'true',
  })(MyApp as any)
);
