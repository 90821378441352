import React from 'react';

const PdiProcessIconV2 = ({ fill = '#212121', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.50065 15C6.58398 15 5.83398 15.75 5.83398 16.6667C5.83398 17.5833 6.58398 18.3333 7.50065 18.3333C8.41732 18.3333 9.16732 17.5833 9.16732 16.6667C9.16732 15.75 8.41732 15 7.50065 15ZM7.50065 17.5C7.00065 17.5 6.66732 17.1667 6.66732 16.6667C6.66732 16.1667 7.00065 15.8333 7.50065 15.8333C8.00065 15.8333 8.33398 16.1667 8.33398 16.6667C8.33398 17.1667 8.00065 17.5 7.50065 17.5Z"
        fill={fill}
      />
      <path
        d="M10.845 12.3634L6.66667 11.6667L4.16667 9.16675C4.16667 9.16675 3.95167 9.16675 0 9.16675V16.6667H5C5 15.2501 6.08333 14.1667 7.5 14.1667C8.91667 14.1667 10 15.2501 10 16.6667L11.6667 15.8301V13.3334C11.6667 12.8751 11.2975 12.4384 10.845 12.3634ZM0.833333 12.5001V10.0001H3.33333L5.83333 12.5001H0.833333Z"
        fill={fill}
      />
      <path
        d="M18.953 12.0887L16.0633 9.19904C16.6365 8.41519 16.9792 7.45196 16.9792 6.40633C16.9792 3.78862 14.8573 1.66675 12.2396 1.66675C9.62188 1.66675 7.5 3.78862 7.5 6.40633C7.5 9.02404 9.62188 11.1459 12.2396 11.1459C13.2852 11.1459 14.2484 10.8032 15.0323 10.2301L17.922 13.1198C18.0642 13.262 18.2508 13.3334 18.4375 13.3334C18.6242 13.3334 18.8108 13.262 18.953 13.1198C19.2381 12.8347 19.2381 12.3738 18.953 12.0887ZM12.2396 9.68758C10.4276 9.68758 8.95833 8.21831 8.95833 6.40633C8.95833 4.59435 10.4276 3.12508 12.2396 3.12508C14.0516 3.12508 15.5208 4.59435 15.5208 6.40633C15.5208 8.21831 14.0516 9.68758 12.2396 9.68758Z"
        fill={fill}
      />
    </svg>
  );
};

export default PdiProcessIconV2;
