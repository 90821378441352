export const WarningIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.46877 11.1644L7.46884 11.1644V11.1607V6.10367C7.46884 5.82511 7.26773 5.59281 7.00248 5.54546V5.53598H6.90248C6.58889 5.53598 6.33546 5.79027 6.33546 6.103V11.1607H6.33539L6.33553 11.1644C6.34108 11.311 6.40321 11.4497 6.50886 11.5514C6.61452 11.6532 6.75548 11.71 6.90215 11.71C7.04882 11.71 7.18978 11.6532 7.29544 11.5514C7.40109 11.4497 7.46322 11.311 7.46877 11.1644ZM13.7904 7.50341C13.7904 3.75279 10.7501 0.7125 6.9995 0.7125C3.24889 0.7125 0.208594 3.75279 0.208594 7.50341C0.208594 11.254 3.24889 14.2943 6.9995 14.2943C10.7501 14.2943 13.7904 11.254 13.7904 7.50341ZM2.99903 3.50293C4.06002 2.44194 5.49903 1.84588 6.9995 1.84588C8.49997 1.84588 9.93899 2.44194 11 3.50293C12.061 4.56393 12.657 6.00294 12.657 7.50341C12.657 9.00388 12.061 10.4429 11 11.5039C9.93899 12.5649 8.49997 13.1609 6.9995 13.1609C5.49903 13.1609 4.06002 12.5649 2.99903 11.5039C1.93803 10.4429 1.34198 9.00388 1.34198 7.50341C1.34198 6.00294 1.93803 4.56393 2.99903 3.50293ZM7.43193 3.88143C7.29648 3.74598 7.11277 3.66988 6.92122 3.66988C6.72967 3.66988 6.54596 3.74598 6.41051 3.88143C6.27506 4.01687 6.19897 4.20058 6.19897 4.39214C6.19897 4.58369 6.27506 4.7674 6.41051 4.90285C6.54596 5.0383 6.72967 5.11439 6.92122 5.11439C7.11277 5.11439 7.29648 5.0383 7.43193 4.90285C7.56738 4.7674 7.64347 4.58369 7.64347 4.39214C7.64347 4.20058 7.56738 4.01687 7.43193 3.88143Z"
        fill="#DE2A00"
        stroke="#DE2A00"
        strokeWidth="0.2"
      />
    </svg>
  );
};
