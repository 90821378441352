import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import ConfigurationService from '../helpers/services/configuration.service';

const Context = createContext<{
  generalData: any;
  setGeneralData: (value: SetStateAction<any>) => void;
}>({
  generalData: [],
  setGeneralData(value) {},
});

export function GlobalDataProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const router = useRouter();
  const [generalData, setGeneralData] = useState<any>({});

  useEffect(() => {
    const initialLoad = async () => {
      const data = await ConfigurationService.getGeneralSetting(router.locale);
      setGeneralData(data);
    };
    initialLoad();
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        generalData,
        setGeneralData,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useGlobalDataContext() {
  return useContext(Context);
}
