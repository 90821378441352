import Head from 'next/head';
import { useSelector } from 'react-redux';

type MetaData = {
  title: string;
  description: string;
  keywords: string[];
};
type DynamicMetaProps = {
  metaData: MetaData;
  canonicalUrl: string;
  hrefLangUrl: string;
  locale: string;
  imageUrl: string;
};

const DynamicMeta = ({
  imageUrl,
  metaData,
  hrefLangUrl,
  canonicalUrl,
  locale,
}: DynamicMetaProps) => {
  const pagelUrl = useSelector((state: any) => state.global.canonicalUrl);
  const isTargetUrl = (url: any) => {
    return (
      (url.includes('cars') ||
        url.includes('all-listings') ||
        url.includes('newcars/new') ||
        url.includes('newcars/outlet')) &&
      !url.includes('newcars/new/info') &&
      !url.includes('newcars/new/leads') &&
      !url.includes('newcars/outlet/info')
    );
  };

  const finalCanonicalUrl =
    isTargetUrl(canonicalUrl) && pagelUrl && pagelUrl !== ''
      ? pagelUrl
      : canonicalUrl;

  const finalHrefLangUrl =
    isTargetUrl(canonicalUrl) && pagelUrl && pagelUrl !== ''
      ? pagelUrl.includes('/en')
        ? pagelUrl.replace('/en', '')
        : pagelUrl.replace(
            `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`,
            `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}/en`
          ) // Add `/en` after the domain if it's missing
      : hrefLangUrl;

  return (
    <Head>
      <title key={'page-title'}>{metaData.title}</title>
      {process.env.NEXT_PUBLIC_ENABLE_CRAWLING === 'true' ? (
        <></>
      ) : (
        <>
          <meta name="robots" content="noindex" />
          <meta name="googlebot" content="noindex" />
        </>
      )}
      <meta
        name="description"
        content={metaData.description}
        key="meta-data-description"
      />
      {/* Open Graph (OG) Tags  */}

      <meta property="og:title" content={metaData.title} key="og-data-title" />
      <meta
        property="og:description"
        content={metaData.description}
        key="og-data-description"
      />
      <meta property="og:url" content={finalCanonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="GoGo Motor" />

      {/* Twitter Tags  */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={metaData.title}
        key="twitter-data-title"
      />
      <meta
        name="twitter:description"
        content={metaData.description}
        key="twitter-data-description"
      />
      <meta name="twitter:url" content={finalCanonicalUrl} />
      <meta name="twitter:image" content={imageUrl} />

      <link rel="canonical" href={finalCanonicalUrl} key={'canonicalUrl'} />
      <link rel="alternate" hrefLang={locale} href={finalCanonicalUrl} />
      <link
        rel="alternate"
        hrefLang={locale === 'ar' ? 'en' : 'ar'}
        href={finalHrefLangUrl}
      />
      <link
        rel="alternate"
        hrefLang={'x-default'}
        href={
          finalCanonicalUrl.includes('/en') || finalCanonicalUrl.includes('/ar')
            ? finalHrefLangUrl
            : finalCanonicalUrl
        }
      />
    </Head>
  );
};

export default DynamicMeta;
