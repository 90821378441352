import { createSlice } from '@reduxjs/toolkit';
import { ReportListingConstants } from '../../types/i18n.labels';
import { ReportListingReasons } from '../../types/enums';

type initialStateType = {
  isOpen: boolean;
  loader: boolean;
  currentStep: ReportListingConstants;
  reportingReason: ReportListingReasons | '';
  reportingReasonText: string;
  vehicleListingId: number | null;
};

const ReportListingInitialState: initialStateType = {
  isOpen: false,
  loader: false,
  currentStep: ReportListingConstants.SELECT_REASON_FOR_REPORTING,
  reportingReason: '',
  reportingReasonText: '',
  vehicleListingId: null,
};

export const reportListingSlice = createSlice({
  name: 'report-this-listing',
  initialState: ReportListingInitialState,
  reducers: {
    setOpenReportListingFlow: (state, action) => {
      return { ...state, isOpen: action.payload };
    },
    setLoader: (state, action) => {
      return { ...state, loader: action.payload };
    },
    updateReportListingStep: (state, action) => {
      return { ...state, currentStep: action.payload };
    },
    updateReportListingReason: (state, action) => {
      return { ...state, reportingReason: action.payload };
    },
    updateReportListingReasonText: (state, action) => {
      return { ...state, reportingReasonText: action.payload };
    },
    updateVehicleListingId: (state, action) => {
      return { ...state, vehicleListingId: action.payload };
    },
  },
});

export const {
  setLoader,
  setOpenReportListingFlow,
  updateReportListingReason,
  updateReportListingStep,
  updateReportListingReasonText,
  updateVehicleListingId,
} = reportListingSlice.actions;

export default reportListingSlice.reducer;
