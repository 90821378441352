import { useRouter } from 'next/router';
import { FC, ReactElement } from 'react';
import { useAppSelector, useAppDispatch } from '../../../lib/hooks';
import { setCanonicalUrl } from '../../../lib/global.slice';
import IdentifierService from '../../../helpers/services/identifier.service';
import { SellMyCarPathname } from '../../../types/constants';
import { PushSCEventsToGTM } from '../../../helpers/utilities/gtm';
import { SellMyCarEvents } from '../../../types/gtm';

type propTypes = {
  IsHide: boolean;
  IsNavigateExternal: boolean;
  IsNewTab: boolean;
  Menu: string;
  Priority: number;
  URL: string;
  cleverName?: string;
  tabIndex?: number;
};

const Menu: FC<propTypes> = ({
  IsHide,
  Menu,
  URL,
  cleverName = '',
  tabIndex = 0,
}): ReactElement => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  return IsHide ? (
    <></>
  ) : (
    <div
      onClick={async () => {
        const clarityId = await IdentifierService.getClarityUserId();
        if (URL && URL.includes(SellMyCarPathname)) {
          PushSCEventsToGTM(SellMyCarEvents.CTA_CLICKED, {
            clarity_id: clarityId,
            button_location: 'Desktop header menu',
          });
        }
        router.push(URL);
        dispatch(
          setCanonicalUrl(
            `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}${
              router.locale === 'ar' ? '' : '/en'
            }${URL}`
          )
        );
      }}
      tabIndex={tabIndex}
      className="cursor-pointer inline-flex text-15 items-center rounded-md text-gray hover:text-gray-900"
    >
      {Menu}
    </div>
  );
};

export default Menu;
