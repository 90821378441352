export const OdometerIcon = ({ className = 'w-[0.875rem]', fill = 'none' }) => {
  return (
    <svg
      width="14"
      height="9"
      viewBox="0 0 14 9"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M13.3326 6.70525C13.2975 6.80612 13.2287 6.8918 13.1378 6.9478C13.0468 7.00381 12.9394 7.02671 12.8335 7.01264C12.1138 7.00622 11.393 7.01264 10.6722 7.01264C10.3262 7.01264 10.1902 6.8809 10.1784 6.53924C10.1705 6.20523 10.1117 5.87439 10.0038 5.55817C9.99152 5.52659 9.98618 5.49272 9.98821 5.45888C9.99025 5.42505 9.9996 5.39205 10.0156 5.36218C10.1645 5.05479 10.3005 4.74098 10.4505 4.43359C10.5312 4.28454 10.5779 4.11942 10.5872 3.95014C10.5964 3.78086 10.568 3.61164 10.504 3.45466C10.4899 3.42859 10.4855 3.39835 10.4915 3.36932C10.4976 3.3403 10.5138 3.31437 10.5372 3.29615C10.8778 2.96127 11.2152 2.62389 11.5493 2.28402C11.6061 2.22618 11.6318 2.22726 11.6853 2.28938C12.458 3.17535 12.9847 4.24856 13.2127 5.40181C13.2534 5.60959 13.2737 5.82165 13.3048 6.03157C13.3048 6.05835 13.2887 6.09476 13.3315 6.10869V6.21579C13.308 6.24042 13.3219 6.27041 13.3176 6.29826C13.3133 6.32611 13.3058 6.36788 13.3315 6.39787L13.3326 6.70525Z" />
      <path d="M13.332 6.39239C13.2924 6.36347 13.3074 6.32276 13.3106 6.28527C13.3192 6.27564 13.3256 6.27778 13.332 6.28527V6.39239Z" />
      <path d="M13.3324 6.2896H13.3109C13.3109 6.26283 13.2885 6.22856 13.3324 6.21356V6.2896Z" />
      <path d="M5.10352 6.95271C5.10986 6.69656 5.18058 6.44613 5.30917 6.22452C5.43776 6.0029 5.62008 5.81722 5.83931 5.6846C6.95105 4.97058 8.06457 4.26013 9.17987 3.55324C9.25215 3.49524 9.34259 3.46463 9.43523 3.46683C9.52787 3.46903 9.61675 3.5039 9.68619 3.56526C9.75563 3.62663 9.80115 3.71055 9.81472 3.80222C9.8283 3.89389 9.80904 3.98741 9.76037 4.06627C9.2045 5.26261 8.65399 6.4611 8.08741 7.65209C7.96696 7.93661 7.76155 8.17698 7.49929 8.34032C7.23703 8.50366 6.93072 8.582 6.62224 8.56462C6.30523 8.5608 5.99725 8.45843 5.74104 8.27169C5.48484 8.08496 5.29309 7.82312 5.1924 7.5225C5.12937 7.33933 5.09963 7.14635 5.10458 6.95271" />
      <path d="M1.57569 7.01021H0.469314C0.141577 7.01021 -0.00622562 6.85813 0.000200587 6.52932C0.0193493 5.26157 0.409576 4.02722 1.12265 2.97884C1.28437 2.73678 1.46431 2.51079 1.64745 2.2848C1.69244 2.22911 1.71814 2.21841 1.77383 2.27517C2.47144 2.97634 3.17082 3.67501 3.87199 4.37118C3.92554 4.42473 3.91805 4.4558 3.87735 4.50935C3.4125 5.1099 3.15674 5.84596 3.14904 6.60537C3.15087 6.66225 3.14035 6.71884 3.11822 6.77127C3.09608 6.8237 3.06286 6.87072 3.02083 6.90909C2.9788 6.94745 2.92896 6.97626 2.87473 6.99354C2.82051 7.01081 2.76319 7.01613 2.70671 7.00914C2.3297 7.00914 1.95698 7.00914 1.57462 7.00914" />
      <path d="M2.26424 1.66507C2.55173 1.4064 2.86219 1.17446 3.19176 0.972112C3.77836 0.60998 4.418 0.341836 5.08749 0.177404C5.45416 0.0891511 5.82739 0.030767 6.2035 0.0028254C6.29776 -0.00467184 6.27527 0.0478089 6.27527 0.0949344C6.27527 0.666153 6.27527 1.23737 6.27527 1.80859C6.27527 2.21666 6.27527 2.62472 6.27527 3.03278C6.27527 3.11632 6.24849 3.13989 6.16816 3.15488C5.58095 3.23143 5.02445 3.46201 4.55518 3.82321C4.5027 3.86284 4.47057 3.87462 4.41595 3.82321C3.69943 3.10347 2.98076 2.38588 2.25781 1.664" />
      <path d="M7.06278 0C8.54779 0.0900501 9.95956 0.676176 11.0717 1.66439L10.5104 2.2224C10.3348 2.39698 10.1592 2.57048 9.98671 2.74828C9.97121 2.77134 9.94735 2.78747 9.92017 2.79327C9.89299 2.79906 9.86462 2.79407 9.84105 2.77934C9.6682 2.70444 9.47906 2.67496 9.29161 2.69371C9.10416 2.71245 8.92462 2.7788 8.77002 2.88644C8.54082 3.03317 8.3084 3.17776 8.08027 3.32771C8.05396 3.34919 8.02215 3.36288 7.98846 3.36723C7.95477 3.37159 7.92053 3.36643 7.88962 3.35234C7.65252 3.25631 7.40361 3.19255 7.14954 3.16277C7.08099 3.15527 7.04778 3.13492 7.04885 3.05566C7.04885 2.06174 7.04885 1.06889 7.04885 0.0749725C7.04885 0.0535518 7.04887 0.0332021 7.05529 0.00856828" />
    </svg>
  );
};
