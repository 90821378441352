import React from 'react';

export const FiveNumberAr = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 73 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.0539 85.8945C28.2436 85.8945 22.2314 84.458 17.0173 81.5849C11.8032 78.7118 7.75962 74.4554 4.88654 68.8157C2.01346 63.1759 0.576924 56.2061 0.576924 47.9061C0.576924 41.9471 1.48141 36.1477 3.29039 30.508C5.20577 24.8682 7.81282 19.8138 11.1115 15.3445C14.5167 10.7689 18.4538 7.15094 22.9231 4.49068C27.4987 1.83042 32.4468 0.500295 37.7673 0.500295C43.7263 0.500295 48.8872 1.93683 53.25 4.80991C57.6128 7.68299 61.1776 11.4073 63.9442 15.983C66.8173 20.5586 68.8923 25.5599 70.1692 30.9868C71.5526 36.3074 72.2442 41.4682 72.2442 46.4695C72.2442 54.3439 71.0205 61.2606 68.5731 67.2195C66.1256 73.0721 62.1885 77.6477 56.7615 80.9465C51.3346 84.2452 44.0987 85.8945 35.0539 85.8945ZM36.3308 63.8676C39.2038 63.8676 41.4917 63.442 43.1942 62.5907C45.0032 61.7394 46.3865 60.5689 47.3442 59.0791C48.3019 57.5894 48.9404 55.8868 49.2596 53.9715C49.6853 52.0561 49.8981 50.0343 49.8981 47.9061C49.8981 45.1394 49.6321 42.2663 49.1 39.2868C48.568 36.3073 47.7699 33.5407 46.7058 30.9868C45.7481 28.433 44.4179 26.4112 42.7154 24.9214C41.1192 23.3253 39.2038 22.5272 36.9692 22.5272C34.5218 22.5272 32.3404 23.3253 30.425 24.9214C28.616 26.4112 27.0731 28.433 25.7962 30.9868C24.6256 33.4343 23.7212 36.2009 23.0827 39.2868C22.5506 42.2663 22.2846 45.1926 22.2846 48.0657C22.2846 52.2157 22.9231 55.4612 24.2 57.8022C25.4769 60.0368 27.1795 61.633 29.3077 62.5907C31.4359 63.442 33.7769 63.8676 36.3308 63.8676Z"
        fill="url(#paint0_linear_15731_216093)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15731_216093"
          x1="-9.00024"
          y1="29.6067"
          x2="81.9997"
          y2="29.6067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F47F20" />
          <stop offset="1" stopColor="#EC2024" />
        </linearGradient>
      </defs>
    </svg>
  );
};
