import React from 'react';

const ShowroomIconV2 = ({ fill = '#212121', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.881 9.8001V15.4001H3.2346V9.8001H16.881ZM14.9266 10.6001H5.69515V14.515H14.9266V10.6001ZM10.0654 11.6788C10.1404 11.7497 10.1478 11.8631 10.0865 11.9425L10.0698 11.9616L8.7032 13.3356C8.62435 13.4149 8.49454 13.4168 8.41326 13.3399C8.33823 13.2689 8.33077 13.1556 8.39209 13.0762L8.40885 13.0571L9.77541 11.683C9.85425 11.6038 9.98407 11.6018 10.0654 11.6788ZM11.4128 11.6788C11.4878 11.7497 11.4953 11.8631 11.434 11.9425L11.4172 11.9616L10.0506 13.3356C9.9718 13.4149 9.84198 13.4168 9.7607 13.3399C9.68567 13.2689 9.67822 13.1556 9.73953 13.0762L9.75629 13.0571L11.1228 11.683C11.2017 11.6038 11.3315 11.6018 11.4128 11.6788ZM1.79492 5.6001L18.0502 6.97031V9.46911H1.79492V5.6001Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShowroomIconV2;
