export const RearWindowIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 69.669 50.998"
      className={className}
      fill="currentColor"
    >
      <g
        id="Group_167990"
        data-name="Group 167990"
        transform="translate(11007.834 -12150.572)"
      >
        <g id="Layer_x0020_1" transform="translate(-18736.834 6785.573)">
          <path
            id="Path_15368"
            data-name="Path 15368"
            d="M7760.767,5411.119v-.837c0-3.629,6.131-3.7,6.131,0a2.7,2.7,0,0,1-1,2.617C7763.722,5415.064,7760.767,5412.721,7760.767,5411.119Zm-20.479-36.507c0,.521,12.725,21.253,13.795,22.854a19.019,19.019,0,0,0-3.166,1.012l-2.966,1.217a42.956,42.956,0,0,1-3.165-3.94l-9.5-12.383c-.716-.953-1.406-1.833-2.112-2.766-1.349-1.807-1.812-1.785-1.812-3.624,0-1.316,1.723-1.975,2.7-2.465,1.19-.595,2.239-1.132,3.5-1.659a63.991,63.991,0,0,1,27.11-5.493,64.643,64.643,0,0,1,28.379,6.874c1.021.51,2.607,1.11,3.076,2.085.858,1.786-.779,3.135-2.055,4.836l-7.685,10.013c-.811,1.09-6.136,8.169-6.668,8.522-1.5-.354-4.562-2.466-11.324-3.309a34.48,34.48,0,0,0-12.083.521,5.993,5.993,0,0,0-.838-1.532c-1.774-2.687-5.346-8.707-7.184-11.767l-5.868-9.6A1.162,1.162,0,0,0,7740.288,5374.612Zm-11.288,2.37c0,2.187.506,2.66,1.67,4.183l7.026,9.133c.422.564.742.938,1.158,1.491l7.617,9.938c1.037,1.37,1.906.237,4.819-.921a22.343,22.343,0,0,1,4.045-1.254l4.456,7.39c-.927,1.38-1.391,1.78-1.391,3.9a5.392,5.392,0,0,0,5.71,5.157c5.288,0,7.331-7.891,1.975-10.329-2.349-1.069-3.3-.222-4.482-.121a8.554,8.554,0,0,0-.895-1.612c-.369-.61-.654-1.037-1.012-1.638l-2-3.3c6.31-1.47,14.942-.09,20.531,2.6,2,.958,2.133,1.227,3.376-.433,4.557-6.072,9.586-12.293,14.116-18.351.416-.553.748-1.026,1.19-1.6,1.021-1.3,1.754-1.907,1.754-3.956,0-3.266-3.292-4.493-5.941-5.762a69.6,69.6,0,0,0-20.542-5.932c-.837-.1-1.822-.273-2.639-.295-1.875-.047-3.776-.273-5.715-.273-2.007,0-6.221.332-8.217.553a65.21,65.21,0,0,0-12.041,2.6,89.183,89.183,0,0,0-8.443,3.261c-1.506.669-4.414,2-5.377,3.4A5.069,5.069,0,0,0,7729,5376.982Z"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};
