import React from 'react';

export const BootSpaceIconV2 = ({ color = '#212121', className = '' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.27261 20.9512V20.9513L9.27617 20.951C9.54925 20.9315 9.78986 21.1666 9.78986 21.4684C9.78986 21.7668 9.5517 21.9857 9.27261 21.9857C8.97421 21.9857 8.75536 21.7475 8.75536 21.4684C8.75536 21.17 8.99352 20.9512 9.27261 20.9512ZM16.2105 6.73532H14.4279V2.94546C14.4279 2.6124 14.1655 2.35002 13.8324 2.35002H10.2108C9.8777 2.35002 9.61532 2.6124 9.61532 2.94546V6.73532H7.83266C6.80145 6.73532 5.95 7.58677 5.95 8.61798V19.0467C5.95 20.0106 6.67796 20.8035 7.6112 20.9017C7.55627 21.0654 7.52085 21.2471 7.52085 21.4466C7.52085 22.3906 8.28503 23.1547 9.22897 23.1547C10.1729 23.1547 10.9371 22.3906 10.9371 21.4466C10.9371 21.2683 10.9024 21.0909 10.8487 20.9294H13.1207C13.1164 20.9589 13.1117 20.9893 13.1069 21.0207C13.0859 21.156 13.0625 21.3076 13.0625 21.4684C13.0625 22.4124 13.8266 23.1765 14.7706 23.1765C15.7145 23.1765 16.4787 22.4124 16.4787 21.4684C16.4787 21.2853 16.4421 21.1034 16.3864 20.9237C17.3206 20.8264 18.0496 20.0331 18.0496 19.0685V8.61849C18.0717 7.58727 17.2421 6.73532 16.2105 6.73532ZM10.8062 3.54089H13.237V6.73532H10.8062V3.54089ZM14.7924 21.9857C14.494 21.9857 14.2752 21.7475 14.2752 21.4684C14.2752 21.17 14.5133 20.9512 14.7924 20.9512C15.0908 20.9512 15.3097 21.1893 15.3097 21.4684C15.3097 21.7463 15.0702 21.9857 14.7924 21.9857ZM16.8805 19.0467C16.8805 19.4336 16.5756 19.7385 16.1887 19.7385H7.83266C7.44574 19.7385 7.14087 19.4336 7.14087 19.0467V8.61798C7.14087 8.23107 7.44574 7.92619 7.83266 7.92619H16.1887C16.5756 7.92619 16.8805 8.23107 16.8805 8.61798V19.0467Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
      <path
        d="M9.25149 9.33208C8.91843 9.33208 8.65605 9.59446 8.65605 9.92751V17.76C8.65605 18.093 8.91843 18.3554 9.25149 18.3554C9.58455 18.3554 9.84692 18.093 9.84692 17.76V9.92751C9.84692 9.59446 9.58455 9.33208 9.25149 9.33208Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
      <path
        d="M12.022 9.33208C11.6889 9.33208 11.4266 9.59446 11.4266 9.92751V17.76C11.4266 18.093 11.6889 18.3554 12.022 18.3554C12.3551 18.3554 12.6174 18.093 12.6174 17.76V9.92751C12.6174 9.59446 12.3551 9.33208 12.022 9.33208Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
      <path
        d="M14.7915 9.33208C14.4585 9.33208 14.1961 9.59446 14.1961 9.92751V17.76C14.1961 18.093 14.4585 18.3554 14.7915 18.3554C15.1246 18.3554 15.387 18.093 15.387 17.76V9.92751C15.387 9.59446 15.1246 9.33208 14.7915 9.33208Z"
        fill={color}
        stroke={color}
        strokeWidth="0.1"
      />
    </svg>
  );
};
