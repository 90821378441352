import React from 'react';

export const TwoNumber = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 109 151"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9625 150.125L0.1625 134.925C4.89583 132.125 10.1625 128.558 15.9625 124.225C21.8292 119.892 27.6958 114.958 33.5625 109.425C39.4292 103.892 44.7958 97.9583 49.6625 91.625C54.5958 85.2917 58.5292 78.6917 61.4625 71.825C64.4625 64.9583 65.9625 58.0583 65.9625 51.125C65.9625 43.725 64.0958 38.1583 60.3625 34.425C56.6292 30.625 51.4958 28.725 44.9625 28.725C38.6958 28.725 32.5292 29.725 26.4625 31.725C20.4625 33.725 15.7625 36.3917 12.3625 39.725L0.9625 20.125C3.62917 17.3917 7.2625 14.4917 11.8625 11.425C16.5292 8.29166 22.2625 5.62499 29.0625 3.42499C35.9292 1.22499 44.0292 0.124988 53.3625 0.124988C65.6292 0.124988 75.3625 2.35832 82.5625 6.82499C89.8292 11.225 95.0292 17.025 98.1625 24.225C101.363 31.425 102.963 39.125 102.963 47.325C102.963 57.3917 100.463 66.9917 95.4625 76.125C90.5292 85.2583 83.7625 93.925 75.1625 102.125C66.5625 110.325 56.8292 118.058 45.9625 125.325L108.163 121.325V150.125H4.9625Z"
        fill="url(#paint0_linear_12832_201886)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12832_201886"
          x1="-6.5"
          y1="87.67"
          x2="115.502"
          y2="87.9372"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
