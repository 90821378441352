import { createSlice } from '@reduxjs/toolkit';
import { FilterParams } from '../../types/models';
import {
  Locales,
  SortByFilter,
  SortDirection,
  VehicleAgeType,
} from '../../types/enums';
import { PageSizes } from '../../types/constants';

type intialStateType = {
  updatedFilter: FilterParams;
};

export const UsedCarsFilterInitialState: intialStateType = {
  updatedFilter: {
    locale: Locales.AR,
    makes: [],
    models: [],
    specs: [],
    page: 1,
    size: PageSizes[0],
    sortBy: SortByFilter.ListedDate,
    sortDir: SortDirection.Desc,
    type: VehicleAgeType.Old,
    multipleSortBy: [],
  },
};

export const usedCarsFilterSlice = createSlice({
  name: 'usedCarsFilter',
  initialState: UsedCarsFilterInitialState,
  reducers: {
    updateUsedCarFilter: (state, action) => {
      return { ...state, updatedFilter: action.payload };
    },
    resetUsedCarFilter: (state, action) => {
      return UsedCarsFilterInitialState;
    },
  },
});

export const { updateUsedCarFilter, resetUsedCarFilter } =
  usedCarsFilterSlice.actions;

export default usedCarsFilterSlice.reducer;
