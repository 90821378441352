export const ShowEyeIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.8729 9.6106C19.6942 9.36618 15.4371 3.62598 9.9999 3.62598C4.56274 3.62598 0.305391 9.36618 0.126914 9.61036C-0.0423048 9.84224 -0.0423048 10.1567 0.126914 10.3886C0.305391 10.633 4.56274 16.3732 9.9999 16.3732C15.4371 16.3732 19.6942 10.633 19.8729 10.3888C20.0423 10.157 20.0423 9.84224 19.8729 9.6106ZM9.9999 15.0546C5.99486 15.0546 2.52606 11.2447 1.49922 9.99915C2.52473 8.75255 5.98626 4.94465 9.9999 4.94465C14.0048 4.94465 17.4733 8.75388 18.5006 10.0001C17.4751 11.2466 14.0135 15.0546 9.9999 15.0546Z"
        fill="#43474D"
      />
      <path
        d="M9.99989 6.04395C7.81855 6.04395 6.04382 7.81868 6.04382 10C6.04382 12.1813 7.81855 13.9561 9.99989 13.9561C12.1812 13.9561 13.9559 12.1813 13.9559 10C13.9559 7.81868 12.1812 6.04395 9.99989 6.04395ZM9.99989 12.6374C8.54559 12.6374 7.36254 11.4543 7.36254 10C7.36254 8.54575 8.54562 7.36266 9.99989 7.36266C11.4541 7.36266 12.6372 8.54575 12.6372 10C12.6372 11.4543 11.4542 12.6374 9.99989 12.6374Z"
        fill="#43474D"
      />
    </svg>
  );
};
