export const BestPriceIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      className={className}
    >
      <path
        fill="#4c0055"
        d="M62,24.8c-.4,1.2-.6,2.5-1.1,3.7-2.9,7.4-8.2,11.9-15.9,13.6-.5,.1-.9,.1-1.3-.3-.5-.6-.2-1.4,.7-1.6,2.3-.5,4.5-1.3,6.5-2.6,5.3-3.4,8.4-8.3,9-14.6,1-10.1-5.9-19.1-16-20.9C33.4,.3,23.1,7.8,21.6,18.4c-.1,.9-.2,1.8-.2,2.6,0,.8-.4,1.2-1,1.2-.6,0-1-.4-1-1.2,0-10.1,7.6-19,17.5-20.7,.6,0,1.2,0,1.7-.3h.6c0,0,.2,0,.2,0h1.6c.2,0,.5,0,.7,0h.4c0,0,.2,0,.2,0,0,0,.2,0,.2,0h.2c1.5,.1,3.1,.3,4.5,.8,3.3,1.1,6.2,2.9,8.7,5.4,2.8,2.8,4.6,6.1,5.6,9.9,.2,.6,.1,1.2,.3,1.8v6.9Z"
      />
      <path fill="#f9f7f9" d="M0,61.4c.2,.2,.4,.4,.6,.6-.5,.1-.7,0-.6-.6Z" />
      <path fill="#581260" d="M41.8,0c-.2,.2-.5,.2-.7,0h.7Z" />
      <path fill="#8e6094" d="M39.5,0c0,.1-.2,.2-.2,0h.2Z" />
      <path
        fill="#906195"
        d="M42.4,.1c-.1,0-.2,0-.2-.1h.2s0,0,0,0c0,0,0,0,0,0Z"
      />
      <path fill="#a580aa" d="M42.4,.1s0,0,0-.1h.2c0,.1-.1,.1-.2,.1Z" />
      <path
        fill="#4c0055"
        d="M17.5,13.6c0,.5-.3,.9-.9,.9-.2,0-.4,0-.6,0-.4,0-.5,.1-.5,.5,0,1-.3,1.4-1,1.4-.7,0-1-.4-.9-1.5,0-.4-.1-.5-.5-.5-1,0-1.4-.3-1.4-1,0-.7,.4-1,1.5-.9,.4,0,.5-.1,.5-.5,0-1,.3-1.4,1-1.4,.7,0,1,.4,1,1.5,0,.4,.1,.5,.5,.5,.2,0,.4,0,.6,0,.5,0,.8,.4,.8,1Z"
      />
      <path
        fill="#4c0055"
        d="M53.3,49.4c0,.5-.4,.9-1.1,.9q-.8,0-.9,.8c0,.7-.4,1.1-1,1.1-.6,0-1-.4-1-1.1,0-.8,0-.8-.8-.9-.2,0-.3,0-.5,0-.4-.2-.6-.5-.6-.9,0-.4,.3-.7,.6-.9,0,0,.2,0,.3,0,.9,0,.9,0,1-1,0-.2,0-.4,.2-.5,.4-.6,1.2-.6,1.6,0,0,.1,.1,.3,.2,.4,0,.4-.1,.8,.1,1,.2,.2,.7,0,1,0,.5,0,.9,.5,.9,1Z"
      />
      <path
        fill="#4c0055"
        d="M38.8,44s0,0,.1-.1c1.5-1.2,2.1-2.8,1.6-4.4-.5-1.7-2-2.7-3.9-2.7-1.8,0-3.6,0-5.4,0s-4.2,0-6.4,0c-.2,0-.5,0-.4-.3,.2-1,.3-2,.4-3,.2-1.7,.6-3.3,.3-5-.4-2.7-2.3-4.7-5-5.2-1.1-.2-1.5,0-1.8,1.1-1.2,4.2-2.4,8.4-3.6,12.6-.5,1.6-1.6,2.6-3,3.5,0-1.6-.2-1.7-1.7-1.7-2.8,0-5.7,0-8.5,0-.6,0-1.1,.1-1.4,.6v22c0,.3,.3,.5,.6,.6H11c.4-.1,.7-.4,.7-.8,0-.5,.2-.5,.6-.4,1.5,.4,3,1,4.6,1.2h17.5s0,0,.1,0c2.7-.7,3.9-3.6,2.4-5.9-.2-.3-.1-.4,.1-.6,.9-.7,1.4-1.7,1.5-2.9,0-1-.3-1.9-.9-2.7,1.9-1.3,2.5-3.7,1-5.8ZM9.2,60c-2.2,0-4.4,0-6.7,0-.4,0-.6,0-.6-.5,0-6.1,0-12.3,0-18.4,0-.4,.1-.5,.5-.5,2.3,0,4.5,0,6.8,0,.4,0,.5,.1,.5,.5,0,3.1,0,6.1,0,9.2s0,6.1,0,9.1c0,.5-.1,.6-.6,.6Zm27.6-17.4c-1.6,0-3.1,0-4.7,0-.5,0-.8,.2-1,.6-.2,.4,0,.7,.2,1,.2,.3,.5,.3,.9,.3,1.2,0,2.3,0,3.5,0,1.2,0,2.1,.8,2.1,1.9,0,1.1-.9,1.9-2.1,1.9-1.5,0-3,0-4.5,0-.7,0-1.2,.4-1.2,.9,0,.6,.4,1,1.2,1,1.2,0,2.3,0,3.5,0,1.2,0,2.1,.8,2.1,2,0,1.1-.9,1.9-2.1,1.9-1.5,0-3,0-4.5,0-.8,0-1.2,.4-1.2,1,0,.6,.4,1,1.2,1,1.1,0,2.2,0,3.3,0,1.4,0,2.3,.7,2.3,1.9,0,1.2-.9,2-2.3,2-2.5,0-5.1,0-7.6,0s-5.2,0-7.8,0c-.3,0-.6,0-1-.1-1.7-.5-3.3-1-5-1.4-.4-.1-.5-.2-.5-.6,0-5,0-9.9,0-14.9,0-.3,0-.5,.4-.6,2.3-.9,3.8-2.6,4.5-4.9,1.2-3.8,2.2-7.7,3.3-11.6,.2-.7,.2-.7,.9-.4,1.8,.8,2.4,2.3,2.4,4.1,0,1.2-.3,2.4-.4,3.7-.2,1.4-.4,2.8-.5,4.2-.1,.9,.3,1.3,1.2,1.3,4.4,0,8.8,0,13.2,0,1.5,0,2.4,1.3,1.9,2.6-.3,.8-1,1.2-1.9,1.3Z"
      />
      <path
        fill="#4c0055"
        d="M43.8,17.7c-1.3-.4-2.6-.3-4-.3-1.2,0-2.1-.8-2.1-1.9,0-1.1,.9-2,2.1-2,1.1,0,2.2,0,3.3,0,1.1,0,2.2,0,3.3,0,1.6,0,2.9-1.3,2.9-2.9,0-1.6-1.3-2.9-2.9-2.9-.8,0-1.5,0-2.3,0-.4,0-.6,0-.6-.6,0-.7,0-1.5,0-2.2,0-.8-.4-1.1-1.1-1.1-1.2,0-2.4,0-3.6,0-.8,0-1.1,.4-1.1,1.2,0,.8,0,1.6,0,2.4,0,.5-.1,.6-.6,.8-3,1-5.1,3.8-5.2,6.9-.2,3.1,1.7,6.1,4.6,7.4,1.6,.7,3.2,.7,4.9,.7,1.3,0,2.2,.8,2.2,1.9,0,1.1-.9,1.9-2.2,1.9-2.2,0-4.4,0-6.5,0-1.7,0-2.9,1.3-2.9,2.9,0,1.6,1.3,2.9,2.9,2.9,.8,0,1.6,0,2.4,0,.4,0,.5,.1,.5,.5,0,.8,0,1.5,0,2.3,0,.7,.4,1.1,1.1,1.1,1.2,0,2.4,0,3.6,0,.7,0,1.1-.4,1.1-1.1,0-.8,0-1.6,0-2.4,0-.4,0-.6,.5-.8,3.3-1.1,5.4-4.1,5.3-7.6,0-3.3-2.4-6.2-5.6-7.2Zm-1,13.1c-1,.2-1.2,.4-1.2,1.4,0,.7,0,1.5,0,2.2,0,.3,0,.4-.4,.4-1.5,0-1.5,0-1.5-1.5,0-.4,0-.9,0-1.3,0-.7-.4-1-1.1-1-1.2,0-2.3,0-3.5,0-.8,0-1.2-.3-1.2-.9,0-.6,.4-1,1.2-1,2.2,0,4.4,0,6.6,0,1.9,0,3.5-1.4,3.8-3.4,.3-1.8-.9-3.6-2.7-4.2-.7-.2-1.4-.2-2.2-.2-1.2,0-2.4,0-3.5-.6-2.3-1.1-3.5-3.5-3.2-6.1,.3-2.3,2.2-4.3,4.5-4.8,1-.2,1.2-.4,1.2-1.4,0-.7,0-1.5,0-2.2,0-.3,0-.4,.4-.4,1.5,0,1.5,0,1.5,1.5,0,.4,0,.9,0,1.3,0,.7,.4,1,1.1,1,1.2,0,2.3,0,3.5,0,.8,0,1.2,.3,1.2,.9,0,.6-.4,1-1.2,1-1.1,0-2.2,0-3.3,0-1.1,0-2.1,0-3.2,0-2.1,0-3.8,1.7-3.8,3.9,0,2.1,1.7,3.8,3.8,3.9,1,0,2,0,3,0,2.7,.5,4.7,2.8,4.8,5.6,0,2.7-1.9,5.2-4.6,5.7Z"
      />
    </svg>
  );
};
