import React from 'react';

export const OneNumberAr = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 37 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.575 109.947L14.3885 110.586C14.3885 107.713 14.2288 103.988 13.9096 99.4127C13.6968 94.7306 13.2712 89.4633 12.6327 83.6107C12.1006 77.7582 11.409 71.5864 10.5577 65.0953C9.81282 58.4979 8.90833 51.7941 7.84423 44.9838C6.78013 38.1735 5.60962 31.5229 4.33269 25.0319C3.05577 18.5408 1.67244 12.4755 0.182693 6.83572L22.6885 0.770339C24.8167 7.89982 26.6256 15.5614 28.1154 23.755C29.7115 31.9485 31.0417 40.2485 32.1058 48.655C33.1699 57.0614 34.0212 65.1485 34.6596 72.9165C35.4045 80.6844 35.8833 87.7607 36.0962 94.1453C36.4154 100.53 36.575 105.797 36.575 109.947Z"
        fill="url(#paint0_linear_15731_215951)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15731_215951"
          x1="-7.00014"
          y1="53.6595"
          x2="46.9998"
          y2="53.6595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F47F20" />
          <stop offset="1" stopColor="#EC2024" />
        </linearGradient>
      </defs>
    </svg>
  );
};
