export const PrevIcon = ({ className = '' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12.0454 1.84049C12.2563 2.05146 12.3748 2.33756 12.3748 2.63587C12.3748 2.93418 12.2563 3.22027 12.0454 3.43124L6.47662 8.99999L12.0454 14.5687C12.2503 14.7809 12.3637 15.0651 12.3611 15.3601C12.3586 15.655 12.2402 15.9372 12.0317 16.1458C11.8231 16.3544 11.5409 16.4727 11.2459 16.4753C10.951 16.4778 10.6668 16.3644 10.4546 16.1595L4.09049 9.79537C3.87959 9.5844 3.76111 9.2983 3.76111 8.99999C3.76111 8.70168 3.87959 8.41559 4.09049 8.20462L10.4546 1.84049C10.6656 1.62959 10.9517 1.51111 11.25 1.51111C11.5483 1.51111 11.8344 1.62959 12.0454 1.84049Z" />
    </svg>
  );
};
