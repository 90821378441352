export const CarUnderPriceIcon = ({ className = '' }) => {
  return (
    <svg
      width="75"
      height="52"
      viewBox="0 0 75 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M55.1341 27.1532L55.3962 27.5061L55.8073 27.6617L71.2995 33.5256C72.3003 34.0468 73.0253 35.2204 73.0253 36.5826V41.8487C73.0253 42.5696 72.4834 43.0079 72.0268 43.0079H61.8675C60.5942 40.5755 58.0507 38.9111 55.1136 38.9111C52.1764 38.9111 49.633 40.5755 48.3596 43.0079H25.1253C23.8519 40.5755 21.3084 38.9111 18.3712 38.9111C15.434 38.9111 12.8905 40.5755 11.6171 43.0079H4.08249C2.66114 43.0079 1.5 41.8502 1.5 40.4111V35.5036C1.5 32.3383 3.70644 29.6105 6.78299 28.9556L10.5431 28.1552C12.1106 27.8216 13.5704 27.1023 14.7914 26.0627L14.9397 25.9365L15.0509 25.7765L23.3245 13.8683C24.5059 12.9177 25.9757 12.3984 27.4921 12.3984H41.0935C42.6177 12.3984 44.0947 12.923 45.279 13.8827L55.1341 27.1532Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle
        cx="18.5754"
        cy="47.0558"
        r="3.44256"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle
        cx="55.3566"
        cy="47.0558"
        r="3.44256"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M73.1499 15.3037L63.7591 24.7358L54.3682 15.3037H59.4344V2H67.919V15.3037H73.1499Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
