import React from 'react';

export const KeyIconV2 = ({ className = '', fill = '#212121' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.8571 10.5714L9.14286 10.5714C8.57454 10.5714 8.02949 10.7972 7.62763 11.1991C7.22576 11.6009 7 12.146 7 12.7143L7 19.8571C7 20.4255 7.22576 20.9705 7.62763 21.3724C8.02949 21.7742 8.57454 22 9.14286 22H14.8571C15.4255 22 15.9705 21.7742 16.3724 21.3724C16.7742 20.9705 17 20.4255 17 19.8571V12.7143C17 12.146 16.7742 11.6009 16.3724 11.1991C15.9705 10.7972 15.4255 10.5714 14.8571 10.5714ZM9.14286 20.5714C8.95342 20.5714 8.77174 20.4962 8.63778 20.3622C8.50383 20.2283 8.42857 20.0466 8.42857 19.8571L8.42857 12.7143C8.42857 12.5248 8.50383 12.3432 8.63778 12.2092C8.77174 12.0753 8.95342 12 9.14286 12L14.8571 12C15.0466 12 15.2283 12.0753 15.3622 12.2092C15.4962 12.3432 15.5714 12.5248 15.5714 12.7143L15.5714 19.8571C15.5714 20.0466 15.4962 20.2283 15.3622 20.3622C15.2283 20.4962 15.0466 20.5714 14.8571 20.5714H9.14286Z"
        fill={fill}
      />
      <path
        d="M13.4288 12.7143H10.5717C10.3823 12.7143 10.2006 12.7896 10.0666 12.9235C9.93268 13.0575 9.85742 13.2392 9.85742 13.4286L9.85742 19.1429C9.85742 19.3323 9.93268 19.514 10.0666 19.648C10.2006 19.7819 10.3823 19.8572 10.5717 19.8572H13.4288C13.6183 19.8572 13.8 19.7819 13.9339 19.648C14.0679 19.514 14.1431 19.3323 14.1431 19.1429V13.4286C14.1431 13.2392 14.0679 13.0575 13.9339 12.9235C13.8 12.7896 13.6183 12.7143 13.4288 12.7143ZM11.286 14.1429H12.7146V18.4286H11.286V14.1429Z"
        fill={fill}
      />
      <path
        d="M13.4286 15.5714H10.5715C10.382 15.5714 10.2004 15.6467 10.0664 15.7806C9.93246 15.9146 9.8572 16.0963 9.8572 16.2857C9.8572 16.4752 9.93246 16.6568 10.0664 16.7908C10.2004 16.9247 10.382 17 10.5715 17H13.4286C13.6181 17 13.7998 16.9247 13.9337 16.7908C14.0677 16.6568 14.1429 16.4752 14.1429 16.2857C14.1429 16.0963 14.0677 15.9146 13.9337 15.7806C13.7998 15.6467 13.6181 15.5714 13.4286 15.5714ZM13.4286 2H10.5715C10.3915 1.99943 10.218 2.06679 10.0856 2.18862C9.95316 2.31045 9.87159 2.47778 9.8572 2.65715L9.14291 11.2286C9.13501 11.3271 9.14763 11.4261 9.17996 11.5195C9.2123 11.6129 9.26366 11.6985 9.3308 11.771C9.39793 11.8435 9.47937 11.9013 9.56998 11.9407C9.66059 11.9801 9.75839 12.0003 9.8572 12H14.1429C14.2417 12.0003 14.3395 11.9801 14.4301 11.9407C14.5207 11.9013 14.6022 11.8435 14.6693 11.771C14.7365 11.6985 14.7878 11.6129 14.8201 11.5195C14.8525 11.4261 14.8651 11.3271 14.8572 11.2286L14.6215 8.37143C14.6071 8.19206 14.5255 8.02474 14.3931 7.90291C14.2607 7.78107 14.0871 7.71371 13.9072 7.71429H12.7143V6.28572H13.6643C13.7632 6.28603 13.861 6.26585 13.9516 6.22643C14.0422 6.18702 14.1236 6.12923 14.1907 6.05673C14.2579 5.98422 14.3092 5.89858 14.3416 5.80521C14.3739 5.71184 14.3865 5.61278 14.3786 5.51429L14.1429 2.65715C14.1285 2.47778 14.047 2.31045 13.9145 2.18862C13.7821 2.06679 13.6086 1.99943 13.4286 2ZM11.2286 3.42857H12.7715L12.8929 4.85715H12.0001C11.8106 4.85715 11.6289 4.9324 11.495 5.06635C11.361 5.20031 11.2858 5.38199 11.2858 5.57143V8.42857C11.2858 8.61801 11.361 8.7997 11.495 8.93365C11.6289 9.0676 11.8106 9.14286 12.0001 9.14286H13.2501L13.3715 10.5714H10.6358L11.2286 3.42857Z"
        fill={fill}
      />
    </svg>
  );
};
