export const ConciergeBodyIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 29.196 15"
      className={className}
    >
      <g id="Group_163918" transform="translate(-1027.5 -470)">
        <g id="Group_163915" transform="translate(1050.945 480.415)">
          <path
            id="Path_1731"
            d="M72.426,58.737a1.881,1.881,0,0,1-1.126-.362l.442-.643a1.356,1.356,0,0,0,.684.2,1.185,1.185,0,0,0,1.206-1.206,1.356,1.356,0,0,0-.2-.684l.643-.442a1.881,1.881,0,0,1,.362,1.126A1.991,1.991,0,0,1,72.426,58.737Z"
            transform="translate(-71.3 -55.6)"
            fill="#7d8593"
          ></path>
        </g>
        <g id="Group_163917" transform="translate(1032.808 479.571)">
          <g id="Group_163916" transform="translate(0 0)">
            <path
              id="Path_1732"
              d="M26.562,56.637a1.881,1.881,0,0,1-.362-1.126A1.991,1.991,0,0,1,28.211,53.5a1.881,1.881,0,0,1,1.126.362l-.442.643a1.356,1.356,0,0,0-.684-.2A1.21,1.21,0,0,0,27,55.511a1.356,1.356,0,0,0,.2.684Z"
              transform="translate(-26.2 -53.5)"
              fill="#7d8593"
            ></path>
          </g>
        </g>
        <rect
          id="Rectangle_838"
          width="1.609"
          height="0.804"
          transform="translate(1042.38 477.761)"
          fill="#7d8593"
        ></rect>
        <path
          id="Path_1733"
          d="M47.411,32.9H41.7v5.188h9.169Zm-4.946.8h4.5l2.373,3.619H42.464Z"
          transform="translate(997.342 438.387)"
          fill="#7d8593"
        ></path>
        <path
          id="Path_1734"
          d="M16.861,41.684a3.445,3.445,0,0,0,6.837,0H34.234a3.4,3.4,0,0,0,6.756,0H42.2V37.059a1.7,1.7,0,0,0-1.689-1.689H34.756l-3.82-5.67h-7.8v5.67h-9.29V40H13v.8h.845v.845h3.016ZM20.279,43.9a2.654,2.654,0,1,1,2.654-2.654A2.652,2.652,0,0,1,20.279,43.9Zm17.333,0a2.614,2.614,0,1,1,2.614-2.614A2.609,2.609,0,0,1,37.612,43.9ZM14.649,36.175h9.29v-5.63h6.6l3.82,5.67h6.193a.893.893,0,0,1,.885.885v3.82h-.4a3.4,3.4,0,0,0-6.756,0H23.7a3.445,3.445,0,0,0-6.837,0H14.649S14.649,36.175,14.649,36.175Z"
          transform="translate(1014.5 440.3)"
          fill="#7d8593"
        ></path>
      </g>
    </svg>
  );
};
