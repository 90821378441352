export const CasaMadaIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 100 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      //  xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <rect width="100" height="60" rx="8" fill="white" />
      <path d="M95 15H5V45.0754H95V15Z" fill="url(#pattern1)" />
      <defs>
        <pattern
          id="pattern1"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_2914_4"
            transform="scale(0.000837521 0.00250627)"
          />
        </pattern>
      </defs>
    </svg>
  );
};
