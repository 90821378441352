export const DownloadInvoiceIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M5.03125 10.2188C4.87516 10.0613 4.78802 9.84825 4.78899 9.62653C4.78997 9.4048 4.87899 9.19255 5.03646 9.03646C5.19393 8.88037 5.40696 8.79323 5.62868 8.7942C5.85041 8.79518 6.06266 8.88419 6.21875 9.04167L9.16667 11.9896V2.33333C9.16667 2.11232 9.25446 1.90036 9.41074 1.74408C9.56703 1.5878 9.77899 1.5 10 1.5C10.221 1.5 10.433 1.5878 10.5893 1.74408C10.7455 1.90036 10.8333 2.11232 10.8333 2.33333V11.9896L13.7813 9.04167C13.8585 8.96369 13.9504 8.90171 14.0517 8.85925C14.1529 8.81679 14.2615 8.79469 14.3713 8.7942C14.4811 8.79372 14.5899 8.81486 14.6915 8.85643C14.7931 8.898 14.8856 8.95917 14.9635 9.03646C15.0415 9.11375 15.1035 9.20564 15.146 9.30688C15.1884 9.40812 15.2105 9.51674 15.211 9.62653C15.2115 9.73631 15.1903 9.84512 15.1488 9.94673C15.1072 10.0483 15.046 10.1408 14.9688 10.2188L10.5938 14.5938C10.4345 14.7478 10.2216 14.8339 10 14.8339C9.77843 14.8339 9.56553 14.7478 9.40625 14.5938L5.03125 10.2188ZM19.1667 11.0333C18.9457 11.0333 18.7337 11.1211 18.5774 11.2774C18.4211 11.4337 18.3333 11.6457 18.3333 11.8667V17.7H1.66667V11.8667C1.66667 11.6457 1.57887 11.4337 1.42259 11.2774C1.26631 11.1211 1.05435 11.0333 0.833333 11.0333C0.61232 11.0333 0.400358 11.1211 0.244078 11.2774C0.0877975 11.4337 0 11.6457 0 11.8667V17.7C0 18.142 0.175595 18.566 0.488155 18.8785C0.800716 19.1911 1.22464 19.3667 1.66667 19.3667H18.3333C18.7754 19.3667 19.1993 19.1911 19.5118 18.8785C19.8244 18.566 20 18.142 20 17.7V11.8667C20 11.6457 19.9122 11.4337 19.7559 11.2774C19.5996 11.1211 19.3877 11.0333 19.1667 11.0333Z"
        fill="#4C0055"
      />
    </svg>
  );
};
