export const HideEyeIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4 16L16 4" stroke="#43474D" strokeWidth="2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5279 6.35093C11.0575 6.15324 10.5412 6.04395 9.99989 6.04395C7.81855 6.04395 6.04382 7.81868 6.04382 10C6.04382 10.5413 6.15312 11.0576 6.35081 11.528L7.40514 10.4736C7.37715 10.32 7.36254 10.1617 7.36254 10C7.36254 8.54575 8.54562 7.36266 9.99989 7.36266C10.1615 7.36266 10.3198 7.37728 10.4735 7.40526L11.5279 6.35093ZM9.5266 12.5948C9.68018 12.6228 9.83836 12.6374 9.99989 12.6374C11.4542 12.6374 12.6372 11.4543 12.6372 10C12.6372 9.83849 12.6226 9.68031 12.5947 9.52673L13.6491 8.47234C13.8467 8.9426 13.9559 9.45879 13.9559 10C13.9559 12.1813 12.1812 13.9561 9.99989 13.9561C9.45867 13.9561 8.94248 13.8468 8.47222 13.6492L9.5266 12.5948Z"
        fill="#43474D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4937 4.38508C12.4129 3.92312 11.237 3.62598 9.9999 3.62598C4.56274 3.62598 0.305391 9.36618 0.126914 9.61036C-0.0423048 9.84224 -0.0423048 10.1567 0.126914 10.3886C0.227988 10.527 1.63719 12.428 3.82799 14.0508L4.77836 13.1004C3.20954 11.9678 2.02917 10.642 1.49922 9.99915C2.52473 8.75255 5.98626 4.94465 9.9999 4.94465C10.8555 4.94465 11.6866 5.11851 12.4747 5.4041L13.4937 4.38508ZM7.52602 14.5954C8.31382 14.8808 9.14463 15.0546 9.9999 15.0546C14.0135 15.0546 17.4751 11.2466 18.5006 10.0001C17.9705 9.35704 16.7905 8.03163 15.2222 6.89924L16.1725 5.94894C18.3628 7.57164 19.7717 9.4722 19.8729 9.6106C20.0423 9.84224 20.0423 10.157 19.8729 10.3888C19.6942 10.633 15.4371 16.3732 9.9999 16.3732C8.76317 16.3732 7.58747 16.0762 6.50693 15.6145L7.52602 14.5954Z"
        fill="#43474D"
      />
    </svg>
  );
};
