import React from 'react';

export const ShareIconV2 = ({ stroke = '#212121', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.44434 9.25918V15.1851C4.44434 15.578 4.59066 15.9548 4.85113 16.2327C5.1116 16.5105 5.46487 16.6666 5.83322 16.6666H14.1666C14.5349 16.6666 14.8882 16.5105 15.1487 16.2327C15.4091 15.9548 15.5554 15.578 15.5554 15.1851V9.25918M12.7777 6.29621L9.99989 3.33325M9.99989 3.33325L7.22211 6.29621M9.99989 3.33325V12.9629"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
