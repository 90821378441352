export const MojazIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect y="0.328125" width="28" height="28" rx="4" fill="#BE1E2D" />
      <rect x="2" y="5" width="23" height="19" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_14037_215299"
            transform="matrix(0.00269151 0 0 0.00332481 -0.0952381 -0.294118)"
          />
        </pattern>
      </defs>
    </svg>
  );
};
