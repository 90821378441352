const GogoLoader = ({ className = '' }) => {
  return (
    <div className={`globalloader ${className}`}>
      <picture>
        <img src="/images/loader.gif" height={150} width={150} alt={'loader'} />
      </picture>
    </div>
  );
};

export default GogoLoader;
