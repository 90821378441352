import React from 'react';
export const FilledTransmissionIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14327_7923)">
        <path
          d="M17.3736 16.7555C12.9768 16.7555 8.57999 16.7555 4.18317 16.7555C3.12646 16.7555 2.06975 16.7444 1.01177 16.7592C0.315336 16.7685 -0.0271743 17.2729 -0.0550825 18.289C-0.0639624 18.5957 -0.0563511 18.9024 -0.0563511 19.2849H18.9238C19.0113 17.0973 18.7944 16.7555 17.3736 16.7555Z"
          fill="#929597"
        />
        <path
          d="M13.539 6.90934C15.2591 6.9009 16.657 5.54531 16.6507 3.89209C16.6456 2.22682 15.268 0.920626 13.5149 0.918216C11.7631 0.915806 10.3842 2.21838 10.3753 3.88486C10.3664 5.56579 11.7808 6.91777 13.5377 6.90813L13.539 6.90934Z"
          fill="#929597"
        />
        <path
          d="M16.3839 18.0182C16.4933 15.8094 16.2662 15.4837 14.672 15.4873C13.6005 15.4873 12.5304 15.4873 11.459 15.4873C8.88496 15.4873 6.3096 15.4818 3.73562 15.4873C2.81331 15.4892 2.47667 15.9622 2.47394 17.2212C2.47394 17.4642 2.47394 17.7071 2.47394 18.0182H16.3839Z"
          fill="#929597"
        />
        <path
          d="M10.6531 12.7101C10.7635 12.7077 10.9271 12.5908 10.9715 12.4896C11.6717 10.8701 12.3567 9.24578 13.0151 7.69137C12.2667 7.36361 11.5703 7.05996 10.8269 6.73341C9.96556 8.67824 9.09028 10.6556 8.17694 12.7185C9.06872 12.7185 9.86155 12.7269 10.6531 12.7089V12.7101Z"
          fill="#929597"
        />
        <path
          d="M12.6031 15.4831C12.6031 15.035 12.614 14.6453 12.6009 14.2574C12.5748 13.4779 12.3243 12.9825 11.8583 12.9734C10.2491 12.9443 8.63875 12.9443 7.02951 12.9734C6.56568 12.9807 6.30764 13.4651 6.28042 14.2483C6.26735 14.638 6.27824 15.0278 6.27824 15.4849H12.6031V15.4831Z"
          fill="#929597"
        />
      </g>
      <defs>
        <clipPath id="clip0_14327_7923">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0 0.918213)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
