export const TelephoneIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      className={className}
    >
      <path
        d="M16.5 13.4469V15.6969C16.5009 15.9058 16.4581 16.1125 16.3744 16.3039C16.2907 16.4953 16.168 16.6671 16.0141 16.8083C15.8602 16.9495 15.6784 17.057 15.4806 17.1239C15.2827 17.1909 15.073 17.2157 14.865 17.1969C12.5571 16.9461 10.3403 16.1575 8.39251 14.8944C6.58038 13.7429 5.04401 12.2065 3.89251 10.3944C2.62499 8.43781 1.83619 6.21015 1.59001 3.89191C1.57127 3.68451 1.59592 3.47548 1.66239 3.27813C1.72886 3.08078 1.83569 2.89943 1.97609 2.74562C2.11648 2.59182 2.28736 2.46894 2.47785 2.3848C2.66834 2.30066 2.87427 2.2571 3.08251 2.25691H5.33251C5.69649 2.25333 6.04935 2.38222 6.32533 2.61956C6.60131 2.8569 6.78157 3.18649 6.83251 3.54691C6.92748 4.26696 7.1036 4.97395 7.35751 5.65441C7.45842 5.92285 7.48026 6.21459 7.42044 6.49507C7.36062 6.77554 7.22166 7.03299 7.02001 7.23691L6.06751 8.18941C7.13518 10.0671 8.68985 11.6217 10.5675 12.6894L11.52 11.7369C11.7239 11.5353 11.9814 11.3963 12.2619 11.3365C12.5423 11.2767 12.8341 11.2985 13.1025 11.3994C13.783 11.6533 14.49 11.8294 15.21 11.9244C15.5743 11.9758 15.9071 12.1593 16.1449 12.44C16.3828 12.7207 16.5091 13.0791 16.5 13.4469Z"
        stroke="#212121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
