import React from 'react';

export const HandHoldingPhoneIconV2 = ({ className = '' }) => {
  return (
    <svg
      width="19"
      height="28"
      viewBox="0 0 19 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.0703 10.4333L16.6828 11.2289C16.0031 10.7204 15.2145 10.0706 14.6138 9.39966L11.902 17.2487L12.7319 18.818L7.51558 16.8869L4.33398 19.9895C5.19371 22.9383 7.27496 24.2012 10.3046 23.5399L16.4158 20.5954C16.847 20.3882 17.1728 20.0141 17.3238 19.56C18.0685 17.3163 18.9792 11.675 18.0694 10.4333H18.0703Z"
        fill="#E0A39F"
      />
      <path
        d="M4.56729 11.9851C2.21293 13.4569 0.792931 16.6973 1.24607 20.4357L7.31683 22.625L10.8119 22.5108L4.56729 11.9851Z"
        fill="#E0A39F"
      />
      <path
        d="M12.5916 19.5996H4.27974C3.62287 19.5996 3.1188 19.0165 3.21364 18.3666L5.73925 1.10626C5.81653 0.577599 6.27054 0.185059 6.80535 0.185059H15.1172C15.7741 0.185059 16.2781 0.768161 16.1833 1.418L13.6577 18.6784C13.5804 19.207 13.1264 19.5996 12.5916 19.5996Z"
        fill="#202124"
      />
      <path
        d="M13.4392 19.5996H5.12739C4.47053 19.5996 3.96646 19.0165 4.0613 18.3666L6.58691 1.10626C6.66419 0.577599 7.1182 0.185059 7.653 0.185059H15.9648C16.6217 0.185059 17.1258 0.768161 17.0309 1.418L14.5053 18.6784C14.4281 19.207 13.974 19.5996 13.4392 19.5996Z"
        fill="#76737F"
      />
      <path
        d="M8.7376 6.36297C7.93056 6.36297 7.31146 5.64638 7.42825 4.84813L7.76547 2.54382C7.86031 1.89398 8.41795 1.41187 9.07481 1.41187C9.88185 1.41187 10.501 2.12845 10.3842 2.9267L10.0469 5.23101C9.9521 5.88085 9.39447 6.36297 8.7376 6.36297Z"
        fill="#CAC9D9"
      />
      <path
        d="M1.24612 20.4358L10.7312 21.9129L9.03282 27.815L0 24.485L1.24612 20.4358Z"
        fill="#E0A39F"
      />
      <path
        d="M11.588 15.0126C12.0487 14.4098 12.9129 14.2943 13.5157 14.755L16.8313 17.2891L15.1611 19.4743L11.8456 16.9403C11.2427 16.4796 11.1273 15.6154 11.588 15.0126Z"
        fill="#E0A39F"
      />
      <path
        d="M16.7163 15.1559L15.046 17.3408C14.2513 16.1737 13.2449 15.2841 12.0611 14.6255C11.3516 14.2303 11.1452 13.3083 11.6378 12.6637C12.0409 12.1359 12.7724 11.9928 13.3476 12.323C14.6236 13.0562 15.7494 13.9967 16.7154 15.1559H16.7163Z"
        fill="#E0A39F"
      />
      <path
        d="M17.3051 12.9167L15.6349 15.1015C14.8401 13.9345 13.8338 13.0449 12.65 12.3862C11.9404 11.9911 11.7341 11.069 12.2267 10.4244C12.6298 9.89665 13.3613 9.75351 13.9365 10.0837C15.2125 10.817 16.3383 11.7575 17.3043 12.9167H17.3051Z"
        fill="#E0A39F"
      />
      <path
        d="M18.0708 10.4332L16.0765 11.8242C15.2352 10.6905 13.9759 9.69198 12.8809 8.89548C12.2881 8.4643 12.0905 7.86891 12.4822 7.28844C12.8738 6.70797 13.5887 6.57185 14.177 6.87921C15.482 7.55979 17.0583 9.31437 18.0708 10.4332ZM14.6987 17.6183C14.1586 18.7555 13.3305 19.4344 11.957 19.8901L14.2991 20.3292C14.2991 20.3292 15.0982 19.9086 15.1395 19.8041C15.1817 19.6987 14.6978 17.6183 14.6978 17.6183H14.6987Z"
        fill="#E0A39F"
      />
      <path
        d="M15.0165 10.3349L14.9322 10.5728C14.9594 10.6422 14.948 10.6817 14.8927 10.6853L14.7803 11.004C14.7803 11.004 15.7024 11.1621 15.7103 11.1384C15.7182 11.1147 15.3871 10.2163 15.3871 10.2163L15.0165 10.3349Z"
        fill="#E0A39F"
      />
      <path
        d="M15.3789 10.9971L16.9482 12.1738"
        stroke="#7B5353"
        strokeWidth="0.5269"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.6387 13.1565L16.2071 14.3332"
        stroke="#7B5353"
        strokeWidth="0.5269"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.9453 15.3477L15.5137 16.5244"
        stroke="#7B5353"
        strokeWidth="0.5269"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};
