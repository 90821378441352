export const FirstIcon = ({ className = '' }) => {
  return (
    <svg
      width="28"
      height="20"
      viewBox="0 0 28 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.8248 2.57547C22.0436 2.79425 22.1664 3.09095 22.1664 3.4003C22.1664 3.70966 22.0436 4.00636 21.8248 4.22514L16.0498 10.0001L21.8248 15.7751C22.0374 15.9952 22.155 16.2899 22.1523 16.5958C22.1496 16.9017 22.0269 17.1943 21.8106 17.4106C21.5943 17.6269 21.3017 17.7496 20.9958 17.7523C20.6899 17.7549 20.3952 17.6373 20.1752 17.4248L13.5753 10.825C13.3566 10.6062 13.2338 10.3095 13.2338 10.0001C13.2338 9.69078 13.3566 9.39409 13.5753 9.1753L20.1752 2.57547C20.394 2.35676 20.6907 2.23389 21 2.23389C21.3094 2.23389 21.6061 2.35676 21.8248 2.57547Z"
        fill="currentColor"
      />
      <path
        d="M12.4914 2.57547C12.7101 2.79425 12.8329 3.09095 12.8329 3.4003C12.8329 3.70966 12.7101 4.00636 12.4914 4.22514L6.71635 10.0001L12.4914 15.7751C12.7039 15.9952 12.8215 16.2899 12.8188 16.5958C12.8161 16.9017 12.6935 17.1943 12.4771 17.4106C12.2608 17.6269 11.9682 17.7496 11.6623 17.7523C11.3564 17.7549 11.0617 17.6373 10.8417 17.4248L4.24185 10.825C4.02314 10.6062 3.90027 10.3095 3.90027 10.0001C3.90027 9.69078 4.02314 9.39409 4.24185 9.1753L10.8417 2.57547C11.0605 2.35676 11.3572 2.23389 11.6665 2.23389C11.9759 2.23389 12.2726 2.35676 12.4914 2.57547Z"
        fill="currentColor"
      />
    </svg>
  );
};
