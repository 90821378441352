export const ShareIcon = ({ className = 'h-8 w-8' }) => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.78651 10.3658L8.10975 10.5774L8.3961 10.318C8.71358 10.0304 9.1227 9.86283 9.55161 9.8438L9.63612 9.84379C10.0237 9.84377 10.4011 9.96459 10.7151 10.1887C11.029 10.4127 11.2635 10.7285 11.3861 11.0911C11.5088 11.4536 11.5136 11.845 11.3999 12.2103C11.2862 12.5757 11.0596 12.897 10.7513 13.1286C10.443 13.3602 10.0687 13.4901 9.68127 13.4995C9.29383 13.5088 8.91354 13.3971 8.59411 13.1806C8.27475 12.9642 8.03257 12.6541 7.90109 12.2946C7.76963 11.9351 7.75526 11.5439 7.85994 11.176L7.96058 10.8223L7.65292 10.6209L4.16851 8.33937L3.85173 8.13195L3.56621 8.38065C3.29951 8.61296 2.97057 8.76438 2.61869 8.81628C2.26678 8.86818 1.90735 8.81827 1.58357 8.67272L1.37857 9.12875L1.58357 8.67271C1.25983 8.52718 0.985771 8.29236 0.793808 7.9969C0.601871 7.70148 0.5 7.35777 0.5 7.00691C0.5 6.65605 0.601871 6.31235 0.793808 6.01692C0.985771 5.72146 1.25983 5.48664 1.58357 5.34111C1.90735 5.19556 2.26678 5.14564 2.61868 5.19754C2.97058 5.24944 3.29951 5.40086 3.56621 5.63317L3.85163 5.88178L4.16837 5.67454L7.65157 3.39548L7.95985 3.19377L7.85853 2.83957C7.73679 2.414 7.77401 1.95911 7.96357 1.55838C8.15316 1.15756 8.48274 0.837305 8.8925 0.657516C9.30233 0.477691 9.76409 0.450844 10.1925 0.582081C10.6208 0.713299 10.986 0.993416 11.2215 1.36982C11.457 1.74613 11.5473 2.19372 11.4764 2.63034C11.4055 3.067 11.178 3.46433 10.8346 3.74875C10.4911 4.03325 10.055 4.18542 9.60653 4.17642C9.15808 4.16742 8.72861 3.99788 8.39708 3.69992L8.11097 3.44279L7.78909 3.65341L4.31993 5.92339L4.00652 6.12846L4.11521 6.48688C4.21861 6.82786 4.21861 7.19141 4.11521 7.53239L4.00656 7.89069L4.31979 8.09579L7.78651 10.3658Z"
        stroke="#929597"
      />
    </svg>
  );
};
