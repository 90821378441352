export const PackageCartIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00343 0.270993L15.0034 3.76466C15.6206 4.12493 16 4.7858 16 5.50174V12.4983C16 12.8501 15.908 13.1957 15.7331 13.5006C15.5582 13.8055 15.3066 14.0588 15.0034 14.2353L9.00343 17.729C8.69858 17.9065 8.35242 18 8 18C7.64758 18 7.30142 17.9065 6.99657 17.729L0.996571 14.2353C0.69343 14.0588 0.441807 13.8055 0.266905 13.5006C0.092003 13.1957 -3.83464e-05 12.8501 1.19845e-08 12.4983V5.50174C1.19845e-08 4.7858 0.379429 4.12378 0.996571 3.76466L6.99657 0.270993C7.30142 0.0934864 7.64758 0 8 0C8.35242 0 8.69858 0.0934864 9.00343 0.270993ZM7.85714 1.76025L2.56571 4.84087L8 8.00525L13.4343 4.84087L8.14286 1.76025C8.0995 1.73485 8.0502 1.72146 8 1.72146C7.9498 1.72146 7.9005 1.73485 7.85714 1.76025ZM1.71429 6.33472V12.4983C1.71429 12.6015 1.768 12.6945 1.85714 12.7461L7.14286 15.8244V9.49565L1.71429 6.33472ZM8.85714 15.8244L14.1429 12.7461C14.1862 12.721 14.2222 12.6848 14.2473 12.6413C14.2724 12.5979 14.2856 12.5485 14.2857 12.4983V6.33472L8.85714 9.49565V15.8244Z"
        fill="currentColor"
      />
    </svg>
  );
};
