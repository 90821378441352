import React from 'react';

export const PhoneIconV2 = ({ stroke = 'black', className = '' }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.666 11.7817V13.7817C14.6667 13.9674 14.6287 14.1511 14.5543 14.3213C14.4799 14.4914 14.3708 14.6441 14.234 14.7696C14.0972 14.8951 13.9357 14.9907 13.7598 15.0502C13.5839 15.1096 13.3976 15.1317 13.2126 15.115C11.1612 14.8921 9.19064 14.1911 7.4593 13.0684C5.84852 12.0448 4.48286 10.6791 3.4593 9.06836C2.33262 7.32916 1.63147 5.34902 1.41264 3.28836C1.39598 3.104 1.41789 2.9182 1.47697 2.74278C1.53605 2.56735 1.63102 2.40615 1.75582 2.26944C1.88061 2.13273 2.03251 2.0235 2.20183 1.94871C2.37116 1.87392 2.5542 1.8352 2.7393 1.83503H4.7393C5.06284 1.83184 5.3765 1.94641 5.62181 2.15738C5.86713 2.36835 6.02736 2.66132 6.07264 2.98169C6.15705 3.62174 6.3136 4.25018 6.5393 4.85503C6.629 5.09364 6.64841 5.35297 6.59524 5.60228C6.54207 5.85159 6.41855 6.08043 6.2393 6.26169L5.39264 7.10836C6.34168 8.77739 7.72361 10.1593 9.39264 11.1084L10.2393 10.2617C10.4206 10.0825 10.6494 9.95893 10.8987 9.90575C11.148 9.85258 11.4074 9.872 11.646 9.96169C12.2508 10.1874 12.8793 10.3439 13.5193 10.4284C13.8431 10.474 14.1389 10.6372 14.3503 10.8867C14.5617 11.1362 14.6741 11.4547 14.666 11.7817Z"
        stroke={stroke}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
