import { useRouter } from 'next/router';
import React, {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import ConfigurationService from '../helpers/services/configuration.service';

const Context = createContext<{
  seoList: any;
  setSeoList: (value: SetStateAction<any>) => void;
}>({
  seoList: {},
  setSeoList(value) {},
});

export function SeoTagProvider({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const [seoList, setSeoList] = useState<any>([]);

  useEffect(() => {
    const initialLoad = async () => {
      const list = await ConfigurationService.getSeoTag(router.locale);
      setSeoList(list);
    };
    initialLoad();
  }, [router.locale]);

  return (
    <Context.Provider
      value={{
        seoList,
        setSeoList,
      }}
    >
      {children}
    </Context.Provider>
  );
}

export function useSeoTagContext() {
  return useContext(Context);
}
