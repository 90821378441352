export const SearchIcon = ({ className = '', fill = 'currentColor' }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.12883 14.8334C11.8107 14.8334 14.7955 11.8486 14.7955 8.1667C14.7955 4.48478 11.8107 1.5 8.12883 1.5C4.44692 1.5 1.46213 4.48478 1.46213 8.1667C1.46213 11.8486 4.44692 14.8334 8.12883 14.8334Z"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4629 16.4999L12.8796 12.9165"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
