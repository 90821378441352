export const FemaleIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.1562"
        cy="20.5"
        r="19.5"
        stroke="#4C0055"
        strokeWidth="2"
      />
      <path
        d="M8.58594 30.2466C8.58594 30.0356 8.58594 29.8246 8.58594 29.6135C8.64388 29.3123 8.6823 29.0055 8.76341 28.7109C9.39525 26.419 10.884 24.9967 13.1765 24.4076C14.3694 24.1008 15.5666 23.8123 16.7614 23.5105C16.848 23.4885 16.9779 23.4458 16.998 23.3836C17.0877 23.1116 17.1438 22.828 17.2133 22.5444C16.7468 22.4438 16.3101 22.3566 15.8771 22.2553C14.9189 22.0315 13.9901 21.7217 13.1131 21.2716C12.6721 21.0453 12.6258 20.683 12.9673 20.3305C13.1021 20.1915 13.2296 20.0415 13.3393 19.8823C13.8919 19.0833 14.2115 18.1868 14.431 17.2488C14.7457 15.9052 14.8018 14.5379 14.8299 13.1644C14.8634 11.5196 15.255 9.96561 16.2003 8.5946C17.2176 7.12052 18.5849 6.11543 20.364 5.78549C21.6124 5.55373 22.8108 5.69156 23.7823 6.65152C23.8445 6.71312 23.972 6.73263 24.0696 6.73263C24.9978 6.73202 25.7083 7.12235 26.2084 7.90238C26.7329 8.72023 26.9897 9.63688 27.0855 10.5828C27.2026 11.7428 27.2446 12.9113 27.2959 14.0774C27.3514 15.3454 27.4703 16.6023 27.8313 17.8239C28.1021 18.74 28.4583 19.6164 29.1334 20.3226C29.4823 20.6873 29.4463 21.0795 28.9816 21.2716C28.1973 21.5954 27.4002 21.8943 26.5927 22.1541C26.0493 22.3291 25.4778 22.4182 24.8996 22.5505C24.9539 22.7756 25.0186 22.9927 25.0552 23.2134C25.0863 23.4007 25.1808 23.4775 25.3632 23.5214C26.5603 23.8105 27.7545 24.1118 28.9499 24.4088C31.6205 25.0724 33.4392 27.271 33.5246 29.9264C33.5349 30.2539 33.438 30.4936 33.1361 30.6357H8.97565C8.84575 30.5058 8.71584 30.3759 8.58594 30.246V30.2466Z"
        fill="#4C0055"
      />
    </svg>
  );
};
