import React from 'react';

export const GalleryIconV2 = ({ fill = 'white', className = '' }) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.175 1.8C4.815 1.8 4.5 2.115 4.5 2.475C4.5 2.835 4.815 3.15 5.175 3.15C5.535 3.15 5.85 2.835 5.85 2.475C5.85 2.115 5.535 1.8 5.175 1.8ZM7.65 0H1.35C0.585 0 0 0.585 0 1.35V7.65C0 8.415 0.585 9 1.35 9H7.65C8.415 9 9 8.415 9 7.65V1.35C9 0.585 8.415 0 7.65 0ZM8.1 5.355L7.245 4.5C6.705 4.005 5.85 4.005 5.355 4.5L4.95 4.905L3.645 3.6C3.105 3.105 2.25 3.105 1.755 3.6L0.9 4.455V1.35C0.9 1.08 1.08 0.9 1.35 0.9H7.65C7.92 0.9 8.1 1.08 8.1 1.35V5.355Z"
        fill={fill}
      />
    </svg>
  );
};
