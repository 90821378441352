export const ImageIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.246 22.246"
      className={className}
    >
      <g id="noun_Image_655983" transform="translate(-9.4 -9.4)">
        <path
          id="Path_2160"
          d="M29.622,9.4H11.4a2.009,2.009,0,0,0-2,2V29.649a2.009,2.009,0,0,0,2,2H29.649a2.009,2.009,0,0,0,2-2V11.4A2.014,2.014,0,0,0,29.622,9.4ZM11.4,11.1H29.649a.274.274,0,0,1,.274.274V24.122l-5.391-5.363a.859.859,0,0,0-1.2,0l-5.09,5.09-1.669-1.669a.859.859,0,0,0-1.2,0L11.1,26.42V11.37A.3.3,0,0,1,11.4,11.1Zm-.3,18.525v-.794l4.843-4.843,5.938,5.938H11.4A.3.3,0,0,1,11.1,29.622Zm18.525.3H24.286l-4.843-4.843,4.488-4.488,5.993,5.993v3.065A.3.3,0,0,1,29.622,29.923Z"
          fill="currentColor"
        ></path>
        <circle
          id="Ellipse_267"
          cx="1.423"
          cy="1.423"
          r="1.423"
          transform="translate(16.241 15.092)"
          fill="currentColor"
        ></circle>
      </g>
    </svg>
  );
};
