export const FileIcon = ({ className = 'h-8 w-8' }) => {
  return (
    <svg
      className={className}
      width="30"
      height="50"
      viewBox="0 0 46 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.8889 33H41.3889V32.5V5V4.5H40.8889H5.11111H4.61111V5V45V45.5H5.11111H28.1111H28.6111V45V33H40.8889ZM45.5 2.5V34.7896L30.4552 49.5H2.55556C2.00688 49.5 1.48284 49.2867 1.09815 48.9104C0.713836 48.5344 0.5 48.0268 0.5 47.5V2.5C0.5 1.97316 0.713835 1.46561 1.09815 1.08965C1.48284 0.713323 2.00689 0.5 2.55556 0.5H43.4444C43.9931 0.5 44.5172 0.713324 44.9019 1.08965C45.2862 1.46561 45.5 1.97316 45.5 2.5Z"
        fill="#BF252D"
        stroke="#F4F4F4"
      />
    </svg>
  );
};
