import { MutableRefObject, useEffect, useState } from 'react';

interface ObserverOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

const useFirstViewportEntry = (
  ref: MutableRefObject<Element | null>,
  observerOptions?: ObserverOptions
): boolean => {
  const [entered, setEntered] = useState(false);

  useEffect(() => {
    // Check if we are running on the client (browser) and if IntersectionObserver is available
    if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
      // Ensure the hook does nothing on the server (SSR) or unsupported environments
      return;
    }

    const element = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => setEntered(entry.isIntersecting),
      observerOptions
    );

    if (element && !entered) {
      observer.observe(element);
    }

    return () => {
      if (element) observer.disconnect();
    };
  }, [entered, ref, observerOptions]);

  return entered;
};

export default useFirstViewportEntry;
