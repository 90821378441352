import React from 'react';

export const ThreeNumber = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 104 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50859 148.273L6.30859 124.673C9.90859 125.473 13.6419 126.173 17.5086 126.773C21.4419 127.373 25.0419 127.673 28.3086 127.673C35.4419 127.673 41.7086 126.773 47.1086 124.973C52.5753 123.107 56.8419 120.44 59.9086 116.973C62.9753 113.44 64.5086 109.207 64.5086 104.273C64.5086 101.607 63.7753 98.9401 62.3086 96.2734C60.8419 93.6068 58.3753 91.4401 54.9086 89.7734C51.5086 88.1068 46.9086 87.4068 41.1086 87.6734L27.9086 90.6734L23.1086 68.2734C27.6419 67.4734 31.8419 66.4734 35.7086 65.2734C39.6419 64.0734 42.9086 62.7401 45.5086 61.2734C49.9086 58.7401 53.0753 55.9401 55.0086 52.8734C57.0086 49.8068 58.1086 46.4734 58.3086 42.8734C58.6419 38.1401 57.1753 34.5068 53.9086 31.9734C50.6419 29.3734 46.2419 28.0734 40.7086 28.0734C34.1086 28.0734 28.4086 29.1401 23.6086 31.2734C18.8086 33.4068 14.7086 35.6734 11.3086 38.0734L0.908594 15.4734C2.77526 13.4734 6.20859 11.3401 11.2086 9.07343C16.2086 6.7401 21.9753 4.77343 28.5086 3.17343C35.1086 1.50677 41.7086 0.673433 48.3086 0.673433C58.2419 0.673433 66.7086 2.07343 73.7086 4.87343C80.7086 7.67343 86.0419 11.7068 89.7086 16.9734C93.4419 22.1734 95.3086 28.4734 95.3086 35.8734C95.3086 42.5401 94.0086 48.4401 91.4086 53.5734C88.8086 58.7068 85.2086 63.1734 80.6086 66.9734C76.0753 70.7734 70.8419 74.0068 64.9086 76.6734V73.2734C74.1753 73.6068 81.6086 75.4734 87.2086 78.8734C92.8086 82.2734 96.8419 86.6068 99.3086 91.8734C101.842 97.0734 103.109 102.54 103.109 108.273C103.109 116.407 100.375 123.907 94.9086 130.773C89.4419 137.64 81.7753 143.14 71.9086 147.273C62.0419 151.407 50.5086 153.473 37.3086 153.473C30.6419 153.473 24.7086 152.873 19.5086 151.673C14.3753 150.473 10.0419 149.34 6.50859 148.273Z"
        fill="url(#paint0_linear_12832_201958)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12832_201958"
          x1="-6.09766"
          y1="88.8185"
          x2="111.904"
          y2="89.0684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8300" />
          <stop offset="1" stopColor="#DE2A00" />
        </linearGradient>
      </defs>
    </svg>
  );
};
