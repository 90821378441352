import React from 'react';

const ShippingTruckIconV2 = ({ fill = '#212121', className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.22 15.6981C15.5186 15.6981 14.95 15.1304 14.95 14.4301C14.95 13.7298 15.5186 13.1621 16.22 13.1621C16.9214 13.1621 17.49 13.7298 17.49 14.4301C17.49 15.1304 16.9214 15.6981 16.22 15.6981Z"
        fill={fill}
      />
      <path
        d="M5.39602 15.6981C4.69461 15.6981 4.12601 15.1304 4.12601 14.4301C4.12601 13.7298 4.69461 13.1621 5.39602 13.1621C6.09742 13.1621 6.66602 13.7298 6.66602 14.4301C6.66602 15.1304 6.09742 15.6981 5.39602 15.6981Z"
        fill={fill}
      />
      <path
        d="M13.17 6.35407V14.0001H6.91198V13.9841C6.89295 13.8965 6.86618 13.8109 6.83198 13.7281C6.81998 13.6981 6.80398 13.6721 6.78998 13.6441C6.76344 13.5876 6.73338 13.5328 6.69998 13.4801C6.67998 13.4501 6.65798 13.4221 6.63598 13.3941C6.60252 13.3481 6.56646 13.304 6.52798 13.2621C6.50198 13.2361 6.47598 13.2101 6.44798 13.1841C6.40652 13.1465 6.36312 13.1111 6.31798 13.0781C6.28798 13.0561 6.25798 13.0341 6.22598 13.0141C6.17693 12.9838 6.12618 12.9565 6.07398 12.9321C6.04198 12.9161 6.00998 12.9001 5.97598 12.8861C5.91653 12.8637 5.85574 12.845 5.79398 12.8301C5.76398 12.8301 5.73398 12.8121 5.70398 12.8061C5.51195 12.767 5.31402 12.767 5.12198 12.8061C5.08998 12.8061 5.06198 12.8221 5.03198 12.8301C4.97022 12.845 4.90944 12.8637 4.84998 12.8861C4.81598 12.9001 4.78398 12.9161 4.75198 12.9321C4.69979 12.9565 4.64904 12.9838 4.59998 13.0141C4.56798 13.0341 4.53798 13.0561 4.50798 13.0781C4.46284 13.1111 4.41945 13.1465 4.37798 13.1841C4.34998 13.2101 4.32398 13.2341 4.29798 13.2621C4.25951 13.304 4.22346 13.3481 4.18998 13.3941C4.16798 13.4221 4.14598 13.4501 4.12598 13.4801C4.0926 13.5328 4.06254 13.5876 4.03598 13.6441C4.02198 13.6721 4.00598 13.6981 3.99398 13.7281C3.95979 13.8109 3.93301 13.8965 3.91398 13.9841V14.0001H1.71398V10.1061H4.65398C4.73514 10.1061 4.81297 10.0738 4.87036 10.0164C4.92774 9.95906 4.95998 9.88123 4.95998 9.80007C4.95998 9.71892 4.92774 9.64108 4.87036 9.5837C4.81297 9.52631 4.73514 9.49407 4.65398 9.49407H1.71398V8.09407H5.13998C5.22114 8.09407 5.29897 8.06183 5.35636 8.00445C5.41374 7.94706 5.44598 7.86923 5.44598 7.78807C5.44598 7.70692 5.41374 7.62908 5.35636 7.5717C5.29897 7.51431 5.22114 7.48207 5.13998 7.48207H1.71398V5.92407H13.17V6.35407Z"
        fill={fill}
      />
      <path
        d="M16.4712 6.65991L18.2852 9.79991V13.9999H17.7232V13.9839C17.7041 13.8964 17.6774 13.8107 17.6432 13.7279C17.6312 13.6979 17.6152 13.6719 17.6012 13.6439C17.5747 13.5874 17.5447 13.5326 17.5112 13.4799C17.4912 13.4499 17.4692 13.4219 17.4472 13.3939C17.4136 13.3479 17.3776 13.3039 17.3392 13.2619C17.3132 13.2359 17.2872 13.2099 17.2592 13.1839C17.2177 13.1464 17.1743 13.111 17.1292 13.0779C17.0992 13.0559 17.0692 13.0339 17.0372 13.0139C16.9881 12.9837 16.9374 12.9563 16.8852 12.9319C16.8532 12.9159 16.8212 12.8999 16.7872 12.8859C16.7277 12.8635 16.6669 12.8448 16.6052 12.8299C16.5752 12.8299 16.5452 12.8119 16.5152 12.8059C16.3231 12.7669 16.1252 12.7669 15.9332 12.8059C15.9012 12.8059 15.8732 12.8219 15.8432 12.8299C15.7814 12.8448 15.7206 12.8635 15.6612 12.8859C15.6272 12.8999 15.5952 12.9159 15.5632 12.9319C15.5109 12.9563 15.4602 12.9837 15.4112 13.0139C15.3792 13.0339 15.3492 13.0559 15.3192 13.0779C15.274 13.111 15.2307 13.1464 15.1892 13.1839C15.1612 13.2099 15.1352 13.2339 15.1092 13.2619C15.0699 13.3058 15.0331 13.3518 14.9992 13.3999C14.9772 13.4279 14.9552 13.4559 14.9352 13.4859C14.9017 13.5386 14.8716 13.5934 14.8452 13.6499C14.8312 13.6779 14.8152 13.7039 14.8032 13.7339C14.7689 13.8167 14.7422 13.9024 14.7232 13.9899V14.0059H13.7892V6.65991H16.4712ZM14.7072 7.81591V9.41591H16.6452L15.7512 7.81591H14.7072Z"
        fill={fill}
      />
    </svg>
  );
};

export default ShippingTruckIconV2;
