export const WarrantyStatusIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2383 0C11.4362 0 11.634 0.0392773 11.8147 0.113904L19.9157 3.25216C20.8622 3.61744 21.5677 4.46976 21.5634 5.49882C21.5419 9.39513 19.7866 16.524 12.374 19.7643C11.6556 20.0786 10.8209 20.0786 10.1025 19.7643C2.68989 16.524 0.934616 9.39513 0.913106 5.49882C0.908803 4.46976 1.61435 3.61744 2.56083 3.25216L10.6661 0.113904C10.8425 0.0392773 11.0404 0 11.2383 0Z"
        fill="#929597"
      />
      <path
        d="M9.73103 11.0128L7.52688 8.94268C7.44786 8.86852 7.35405 8.80968 7.25081 8.76955C7.14757 8.72941 7.03691 8.70875 6.92517 8.70875C6.81342 8.70875 6.70276 8.72941 6.59952 8.76955C6.49628 8.80968 6.40247 8.86852 6.32346 8.94268C6.16387 9.09246 6.07422 9.29561 6.07422 9.50744C6.07422 9.71927 6.16387 9.92241 6.32346 10.0722L9.13002 12.7064C9.20882 12.7809 9.30255 12.8401 9.40583 12.8805C9.5091 12.9209 9.61986 12.9416 9.73173 12.9416C9.84361 12.9416 9.95437 12.9209 10.0576 12.8805C10.1609 12.8401 10.2547 12.7809 10.3334 12.7064L16.145 7.2504C16.2251 7.17654 16.2888 7.08853 16.3325 6.99145C16.3761 6.89437 16.3989 6.79014 16.3994 6.68477C16.3999 6.5794 16.3782 6.47497 16.3355 6.37752C16.2928 6.28006 16.23 6.1915 16.1506 6.11695C16.0713 6.04239 15.977 5.98332 15.8732 5.94314C15.7695 5.90295 15.6582 5.88245 15.546 5.88282C15.4337 5.88318 15.3226 5.90441 15.2191 5.94527C15.1156 5.98613 15.0218 6.04581 14.943 6.12088L9.73103 11.0128Z"
        fill="white"
      />
    </svg>
  );
};
