import Fuse, { IFuseOptions } from 'fuse.js';

export class SearchUtils {
  static fuzzySearch = <T>(
    searchKey: string,
    data: T[],
    options: IFuseOptions<T>
  ) => {
    const fuse = new Fuse(data, options);
    const result = fuse.search(searchKey);
    return result
      .sort((a, b) => a.refIndex - b.refIndex)
      .map((item) => item.item);
  };
}
