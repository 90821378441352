import { createSlice } from '@reduxjs/toolkit';
import { DealsConstants, OffersConstants } from '../../types/i18n.labels';

type initialStateType = {
  isLoader: boolean;
  isOpen: boolean;
  isOpenFlow: boolean;
  isOfferOpenFlow: boolean;
  filterType: string;
  loader: boolean;
  currentStep: DealsConstants;
  offerCurrentStep: OffersConstants;
  userSelectedOffer: any;
  dealsOffersList: {
    data: {
      data: Array<any>;
      offerCount: number;
    };
    success: boolean;
  };
};

const DealsOffersInitialState: initialStateType = {
  isLoader: false,
  isOpen: false,
  isOpenFlow: false,
  isOfferOpenFlow: false,
  filterType: '',
  loader: false,
  currentStep: DealsConstants.DEAL_SELECT_CITY,
  offerCurrentStep: OffersConstants.OFFER_SHARE,
  userSelectedOffer: {},
  dealsOffersList: {
    data: {
      data: [],
      offerCount: 0,
    },
    success: false,
  },
};

export const dealsOffersSlice = createSlice({
  name: 'deals-offers',
  initialState: DealsOffersInitialState,
  reducers: {
    isDealLoader: (state, action) => {
      return { ...state, isLoader: action.payload };
    },
    setOpenFilter: (state, action) => {
      return { ...state, isOpen: action.payload };
    },
    setFilterType: (state, action) => {
      return { ...state, filterType: action.payload };
    },
    setOpenFlow: (state, action) => {
      return { ...state, isOpenFlow: action.payload };
    },
    setOfferOpenFlow: (state, action) => {
      return { ...state, isOfferOpenFlow: action.payload };
    },
    updateDealsStep: (state, action) => {
      return { ...state, currentStep: action.payload };
    },
    updateOffersStep: (state, action) => {
      return { ...state, offerCurrentStep: action.payload };
    },
    updateUserSelectedOffer: (state, action) => {
      return { ...state, userSelectedOffer: action.payload };
    },
    dealOfferList: (state, action) => {
      return { ...state, dealsOffersList: action.payload };
    },
  },
});

export const {
  isDealLoader,
  setOpenFilter,
  setFilterType,
  setOpenFlow,
  setOfferOpenFlow,
  updateDealsStep,
  updateOffersStep,
  updateUserSelectedOffer,
  dealOfferList,
} = dealsOffersSlice.actions;

export default dealsOffersSlice.reducer;
