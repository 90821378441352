export const AskingPriceYellowIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 40 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0703 0.5C21.148 0.5 22.1422 1.0692 22.6886 2.00268L39.0815 29.9312C39.6355 30.8723 39.6355 32.0335 39.0966 32.9746C38.5578 33.9156 37.5484 34.5 36.4632 34.5H3.67744C2.59218 34.5 1.5828 33.9156 1.04396 32.9746C0.505123 32.0335 0.512712 30.8647 1.05914 29.9312L17.452 2.00268C17.9984 1.0692 18.9926 0.5 20.0703 0.5ZM20.0703 10.2143C19.0609 10.2143 18.2489 11.0263 18.2489 12.0357V20.5357C18.2489 21.5451 19.0609 22.3571 20.0703 22.3571C21.0797 22.3571 21.8917 21.5451 21.8917 20.5357V12.0357C21.8917 11.0263 21.0797 10.2143 20.0703 10.2143ZM22.4989 27.2143C22.4989 26.5702 22.243 25.9525 21.7876 25.497C21.3321 25.0416 20.7144 24.7857 20.0703 24.7857C19.4262 24.7857 18.8085 25.0416 18.353 25.497C17.8976 25.9525 17.6417 26.5702 17.6417 27.2143C17.6417 27.8584 17.8976 28.4761 18.353 28.9315C18.8085 29.387 19.4262 29.6429 20.0703 29.6429C20.7144 29.6429 21.3321 29.387 21.7876 28.9315C22.243 28.4761 22.4989 27.8584 22.4989 27.2143Z"
        fill="#FFCC00"
      />
    </svg>
  );
};
