import { isMobile } from 'react-device-detect';
import GlobalService from './global.service';
import { SessionUtils } from '../utilities';
import { AnalyticsSource } from '../../types/enums';

function getClarityUserId() {
  const cookies = document.cookie.split('; ').reduce((acc: any, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
  const clckValue = cookies['_clck'];
  return clckValue ? clckValue.split('|')[0] : null;
}

export default class AnalyticsService {
  // Geneic method to create strapi analytics
  static createStrapiAnalytics = async (
    eventName: string,
    source: AnalyticsSource,
    Params?: string
  ) => {
    const userDetails = SessionUtils.getUserDetails();
    let payload: any = {
      UserId: userDetails?.UserId || null,
      EventName: eventName,
      Params,
      PageName: isMobile ? 'Mobile' : 'Web',
      PageURL: typeof window !== 'undefined' && `${window?.location?.href}`,
      Source: source,
      ClearityId: getClarityUserId(),
    };
    await GlobalService.createStrapiAnalytic(payload);
  };
}
