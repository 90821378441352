import React from 'react';

export const FiveNumber = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 76 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6606 120.801C10.3304 120.801 8.57468 120.721 6.39327 120.562C4.26507 120.402 2.40289 120.136 0.806732 119.764V102.525C1.81763 102.578 2.80192 102.632 3.75962 102.685C4.77051 102.685 5.72821 102.685 6.63269 102.685C15.5712 102.685 23.3125 101.674 29.8567 99.6522C36.4542 97.6304 41.5353 94.7573 45.1 91.033C48.6647 87.3086 50.4471 82.8926 50.4471 77.7849C50.4471 71.7727 48.6913 67.3567 45.1798 64.5368C41.7215 61.7169 36.1083 60.307 28.3404 60.307C24.9885 60.307 21.3705 60.7592 17.4865 61.6637C13.6558 62.5682 9.90481 63.6589 6.23366 64.9358L1.68462 62.382L6.39327 0.610841H71.9952L69.7606 20.8022H24.1904L21.4769 45.5426C24.8288 44.5849 28.101 43.8666 31.2933 43.3878C34.4856 42.9089 37.5183 42.6695 40.3913 42.6695C51.8305 42.6695 60.5029 45.6756 66.4087 51.6878C72.3676 57.6467 75.3471 65.9733 75.3471 76.6676C75.3471 82.9458 73.9106 88.7717 71.0375 94.1455C68.1644 99.466 63.9878 104.121 58.5077 108.112C53.0276 112.102 46.3503 115.215 38.476 117.449C30.6016 119.684 21.6631 120.801 11.6606 120.801Z"
        fill="url(#paint0_linear_15731_215780)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15731_215780"
          x1="-13.0003"
          y1="62.9173"
          x2="86.9997"
          y2="62.9173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F47F20" />
          <stop offset="1" stopColor="#EC2024" />
        </linearGradient>
      </defs>
    </svg>
  );
};
