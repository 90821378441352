import React from 'react';

export const CarInGarageIconV2 = ({ fill = 'white', className = '' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.5539 9.8136H11.8959L11.4741 8.48062C11.2547 7.8057 10.6304 7.33325 9.90485 7.33325H6.12527C5.4166 7.33325 4.77542 7.78883 4.55607 8.48062L4.13424 9.8136H3.47619C3.18935 9.8136 2.95312 10.0498 2.95312 10.3367V10.6235C2.95312 10.9104 3.18935 11.1466 3.47619 11.1466H3.6618C3.56056 11.619 3.49307 12.1083 3.49307 12.5977V15.0949C3.49307 15.3817 3.72929 15.618 4.01613 15.618H4.87666C5.1635 15.618 5.39973 15.3817 5.39973 15.0949V14.3019H10.6135V15.0949C10.6135 15.3817 10.8497 15.618 11.1366 15.618H11.9971C12.284 15.618 12.5202 15.3817 12.5202 15.0949V12.5977C12.5202 12.1083 12.4696 11.619 12.3515 11.1466H12.5371C12.8239 11.1466 13.0601 10.9104 13.0601 10.6235V10.3367C13.0601 10.0498 12.8239 9.8136 12.5539 9.8136ZM5.55159 8.80121C5.63595 8.54812 5.87218 8.37939 6.12527 8.37939H9.88798C10.1579 8.37939 10.3773 8.54812 10.4617 8.80121L10.951 10.3535C10.9679 10.421 10.9172 10.4885 10.8497 10.4885H5.1635C5.09601 10.4885 5.02852 10.421 5.06227 10.3535L5.55159 8.80121ZM6.14215 12.8845H5.09601C4.92728 12.8845 4.77542 12.7495 4.77542 12.5639V12.0746C4.77542 11.8721 4.94415 11.7371 5.14663 11.754L6.12527 11.9059C6.26026 11.9227 6.3615 12.024 6.37837 12.159L6.44586 12.4964C6.49648 12.6989 6.34462 12.8845 6.14215 12.8845ZM11.2547 12.5639C11.2547 12.7327 11.1197 12.8845 10.9341 12.8845H9.88798C9.6855 12.8845 9.53364 12.6989 9.58426 12.4964L9.65176 12.159C9.6855 12.024 9.78674 11.9396 9.90485 11.9059L10.8835 11.754C11.0691 11.7203 11.2547 11.8721 11.2547 12.0746V12.5639Z"
        fill={fill}
      />
      <path
        d="M15.5908 3.4528L8.26783 0.550619C8.0991 0.483127 7.93037 0.483127 7.76164 0.550619L0.438701 3.4528C0.168731 3.55404 0 3.80713 0 4.09398V15.3483C0 15.5002 0.118112 15.6183 0.26997 15.6183H1.09675C1.24861 15.6183 1.36672 15.5002 1.36672 15.3483V5.59568H14.629V15.3483C14.629 15.5002 14.7471 15.6183 14.899 15.6183H15.7257C15.8776 15.6183 15.9957 15.5002 15.9957 15.3483V4.09398C16.0295 3.80713 15.8607 3.55404 15.5908 3.4528Z"
        fill={fill}
      />
    </svg>
  );
};
