import React from 'react';

export const AllFiltersIconV2 = ({ stroke = '#212121' }) => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.99902H6.05C6.25 9.84902 7.05 10.499 8 10.499C8.95 10.499 9.7 9.84902 9.95 8.99902H10C10.3 8.99902 10.5 8.79902 10.5 8.49902C10.5 8.19902 10.3 7.99902 10 7.99902H9.95C9.75 7.14902 8.95 6.49902 8 6.49902C7.05 6.49902 6.3 7.14902 6.05 7.99902H2C1.7 7.99902 1.5 8.19902 1.5 8.49902C1.5 8.79902 1.7 8.99902 2 8.99902ZM8 7.49902C8.55 7.49902 9 7.94902 9 8.49902C9 9.04902 8.55 9.49902 8 9.49902C7.45 9.49902 7 9.04902 7 8.49902C7 7.94902 7.45 7.49902 8 7.49902ZM2 4.99902H2.05C2.25 5.84902 3.05 6.49902 4 6.49902C4.95 6.49902 5.7 5.84902 5.95 4.99902H10C10.3 4.99902 10.5 4.79902 10.5 4.49902C10.5 4.19902 10.3 3.99902 10 3.99902H5.95C5.75 3.14902 4.95 2.49902 4 2.49902C3.05 2.49902 2.3 3.14902 2.05 3.99902H2C1.7 3.99902 1.5 4.19902 1.5 4.49902C1.5 4.79902 1.7 4.99902 2 4.99902ZM4 3.49902C4.55 3.49902 5 3.94902 5 4.49902C5 5.04902 4.55 5.49902 4 5.49902C3.45 5.49902 3 5.04902 3 4.49902C3 3.94902 3.45 3.49902 4 3.49902Z"
        fill={stroke}
      />
    </svg>
  );
};
