import React from 'react';

export const ThreeDotsIconV2 = ({ color = '#212121', className = '' }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.0002 12.9524C12.5262 12.9524 12.9526 12.526 12.9526 12C12.9526 11.474 12.5262 11.0476 12.0002 11.0476C11.4742 11.0476 11.0479 11.474 11.0479 12C11.0479 12.526 11.4742 12.9524 12.0002 12.9524Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6662 12.9524C19.1922 12.9524 19.6186 12.526 19.6186 12C19.6186 11.474 19.1922 11.0476 18.6662 11.0476C18.1403 11.0476 17.7139 11.474 17.7139 12C17.7139 12.526 18.1403 12.9524 18.6662 12.9524Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33324 12.9524C5.85923 12.9524 6.28562 12.526 6.28562 12C6.28562 11.474 5.85923 11.0476 5.33324 11.0476C4.80725 11.0476 4.38086 11.474 4.38086 12C4.38086 12.526 4.80725 12.9524 5.33324 12.9524Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
