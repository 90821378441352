import React from 'react';
export const FinanceIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.502106"
        y="1.41821"
        width="27"
        height="27"
        rx="3.5"
        fill="#4C0055"
        stroke="#824C88"
      />
      <path
        d="M8.39536 11.8649H9.00666C9.00666 12.2317 9.4254 12.5441 9.92361 12.5441C10.4218 12.5441 10.8406 12.2317 10.8406 11.8649C10.8406 11.4913 10.5227 11.3555 9.85025 11.1755C9.20227 10.9955 8.39536 10.7714 8.39536 9.82725C8.39536 9.21935 8.84466 8.70314 9.46513 8.52993V7.78958H10.3821V8.52993C11.0026 8.70314 11.4519 9.21935 11.4519 9.82725H10.8406C10.8406 9.46047 10.4218 9.14803 9.92361 9.14803C9.4254 9.14803 9.00666 9.46047 9.00666 9.82725C9.00666 10.2008 9.32453 10.3367 9.99696 10.5167C10.6449 10.6967 11.4519 10.9208 11.4519 11.8649C11.4519 12.4728 11.0026 12.989 10.3821 13.1622V13.9026H9.46513V13.1622C8.84466 12.989 8.39536 12.4728 8.39536 11.8649Z"
        fill="white"
      />
      <path
        d="M18.2821 12.8825C18.9627 12.8825 19.5913 13.2228 19.9698 13.7898L21.3865 15.9074C21.5532 16.1571 21.6419 16.4508 21.6412 16.751V20.1259C21.6421 20.352 21.5674 20.572 21.429 20.7508C21.2905 20.9296 21.0962 21.057 20.8771 21.1126L19.5556 21.4473C19.4606 21.9052 19.211 22.3164 18.8487 22.612C18.4864 22.9076 18.0335 23.0696 17.5659 23.0708C16.6224 23.0708 15.8349 22.4183 15.6077 21.5426H10.864C10.6368 22.4183 9.84921 23.0708 8.90577 23.0708C7.96233 23.0708 7.17477 22.4183 6.94757 21.5426H6.35869C5.80138 21.5426 5.33985 21.0811 5.33985 20.5238V18.6613C5.34034 18.2073 5.49255 17.7664 5.77231 17.4087C6.05206 17.0511 6.44331 16.7972 6.88389 16.6873L9.63831 15.9869L11.6281 13.615C11.8184 13.386 12.0569 13.2016 12.3264 13.075C12.596 12.9484 12.8901 12.8827 13.1879 12.8825H18.2821ZM18.2821 13.9013H16.0376V15.939H20.1766L19.1257 14.3629V14.3471C19.0334 14.2084 18.9079 14.0951 18.7607 14.0172C18.6135 13.9394 18.4486 13.8995 18.2821 13.9013ZM16.0376 13.9013H13.1879C12.8858 13.9013 12.6011 14.0348 12.408 14.2676L11.0071 15.939H16.0376V13.9013ZM20.6224 16.9578H9.98828L7.12281 17.6741C6.90293 17.7286 6.70788 17.8558 6.56922 18.0349C6.43055 18.2141 6.35637 18.4348 6.35869 18.6613V20.5238H6.94757C7.17477 19.6481 7.96233 18.9955 8.90577 18.9955C9.84921 18.9955 10.6368 19.6481 10.864 20.5238H15.6077C15.8349 19.6481 16.6224 18.9955 17.5659 18.9955C18.4691 18.9955 19.2276 19.5925 19.492 20.4122L20.6224 20.1264V16.9578ZM17.5659 20.0143C16.9968 20.0143 16.547 20.4641 16.547 21.0332C16.547 21.6022 16.9968 22.052 17.5659 22.052C18.1349 22.052 18.5847 21.6022 18.5847 21.0332C18.5847 20.4641 18.1349 20.0143 17.5659 20.0143ZM8.90577 20.0143C8.33675 20.0143 7.88694 20.4641 7.88694 21.0332C7.88694 21.6022 8.33675 22.052 8.90577 22.052C9.47479 22.052 9.92461 21.6022 9.92461 21.0332C9.92461 20.4641 9.47479 20.0143 8.90577 20.0143Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6521 6.76559C15.3481 6.76559 15.0566 6.88635 14.8416 7.10131C14.6266 7.31626 14.5059 7.6078 14.5059 7.91178V11.8598V12.8771H15.27V11.8598V7.91178C15.27 7.81045 15.3103 7.71327 15.3819 7.64162C15.4536 7.56997 15.5507 7.52972 15.6521 7.52972H21.5104C21.6117 7.52972 21.7089 7.56997 21.7805 7.64162C21.8522 7.71327 21.8924 7.81045 21.8924 7.91178V15.8078C21.8924 15.9091 21.8522 16.0063 21.7805 16.0779C21.7138 16.1446 21.625 16.1841 21.5313 16.1892C21.6005 16.3656 21.6364 16.5543 21.636 16.7456V16.947C21.7434 16.9352 21.8488 16.9082 21.949 16.8667C22.0881 16.8091 22.2144 16.7247 22.3209 16.6182C22.4273 16.5118 22.5117 16.3854 22.5693 16.2464C22.6269 16.1073 22.6566 15.9583 22.6566 15.8078V7.91178C22.6566 7.76126 22.6269 7.61222 22.5693 7.47316C22.5117 7.33409 22.4273 7.20774 22.3209 7.10131C22.2144 6.99487 22.0881 6.91044 21.949 6.85284C21.8099 6.79524 21.6609 6.76559 21.5104 6.76559H15.6521ZM14.5064 15.8411V13.9005H15.27V13.8959H14.5059V15.8078C14.5059 15.8189 14.5061 15.83 14.5064 15.8411ZM15.6521 16.9539H20.6171V16.9524H15.5934C15.6129 16.9534 15.6324 16.9539 15.6521 16.9539ZM16.4162 9.05797C16.3149 9.05797 16.2177 9.09822 16.146 9.16988C16.0744 9.24153 16.0341 9.33871 16.0341 9.44003C16.0341 9.54136 16.0744 9.63854 16.146 9.71019C16.2177 9.78185 16.3149 9.8221 16.4162 9.8221H20.7463C20.8476 9.8221 20.9448 9.78185 21.0164 9.71019C21.0881 9.63854 21.1283 9.54136 21.1283 9.44003C21.1283 9.33871 21.0881 9.24153 21.0164 9.16988C20.9448 9.09822 20.8476 9.05797 20.7463 9.05797H16.4162ZM16.4162 11.3503C16.3149 11.3503 16.2177 11.3906 16.146 11.4623C16.0744 11.5339 16.0341 11.6311 16.0341 11.7324C16.0341 11.8337 16.0744 11.9309 16.146 12.0026C16.2177 12.0742 16.3149 12.1145 16.4162 12.1145H20.7463C20.8476 12.1145 20.9448 12.0742 21.0164 12.0026C21.0881 11.9309 21.1283 11.8337 21.1283 11.7324C21.1283 11.6311 21.0881 11.5339 21.0164 11.4623C20.9448 11.3906 20.8476 11.3503 20.7463 11.3503H16.4162Z"
        fill="white"
      />
    </svg>
  );
};
