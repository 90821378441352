export const SpeedIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12.468"
      className={className}
    >
      <path
        id="Path_25"
        data-name="Path 25"
        d="M27,978.206a7.992,7.992,0,0,0-6.629,12.468l.806-.637a6.956,6.956,0,0,1-1.129-3.315H22.1V985.69H20.048a6.93,6.93,0,0,1,1.677-4.032l1.081,1.081.363.363.726-.734L23.532,982l-1.081-1.081a6.937,6.937,0,0,1,4.032-1.669V981.3h1.032v-2.048a6.937,6.937,0,0,1,4.032,1.669L30.468,982l-.363.363.726.734.363-.363,1.081-1.081a6.93,6.93,0,0,1,1.677,4.032H31.9v1.032h2.048a6.956,6.956,0,0,1-1.129,3.315l.806.637A7.992,7.992,0,0,0,27,978.206Zm3.226,4.992-3.581,4.347a1.3,1.3,0,1,0,1.6.927Z"
        transform="translate(-19 -978.206)"
        fill="currentColor"
      />
    </svg>
  );
};
