import React from 'react';

export const CheckBadgeIconV2 = ({ fill = '#299C79', className = '' }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54515 1.57463C7.36375 0.808458 8.63625 0.808458 9.45485 1.57463L10.1637 2.23807C10.3635 2.42506 10.6244 2.53313 10.8979 2.54218L11.8683 2.57428C12.9889 2.61136 13.8887 3.51115 13.9257 4.63175L13.9578 5.60213C13.9669 5.8756 14.075 6.1365 14.2619 6.3363L14.9254 7.04515C15.6916 7.86375 15.6916 9.13625 14.9254 9.95485L14.2619 10.6637C14.075 10.8635 13.9669 11.1244 13.9578 11.3979L13.9257 12.3683C13.8887 13.4889 12.9889 14.3887 11.8683 14.4257L10.8979 14.4578C10.6244 14.4669 10.3635 14.575 10.1637 14.7619L9.45485 15.4254C8.63625 16.1916 7.36375 16.1916 6.54515 15.4254L5.8363 14.7619C5.6365 14.575 5.3756 14.4669 5.10213 14.4578L4.13175 14.4257C3.01115 14.3887 2.11136 13.4889 2.07428 12.3683L2.04218 11.3979C2.03313 11.1244 1.92506 10.8635 1.73807 10.6637L1.07463 9.95485C0.308458 9.13625 0.308458 7.86375 1.07463 7.04515L1.73807 6.3363C1.92506 6.1365 2.03313 5.8756 2.04218 5.60213L2.07428 4.63175C2.11136 3.51115 3.01115 2.61136 4.13175 2.57428L5.10213 2.54218C5.3756 2.53313 5.6365 2.42506 5.8363 2.23807L6.54515 1.57463ZM11.115 6.8417C11.3037 6.64013 11.2933 6.3237 11.0917 6.135C10.8901 5.94628 10.5737 5.9567 10.385 6.1583L7.00533 9.7684L5.615 8.2833C5.4263 8.0817 5.10988 8.07128 4.9083 8.26C4.7067 8.4487 4.69628 8.76513 4.885 8.9667L6.6403 10.8417C6.73485 10.9427 6.867 11 7.00533 11C7.14365 11 7.2758 10.9427 7.37033 10.8417L11.115 6.8417Z"
        fill={fill}
      />
    </svg>
  );
};
