export const BookmarkIconFilledV2 = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.8356 11.6715C17.0773 10.4549 18.3356 8.99655 18.3356 7.08822C18.3356 5.87264 17.8527 4.70685 16.9932 3.84731C16.1336 2.98777 14.9679 2.50488 13.7523 2.50488C12.2856 2.50488 11.2523 2.92155 10.0023 4.17155C8.75228 2.92155 7.71894 2.50488 6.25228 2.50488C5.0367 2.50488 3.87091 2.98777 3.01137 3.84731C2.15183 4.70685 1.66895 5.87264 1.66895 7.08822C1.66895 9.00488 2.91895 10.4632 4.16895 11.6715L10.0023 17.5049L15.8356 11.6715Z"
        fill="#E02E01"
        stroke="#E9E9E9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
