export const VisaIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="15"
      viewBox="0 0 43 15"
      fill="none"
    >
      <rect
        x="0.854004"
        y="0.339844"
        width="42.1226"
        height="14.3217"
        fill="url(#pattern0)"
      />
    </svg>
  );
};
