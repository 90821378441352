const ApplePayIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 100 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="60" rx="8" fill="white" />
      <path
        d="M22.8122 17.081C21.798 18.2812 20.1752 19.2278 18.5524 19.0926C18.3496 17.4698 19.1441 15.7456 20.0738 14.6806C21.088 13.4466 22.8629 12.5676 24.2998 12.5C24.4688 14.1904 23.8096 15.847 22.8122 17.081ZM24.2829 19.4137C21.9332 19.2785 19.9216 20.7491 18.806 20.7491C17.6734 20.7491 15.9661 19.4813 14.1067 19.5152C11.6894 19.549 9.44116 20.9182 8.20717 23.0988C5.67157 27.46 7.54792 33.9174 9.999 37.4672C11.1992 39.2252 12.636 41.1523 14.5293 41.0846C16.3211 41.017 17.0311 39.9183 19.1948 39.9183C21.3754 39.9183 22.0008 41.0846 23.8941 41.0508C25.8549 41.017 27.0889 39.2928 28.2891 37.5348C29.6583 35.5401 30.2162 33.5962 30.25 33.4948C30.2162 33.4609 26.4635 32.0241 26.4297 27.6967C26.3959 24.0792 29.3879 22.355 29.5231 22.2536C27.8327 19.7518 25.1957 19.4813 24.2829 19.4137ZM37.8568 14.5116V40.8649H41.9475V31.8551H47.6104C52.783 31.8551 56.4174 28.3052 56.4174 23.1664C56.4174 18.0276 52.8506 14.5116 47.7456 14.5116H37.8568ZM41.9475 17.96H46.6638C50.2136 17.96 52.2421 19.8532 52.2421 23.1833C52.2421 26.5134 50.2136 28.4236 46.6468 28.4236H41.9475V17.96ZM63.8889 41.0677C66.4583 41.0677 68.8418 39.7661 69.9236 37.7039H70.0082V40.8649H73.7947V27.7474C73.7947 23.944 70.7519 21.4929 66.0695 21.4929C61.7252 21.4929 58.5134 23.9778 58.3951 27.3924H62.0802C62.3845 25.7696 63.8889 24.7047 65.9512 24.7047C68.453 24.7047 69.856 25.8711 69.856 28.0179V29.4716L64.751 29.7759C60.001 30.0632 57.4316 32.0072 57.4316 35.388C57.4316 38.8026 60.0855 41.0677 63.8889 41.0677ZM64.9877 37.9405C62.8071 37.9405 61.4209 36.8925 61.4209 35.2866C61.4209 33.63 62.7564 32.6665 65.3089 32.5143L69.856 32.227V33.7145C69.856 36.1825 67.7599 37.9405 64.9877 37.9405ZM78.8489 48.0322C82.8383 48.0322 84.7146 46.5108 86.3543 41.896L93.5385 21.7465H89.3801L84.5625 37.3151H84.478L79.6603 21.7465H75.3836L82.3143 40.9325L81.9424 42.0989C81.3169 44.0767 80.3027 44.8373 78.494 44.8373C78.1728 44.8373 77.5473 44.8035 77.2938 44.7697V47.9308C77.5304 47.9984 78.5447 48.0322 78.8489 48.0322Z"
        fill="black"
      />
    </svg>
  );
};

export default ApplePayIcon;
