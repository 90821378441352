export const CrossIcon = ({ className = '' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.2202 0.781423C18.1804 -0.260474 16.4985 -0.260474 15.4281 0.781423L10.0153 6.21701L4.57187 0.781423C3.50153 -0.260474 1.81957 -0.260474 0.779816 0.781423C-0.259939 1.82027 -0.259939 3.5313 0.779816 4.56709L6.22324 10.0088L0.779816 15.4169C-0.259939 16.4863 -0.259939 18.1667 0.779816 19.2025C1.29969 19.722 2.00306 20 2.67584 20C3.3792 20 4.05199 19.722 4.57187 19.2025L10.0153 13.7975L15.4587 19.2025C15.9786 19.7556 16.6514 20 17.3242 20C18.0275 20 18.7003 19.722 19.2202 19.2025C20.2599 18.1667 20.2599 16.4863 19.2202 15.4474L13.7768 10.0088L19.2202 4.56709C20.2599 3.5313 20.2599 1.82027 19.2202 0.781423Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};
